import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, putReq } from "../api.services";

export const getProfile = createAsyncThunk("profile/merchants/profile", async () => {
  const response = await getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/merchants/profile`);
  return response;
});

export const uploadProfileImage = createAsyncThunk(
  "profile/merchants/profile_image",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/profile/upload_image`,
      payload,
    );
    return response;
  },
);

export const updateName = createAsyncThunk("profile/merchants/update_name", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/profile/business_name`,
    payload,
  );
  return response;
});

export const updateBusinessRegNumber = createAsyncThunk(
  "profile/merchants/update_business_reg_no",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/profile/business_reg_no`,
      payload,
    );
    return response;
  },
);

export const updateWebsite = createAsyncThunk(
  "profile/merchants/update_website",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/profile/website`,
      payload,
    );
    return response;
  },
);

export const updateContactNo = createAsyncThunk("profile/merchants/contact_no", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/profile/contact_no`,
    payload,
  );
  return response;
});

export const updateEmail = createAsyncThunk("profile/merchants/update_email", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/profile/email`,
    payload,
  );
  return response;
});

export const updatePassword = createAsyncThunk(
  "profile/merchants/update_password",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/profile/password`,
      payload,
    );
    return response;
  },
);

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    getProfileObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    uploadProfileImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateNameObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateBusinessRegNumberObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateWebsiteObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateEmailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateContactNoObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updatePasswordObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getProfile.pending]: (state) => {
      state.getProfileObj.status = "pending";
    },
    [getProfile.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getProfileObj.status = "succeeded";
      state.getProfileObj.data = data;
      state.getProfileObj.successMessage = message;
    },
    [getProfile.rejected]: (state, action) => {
      const { message } = action.error;

      state.getProfileObj.status = "failed";
      state.getProfileObj.errorMessage = message;
    },
    [uploadProfileImage.pending]: (state) => {
      state.uploadProfileImageObj.status = "pending";
    },
    [uploadProfileImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.uploadProfileImageObj.status = "succeeded";
      state.uploadProfileImageObj.data = data;
      state.uploadProfileImageObj.successMessage = message;
      state.getProfileObj.data = data;
    },
    [uploadProfileImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.uploadProfileImageObj.status = "failed";
      state.uploadProfileImageObj.errorMessage = message;
    },
    [updateName.pending]: (state) => {
      state.updateNameObj.status = "pending";
    },
    [updateName.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateNameObj.status = "succeeded";
      state.updateNameObj.data = data;
      state.updateNameObj.successMessage = message;
      state.getProfileObj.data = data;
    },
    [updateName.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateNameObj.status = "failed";
      state.updateNameObj.errorMessage = message;
    },
    [updateBusinessRegNumber.pending]: (state) => {
      state.updateBusinessRegNumberObj.status = "pending";
    },
    [updateBusinessRegNumber.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateBusinessRegNumberObj.status = "succeeded";
      state.updateBusinessRegNumberObj.data = data;
      state.updateBusinessRegNumberObj.successMessage = message;
      state.getProfileObj.data = data;
    },
    [updateBusinessRegNumber.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateBusinessRegNumberObj.status = "failed";
      state.updateBusinessRegNumberObj.errorMessage = message;
    },
    [updateWebsite.pending]: (state) => {
      state.updateWebsiteObj.status = "pending";
    },
    [updateWebsite.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateWebsiteObj.status = "succeeded";
      state.updateWebsiteObj.data = data;
      state.updateWebsiteObj.successMessage = message;
      state.getProfileObj.data = data;
    },
    [updateWebsite.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateWebsiteObj.status = "failed";
      state.updateWebsiteObj.errorMessage = message;
    },
    [updateEmail.pending]: (state) => {
      state.updateEmailObj.status = "pending";
    },
    [updateEmail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateEmailObj.status = "succeeded";
      state.updateEmailObj.data = data;
      state.updateEmailObj.successMessage = message;
      state.getProfileObj.data = data;
    },
    [updateEmail.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateEmailObj.status = "failed";
      state.updateEmailObj.errorMessage = message;
    },
    [updateContactNo.pending]: (state) => {
      state.updateContactNoObj.status = "pending";
    },
    [updateContactNo.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateContactNoObj.status = "succeeded";
      state.updateContactNoObj.data = data;
      state.updateContactNoObj.successMessage = message;
      state.getProfileObj.data = data;
    },
    [updateContactNo.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateContactNoObj.status = "failed";
      state.updateContactNoObj.errorMessage = message;
    },
    [updatePassword.pending]: (state) => {
      state.updatePasswordObj.status = "pending";
    },
    [updatePassword.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updatePasswordObj.status = "succeeded";
      state.updatePasswordObj.data = data;
      state.updatePasswordObj.successMessage = message;
      state.getProfileObj.data = data;
    },
    [updatePassword.rejected]: (state, action) => {
      const { message } = action.error;

      state.updatePasswordObj.status = "failed";
      state.updatePasswordObj.errorMessage = message;
    },
  },
});

export default profileSlice.reducer;

export const profileSelector = (state) => state.profile;
