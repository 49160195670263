import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Pagination,
  styled,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-js-loader";
import { v4 as uuidv4 } from "uuid";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import {
  bookingSelector,
  confirmBooking,
  getBookingDetails,
  getBookingList,
  getCancelledBookingDetails,
  getExperiencesList,
  getFlexiBookingDetails,
  getMultipleBookingDetails,
  getRescheduleBookingDetails,
  rescheduleBooking,
} from "../../../services/booking/booking-slice.services";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import SkeletonLoader from "../../../components/skeleton.component";
import BookingDecisionModal from "../components/booking-decision-modal.component";
import Loading from "../../../components/notifications/loading.component";
import BookingDetailsModal from "../components/booking-details-modal.component";
import MultipleBookingCard from "../components/multiple-booking-card.component";
import MultipleBookingListModal from "../components/multiple-booking-list-modal.component";
import FlexiBookingCard from "../components/flexi-booking-card.component";
import FlexiBookingListModal from "../components/flexi-booking-list-modal.component";
import CancelledBookingCard from "../components/cancelled-booking-card.component";
import CancelledBookingListModal from "../components/cancelled-booking-list-modal.component";
import RescheduleBookingCard from "../components/reschedule-booking-card.component";
import RescheduleBookingListModal from "../components/reschedule-booking-list-modal.component";

const SpaceBetweenRowBox = styled(Box)(({ isMobile }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: isMobile ? null : "center",
  flexDirection: isMobile ? "column" : "row",
}));

const CenterBox = styled(Box)({ display: "flex", justifyContent: "center", alignItems: "center" });

const StyledMenu = styled((props) => <Menu elevation={0} {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 20,
        color: theme.palette.colors.brand.secondary,
      },
    },
  },
  " .MuiMenuItem-root": {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  " .MuiCheckbox-root": {
    paddingLeft: "0px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const FIELDHEIGHT = "40px";

const FilterButton = styled(Button)(({ theme }) => ({
  width: "160px",
  textTransform: "none",
  border: `1px solid ${theme.palette.colors.brand.secondary}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  height: FIELDHEIGHT,
  ":hover": {
    backgroundColor: "transparent",
  },
}));

const AntTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.colors.brand.secondary,
    height: "3px",
  },
  borderBottom: `1px solid ${theme.palette.colors.ui.border}`,
  width: "100%",
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.colors.text.secondary,
  "&.Mui-selected": {
    color: theme.palette.colors.brand.secondary,
  },
}));

function BookingScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("700"));
  const isOneRow = useMediaQuery(theme.breakpoints.down("1024"));
  const [showMenu, setShowMenu] = useState(null);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getExperiencesListObj, getBookingListObj, confirmBookingObj, rescheduleBookingObj } =
    useSelector(bookingSelector);
  const [currentTab, setCurrentTab] = useState(0);
  const [currentTabName, setCurrentTabName] = useState("multiple");
  const [currentExperiences, setCurrentExperiences] = useState([]);
  const [page, setPage] = useState(1);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showBookingDetails, setShowBookingDetails] = useState(false);
  const [showMultipleBookingList, setShowMultipleBookingList] = useState(false);
  const [showFlexiBookingList, setShowFlexiBookingList] = useState(false);
  const [showCancelledBookingList, setShowCancelledBookingList] = useState(false);
  const [showRescheduleBookingList, setShowRescheduleBookingList] = useState(false);
  const [currentExperienceId, setCurrentExperienceId] = useState(null);

  const viewRescheduleBookingDetails = (experienceId) => {
    setShowRescheduleBookingList(true);
    dispatch(getRescheduleBookingDetails(experienceId)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const viewMultipleBookingDetails = (experienceId) => {
    setCurrentExperienceId(experienceId);
    setShowMultipleBookingList(true);
    dispatch(getMultipleBookingDetails(experienceId)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const ViewFlexiBookingDetails = (experienceId) => {
    setShowFlexiBookingList(true);
    dispatch(getFlexiBookingDetails(experienceId)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const ViewCancelledBookingDetails = (experienceId) => {
    setShowCancelledBookingList(true);
    dispatch(getCancelledBookingDetails(experienceId)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onClickExperienceCardButton = (bookingId, isConfirm) => {
    dispatch(getBookingDetails(bookingId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
    if (isConfirm === "Confirm") {
      setShowConfirmModal(true);
    } else if (isConfirm === "Reschedule") {
      setShowRescheduleModal(true);
    } else {
      setShowBookingDetails(true);
    }
  };

  const onConfirmBooking = (bookingId) => {
    setIsLoading(true);
    dispatch(confirmBooking(bookingId)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        setShowConfirmModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        if (currentTabName === "multiple") {
          dispatch(getMultipleBookingDetails(currentExperienceId));
        }
        dispatch(getBookingList({ page, type: currentTabName, experience: currentExperiences }));
      }
    });
  };

  const onRescheduleBooking = (bookingId) => {
    setIsLoading(true);
    dispatch(rescheduleBooking(bookingId)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
      if (meta.requestStatus === "fulfilled") {
        setShowRescheduleModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        if (currentTabName === "multiple") {
          dispatch(getMultipleBookingDetails(currentExperienceId));
        }

        dispatch(getBookingList({ page, type: currentTabName, experience: currentExperiences }));
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getBookingList({ page: 1, type: currentTabName, experience: currentExperiences }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getExperiencesList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const loadDataOnTabChange = (tabName) => {
    dispatch(getBookingList({ page: 1, type: tabName, experience: currentExperiences })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const loadDataOnExperiencesChange = (experienceName) => {
    dispatch(getBookingList({ page: 1, type: currentTabName, experience: experienceName })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
    dispatch(
      getBookingList({ page: value, type: currentTabName, experience: currentExperiences }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const handleCheckboxChange = (data) => {
    const isChecked = currentExperiences.some((checkedCheckbox) => checkedCheckbox.id === data.id);
    let experienceParams = currentExperiences.some(
      (checkedCheckbox) => checkedCheckbox.id === data.id,
    );
    if (isChecked) {
      setCurrentExperiences(
        currentExperiences.filter((checkedCheckbox) => checkedCheckbox.id !== data.id),
      );
      experienceParams = currentExperiences.filter(
        (checkedCheckbox) => checkedCheckbox.id !== data.id,
      );
    } else {
      setCurrentExperiences(currentExperiences.concat(data));
      experienceParams = currentExperiences.concat(data);
    }
    loadDataOnExperiencesChange(experienceParams);
  };

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
    if (newValue === 0) {
      setCurrentTabName("multiple");
      loadDataOnTabChange("multiple");
    } else if (newValue === 1) {
      setCurrentTabName("flexi");
      loadDataOnTabChange("flexi");
    } else if (newValue === 3) {
      setCurrentTabName("cancelled");
      loadDataOnTabChange("cancelled");
    } else if (newValue === 2) {
      setCurrentTabName("reschedule");
      loadDataOnTabChange("reschedule");
    }
  };

  const renderFilterButtonList = () => {
    if (getExperiencesListObj.status === "succeeded") {
      return (
        <>
          <FilterButton disableRipple onClick={handleOpenMenu}>
            <CenterRowBox>
              <Text sx={{ paddingLeft: "5px", paddingRight: "15px" }}>Experiences</Text>
              <Spacer size="s" position="left" />
              {showMenu ? (
                <KeyboardArrowUpIcon sx={{ color: theme.palette.colors.brand.secondary }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ color: theme.palette.colors.brand.secondary }} />
              )}
            </CenterRowBox>
          </FilterButton>
          <StyledMenu
            sx={{ mt: "40px", height: "400px" }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            anchorEl={showMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={Boolean(showMenu)}
            onClose={handleCloseMenu}
          >
            <FormControl sx={{ maxWidth: "350px" }}>
              {getExperiencesListObj.data.map(
                (item) =>
                  item.title !== null && (
                    <MenuItem key={uuidv4()} value={item.label} disableRipple>
                      <Checkbox
                        disableRipple
                        onChange={() => handleCheckboxChange(item)}
                        checked={currentExperiences.some(
                          (checkedCheckbox) => checkedCheckbox.id === item.id,
                        )}
                        sx={{
                          color: theme.palette.colors.brand.secondary,
                          "&.Mui-checked": {
                            color: theme.palette.colors.brand.secondary,
                          },
                        }}
                      />
                      <ListItemText
                        primary={
                          <Text
                            noWrap
                            sx={{
                              color: theme.palette.colors.text.primary,
                              fontSize: theme.fonts.fontSizes.size16,
                            }}
                          >
                            {item.title}
                          </Text>
                        }
                      />
                    </MenuItem>
                  ),
              )}
            </FormControl>
          </StyledMenu>
        </>
      );
    }
    return (
      <SkeletonLoader
        height={FIELDHEIGHT}
        width="200px"
        borderRadius={theme.shape.borderRadius[0]}
      />
    );
  };

  const renderBookingList = () => {
    if (getBookingListObj.status === "succeeded") {
      if (getBookingListObj.data.items.length <= 0) {
        return (
          <Grid item xs={12}>
            <Spacer size="xxxl" position="top" />
            <CenterBox>
              <Text>No Items</Text>
            </CenterBox>
          </Grid>
        );
      }
      return (
        <>
          <Grid item xs={12}>
            <Grid container rowSpacing={4} spacing={3} sx={{ justifyContent: "space-evenly" }}>
              {getBookingListObj.data.items.map((item) => (
                <Grid item xs={isOneRow ? 12 : 6} key={uuidv4()}>
                  {currentTabName === "multiple" && (
                    <MultipleBookingCard
                      cardDetails={item}
                      viewMultipleBookingDetails={viewMultipleBookingDetails}
                    />
                  )}
                  {currentTabName === "flexi" && (
                    <FlexiBookingCard
                      cardDetails={item}
                      viewMultipleBookingDetails={ViewFlexiBookingDetails}
                    />
                  )}
                  {currentTabName === "cancelled" && (
                    <CancelledBookingCard
                      cardDetails={item}
                      viewCancelledBookingDetails={ViewCancelledBookingDetails}
                    />
                  )}
                  {currentTabName === "reschedule" && (
                    <RescheduleBookingCard
                      cardDetails={item}
                      viewRescheduleBookingDetails={viewRescheduleBookingDetails}
                    />
                  )}
                </Grid>
              ))}
              {getBookingListObj.data.items.length % 2 !== 0 && (
                <Grid item xs={isOneRow ? 12 : 6}>
                  <Box sx={{ width: "100%" }} />
                </Grid>
              )}
            </Grid>
          </Grid>
          {getBookingListObj.data.pagination.totalPages >= 2 && (
            <Grid item xs={12}>
              <Grid container justifyContent="center" paddingTop="50px">
                <Pagination
                  page={page}
                  onChange={handlePageChange}
                  count={getBookingListObj.data.pagination.totalPages}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          )}
        </>
      );
    }
    return (
      <Grid item xs={12}>
        <Spacer size="xxxl" position="top" />
        <CenterBox>
          <Loader type="bubble-top" bgColor={theme.palette.colors.brand.secondary} size={40} />
        </CenterBox>
      </Grid>
    );
  };

  return (
    <Box sx={{ padding: theme.padding.paddingX[0], width: "100%" }}>
      <Loading isLoading={isLoading} />
      <FlexiBookingListModal
        setShowModal={setShowFlexiBookingList}
        showModal={showFlexiBookingList}
        onClickExperienceCardButton={onClickExperienceCardButton}
      />
      <MultipleBookingListModal
        setShowModal={setShowMultipleBookingList}
        showModal={showMultipleBookingList}
        onClickExperienceCardButton={onClickExperienceCardButton}
      />
      <RescheduleBookingListModal
        setShowModal={setShowRescheduleBookingList}
        showModal={showRescheduleBookingList}
        onClickExperienceCardButton={onClickExperienceCardButton}
      />
      <CancelledBookingListModal
        setShowModal={setShowCancelledBookingList}
        showModal={showCancelledBookingList}
        onClickExperienceCardButton={onClickExperienceCardButton}
      />

      <BookingDecisionModal
        showModal={showConfirmModal}
        setShowModal={setShowConfirmModal}
        title="Confirm this booking?"
        label="After confirmed, you and your guest will not be able to cancel and edit this booking."
        onConfirmHandle={onConfirmBooking}
        isLoading={confirmBookingObj.status === "pending"}
      />
      <BookingDecisionModal
        showModal={showRescheduleModal}
        setShowModal={setShowRescheduleModal}
        title="Reschedule this booking?"
        label="Guest can reselect the date and time or cancel this booking."
        onConfirmHandle={onRescheduleBooking}
        isLoading={rescheduleBookingObj.status === "pending"}
      />
      <BookingDetailsModal showModal={showBookingDetails} setShowModal={setShowBookingDetails} />
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <SpaceBetweenRowBox isMobile={isMobile}>
            <Text
              variant={isMobile ? "h5" : "h4"}
              sx={{ fontWeight: theme.fonts.fontWeights.bold }}
            >
              Bookings
            </Text>
            {isMobile && <Spacer position="top" size="m" />}
            {renderFilterButtonList()}
          </SpaceBetweenRowBox>
        </Grid>
        <Grid item xs={12}>
          <AntTabs
            variant={isMobile ? "scrollable" : null}
            value={currentTab}
            onChange={handleChangeTab}
          >
            <AntTab label={<Text>Standard Booking</Text>} />
            <AntTab label={<Text>Flexi Booking</Text>} />
            <AntTab label={<Text>Rescheduled Booking</Text>} />
            <AntTab label={<Text>Cancelled Booking</Text>} />
          </AntTabs>
        </Grid>

        {renderBookingList()}
      </Grid>
    </Box>
  );
}

export default BookingScreen;
