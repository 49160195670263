import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../api.services";

export const getCalendarDetails = createAsyncThunk("auth/merchants/calendar", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/calendar?startDate=${payload.startDate}&endDate=${payload.endDate}&experience=${payload.experience}`,
  );
  return response;
});

const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    getCalendarDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getCalendarDetails.pending]: (state) => {
      state.getCalendarDetailsObj.status = "pending";
    },
    [getCalendarDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCalendarDetailsObj.status = "succeeded";
      state.getCalendarDetailsObj.data = data;
      state.getCalendarDetailsObj.successMessage = message;
    },
    [getCalendarDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getCalendarDetailsObj.status = "failed";
      state.getCalendarDetailsObj.errorMessage = message;
    },
  },
});

export default calendarSlice.reducer;

// state
export const calendarSelector = (state) => state.calendar;
