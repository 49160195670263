import React from "react";
import { Box, Grid, styled } from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import HTMLRenderer from "react-html-renderer";
import Text from "../../../../components/text.component";
import TextButton from "../../../../components/buttons/text-button.component";
import Spacer from "../../../../components/spacer.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import { experienceSelector } from "../../../../services/experience/experience-slice.services";
import FormTextEditor from "../../../../components/forms/form-text-editor.component";

const SeperateLine = styled(Box)(({ theme }) => ({
  marginTop: "25px",
  marginBottom: "25px",
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

function DescriptionEditForm({ isEditing, setIsEditing }) {
  const { values, resetForm } = useFormikContext();
  const { updateExperienceObj } = useSelector(experienceSelector);
  const toggleEdit = () => {
    setIsEditing((i) => ({ ...i, description: true }));
  };

  const onCancelEdit = () => {
    resetForm();
    setIsEditing((i) => ({ ...i, description: false }));
  };

  if (isEditing.description) {
    return (
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Text>Experience Description</Text>
          <TextButton label="Cancel" onClickButton={onCancelEdit} />
        </SpaceBetweenBox>
        <Spacer size="m" position="top" />
        <FormTextEditor name="description" placeholder="Description" />
        <Spacer size="m" position="top" />
        <FormSubmitButton width="100px" isLoading={updateExperienceObj.status === "pending"}>
          <Text type="WhiteColor">Save</Text>
        </FormSubmitButton>
        <SeperateLine />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <SpaceBetweenBox>
        <Text>Experience Description</Text>
        <TextButton label="Edit" onClickButton={toggleEdit} isDisabled={true} />
      </SpaceBetweenBox>
      <Spacer size="xs" position="top" />
      <HTMLRenderer html={values.description || ""} />
      <SeperateLine />
    </Grid>
  );
}

DescriptionEditForm.propTypes = {
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.shape({ description: PropTypes.bool }).isRequired,
};

export default DescriptionEditForm;
