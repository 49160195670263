import React from "react";
import { Box, Checkbox, FormControl, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useFormikContext } from "formik";
import Text from "../../../../components/text.component";
import Spacer from "../../../../components/spacer.component";
import { experienceSelector } from "../../../../services/experience/experience-slice.services";
import { categorySelector } from "../../../../services/category/category-slice.services";
import SkeletonLoader from "../../../../components/skeleton.component";
import PCBottomBar from "../../components/pc-bottom-bar.component";
import routes from "../../../../navigation/routes";

const ContentContainer = styled(Box)(({ isMobile }) => ({
  width: "100%",
  overflowY: "scroll",
  height: isMobile ? null : "85%",
  display: "flex",
  flexDirection: "column",
  alignItems: isMobile ? null : "center",
}));

const CategoriesCard = styled(Box)(({ theme, isMobile }) => ({
  display: "flex",
  flexDirection: "row",
  backgroundColor: theme.palette.colors.bg.secondary,
  alignItems: "center",
  paddingLeft: theme.padding.paddingX[2],
  paddingRight: theme.padding.paddingX[2],
  width: isMobile ? "100%" : null,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  " .MuiMenuItem-root": {
    padding: "0px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  " .MuiCheckbox-root": {
    paddingLeft: "0px",
  },
}));

function SelectCategoryForm({ handleSubmitCategory }) {
  const theme = useTheme();
  const TitleText = "What's Your Experience Categories?";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { updateExperienceCategoryObj, getExperiencesDetailsObj } =
    useSelector(experienceSelector);
  const { getCategoriesObj } = useSelector(categorySelector);
  const { values, setFieldValue } = useFormikContext();
  const history = useHistory();

  const handleCheckbox = (data) => {
    const isChecked = values.categories.some((checkedCheckbox) => checkedCheckbox.id === data.id);
    if (isChecked) {
      setFieldValue(
        "categories",
        values.categories.filter((checkedCheckbox) => checkedCheckbox.id !== data.id),
      );
    } else {
      setFieldValue("categories", values.categories.concat(data));
    }
  };

  const renderCategoryList = () => {
    if (
      getCategoriesObj.status === "succeeded" &&
      getExperiencesDetailsObj.status === "succeeded"
    ) {
      return (
        <FormControl>
          <Grid container spacing={3} justifyContent="center">
            {getCategoriesObj.data.map((item) => (
              <Grid item key={item.id} width={isMobile ? "100%" : null}>
                <CategoriesCard
                  isMobile
                  sx={{
                    border: `1px solid ${
                      values.categories.some((checkedCheckbox) => checkedCheckbox.id === item.id)
                        ? theme.palette.colors.brand.secondary
                        : theme.palette.colors.ui.border
                    }`,
                  }}
                >
                  <Checkbox
                    disableRipple
                    sx={{
                      color: theme.palette.colors.brand.secondary,
                      "&.Mui-checked": {
                        color: theme.palette.colors.brand.secondary,
                      },
                      ":hover": { backgroundColor: "transparent" },
                    }}
                    onChange={() => handleCheckbox(item)}
                    checked={values.categories.some(
                      (checkedCheckbox) => checkedCheckbox.id === item.id,
                    )}
                  />
                  <Text>{item.label}</Text>
                </CategoriesCard>
              </Grid>
            ))}
          </Grid>
        </FormControl>
      );
    }
    return (
      <Grid container spacing={3} justifyContent="center">
        <Grid item width={isMobile ? "100%" : null}>
          <CategoriesCard sx={{ paddingY: theme.padding.paddingX[3] }}>
            <SkeletonLoader height="30px" width="200px" />
          </CategoriesCard>
        </Grid>
        <Grid item width={isMobile ? "100%" : null}>
          <CategoriesCard sx={{ paddingY: theme.padding.paddingX[3] }}>
            <SkeletonLoader height="30px" width="200px" />
          </CategoriesCard>
        </Grid>
        <Grid item width={isMobile ? "100%" : null}>
          <CategoriesCard sx={{ paddingY: theme.padding.paddingX[3] }}>
            <SkeletonLoader height="30px" width="200px" />
          </CategoriesCard>
        </Grid>
        <Grid item width={isMobile ? "100%" : null}>
          <CategoriesCard sx={{ paddingY: theme.padding.paddingX[3] }}>
            <SkeletonLoader height="30px" width="200px" />
          </CategoriesCard>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      sx={{
        width: "100%",
        padding: theme.padding.paddingX[0],
        height: isMobile ? null : theme.dimensions.heightWithoutAppBar,
      }}
    >
      <ContentContainer isMobile={isMobile}>
        <Text
          variant={isMobile ? "h5" : "h3"}
          sx={{
            fontWeight: theme.fonts.fontWeights.bold,
            textAlign: isMobile ? "start" : "center",
          }}
        >
          {TitleText}
        </Text>
        <Spacer size={isMobile ? "xl" : "xxl"} position="top" />
        <Box sx={{ width: "100%" }}>{renderCategoryList()}</Box>
      </ContentContainer>
      {!isMobile && (
        <PCBottomBar
          isLoading={updateExperienceCategoryObj.status === "pending"}
          handleExit={handleSubmitCategory}
          onBackPress={() => history.push(routes.CREATEEXPERIENCES)}
        />
      )}
    </Grid>
  );
}

SelectCategoryForm.propTypes = {
  handleSubmitCategory: PropTypes.func.isRequired,
};

export default SelectCategoryForm;
