import React from "react";
import { Box, Grid, styled } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import YoloLogo from "../../../assets/images/yolo_logo";
import Text from "../../../components/text.component";
import AvatarMenu from "./avatar-with-menu.component";
import routes from "../../../navigation/routes";

const AppBarContainer = styled(Box)({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  width: "100%",

  height: "100%",
});

const MaxWidthBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  maxWidth: theme.dimensions.PCMaxWidth,
  paddingLeft: theme.padding.paddingX[0],
  paddingRight: theme.padding.paddingX[0],
}));

function PCViewAppBar({ handleLogout }) {
  return (
    <AppBarContainer>
      <MaxWidthBox>
        <YoloLogo />
        <Grid
          container
          spacing={5}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item>
            <Link style={{ textDecoration: "none" }} to={{ pathname: routes.HOME }}>
              <Text variant="h6">Home</Text>
            </Link>
          </Grid>
          <Grid item>
            <Link style={{ textDecoration: "none" }} to={{ pathname: routes.EXPERIENCES }}>
              <Text variant="h6">Experiences</Text>
            </Link>
          </Grid>
          <Grid item>
            <Link style={{ textDecoration: "none" }} to={{ pathname: routes.BOOKINGS }}>
              <Text variant="h6">Bookings</Text>
            </Link>
          </Grid>
          <Grid item>
            <Link style={{ textDecoration: "none" }} to={{ pathname: routes.CALENDAR }}>
              <Text variant="h6">Calendar</Text>
            </Link>
          </Grid>
        </Grid>

        <AvatarMenu handleLogout={handleLogout} />
      </MaxWidthBox>
    </AppBarContainer>
  );
}

PCViewAppBar.propTypes = {
  handleLogout: PropTypes.func.isRequired,
};

export default PCViewAppBar;
