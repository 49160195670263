import React from "react";
import { Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useHistory, useLocation } from "react-router-dom";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ProfileSideMenuButton from "./profile-side-menu-button.component";
import routes from "../../../navigation/routes";

const StyledText = styled(Typography)(({ theme }) => ({
  paddingLeft: "5px",
  fontSize: theme.fonts.fontSizes.label,
}));

const StyledIcon = styled(Typography)(({ theme, isSelected }) => ({
  paddingTop: "5px",
  height: "100%",
  color: isSelected ? theme.palette.colors.text.primary : theme.palette.colors.text.secondary,
}));

function ProfileSideMenuButtonList() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const location = useLocation();

  const pushToUserProfile = () => {
    history.push(routes.PROFILE);
  };

  const pushToSalesTracking = () => {
    history.push(routes.SALESTRACKING);
  };

  return (
    <Grid container spacing={2}>
      <ProfileSideMenuButton
        label={
          <StyledText
            sx={{
              color:
                isMobile || location.pathname === routes.PROFILE
                  ? theme.palette.colors.text.primary
                  : theme.palette.colors.text.secondary,
            }}
          >
            Profile
          </StyledText>
        }
        icon={
          <StyledIcon
            sx={{
              color:
                isMobile || location.pathname === routes.PROFILE
                  ? theme.palette.colors.text.primary
                  : theme.palette.colors.text.secondary,
            }}
          >
            <PersonIcon />
          </StyledIcon>
        }
        handleSubmit={pushToUserProfile}
      />
      <ProfileSideMenuButton
        label={
          <StyledText
            sx={{
              color:
                isMobile || location.pathname.includes(routes.SALESTRACKING)
                  ? theme.palette.colors.text.primary
                  : theme.palette.colors.text.secondary,
            }}
          >
            Sales Tracking
          </StyledText>
        }
        icon={
          <StyledIcon
            sx={{
              color:
                isMobile || location.pathname.includes(routes.SALESTRACKING)
                  ? theme.palette.colors.text.primary
                  : theme.palette.colors.text.secondary,
            }}
          >
            <MonetizationOnIcon />
          </StyledIcon>
        }
        handleSubmit={pushToSalesTracking}
      />
    </Grid>
  );
}

export default ProfileSideMenuButtonList;
