import React, { useContext, useEffect } from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SideProgressView from "../../components/side-progress-view.component";
import CreateExperiences from "../components/pc-view/create-experiences.component";
import MobileCreateExperiences from "../components/mobile-view/mobile-create-experiences.component";
import MobileBottomBar from "../../components/mobile-bottom-bar.component";
import routes from "../../../../navigation/routes";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import {
  createExperience,
  experienceSelector,
  resetExperienceDetailsObj,
} from "../../../../services/experience/experience-slice.services";
import CreateExpBg from "../../../../assets/images/mobile-create-exp-bg.png";

const BackgroundImage = styled(Box)({
  backgroundImage: `url(${CreateExpBg})`,
  minHeight: "200px",
  opacity: 1,
  width: "100%",
  backgroundSize: "cover",
});

function CreateExperienceScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { createExperienceObj } = useSelector(experienceSelector);

  useEffect(() => {
    dispatch(resetExperienceDetailsObj());
  }, []);

  const handleCreateExperience = () => {
    dispatch(createExperience()).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        history.push({
          pathname: `${routes.SELECT_CATEGORY}`,
          search: `experienceId=${payload.data.experienceId}`,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <Grid container>
      {isMobile ? (
        <Grid item xs={12}>
          <BackgroundImage />
        </Grid>
      ) : (
        <Grid item xs={3}>
          <SideProgressView />
        </Grid>
      )}
      {isMobile ? (
        <Grid item xs={12}>
          <MobileCreateExperiences />
        </Grid>
      ) : (
        <Grid item xs={9}>
          <CreateExperiences createExperience={handleCreateExperience} />
        </Grid>
      )}
      {isMobile && (
        <Grid item xs={12}>
          <MobileBottomBar
            borderButtonLabel="Exit"
            backgroundButtonLabel="Let's Go"
            borderButtonOnPress={() => history.push(routes.HOME)}
            backgroundButtonPress={handleCreateExperience}
            isBackgroundButtonLoading={createExperienceObj.status === "pending"}
            isForm={false}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default CreateExperienceScreen;
