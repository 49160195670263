import React from "react";
import { Box, Grid, IconButton, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import Text from "../../../components/text.component";
import { bookingSelector } from "../../../services/booking/booking-slice.services";
import Spacer from "../../../components/spacer.component";
import BookingDetailsModalLoader from "../loader/booking-details-modal-loader.component";

const ModalBox = styled(Box)(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: isMobile ? theme.padding.paddingX[1] : theme.padding.paddingX[0],
  width: isMobile ? "350px" : "450px",
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "0.5px",
  backgroundColor: theme.palette.colors.ui.border,
}));

const StatusTimeContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.secondary,
  padding: theme.padding.paddingX[2],
  borderRadius: theme.shape.borderRadius[0],
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const StatusBox = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  marginTop: "5px",
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export default function BookingDetailsModal({ showModal, setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getBookingDetailsObj } = useSelector(bookingSelector);

  const renderStatusBox = () => {
    if (getBookingDetailsObj.data.status === "pending") {
      return (
        <StatusBox sx={{ border: `1px solid ${theme.palette.colors.button.pendingBooking}` }}>
          <Text
            sx={{
              paddingX: theme.padding.paddingX[3],
              color: theme.palette.colors.button.pendingBooking,
            }}
          >
            Pending
          </Text>
        </StatusBox>
      );
    }
    if (getBookingDetailsObj.data.status === "confirmed") {
      return (
        <StatusBox sx={{ border: `1px solid ${theme.palette.colors.button.confirmedBooking}` }}>
          <Text
            sx={{
              paddingX: theme.padding.paddingX[3],
              color: theme.palette.colors.button.confirmedBooking,
            }}
          >
            Confirmed
          </Text>
        </StatusBox>
      );
    }
    if (getBookingDetailsObj.data.status === "completed") {
      return (
        <StatusBox sx={{ border: `1px solid ${theme.palette.colors.brand.primary}` }}>
          <Text
            sx={{
              paddingX: theme.padding.paddingX[3],
              color: theme.palette.colors.brand.primary,
            }}
          >
            Completed
          </Text>
        </StatusBox>
      );
    }
    if (getBookingDetailsObj.data.status === "reschedule") {
      return (
        <StatusBox sx={{ border: `1px solid ${theme.palette.colors.button.rescheduleBooking}` }}>
          <Text
            sx={{
              paddingX: theme.padding.paddingX[3],
              color: theme.palette.colors.button.rescheduleBooking,
            }}
          >
            Reschedule
          </Text>
        </StatusBox>
      );
    }
    return (
      <StatusBox sx={{ border: `1px solid ${theme.palette.colors.button.cancelledBooking}` }}>
        <Text
          sx={{
            paddingX: theme.padding.paddingX[3],
            color: theme.palette.colors.button.cancelledBooking,
          }}
        >
          Cancelled
        </Text>
      </StatusBox>
    );
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const renderStatusDetails = () => {
    if (
      getBookingDetailsObj.data.status === "pending" ||
      getBookingDetailsObj.data.status === "confirmed" ||
      getBookingDetailsObj.data.status === "reschedule"
    ) {
      return (
        <Grid item xs={12}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Text>Message</Text>
            <Spacer size="xs" position="top" />
            <Text variant="body2">
              {getBookingDetailsObj.data.notes ? getBookingDetailsObj.data.notes : "No Message"}
            </Text>
          </Box>
        </Grid>
      );
    }
    if (getBookingDetailsObj.data.status === "completed") {
      return (
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Text type="BrandColor">
              {getBookingDetailsObj.data.rated ? "Guest Rated!" : "Guest Not Rated Yet!"}
            </Text>
          </Grid>
        </Grid>
      );
    }
    if (getBookingDetailsObj.data.status === "cancelled") {
      return (
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Text sx={{ color: theme.palette.colors.button.cancelledBooking }}>
              {`Cancelled on ${getBookingDetailsObj.data.cancelledAt}`}
            </Text>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (getBookingDetailsObj.status === "succeeded") {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Box />
              <Text variant="h5" textAlign="center">
                Booking Details
              </Text>
              <CloseIconButton onClick={handleCancel}>
                <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
              </CloseIconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SeperateLine />
          </Grid>
          <Grid item xs={12}>
            <StatusTimeContainer>
              <Text variant="h6" sx={{ fontWeight: theme.fonts.fontWeights.regular }}>
                {getBookingDetailsObj.data.slot.startAt
                  ? getBookingDetailsObj.data.slot.startAt
                  : "No Time Selected"}
              </Text>
              {renderStatusBox()}
            </StatusTimeContainer>
          </Grid>
          <Grid item xs={12}>
            <SpaceBetweenRowBox>
              <Text sx={{ width: "120px" }}>Experience</Text>
              <Text sx={{ textAlign: "end" }}>{getBookingDetailsObj.data.experience.title}</Text>
            </SpaceBetweenRowBox>
            <Spacer position="top" size="s" />
            <Spacer position="top" size="s" />
            <SpaceBetweenRowBox>
              <Text>Price</Text>
              <Text>{`RM${getBookingDetailsObj.data.price}`}</Text>
            </SpaceBetweenRowBox>
            <Spacer position="top" size="s" />
            <SpaceBetweenRowBox>
              <Text sx={{ width: "90px" }}>Group of</Text>
              <Text sx={{ textAlign: "end" }}>{getBookingDetailsObj.data.noPax}</Text>
            </SpaceBetweenRowBox>
            <Spacer position="top" size="s" />
            <SpaceBetweenRowBox>
              <Text>Guest</Text>
              <Text>{getBookingDetailsObj.data.guest.name}</Text>
            </SpaceBetweenRowBox>
            <Spacer position="top" size="s" />
            <SpaceBetweenRowBox>
              <Text sx={{ width: "60px" }}>Email</Text>
              <Text sx={{ textAlign: "end" }}>{getBookingDetailsObj.data.guest.email}</Text>
            </SpaceBetweenRowBox>
            <Spacer position="top" size="s" />
            {getBookingDetailsObj.data.guest.phoneNumber && (
              <SpaceBetweenRowBox>
                <Text>Phone No.</Text>
                <Text>{getBookingDetailsObj.data.guest.phoneNumber}</Text>
              </SpaceBetweenRowBox>
            )}
          </Grid>
          <Grid item xs={12}>
            <SeperateLine />
          </Grid>
          {renderStatusDetails()}
        </Grid>
      );
    }
    return <BookingDetailsModalLoader handleCancel={handleCancel} />;
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox isMobile={isMobile}>{renderContent()}</ModalBox>
    </Modal>
  );
}

BookingDetailsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
