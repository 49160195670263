import { Box, Grid, InputAdornment, styled, TextField } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

const FIELDHEIGHT = "45px";

const SearchFieldBox = styled(Box)(({ theme }) => ({
  "& .MuiTextField-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    "& .MuiOutlinedInput-root": {
      width: "100%",
      height: FIELDHEIGHT,
      borderTopLeftRadius: theme.shape.borderRadius[0],
      borderTopRightRadius: theme.shape.borderRadius[0],
      borderBottomLeftRadius: theme.shape.borderRadius[0],
      borderBottomRightRadius: theme.shape.borderRadius[0],
      backgroundColor: theme.palette.colors.bg.white,
    },
  },
}));

function SearchBar({ name, placeholder, width }) {
  const { setFieldValue, values, handleSubmit } = useFormikContext();
  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <Grid item>
      <SearchFieldBox>
        <TextField
          onKeyDown={keyPress}
          value={values[name]}
          placeholder={placeholder}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
          }}
          sx={{ width }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </SearchFieldBox>
    </Grid>
  );
}

SearchBar.defaultProps = {
  width: "100%",
};

SearchBar.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  width: PropTypes.string,
};

export default SearchBar;
