import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { TextField, InputAdornment, Box, FormHelperText } from "@mui/material";
import { DebounceInput } from "react-debounce-input";
import PersonIcon from "@mui/icons-material/Person";
import Spacer from "../../../../components/spacer.component";

function FormDebounceFieldText({
  name,
  placeholder,
  width,
  showIcon,
  errorMessage,
  isDisableEnter,
}) {
  const theme = useTheme();
  const { setFieldValue, errors, touched, values, handleSubmit } = useFormikContext();

  const keyPress = (e) => {
    if (e.keyCode === 9) {
      setFieldValue(name, e.target.value);
      setTimeout(() => {
        handleSubmit();
      }, 50);
    }
    if (e.keyCode === 13 && !isDisableEnter) {
      setFieldValue(name, e.target.value);
      setTimeout(() => {
        handleSubmit();
      }, 50);
    }
  };

  const onChangeText = (e) => {
    setFieldValue(name, e.target.value);
    setTimeout(() => {
      handleSubmit();
    }, 50);
  };

  const showError = touched[name] && typeof errors[name] === "string";

  return (
    <Box
      sx={{
        "& .MuiTextField-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          width,
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: theme.palette.colors.text.inputField,
          },
        },
      }}
    >
      <DebounceInput
        variant="outlined"
        debounceTimeout={500}
        onChange={onChangeText}
        element={TextField}
        value={values.name}
        onKeyDown={keyPress}
        InputProps={{
          placeholder,
          error: showError || errorMessage !== "",
          startAdornment: name === "email" && showIcon && (
            <InputAdornment position="start">
              <PersonIcon />
              <Spacer position="right" />
            </InputAdornment>
          ),
        }}
        placeholder="Experiences ..."
      />
      {(showError || errorMessage) && (
        <FormHelperText
          sx={{ margin: "3px 14px 0px 14px" }}
          error={showError || errorMessage !== ""}
        >
          {errors[name] || errorMessage}
        </FormHelperText>
      )}
    </Box>
  );
}

FormDebounceFieldText.defaultProps = {
  width: "100%",
  showIcon: true,
  errorMessage: "",
  isDisableEnter: false,
};

FormDebounceFieldText.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  width: PropTypes.string,
  showIcon: PropTypes.bool,
  errorMessage: PropTypes.string,
  isDisableEnter: PropTypes.bool,
};

export default FormDebounceFieldText;
