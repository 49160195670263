import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, styled, useTheme } from "@mui/material";

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  "& .MuiButton-root": {
    backgroundColor: "transparent",
    textTransform: "none",
    boxShadow: 0,
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
});
function ProfileSideMenuButton({ icon, label, handleSubmit }) {
  const theme = useTheme();
  return (
    <Grid item xs={12}>
      <Grid container alignItems="center" justifyContent="center">
        <ButtonContainer>
          <Button disableRipple onClick={handleSubmit}>
            {icon}
            {label}
          </Button>
        </ButtonContainer>
        <Box
          sx={{ backgroundColor: theme.palette.colors.ui.border, height: "1px", width: "100%" }}
        />
      </Grid>
    </Grid>
  );
}

ProfileSideMenuButton.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
  icon: PropTypes.shape({}).isRequired,
};

export default ProfileSideMenuButton;
