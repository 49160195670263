import React from "react";
import { Box, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useFormikContext } from "formik";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GoogleMapReact from "google-map-react";
import Text from "../../../../components/text.component";
import Spacer from "../../../../components/spacer.component";

const ContentContainer = styled(Box)(({ isMobile }) => ({
  width: "100%",
  overflowY: "scroll",
  height: isMobile ? null : "85%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  paddingBottom: isMobile ? "100px" : null,
}));

const FormContainer = styled(Box)(({ theme, isMobile }) => ({
  width: "100%",
  backgroundColor: theme.palette.colors.bg.secondary,
  padding: isMobile ? theme.padding.paddingX[2] : theme.padding.paddingX[0],
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const StyledIcon = styled(Box)(({ theme }) => ({
  width: "30px",
  height: "30px",
  borderRadius: "15px",
  color: theme.palette.colors.brand.white,
  background: theme.palette.colors.brand.primary,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
}));

const StyledLocationOnIcon = styled(LocationOnIcon)({
  fontSize: "20px",
});

function AnyReactComponent() {
  return (
    <StyledIcon>
      <StyledLocationOnIcon />
    </StyledIcon>
  );
}

function LocationMap({ setCurrentScreen }) {
  const { values } = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const defaultProps = {
    center: {
      lat: parseFloat(values.locationLat),
      lng: parseFloat(values.locationLong),
    },
    zoom: 17,
  };

  return (
    <ContentContainer isMobile={isMobile}>
      <FormContainer isMobile={isMobile}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <IconButton onClick={() => setCurrentScreen("Address Form")}>
            <ArrowBackIosIcon />
          </IconButton>
          <Text>{values.address}</Text>
        </Box>
        <Spacer position="top" size="m" />
        <Box sx={{ width: "100%", height: isMobile ? "200px" : "350px" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            draggable={false}
            options={{
              panControl: false,
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              overviewMapControl: false,
              rotateControl: false,
              zoomControlOptions: false,
            }}
          >
            <AnyReactComponent
              lat={parseFloat(values.locationLat)}
              lng={parseFloat(values.locationLong)}
            />
          </GoogleMapReact>
        </Box>
      </FormContainer>
    </ContentContainer>
  );
}

LocationMap.propTypes = {
  setCurrentScreen: PropTypes.func.isRequired,
};

export default LocationMap;
