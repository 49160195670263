import React from "react";
import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import Text from "../../../../components/text.component";
import Spacer from "../../../../components/spacer.component";
import FormFieldTextWithCounter from "../../../../components/forms/form-field-text-with-counter.component";
import { experienceSelector } from "../../../../services/experience/experience-slice.services";
import SkeletonLoader from "../../../../components/skeleton.component";

const ContentContainer = styled(Box)(({ isMobile }) => ({
  width: "100%",
  overflowY: "scroll",
  height: isMobile ? null : "85%",
  display: "flex",
  flexDirection: "column",
  alignItems: isMobile ? null : "center",
  paddingBottom: isMobile ? "100px" : null,
}));

function ExperienceTitleForm() {
  const theme = useTheme();
  const TitleText = "Name Your Experience";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getExperiencesDetailsObj } = useSelector(experienceSelector);

  return (
    <ContentContainer isMobile={isMobile}>
      <Text
        variant={isMobile ? "h5" : "h3"}
        sx={{
          fontWeight: theme.fonts.fontWeights.bold,
          textAlign: isMobile ? "start" : "center",
        }}
      >
        {TitleText}
      </Text>
      <Spacer size={isMobile ? "l" : "xl"} position="top" />

      <Box sx={{ width: "100%" }}>
        <Text sx={{ textAlign: "start" }}>Create your title</Text>
        <Spacer size="s" position="top" />
        {getExperiencesDetailsObj.status === "succeeded" ? (
          <FormFieldTextWithCounter
            name="title"
            placeholder="Title"
            maxLength={50}
            height="250px"
          />
        ) : (
          <SkeletonLoader height="250px" />
        )}
      </Box>
    </ContentContainer>
  );
}

export default ExperienceTitleForm;
