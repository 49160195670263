import React from "react";
import { Box, styled, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

const QuantityBox = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  padding: "0px",
  "& .MuiTextField-root": {
    padding: "0px",
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    width: "150px",
    backgroundColor: theme.palette.colors.text.white,
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      borderTopLeftRadius: theme.shape.borderRadius[0],
      borderTopRightRadius: theme.shape.borderRadius[0],
      borderBottomLeftRadius: theme.shape.borderRadius[0],
      borderBottomRightRadius: theme.shape.borderRadius[0],
      " &.Mui-focused fieldset": {
        borderColor: theme.palette.colors.brand.secondary,
      },
    },
  },
}));

function FormFieldQuantity({ name, placeholder }) {
  const { setFieldValue, values, touched, errors, setFieldTouched } = useFormikContext();

  const showError = touched[name] && typeof errors[name] === "string";

  const onBlurHandle = (event) => {
    setFieldTouched(name);
    if (event.target.value === "") {
      setFieldValue(name, 1);
      return;
    }
    setFieldValue(name, event.target.value);
  };

  return (
    <QuantityBox>
      <TextField
        error={showError}
        helperText={showError ? errors[name] : null}
        onBlur={onBlurHandle}
        variant="outlined"
        type="number"
        placeholder={placeholder}
        onChange={(e) => {
          setFieldValue(name, parseInt(e.target.value, 10));
        }}
        value={values[name]}
      />
    </QuantityBox>
  );
}

FormFieldQuantity.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default FormFieldQuantity;
