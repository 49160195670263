import { Box, Grid, IconButton, styled } from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";

const BookingCardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.secondary,
  padding: theme.padding.paddingX[2],
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
});

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

function MultipleBookingTimeSlotCard({
  cardDetails,
  setShowDeleteSlotModal,
  setCancelTimeSlotId,
  setShowEditSlotModal,
  setShowUnableDeleteModal,
  setShowBookedModal,
}) {
  const { setFieldValue } = useFormikContext();

  const setFieldData = () => {
    setFieldValue("startDate", cardDetails.checkIn.date);
    setFieldValue("startTime", new Date(cardDetails.checkIn.datetime.replace(" ", "T")));
    setFieldValue("endDate", cardDetails.checkOut.date);
    setFieldValue("endTime", new Date(cardDetails.checkOut.datetime.replace(" ", "T")));
    setFieldValue("id", cardDetails.massSlot.id);
    setFieldValue("capacity", cardDetails.massSlot.capacity.total);
    setFieldValue("bookingType", "multiple");
    setFieldValue("bookedCapacity", cardDetails.massSlot.capacity.booked);
    if (cardDetails.massSlot.capacity.booked !== 0) {
      setShowBookedModal(true);
    } else {
      setShowEditSlotModal(true);
    }
  };

  return (
    <Grid xs={12} item key={cardDetails.massSlot.id}>
      <BookingCardContainer>
        <SpaceBetweenRowBox>
          <Text type="GreyColor">Check-in</Text>
          <CenterRowBox>
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => {
                setFieldData();
              }}
            >
              <EditIcon />
            </IconButton>
            <Spacer size="xs" position="left" />
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => {
                if (cardDetails.massSlot.capacity.booked !== 0) {
                  setShowUnableDeleteModal(true);
                  return;
                }
                setCancelTimeSlotId({
                  classType: "massSlot",
                  id: cardDetails.massSlot.id,
                });
                setShowDeleteSlotModal(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </CenterRowBox>
        </SpaceBetweenRowBox>
        <Text>{`${cardDetails.checkIn.date}, ${cardDetails.checkIn.time}`}</Text>
        <Text type="GreyColor">Check-out</Text>
        <Text>{`${cardDetails.checkOut.date}, ${cardDetails.checkOut.time}`}</Text>
        <Text type="GreyColor">Capacity</Text>
        <Text>{cardDetails.massSlot.capacity.total}</Text>
      </BookingCardContainer>
    </Grid>
  );
}

MultipleBookingTimeSlotCard.defaultProps = {
  cardDetails: {
    checkIn: {
      date: "",
      time: "",
    },
    checkOut: {
      date: "",
      time: "",
    },
    id: null,
    multipleBooking: true,
    massSlot: {
      capacity: { total: 0, available: 0, booked: 0 },
      id: null,
    },
  },
};

MultipleBookingTimeSlotCard.propTypes = {
  setShowDeleteSlotModal: PropTypes.func.isRequired,
  setCancelTimeSlotId: PropTypes.func.isRequired,
  setShowEditSlotModal: PropTypes.func.isRequired,
  setShowUnableDeleteModal: PropTypes.func.isRequired,
  setShowBookedModal: PropTypes.func.isRequired,
  cardDetails: PropTypes.shape({
    checkIn: PropTypes.shape({
      date: PropTypes.string,
      time: PropTypes.string,
      datetime: PropTypes.string,
    }),
    checkOut: PropTypes.shape({
      date: PropTypes.string,
      time: PropTypes.string,
      datetime: PropTypes.string,
    }),
    id: PropTypes.number,
    multipleBooking: PropTypes.bool,
    massSlot: PropTypes.shape({
      capacity: PropTypes.shape({
        total: PropTypes.number,
        available: PropTypes.number,
        booked: PropTypes.number,
      }),
      id: PropTypes.number,
    }),
  }),
};

export default MultipleBookingTimeSlotCard;
