import React, { useContext, useEffect, useState } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { format } from "date-fns";
import SideProgressView from "../../components/side-progress-view.component";
import SaveAndExitWithBg from "../../components/save-exit-with-bg.component";
import MobileBottomBar from "../../components/mobile-bottom-bar.component";
import routes from "../../../../navigation/routes";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import {
  deleteMassTimeSlot,
  createMassTimeSlot,
  experienceSelector,
  getExperiencesDetails,
} from "../../../../services/experience/experience-slice.services";
import Form from "../../../../components/forms/form.component";
import PCBottomBar from "../../components/pc-bottom-bar.component";
import TimeSlotForm from "../components/time-slot-form.component";
import Loading from "../../../../components/notifications/loading.component";

const validationSchema = Yup.object().shape({
  startDate: Yup.date().label("Start Date").nullable().when("bookingType", {
    is: "multiple",
    then: Yup.date().required(),
  }),
  startTime: Yup.date().label("Check-in Time").nullable().when("bookingType", {
    is: "multiple",
    then: Yup.date().required(),
  }),
  endDate: Yup.date()
    .label("End Date")
    .nullable()
    .when("bookingType", {
      is: "multiple",
      then: Yup.date().min(Yup.ref("startDate"), "End date can't be before start date").required(),
    }),
  endTime: Yup.date().label("Check-out Time").nullable().when("bookingType", {
    is: "multiple",
    then: Yup.date().required(),
  }),
  experienceId: Yup.string().required().label("Experience Id"),
  isExit: Yup.bool(),
  bookingType: Yup.string().required(),
  capacity: Yup.number()
    .nullable()
    .label("Capacity")
    .when("bookingType", {
      is: "multiple",
      then: Yup.number().min(1).max(300).required(),
    })
    .when("bookingType", {
      is: "flexi",
      then: Yup.number().min(1).max(300).required(),
    }),
  flexiTitle: Yup.string()
    .label("Title")
    .when("bookingType", {
      is: "flexi",
      then: Yup.string().min(2).required(),
    }),
});

function CreateAvailableSlotScreen() {
  const location = useLocation();
  const experienceId = new URLSearchParams(location.search).get("experienceId");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { updateExperienceObj, getExperiencesDetailsObj } = useSelector(experienceSelector);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitSlot = (values, { isExit }) => {
    if (isExit) {
      history.push(routes.HOME);
      return;
    }

    let startAt = "";
    let endAt = "";
    if (values.bookingType !== "flexi") {
      startAt = `${values.startDate} ${format(new Date(values.startTime), "HH:mm:ss")}`;
      endAt = `${values.endDate} ${format(new Date(values.endTime), "HH:mm:ss")}`;
    }

    setIsLoading(true);
    dispatch(createMassTimeSlot({ startAt, endAt, ...values })).then(({ meta, error }) => {
      setIsLoading(false);

      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleDeleteTimeSlot = (id) => {
    setIsLoading(true);
    dispatch(deleteMassTimeSlot({ experienceId, id })).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const pushToPreview = () => {
    if (getExperiencesDetailsObj.status === "succeeded") {
      if (getExperiencesDetailsObj.data.slots.length !== 0) {
        history.push({
          pathname: `${routes.CHECK_OUT_PREVIEW}`,
          search: `experienceId=${experienceId}`,
        });
      } else {
        createSnackBar({
          message: "Please create at least one timeslot.",
          type: "error",
          open: true,
        });
      }
    } else {
      createSnackBar({
        message: "Try again later.",
        type: "error",
        open: true,
      });
    }
  };

  useEffect(() => {
    dispatch(getExperiencesDetails(experienceId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  return (
    <Form
      validationSchema={validationSchema}
      initialValues={{
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        experienceId,
        bookingType: "multiple",
        isExit: false,
        capacity: "",
        flexiTitle: "",
      }}
      onSubmit={handleSubmitSlot}
    >
      <Loading isLoading={isLoading} />
      <Grid container>
        {isMobile ? (
          <Grid item xs={12}>
            <SaveAndExitWithBg handleSubmit={handleSubmitSlot} />
          </Grid>
        ) : (
          <Grid item xs={3}>
            <SideProgressView step={8} />
          </Grid>
        )}

        <Grid item xs={isMobile ? 12 : 9}>
          <Grid
            container
            sx={{
              width: "100%",
              padding: theme.padding.paddingX[0],
              height: isMobile ? null : theme.dimensions.heightWithoutAppBar,
            }}
          >
            <TimeSlotForm handleDeleteTimeSlot={handleDeleteTimeSlot} />
            {!isMobile && (
              <PCBottomBar
                isLoading={
                  updateExperienceObj.status === "pending" ||
                  getExperiencesDetailsObj.status === "pending"
                }
                handleExit={handleSubmitSlot}
                onBackPress={() => history.goBack()}
                isForm={false}
                backgroundButtonPress={pushToPreview}
              />
            )}
          </Grid>
        </Grid>

        {isMobile && (
          <Grid item xs={12}>
            <MobileBottomBar
              borderButtonLabel="Back"
              backgroundButtonLabel="Next"
              borderButtonOnPress={() => history.goBack()}
              isBackgroundButtonLoading={
                updateExperienceObj.status === "pending" ||
                getExperiencesDetailsObj.status === "pending"
              }
              isForm={false}
              backgroundButtonPress={pushToPreview}
            />
          </Grid>
        )}
      </Grid>
    </Form>
  );
}

export default CreateAvailableSlotScreen;
