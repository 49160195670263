import React from "react";
import { Box, Grid, styled, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Text from "../../../../../components/text.component";
import Spacer from "../../../../../components/spacer.component";
import BorderButton from "../../../../../components/buttons/border-button.component";
import routes from "../../../../../navigation/routes";
import { experienceSelector } from "../../../../../services/experience/experience-slice.services";
import CtaButton from "../../../../../components/buttons/cta-button.component";

const CreateExperienceFieldContainer = styled(Grid)(({ theme }) => ({
  padding: theme.padding.paddingX[0],
  width: "100%",
  height: theme.dimensions.heightWithoutAppBar,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: "10px",
  marginBottom: "10px",
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
});

const ContentContainer = styled(Box)({
  width: "100%",
  overflowY: "scroll",
  height: "85%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

function CreateExperiences({ createExperience }) {
  const theme = useTheme();
  const letsGoText = "Let's Go";
  const labelText = "We'll help you every step along the way.";
  const history = useHistory();
  const { createExperienceObj } = useSelector(experienceSelector);

  return (
    <CreateExperienceFieldContainer container>
      <ContentContainer>
        <Text
          variant="h2"
          sx={{ fontWeight: theme.fonts.fontWeights.bold, textAlign: "center", width: "80%" }}
        >
          Create an Experience in 10 easy steps
        </Text>
        <Spacer size="m" position="top" />
        <Text type="GreyColor">{labelText}</Text>
      </ContentContainer>
      <SeperateLine />
      <SpaceBetweenBox>
        <BorderButton onClickButton={() => history.push(routes.HOME)}>
          <Text type="BrandColor" sx={{ paddingX: "15px" }}>
            Exit
          </Text>
        </BorderButton>
        <CtaButton
          onClickButton={createExperience}
          isLoading={createExperienceObj.status === "pending"}
        >
          <Text type="WhiteColor" sx={{ paddingX: "15px" }}>
            {letsGoText}
          </Text>
        </CtaButton>
      </SpaceBetweenBox>
    </CreateExperienceFieldContainer>
  );
}

CreateExperiences.propTypes = {
  createExperience: PropTypes.func.isRequired,
};

export default CreateExperiences;
