import { createTheme } from "@mui/material/styles";
import { colors } from "./colors";
import { darkColors } from "./dark-colors";
import { borderSizes } from "./borders";
import { fonts, fontSizes, fontWeights } from "./fonts";
import { paddingX } from "./padding-size";
import { PCMaxWidth, heightWithoutAppBar } from "./app-dimensions";

const theme = createTheme({
  palette: {
    colors,
    darkColors,
  },
  shape: {
    borderRadius: borderSizes,
  },
  padding: {
    paddingX,
  },
  dimensions: {
    PCMaxWidth,
    heightWithoutAppBar,
  },
  mixins: {
    toolbar: {
      minHeight: 76,
      maxHeight: 80,
    },
  },
  fonts: {
    fonts,
    fontSizes,
    fontWeights,
  },
});

export default theme;
