import React from "react";
import { Grid, useTheme } from "@mui/material";
import PaddedView from "../../../components/padded-view.component";
import ProfileSideMenuButtonList from "../components/profile-side-menu-button-list.component";
import Text from "../../../components/text.component";

function AccountMenuScreen() {
  const theme = useTheme();

  return (
    <PaddedView multiples={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }} variant="h4">
            Account
          </Text>
        </Grid>
        <Grid item xs={12}>
          <ProfileSideMenuButtonList />
        </Grid>
      </Grid>
    </PaddedView>
  );
}

export default AccountMenuScreen;
