import React, { useState } from "react";
import { Box, Grid, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GoogleMapReact from "google-map-react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Text from "../../../../components/text.component";
import TextButton from "../../../../components/buttons/text-button.component";
import Spacer from "../../../../components/spacer.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import { experienceSelector } from "../../../../services/experience/experience-slice.services";
import FormLabel from "../../../../components/forms/form-label.component";
import FormAddressAutoComplete from "../../../../components/forms/form-address-autocomplete.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import CtaButton from "../../../../components/buttons/cta-button.component";

const ContentContainer = styled(Box)(({ isMobile }) => ({
  width: "100%",
  height: isMobile ? null : "85%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const FormContainer = styled(Box)(({ theme, isMobile }) => ({
  width: "100%",
  backgroundColor: theme.palette.colors.bg.secondary,
  padding: isMobile ? theme.padding.paddingX[2] : theme.padding.paddingX[0],
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  marginTop: "25px",
  marginBottom: "25px",
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const StyledIcon = styled(Box)(({ theme }) => ({
  width: "30px",
  height: "30px",
  borderRadius: "15px",
  color: theme.palette.colors.brand.white,
  background: theme.palette.colors.brand.primary,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
}));

const StyledLocationOnIcon = styled(LocationOnIcon)({
  fontSize: "20px",
});

function AnyReactComponent() {
  return (
    <StyledIcon>
      <StyledLocationOnIcon />
    </StyledIcon>
  );
}

function AddressEditForm({ isEditing, setIsEditing }) {
  const { values, resetForm } = useFormikContext();
  const { updateExperienceObj } = useSelector(experienceSelector);
  const [currentScreen, setCurrentScreen] = useState("Form");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleEdit = () => {
    setIsEditing((i) => ({ ...i, address: true }));
  };

  const onCancelEdit = () => {
    resetForm();
    setIsEditing((i) => ({ ...i, address: false }));
  };

  const defaultProps = {
    center: {
      lat: parseFloat(values.locationLat),
      lng: parseFloat(values.locationLong),
    },
    zoom: 17,
  };

  const renderMap = () => (
    <ContentContainer isMobile={isMobile}>
      <FormContainer isMobile={isMobile}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <IconButton onClick={() => setCurrentScreen("Form")}>
            <ArrowBackIosIcon />
          </IconButton>
          <Text>{values.address}</Text>
        </Box>
        <Spacer position="top" size="m" />
        <Box sx={{ width: "100%", height: isMobile ? "200px" : "350px" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            draggable={false}
            options={{
              panControl: false,
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              overviewMapControl: false,
              rotateControl: false,
              zoomControlOptions: false,
            }}
          >
            <AnyReactComponent
              lat={parseFloat(values.locationLat)}
              lng={parseFloat(values.locationLong)}
            />
          </GoogleMapReact>
        </Box>
      </FormContainer>
    </ContentContainer>
  );

  const renderForm = () => (
    <ContentContainer isMobile={isMobile}>
      <FormContainer isMobile={isMobile}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel>Address</FormLabel>
            <Spacer size="xs" />
            <FormAddressAutoComplete name="address" />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <FormLabel>Zip Code</FormLabel>
            <Spacer size="xs" />
            <FormFieldText name="postCode" placeholder="Zip Code" disabled={true} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <FormLabel>City</FormLabel>
            <Spacer size="xs" />
            <FormFieldText name="city" placeholder="City" disabled={true} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <FormLabel>State</FormLabel>
            <Spacer size="xs" />
            <FormFieldText name="state" placeholder="State" disabled={true} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <FormLabel>Country</FormLabel>
            <Spacer size="xs" />
            <FormFieldText name="country" placeholder="Country" disabled={true} />
          </Grid>

          {values.locationLat !== "" && values.locationLong !== "" && (
            <Grid item xs={isMobile ? 12 : 3}>
              <Spacer size="m" position="top" />
              <CtaButton
                onClickButton={() => {
                  setCurrentScreen("Map");
                }}
              >
                <Text type="WhiteColor">View in Map</Text>
              </CtaButton>
            </Grid>
          )}
        </Grid>
      </FormContainer>
    </ContentContainer>
  );

  if (isEditing.address) {
    return (
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Text>Address</Text>
          <TextButton label="Cancel" onClickButton={onCancelEdit} />
        </SpaceBetweenBox>
        <Spacer size="m" position="top" />
        {currentScreen === "Form" ? renderForm() : renderMap()}
        <Spacer size="m" position="top" />
        <FormSubmitButton width="100px" isLoading={updateExperienceObj.status === "pending"}>
          <Text type="WhiteColor">Save</Text>
        </FormSubmitButton>
        <SeperateLine />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <SpaceBetweenBox>
        <Text>Address</Text>
        <TextButton label="Edit" onClickButton={toggleEdit} isDisabled={true} />
      </SpaceBetweenBox>
      <Spacer size="xs" position="top" />
      <Text type="GreyColor">{values.address}</Text>
      <SeperateLine />
    </Grid>
  );
}

AddressEditForm.propTypes = {
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.shape({ address: PropTypes.bool }).isRequired,
};

export default AddressEditForm;
