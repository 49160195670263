import React from "react";
import { Box, Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import PaddedView from "../../../../components/padded-view.component";
import Spacer from "../../../../components/spacer.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Text from "../../../../components/text.component";
import { authSelector } from "../../../../services/auth/auth-slice.services";
import ResendCountdownButton from "../../../../components/buttons/resend-countdown-button.component";

const CardContainer = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: theme.palette.colors.bg.white,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  flex: 1,
  maxWidth: 500,
  minWidth: isMobile ? 340 : 450,
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "80px",
  backgroundColor: theme.palette.colors.bg.primary,
  justifyContent: "center",
  alignItems: "center",
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  "& .MuiButton-root": {
    marginTop: "-3px",
    height: "13px",
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    textTransform: "none",
    backgroundColor: "transparent",
    boxShadow: "none",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
    "&:disabled": { backgroundColor: "transparent" },
  },
}));

function VerificationForm({ handleResendCode, handleLogout }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { postVerificationCodeObj } = useSelector(authSelector);
  const { values } = useFormikContext();

  return (
    <CardContainer isMobile={isMobile} sx={{ boxShadow: 2 }}>
      <TitleContainer>
        <Text variant="h5">Enter Verification Code</Text>
      </TitleContainer>

      <PaddedView multiples={isMobile ? 3 : 4}>
        <Grid container sx={{ justifyContent: "center" }}>
          <Text>{`A verification code has been sent to ${values.email}`}</Text>
        </Grid>

        <Spacer size="xl" />
        <FormFieldText
          type="verificationCode"
          name="verificationCode"
          placeholder="Enter Verification Code"
        />
        <Grid container sx={{ justifyContent: "center" }}>
          <ResendCountdownButton onClickButton={handleResendCode} />
        </Grid>
        <Spacer size="xxxl" />

        <FormSubmitButton isLoading={postVerificationCodeObj.status === "pending"}>
          <Text type="WhiteColor">Verify</Text>
        </FormSubmitButton>
        <Spacer size="xl" />
        <Grid container sx={{ justifyContent: "space-between" }}>
          <ButtonContainer>
            <Button disableRipple onClick={handleLogout}>
              <Text type="BrandColor">Logout</Text>
            </Button>
          </ButtonContainer>
        </Grid>
      </PaddedView>
    </CardContainer>
  );
}

VerificationForm.propTypes = {
  handleResendCode: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default VerificationForm;
