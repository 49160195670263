import React from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Text from "../../../components/text.component";

function TopLeftLink({ label, pathname }) {
  return (
    <Box sx={{ position: "absolute", top: 20, left: 20 }}>
      <Link
        style={{
          textTransform: "none",
          textDecoration: "none",
        }}
        to={{ pathname }}
      >
        <Text
          type="GreyColor"
          sx={{
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          {label}
        </Text>
      </Link>
    </Box>
  );
}

TopLeftLink.defaultProps = {
  label: "",
  pathname: "",
};

TopLeftLink.propTypes = {
  pathname: PropTypes.string,
  label: PropTypes.string,
};

export default TopLeftLink;
