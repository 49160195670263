import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  styled,
  useMediaQuery,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Text from "../../../components/text.component";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/webp"];

const StyledButtonIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  padding: 0,
  top: "10px",
  right: "10px",
  backgroundColor: theme.palette.colors.brand.white,
  width: "30px",
  height: "30px",
  ":hover": { backgroundColor: theme.palette.colors.brand.white },
  ":disabled": {
    backgroundColor: theme.palette.colors.ui.disabled,
  },
}));

const StyledMoreHorizIcon = styled(MoreHorizIcon)(({ theme }) => ({
  fontSize: "25px",
  color: theme.palette.colors.brand.secondary,
}));

const UploadButtonBox = styled(Box)(({ theme }) => ({
  width: "100%",
  "& .MuiButton-root": {
    backgroundColor: "transparent",
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    border: `2px dashed ${theme.palette.colors.ui.disabled}`,
    width: "100%",

    "&:hover": {
      background: "transparent",
    },
  },
}));

const CoverPhotoLabelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  backgroundColor: theme.palette.colors.brand.white,
  top: "15px",
  left: "10px",
  paddingLeft: "5px",
  paddingRight: "5px",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

function UploadImagesGrid({ images, deleteImage, handleOrderImage, setFile, setShowImageCropper }) {
  const theme = useTheme();
  const filePreview = images;
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const coverHeight = isMobile ? "200px" : "400px";
  const [showMenu, setShowMenu] = useState(null);
  const [imageId, setImageId] = useState(null);
  const [imageIndex, setImageIndex] = useState(null);

  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const handleRemoveImage = (values) => {
    deleteImage({ imageId: values });
  };

  const renderMenu = () => (
    <StyledMenu
      sx={{ mt: "30px" }}
      anchorEl={showMenu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(showMenu)}
      onClose={handleCloseMenu}
    >
      {imageIndex !== 0 && imageIndex !== images.length - 1 && (
        <MenuItem
          key={uuidv4()}
          onClick={() => {
            handleOrderImage({ imageId, type: "backward" });
            handleCloseMenu();
          }}
        >
          <Text textAlign="center">Move Backward</Text>
        </MenuItem>
      )}

      {imageIndex !== 0 && imageIndex !== 1 && (
        <MenuItem
          key={uuidv4()}
          onClick={() => {
            handleOrderImage({ imageId, type: "forward" });
            handleCloseMenu();
          }}
        >
          <Text textAlign="center">Move Foward</Text>
        </MenuItem>
      )}
      {imageIndex !== 0 && (
        <MenuItem
          key={uuidv4()}
          onClick={() => {
            handleOrderImage({ imageId, type: "cover" });
            handleCloseMenu();
          }}
        >
          <Text textAlign="center">Set as Cover Photo</Text>
        </MenuItem>
      )}
      <MenuItem
        key={4}
        onClick={() => {
          handleRemoveImage(imageId);
          handleCloseMenu();
        }}
      >
        <Text textAlign="center">Delete</Text>
      </MenuItem>
    </StyledMenu>
  );

  const renderUploadButton = () => (
    <UploadButtonBox sx={{ height: "100%", minHeight: "150px" }}>
      <Button component="label" sx={{ height: "98%", textTransform: "none" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: theme.palette.colors.ui.disabled,
            flexDirection: "column",
          }}
        >
          <AddPhotoAlternateIcon
            sx={{ fontSize: "60px", color: theme.palette.colors.brand.secondary }}
          />
          <Text>Upload</Text>
        </Box>
        <input
          onClick={(event) => {
            event.target.value = null;
          }}
          type="file"
          hidden
          accept="image/*"
          onChange={(e) => {
            const { files } = e.target;
            if (files) {
              const selectedFIles = [];
              const targetFilesObject = [...files];
              targetFilesObject.map((item) => selectedFIles.push(item));
              const withinFileSizeLimit = selectedFIles.some((item) => item.size <= 5000000);
              const allowedFileType = selectedFIles.some((item) =>
                SUPPORTED_FORMATS.includes(item.type),
              );
              if (
                withinFileSizeLimit &&
                allowedFileType &&
                filePreview.length + selectedFIles.length <= 10
              ) {
                setFile(URL.createObjectURL(selectedFIles[0]));
                setShowImageCropper(true);
              } else if (!withinFileSizeLimit) {
                createSnackBar({
                  message: "Image file cannot be more than 5mb",
                  type: "error",
                  open: true,
                });
              } else if (!allowedFileType) {
                createSnackBar({
                  message: `Only ${SUPPORTED_FORMATS} are allowed`,
                  type: "error",
                  open: true,
                });
              }
            }
          }}
        />
      </Button>
    </UploadButtonBox>
  );

  // const renderCoverUploadButton = () => (
  //   <Grid item maxWidth={isMobile ? "100%" : "1000px"}>
  //     <UploadButtonBox>
  //       <Button component="label" sx={{ height: coverHeight, textTransform: "none" }}>
  //         <Box
  //           sx={{
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //             color: theme.palette.colors.ui.disabled,
  //             flexDirection: "column",
  //           }}
  //         >
  //           <AddPhotoAlternateIcon
  //             sx={{ fontSize: "60px", color: theme.palette.colors.brand.secondary }}
  //           />
  //           <Text>Upload</Text>
  //         </Box>
  //         <input
  //           onClick={(event) => {
  //             event.target.value = null;
  //           }}
  //           type="file"
  //           hidden
  //           accept="image/*"
  //           onChange={(e) => {
  //             const { files } = e.target;
  //             if (files) {
  //               const selectedFIles = [];
  //               const targetFilesObject = [...files];
  //               targetFilesObject.map((item) => selectedFIles.push(item));
  //               const withinFileSizeLimit = selectedFIles.some((item) => item.size <= 5000000); // 1mb = 1^6 bytes
  //               const allowedFileType = selectedFIles.some((item) =>
  //                 SUPPORTED_FORMATS.includes(item.type),
  //               );
  //               if (
  //                 withinFileSizeLimit &&
  //                 allowedFileType &&
  //                 filePreview.length + selectedFIles.length <= 10
  //               ) {
  //                 setFile(URL.createObjectURL(selectedFIles[0]));
  //                 setShowImageCropper(true);
  //               } else if (!withinFileSizeLimit) {
  //                 createSnackBar({
  //                   message: "Image file cannot be more than 5mb",
  //                   type: "error",
  //                   open: true,
  //                 });
  //               } else if (!allowedFileType) {
  //                 createSnackBar({
  //                   message: `Only ${SUPPORTED_FORMATS} are allowed`,
  //                   type: "error",
  //                   open: true,
  //                 });
  //               }
  //             }
  //           }}
  //         />
  //       </Button>
  //     </UploadButtonBox>
  //   </Grid>
  // );

  const renderPreview = () => {
    if (filePreview) {
      return (
        <>
          {filePreview.map(
            (i, index) =>
              index !== 0 && (
                <Grid item key={i.id} xs={isMobile ? 12 : 6}>
                  <Box sx={{ position: "relative" }}>
                    <img
                      src={i.path}
                      alt="img"
                      style={{
                        width: "100%",
                        borderTopLeftRadius: theme.shape.borderRadius[0],
                        borderTopRightRadius: theme.shape.borderRadius[0],
                        borderBottomLeftRadius: theme.shape.borderRadius[0],
                        borderBottomRightRadius: theme.shape.borderRadius[0],
                      }}
                    />
                    <StyledButtonIcon
                      onClick={(event) => {
                        setImageId(i.id);
                        setImageIndex(index);
                        handleOpenMenu(event);
                      }}
                      disabled
                    >
                      <StyledMoreHorizIcon />
                    </StyledButtonIcon>
                    {renderMenu()}
                  </Box>
                </Grid>
              ),
          )}
          {/* {filePreview.length < 10 && (
            <Grid item xs={isMobile ? 12 : 6}>
              {renderUploadButton()}
            </Grid>
          )} */}
        </>
      );
    }
    return renderUploadButton();
  };

  const renderCoverPreview = () => {
    if (filePreview.length !== 0) {
      return (
        <Box sx={{ position: "relative", maxWidth: "990px" }}>
          <img
            src={filePreview[0].path}
            alt="cover"
            style={{
              width: "100%",
              borderTopLeftRadius: theme.shape.borderRadius[0],
              borderTopRightRadius: theme.shape.borderRadius[0],
              borderBottomLeftRadius: theme.shape.borderRadius[0],
              borderBottomRightRadius: theme.shape.borderRadius[0],
            }}
          />
          <CoverPhotoLabelContainer>
            <Text>Cover Photo</Text>
          </CoverPhotoLabelContainer>
          <StyledButtonIcon
            onClick={(event) => {
              setImageIndex(0);
              setImageId(filePreview[0].id);
              handleOpenMenu(event);
            }}
            disabled
          >
            <StyledMoreHorizIcon />
          </StyledButtonIcon>
          {renderMenu()}
        </Box>
      );
    }
    return null;
  };

  return (
    <Grid container spacing={2} sx={{ paddingRight: isMobile ? null : "10px" }}>
      <Grid item xs={12}>
        {renderCoverPreview()}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} maxWidth="1000px">
          {filePreview.length >= 1 && renderPreview()}
        </Grid>
      </Grid>
    </Grid>
  );
}

UploadImagesGrid.defaultProps = {
  images: [],
  deleteImage: null,
};

UploadImagesGrid.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      path: PropTypes.string,
    }),
  ),
  deleteImage: PropTypes.func,
  handleOrderImage: PropTypes.func.isRequired,
  setShowImageCropper: PropTypes.func.isRequired,
  setFile: PropTypes.func.isRequired,
};

export default UploadImagesGrid;
