import React from "react";
import { Grid, styled, useTheme } from "@mui/material";
import Text from "../../../../../components/text.component";
import Spacer from "../../../../../components/spacer.component";

const CreateExperienceFieldContainer = styled(Grid)(({ theme }) => ({
  padding: theme.padding.paddingX[2],
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

function MobileCreateExperiences() {
  const theme = useTheme();
  const labelText = "We'll help you every step along the way.";
  return (
    <CreateExperienceFieldContainer container>
      <Text variant="h4" sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
        Create an Experience in 10 easy steps
      </Text>
      <Spacer size="xs" position="top" />
      <Text type="GreyColor">{labelText}</Text>
    </CreateExperienceFieldContainer>
  );
}

export default MobileCreateExperiences;
