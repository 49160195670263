import React from "react";
import { Box, FormLabel, Grid, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import Text from "../../../../components/text.component";
import Spacer from "../../../../components/spacer.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormAddressAutoComplete from "../../../../components/forms/form-address-autocomplete.component";
import { experienceSelector } from "../../../../services/experience/experience-slice.services";
import SkeletonLoader from "../../../../components/skeleton.component";
import CtaButton from "../../../../components/buttons/cta-button.component";

const ContentContainer = styled(Box)(({ isMobile }) => ({
  width: "100%",
  overflowY: "scroll",
  height: isMobile ? null : "85%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  paddingBottom: isMobile ? "100px" : null,
}));

const FormContainer = styled(Box)(({ theme, isMobile }) => ({
  width: "100%",
  backgroundColor: theme.palette.colors.bg.secondary,
  padding: isMobile ? theme.padding.paddingX[2] : theme.padding.paddingX[0],
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "row",
});

function AddressForm({ setCurrentScreen }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { values } = useFormikContext();
  const { getExperiencesDetailsObj } = useSelector(experienceSelector);

  const renderForm = () => {
    if (getExperiencesDetailsObj.status === "succeeded") {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel>Address</FormLabel>
            <Spacer size="xs" />
            <FormAddressAutoComplete name="address" />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <FormLabel>Zip Code</FormLabel>
            <Spacer size="xs" />
            <FormFieldText name="postCode" placeholder="Zip Code" disabled={true} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <FormLabel>City</FormLabel>
            <Spacer size="xs" />
            <FormFieldText name="city" placeholder="City" disabled={true} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <FormLabel>State</FormLabel>
            <Spacer size="xs" />
            <FormFieldText name="state" placeholder="State" disabled={true} />
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <FormLabel>Country</FormLabel>
            <Spacer size="xs" />
            <FormFieldText name="country" placeholder="Country" disabled={true} />
          </Grid>

          {values.locationLat !== "" && values.locationLong !== "" && (
            <Grid item xs={isMobile ? 12 : 3}>
              <Spacer size="m" position="top" />
              <CtaButton
                onClickButton={() => {
                  setCurrentScreen("Map");
                }}
              >
                <Text type="WhiteColor">View in Map</Text>
              </CtaButton>
            </Grid>
          )}
        </Grid>
      );
    }
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormLabel>Address</FormLabel>
          <Spacer size="xs" />
          <SkeletonLoader height="55px" />
        </Grid>
        <Grid item xs={isMobile ? 12 : 6}>
          <FormLabel>Zip Code</FormLabel>
          <Spacer size="xs" />
          <SkeletonLoader height="55px" />
        </Grid>
        <Grid item xs={isMobile ? 12 : 6}>
          <FormLabel>City</FormLabel>
          <Spacer size="xs" />
          <SkeletonLoader height="55px" />
        </Grid>
        <Grid item xs={isMobile ? 12 : 6}>
          <FormLabel>State</FormLabel>
          <Spacer size="xs" />
          <SkeletonLoader height="55px" />
        </Grid>
        <Grid item xs={isMobile ? 12 : 6}>
          <FormLabel>Country</FormLabel>
          <Spacer size="xs" />
          <SkeletonLoader height="55px" />
        </Grid>
      </Grid>
    );
  };
  return (
    <ContentContainer isMobile={isMobile}>
      <FormContainer isMobile={isMobile}>
        <SpaceBetweenRowBox>
          <Box sx={{ width: "15%" }}>
            <IconButton onClick={() => setCurrentScreen("Select Location Type")}>
              <ArrowBackIosIcon />
            </IconButton>
          </Box>
          <Text variant="h6">Address</Text>
          <Box sx={{ width: "15%" }} />
        </SpaceBetweenRowBox>
        <Spacer size="m" position="top" />
        {renderForm()}
      </FormContainer>
    </ContentContainer>
  );
}

AddressForm.propTypes = {
  setCurrentScreen: PropTypes.func.isRequired,
};

export default AddressForm;
