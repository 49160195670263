import React, { useContext, useEffect } from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import {
  logout,
  putBusinessDetails,
  resetUser,
} from "../../../../services/auth/auth-slice.services";
import routes from "../../../../navigation/routes";
import PaddedView from "../../../../components/padded-view.component";
import YoloLogo from "../../../../assets/images/yolo_logo";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import Form from "../../../../components/forms/form.component";
import BusinessDetailsForm from "../components/business-details-form.component";
import { getProfile, profileSelector } from "../../../../services/profile/profile-slice.services";
import BusinessFormLoader from "../loader/business-form-loader.component";

const StyledMainContainer = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  width: "100%",
  backgroundColor: theme.palette.colors.bg.primary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledMainGrid = styled(Grid)({
  justifyContent: "center",
  height: "100%",
  alignItems: "center",
  flexDirection: "column",
});

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label("Name"),
  companyWebsite: Yup.string().label("Website"),
  businessRegNumber: Yup.string().required().label("Business Registration Number"),
  contactNo: Yup.string().required().label("Contact No"),
  bankAccountNo: Yup.number().required().label("Account Number"),
  bankName: Yup.string().required().label("Bank Name"),
  accHolderName: Yup.string().required().label("Account Holder Name"),
});

function BusinessDetailScreen() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const createSnackBar = useContext(SnackbarContext);
  const { getProfileObj } = useSelector(profileSelector);

  useEffect(() => {
    localStorage.setItem("backToScreen", "businessDetails");
    dispatch(getProfile()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const handleSubmitDetails = (values) => {
    const bank = {};
    bank.accountNo = values.bankAccountNo;
    bank.name = values.bankName;
    bank.accountHolderName = values.accHolderName;
    const website = values.companyWebsite !== "" ? `https://${values.companyWebsite}` : "";

    dispatch(putBusinessDetails({ bank, website, ...values })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        localStorage.removeItem("backToScreen");
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        history.push(routes.HOME);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleLogout = () => {
    dispatch(logout()).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        history.push(routes.LOGIN);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: "Logout Successful",
          type: "success",
          open: true,
        });
        dispatch(resetUser());
        history.push(routes.LOGIN);
      }
    });
  };

  return (
    <StyledMainContainer>
      <PaddedView multiples={isMobile ? 2 : 4}>
        <StyledMainGrid container spacing={4}>
          <Grid item xs={12} sx={{ height: isMobile ? 45 : 60, textAlign: "center" }}>
            <YoloLogo isDisable={true} />
          </Grid>
          <Grid item xs={12}>
            {getProfileObj.status === "succeeded" ? (
              <Form
                initialValues={{
                  name:
                    (getProfileObj.status === "succeeded" && getProfileObj.data.merchant.name) ||
                    "",
                  companyWebsite:
                    getProfileObj.data.merchant.website?.replace("https://", "") || "",
                  contactNo: getProfileObj.data.merchant.contactNo || "",
                  businessRegNumber: getProfileObj.data.merchant.businessRegNumber || "",
                  bankAccountNo: getProfileObj.data.bankDetails.bankAccountNo || "",
                  bankName: getProfileObj.data.bankDetails.bankName || "",
                  accHolderName: getProfileObj.data.bankDetails.name || "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmitDetails}
              >
                <BusinessDetailsForm handleLogout={handleLogout} />
              </Form>
            ) : (
              <BusinessFormLoader />
            )}
          </Grid>
        </StyledMainGrid>
      </PaddedView>
    </StyledMainContainer>
  );
}

export default BusinessDetailScreen;
