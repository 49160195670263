import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { TextField, InputAdornment, Box, IconButton, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import Spacer from "../spacer.component";

function FormFieldText({
  name,
  placeholder,
  type,
  width,
  disabled,
  isDisableEnter,
  onClickHandle,
  isTransparent,
}) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit } =
    useFormikContext();
  const [isShowPassword, setIsShowPassword] = useState(false);

  const keyPress = (e) => {
    if (e.keyCode === 13 && !isDisableEnter) {
      handleSubmit();
    }
  };

  const showError = touched[name] && typeof errors[name] === "string";

  const getType = (inputType) => {
    const tempType = inputType;
    if (tempType === "password" && isShowPassword) {
      return "string";
    }
    return tempType;
  };

  return (
    <Box
      sx={{
        "& .MuiTextField-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          width,
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: isTransparent ? "transparent" : theme.palette.colors.text.inputField,
          },
        },
      }}
    >
      <TextField
        onClick={onClickHandle}
        disabled={disabled}
        onKeyDown={keyPress}
        error={showError}
        variant="outlined"
        helperText={showError ? errors[name] : null}
        onBlur={() => setFieldTouched(name)}
        name={name}
        type={getType(type || name)}
        placeholder={placeholder}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
        InputProps={{
          startAdornment: (
            <>
              {name.toUpperCase().includes("WEBSITE") && (
                <InputAdornment position="start">
                  <Typography>https://</Typography>
                  <Spacer position="right" />
                </InputAdornment>
              )}
              {name === "email" && (
                <InputAdornment position="start">
                  <PersonIcon />
                  <Spacer position="right" />
                </InputAdornment>
              )}
              {name.toUpperCase().includes("PASSWORD") && (
                <InputAdornment position="start">
                  <LockIcon />
                  <Spacer position="right" />
                </InputAdornment>
              )}
            </>
          ),
          endAdornment: name.toUpperCase().includes("PASSWORD") && (
            <InputAdornment position="start">
              <IconButton
                onClick={() => {
                  setIsShowPassword(!isShowPassword);
                }}
              >
                {isShowPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={values[name] === null ? "" : values[name]}
      />
    </Box>
  );
}

FormFieldText.defaultProps = {
  type: null,
  width: "100%",
  disabled: false,
  isDisableEnter: false,
  onClickHandle: null,
  isTransparent: false,
};

FormFieldText.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  isDisableEnter: PropTypes.bool,
  onClickHandle: PropTypes.func,
  isTransparent: PropTypes.bool,
};

export default FormFieldText;
