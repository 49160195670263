import React from "react";
import PropTypes from "prop-types";
import { Button, Box, useTheme, styled } from "@mui/material";
import Loader from "react-js-loader";

const StyledButtonBox = styled(Box)(({ theme }) => ({
  "& .MuiButton-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    textTransform: "none",
    backgroundColor: theme.palette.colors.brand.primary,
    boxShadow: 0,
    border: `1px solid ${theme.palette.colors.brand.primary}`,
    "&:hover": {
      backgroundColor: theme.palette.colors.brand.primary,
    },
    "&:disabled": {
      backgroundColor: theme.palette.colors.ui.disabled,
      border: `0px solid ${theme.palette.colors.brand.primary}`,
    },
  },
}));

const CenterLoadingBox = styled(Box)({
  height: "25px",
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
});

function CtaButton({ children, width, isLoading, onClickButton, disabled }) {
  const theme = useTheme();

  return (
    <StyledButtonBox>
      {isLoading ? (
        <Button variant="contained" disabled sx={{ width }}>
          <CenterLoadingBox>
            <Loader type="bubble-loop" bgColor={theme.palette.colors.brand.white} size={30} />
          </CenterLoadingBox>
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={onClickButton}
          sx={{ width, boxShadow: 0 }}
          disabled={disabled}
        >
          {children}
        </Button>
      )}
    </StyledButtonBox>
  );
}

CtaButton.defaultProps = {
  width: "100%",
  isLoading: false,
  onClickButton: null,
  disabled: false,
};

CtaButton.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  onClickButton: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CtaButton;
