import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import source from "./green_lock.png";

function GreenLock() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return <img width={isMobile ? 75 : 100} height="auto" src={source} alt="logo" />;
}

export default GreenLock;
