import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { FormControl, Select, MenuItem, FormHelperText } from "@mui/material";

function FormFieldDropdown({ name, width, itemList, placeholder }) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values } = useFormikContext();
  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
  };

  const showError = touched[name] && typeof errors[name] === "string";

  return (
    <FormControl sx={{ width }}>
      <Select
        onBlur={() => setFieldTouched(name)}
        value={values[name] === null ? "" : values[name]}
        onChange={handleChange}
        inputProps={{ "aria-label": "Without label", placeholder }}
        error={showError}
        displayEmpty
        sx={{
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
        }}
      >
        {itemList.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {showError && <FormHelperText error={showError}>{errors[name]}</FormHelperText>}
    </FormControl>
  );
}

FormFieldDropdown.defaultProps = {
  width: "100%",
  placeholder: "",
};

FormFieldDropdown.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default FormFieldDropdown;
