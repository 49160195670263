import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Pagination,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import Text from "../../../components/text.component";
import VerticalPaddedView from "../../../components/vertical-padded-view.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import ProfileSideMenu from "../../profile/components/profile-side-menu.component";
import Spacer from "../../../components/spacer.component";
import routes from "../../../navigation/routes";
import {
  getStatementDetail,
  sendStatementEmail,
  statementSelector,
} from "../../../services/statement/statement-slice.services";
import SkeletonLoader from "../../../components/skeleton.component";
import Form from "../../../components/forms/form.component";
import { profileSelector } from "../../../services/profile/profile-slice.services";
import EmailFieldModal from "../components/email-field-modal.component";
import DisputeLogModal from "../components/dispute-log-modal.component";

const StyledBackButton = styled(IconButton)(({ theme }) => ({
  padding: "0px",
  strokeWidth: 1,
  stroke: theme.palette.colors.brand.secondary,
  color: theme.palette.colors.brand.secondary,
  ":hover": {
    backgroundColor: "transparent",
  },
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.padding.paddingX[2],
  boxShadow: `0px 1px 5px 1px ${theme.palette.colors.loading.foregroundColor}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "0.5px",
  backgroundColor: theme.palette.colors.ui.border,
}));

const EmailButton = styled(Button)(({ theme }) => ({
  padding: "0px",
  color: theme.palette.colors.ui.border,
  textTransform: "none",
  textDecoration: "underline",
  textDecorationColor: theme.palette.colors.brand.primary,
  "&:hover": {
    backgroundColor: "transparent",
    borderColor: "transparent",
    boxShadow: "none",
    textDecoration: "underline",
    textDecorationColor: theme.palette.colors.brand.primary,
  },
}));

const DisputeLogButton = styled(Button)(({ theme }) => ({
  padding: "0px",
  color: theme.palette.colors.ui.border,
  textTransform: "none",
  textDecoration: "underline",
  textDecorationColor: theme.palette.colors.brand.primary,
  "&:hover": {
    backgroundColor: "transparent",
    borderColor: "transparent",
    boxShadow: "none",
    textDecoration: "underline",
    textDecorationColor: theme.palette.colors.brand.primary,
  },
}));

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const EmailIconButton = styled(IconButton)(({ theme }) => ({
  padding: "0px",
  color: theme.palette.colors.brand.primary,
}));

const emailValidation = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});

function StatementDetailScreen() {
  const location = useLocation();
  const statementId = new URLSearchParams(location.search).get("statementId");
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const { getStatementDetailObj } = useSelector(statementSelector);
  const { getProfileObj } = useSelector(profileSelector);
  const [page, setPage] = useState(1);
  const [showEmailFieldModal, setShowEmailFieldModal] = useState(false);
  const [showDisputeLogModal, setShowDisputeLogModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getStatementDetail({ statementId, page })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
    dispatch(getStatementDetail({ statementId, page: value })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderStatus = (status) => {
    if (status === "dispute") {
      return (
        <Text variant="h6" type="RejectText">
          {status.toUpperCase()}
        </Text>
      );
    }
    if (status === "open") {
      return (
        <Text variant="h6" type="GreenColor">
          {status.toUpperCase()}
        </Text>
      );
    }
    if (status === "pending") {
      return (
        <Text variant="h6" type="BlueColor">
          {status.toUpperCase()}
        </Text>
      );
    }
    return (
      <Text variant="h6" type="BrandColor">
        {status.toUpperCase()}
      </Text>
    );
  };

  const renderStatementCard = () => {
    if (getStatementDetailObj.status === "succeeded") {
      const { statement } = getStatementDetailObj.data.items;
      return (
        <CardContainer>
          <SpaceBetweenRowBox>
            <Box>
              <Text type="GreyColor">{`Ref ID: ${statement.id}`}</Text>
              {renderStatus(statement.status)}
            </Box>

            <Text variant="h6">{`RM${statement.totalAmount}`}</Text>
          </SpaceBetweenRowBox>
          {statement.status === "dispute" && (
            <>
              <Spacer position="top" size="m" />
              <Text>Reason :</Text>
              <Text>{statement.rejectedReason}</Text>
            </>
          )}
        </CardContainer>
      );
    }
    return (
      <CardContainer>
        <SpaceBetweenRowBox>
          <SkeletonLoader width="100px" height="30px" />
          <SkeletonLoader width="100px" height="30px" />
        </SpaceBetweenRowBox>
      </CardContainer>
    );
  };

  const getPrice = (price) => {
    let tempPrice = "";
    if (price.includes("-")) {
      tempPrice = `-RM${price.substring(1)}`;
    } else {
      tempPrice = `+RM${price}`;
    }
    return tempPrice;
  };

  const renderStatementList = () => {
    if (getStatementDetailObj.status === "succeeded") {
      if (getStatementDetailObj.data.items.transactions.length === 0) {
        return (
          <Grid item xs={12}>
            <Text sx={{ textAlign: "center" }}>No Transactions.</Text>
          </Grid>
        );
      }
      return (
        <>
          {getStatementDetailObj.data.items.transactions.map((item) => (
            <Grid item xs={12} key={item.id}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Box>
                    <SpaceBetweenRowBox>
                      <Text>{item.description}</Text>
                      <Spacer size="s" position="left" />
                      <Text>{getPrice(item.amount)}</Text>
                    </SpaceBetweenRowBox>
                    <Text type="GreyColor">{item.dateTime}</Text>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <SeperateLine />
                </Grid>
              </Grid>
            </Grid>
          ))}
          {getStatementDetailObj.data.pagination.totalPages > 1 && (
            <Grid item xs={12}>
              <Grid container justifyContent="center" paddingTop="50px">
                <Pagination
                  page={page}
                  onChange={handlePageChange}
                  count={getStatementDetailObj.data.pagination.totalPages}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          )}
        </>
      );
    }
    return (
      <Grid item xs={12}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Box>
              <SpaceBetweenRowBox>
                <SkeletonLoader width="100px" height="30px" />
                <Spacer size="s" position="left" />
                <SkeletonLoader width="100px" height="30px" />
              </SpaceBetweenRowBox>
              <SkeletonLoader width="100px" height="30px" />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <SeperateLine />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleSendStatementEmail = (value) => {
    dispatch(sendStatementEmail({ statementId, ...value })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowEmailFieldModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <Form
      initialValues={{
        email: getProfileObj.status === "succeeded" ? getProfileObj.data.merchant.email : "",
      }}
      validationSchema={emailValidation}
      onSubmit={handleSendStatementEmail}
    >
      <EmailFieldModal
        showModal={showEmailFieldModal}
        setShowModal={setShowEmailFieldModal}
        name="email"
      />
      <DisputeLogModal showModal={showDisputeLogModal} setShowModal={setShowDisputeLogModal} />
      <VerticalPaddedView multiples={isMobile ? 0 : 7}>
        <Grid container>
          {!isMobile && (
            <Grid item width="450px">
              <ProfileSideMenu />
            </Grid>
          )}

          <Grid item sx={{ display: "flex", flex: 1 }}>
            <PaddedView multiples={isMobile ? 3 : 0}>
              <Box sx={{ width: isMobile ? "100%" : "90%" }}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <SpaceBetweenRowBox>
                      <CenterRowBox>
                        <Link to={{ pathname: routes.SALESTRACKING }}>
                          <StyledBackButton>
                            <ArrowBackIosIcon />
                          </StyledBackButton>
                        </Link>
                        <Text variant="h5">Statement Details</Text>
                      </CenterRowBox>
                      <RowBox>
                        {getStatementDetailObj.status === "succeeded" && (
                          <>
                            {getStatementDetailObj.data.items.statement.status !== "open" &&
                              getStatementDetailObj.data.items.statement.status !== "pending" &&
                              getStatementDetailObj.data.items.statement.disputeLog.length > 0 && (
                                <>
                                  <DisputeLogButton onClick={() => setShowDisputeLogModal(true)}>
                                    <Text type="BrandColor">View Dispute Log</Text>
                                  </DisputeLogButton>
                                  <Spacer size="s" position="left" />
                                </>
                              )}
                            {isMobile ? (
                              <EmailIconButton onClick={() => setShowEmailFieldModal(true)}>
                                <ForwardToInboxOutlinedIcon />
                              </EmailIconButton>
                            ) : (
                              <EmailButton onClick={() => setShowEmailFieldModal(true)}>
                                <Text type="BrandColor">Send Email</Text>
                              </EmailButton>
                            )}
                          </>
                        )}
                      </RowBox>
                    </SpaceBetweenRowBox>
                  </Grid>
                  <Grid item xs={12}>
                    {renderStatementCard()}
                    <Spacer position="top" size="m" />
                  </Grid>
                  {renderStatementList()}
                </Grid>
              </Box>
            </PaddedView>
          </Grid>
        </Grid>
      </VerticalPaddedView>
    </Form>
  );
}

export default StatementDetailScreen;
