import React, { useState } from "react";
import { Box, Grid, Pagination, styled } from "@mui/material";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Text from "../../../components/text.component";
import DialogModal from "../../../components/notifications/dialog-modal.component";
import StatementCard from "./statement-card.component";
import { statementSelector } from "../../../services/statement/statement-slice.services";

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

function ClosedStatement({ page, handlePageChange }) {
  const [showModal, setShowModal] = useState(false);
  const { getClosedStatementObj } = useSelector(statementSelector);

  return (
    <Grid container spacing={5}>
      <DialogModal
        showModal={showModal}
        setShowModal={setShowModal}
        title="Payments made through bank accounts take up to 3-5 business days."
        buttonText="OK"
        onConfirm={() => setShowModal(false)}
      />
      {getClosedStatementObj.data.items.length === 0 && (
        <Grid item xs={12}>
          <CenterRowBox sx={{ justifyContent: "center" }}>
            <Text>Currently no statement</Text>
          </CenterRowBox>
        </Grid>
      )}
      {getClosedStatementObj.data.items.length > 0 && (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {getClosedStatementObj.data.items.map((item) => (
              <Grid item xs={12} key={item.statementId}>
                <StatementCard
                  id={item.statementId}
                  price={item.amount}
                  status="closed"
                  date={item.transferredDate}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {getClosedStatementObj.data.pagination.totalPages >= 2 && (
        <Grid item xs={12}>
          <Grid container justifyContent="center" paddingTop="50px">
            <Pagination
              page={page}
              onChange={handlePageChange}
              count={getClosedStatementObj.data.pagination.totalPages}
              variant="outlined"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

ClosedStatement.propTypes = {
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
};

export default ClosedStatement;
