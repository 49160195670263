import React from "react";
import { Box, Button, styled } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import BorderButton from "../../../components/buttons/border-button.component";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import CtaButton from "../../../components/buttons/cta-button.component";

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: "10px",
  marginBottom: "10px",
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
});

const ButtonBox = styled(Box)(({ theme }) => ({
  "& .MuiButton-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
}));
function PCBottomBar({ handleExit, onBackPress, isLoading, isForm, backgroundButtonPress }) {
  const { values } = useFormikContext();

  return (
    <>
      <SeperateLine />
      <SpaceBetweenBox>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <BorderButton onClickButton={onBackPress}>
            <Text type="BrandColor" sx={{ paddingX: "15px" }}>
              Back
            </Text>
          </BorderButton>
          <Spacer size="l" position="left" />
          <ButtonBox>
            <Button
              sx={{ padding: 0 }}
              disableRipple
              onClick={() => handleExit(values, { isExit: true })}
            >
              <Text type="BrandColor" sx={{ textDecoration: "underline" }}>
                Save and Exit
              </Text>
            </Button>
          </ButtonBox>
        </Box>
        {isForm ? (
          <FormSubmitButton isLoading={isLoading}>
            <Text type="WhiteColor" sx={{ paddingX: "15px" }}>
              Next
            </Text>
          </FormSubmitButton>
        ) : (
          <CtaButton onClickButton={backgroundButtonPress} isLoading={isLoading}>
            <Text type="WhiteColor" sx={{ paddingX: "15px" }}>
              Next
            </Text>
          </CtaButton>
        )}
      </SpaceBetweenBox>
    </>
  );
}
PCBottomBar.defaultProps = {
  isLoading: false,
  backgroundButtonPress: null,
  isForm: true,
};

PCBottomBar.propTypes = {
  handleExit: PropTypes.func.isRequired,
  onBackPress: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isForm: PropTypes.bool,
  backgroundButtonPress: PropTypes.func,
};

export default PCBottomBar;
