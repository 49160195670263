import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { TextField, Box } from "@mui/material";

function FormFieldTextWithCounter({ name, placeholder, width, height }) {
  const theme = useTheme();
  const { setFieldTouched, setFieldValue, errors, touched, values, handleSubmit } =
    useFormikContext();

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const showError = touched[name] && typeof errors[name] === "string";

  return (
    <Box
      sx={{
        "& .MuiTextField-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          width,
          "& .MuiOutlinedInput-root": {
            minHeight: height,
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: "transparent",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          },
        },
      }}
    >
      <TextField
        multiline
        onKeyDown={keyPress}
        error={showError}
        helperText={showError ? errors[name] : null}
        onBlur={() => setFieldTouched(name)}
        name={name}
        placeholder={placeholder}
        // inputProps={{ maxLength }}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
        value={values[name] === null ? "" : values[name]}
      />
      {/* <Text type="GreyColor" sx={{ fontSize: "13px" }}>{`${
        (values[name] && values[name].length) || 0
      }/${maxLength} characters`}</Text> */}
    </Box>
  );
}

FormFieldTextWithCounter.defaultProps = {
  width: "100%",
  height: "300px",
};

FormFieldTextWithCounter.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  // maxLength: PropTypes.number.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default FormFieldTextWithCounter;
