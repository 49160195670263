import React, { useState } from "react";
import { Box, Grid, IconButton, styled, useTheme } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useSelector } from "react-redux";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import DialogModal from "../../../components/notifications/dialog-modal.component";
import StatementCard from "./statement-card.component";
import { statementSelector } from "../../../services/statement/statement-slice.services";

const StatusContainer = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  paddingTop: theme.padding.paddingX[4],
  paddingBottom: theme.padding.paddingX[4],
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "125px",
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const InfoButton = styled(IconButton)(({ theme }) => ({
  padding: "0px",
  color: theme.palette.colors.ui.blue,
}));

function CurrentStatement() {
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);
  const { getCurrentStatementObj } = useSelector(statementSelector);

  return (
    <Grid container spacing={5}>
      <DialogModal
        showModal={showModal}
        setShowModal={setShowModal}
        title="Payments made through bank accounts take up to 3-5 business days."
        buttonText="OK"
        onConfirm={() => setShowModal(false)}
      />
      {getCurrentStatementObj.data.rejected.length === 0 &&
        getCurrentStatementObj.data.open.length === 0 &&
        getCurrentStatementObj.data.pending.length === 0 && (
          <Grid item xs={12}>
            <CenterRowBox sx={{ justifyContent: "center" }}>
              <Text>Currently no statement</Text>
            </CenterRowBox>
          </Grid>
        )}
      {getCurrentStatementObj.data.rejected.length > 0 && (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StatusContainer sx={{ backgroundColor: theme.palette.colors.ui.redDot }}>
                <Text type="WhiteColor">Rejected</Text>
              </StatusContainer>
            </Grid>
            {getCurrentStatementObj.data.rejected.map((item) => (
              <Grid item xs={12} key={item.statementId}>
                <StatementCard
                  id={item.statementId}
                  price={item.amount}
                  status="rejected"
                  reason={item.rejectedReason}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {getCurrentStatementObj.data.open.length > 0 && (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StatusContainer sx={{ backgroundColor: theme.palette.colors.button.switch }}>
                <Text type="WhiteColor">Open</Text>
              </StatusContainer>
            </Grid>
            {getCurrentStatementObj.data.open.map((item) => (
              <Grid item xs={12} key={item.statementId}>
                <StatementCard id={item.statementId} price={item.amount} status="open" />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {getCurrentStatementObj.data.pending.length > 0 && (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CenterRowBox>
                <StatusContainer sx={{ backgroundColor: theme.palette.colors.ui.blue }}>
                  <Text type="WhiteColor">To be paid</Text>
                </StatusContainer>
                <Spacer size="s" position="left" />
                <InfoButton onClick={() => setShowModal(true)}>
                  <InfoIcon />
                </InfoButton>
              </CenterRowBox>
            </Grid>
            {getCurrentStatementObj.data.pending.map((item) => (
              <Grid item xs={12} key={item.statementId}>
                <StatementCard
                  id={item.statementId}
                  price={item.amount}
                  status="pending"
                  date={item.nextTransferDate}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default CurrentStatement;
