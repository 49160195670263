import React, { useContext, useEffect } from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import {
  logout,
  postVerificationCode,
  resendVerificationCode,
  resetUser,
} from "../../../../services/auth/auth-slice.services";
import routes from "../../../../navigation/routes";
import PaddedView from "../../../../components/padded-view.component";
import YoloLogo from "../../../../assets/images/yolo_logo";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import Form from "../../../../components/forms/form.component";
import VerificationForm from "../components/verification-form.component";

const StyledMainContainer = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  width: "100%",
  backgroundColor: theme.palette.colors.bg.primary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledMainGrid = styled(Grid)({
  justifyContent: "center",
  height: "100%",
  alignItems: "center",
  flexDirection: "column",
});

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  verificationCode: Yup.string().required().label("Verification Code"),
});

function EmailVerificationScreen(props) {
  const newProps = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { email } = newProps.history.location.state;
  const createSnackBar = useContext(SnackbarContext);

  useEffect(() => {
    localStorage.setItem("backToScreen", "email");
    localStorage.setItem("merchantEmail", email);
  }, []);

  const handleLogout = () => {
    dispatch(logout()).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        history.push(routes.LOGIN);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: "Logout Successful",
          type: "success",
          open: true,
        });
        dispatch(resetUser());
        history.push(routes.LOGIN);
      }
    });
  };

  const handleSubmitVerificationCode = (values) => {
    dispatch(postVerificationCode(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        localStorage.removeItem("merchantEmail");
        if (
          !newProps.history.location.state?.fromRegister &&
          !newProps.history.location.state?.fromLogin
        ) {
          history.push(routes.PROFILE);
          return;
        }
        history.push(routes.BUSINESSDETAILS);
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleResendCode = () => {
    const values = {};
    values.email = email;
    values.type = "register";
    dispatch(resendVerificationCode(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    if (
      !newProps.history.location.state?.fromRegister &&
      !newProps.history.location.state?.updateEmail
    ) {
      handleResendCode();
    }
  }, []);

  return (
    <StyledMainContainer>
      <PaddedView multiples={isMobile ? 2 : 4}>
        <StyledMainGrid container spacing={4}>
          <Grid item xs={12} sx={{ height: isMobile ? 45 : 60, textAlign: "center" }}>
            <YoloLogo isDisable={true} />
          </Grid>
          <Grid item xs={12}>
            <Form
              initialValues={{
                email,
                verificationCode: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmitVerificationCode}
            >
              <VerificationForm handleResendCode={handleResendCode} handleLogout={handleLogout} />
            </Form>
          </Grid>
        </StyledMainGrid>
      </PaddedView>
    </StyledMainContainer>
  );
}

export default EmailVerificationScreen;
