import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq } from "../api.services";

export const getCategories = createAsyncThunk("auth/merchants/get_categories", async () => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/categories`,
    );
    return response;
});

const categorySlice = createSlice({
    name: "category",
    initialState: {
      getCategoriesObj: {
        status: "idle",
        data: null,
        successMessage: null,
        errorMessage: null,
      },
    },
    reducers: {},
    extraReducers: {
      [getCategories.pending]: (state) => {
        state.getCategoriesObj.status = "pending";
      },
      [getCategories.fulfilled]: (state, action) => {
        const { data, message } = action.payload;
  
        state.getCategoriesObj.status = "succeeded";
        state.getCategoriesObj.data = data;
        state.getCategoriesObj.successMessage = message;
      },
      [getCategories.rejected]: (state, action) => {
        const { message } = action.error;
  
        state.getCategoriesObj.status = "failed";
        state.getCategoriesObj.errorMessage = message;
      },
    },
  });
  
  export default categorySlice.reducer;
  
  // state
  export const categorySelector = (state) => state.category;