import React from "react";
import { Box, Button, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import Text from "../../../../components/text.component";
import Spacer from "../../../../components/spacer.component";

const ContentContainer = styled(Box)(({ isMobile }) => ({
  width: "100%",
  overflowY: "scroll",
  height: isMobile ? null : "85%",
  display: "flex",
  flexDirection: "column",
  alignItems: isMobile ? null : "center",
  paddingBottom: isMobile ? "100px" : null,
}));

const ButtonBox = styled(Box)(({ theme, isMobile }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: isMobile ? "100%" : "80%",
  flexDirection: "column",
  "& .MuiButton-root": {
    width: isMobile ? "100%" : "80%",
    backgroundColor: theme.palette.colors.bg.secondary,
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    textTransform: "none",
    padding: 1,
    "&:hover": {
      backgroundColor: theme.palette.colors.bg.secondary,
    },
  },
}));

function SelectLocationInputType({ setCurrentScreen }) {
  const theme = useTheme();
  const TitleText = "Where's Your Experience Located At?";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ContentContainer isMobile={isMobile}>
      <Text
        variant={isMobile ? "h5" : "h3"}
        sx={{
          fontWeight: theme.fonts.fontWeights.bold,
          textAlign: isMobile ? "start" : "center",
        }}
      >
        {TitleText}
      </Text>
      <Spacer size={isMobile ? "xl" : "xxl"} position="top" />
      <ButtonBox isMobile={isMobile}>
        <Button variant="contained" onClick={() => setCurrentScreen("Address Form")}>
          <FmdGoodIcon sx={{ color: theme.palette.colors.brand.secondary }} />
          <Text
            variant={isMobile ? "body1" : "h5"}
            sx={{
              padding: theme.padding.paddingX[3],
              fontWeight: theme.fonts.fontWeights.regular,
              textAlign: isMobile ? "start" : "center",
            }}
          >
            Enter address manually
          </Text>
        </Button>
        {/* <Spacer size="xl" position="top" />
          <Button onClick={() => setCurrentScreen("Location Map")}>
            <GpsFixedIcon sx={{ color: theme.palette.colors.brand.secondary }} />

            <Text
              variant={isMobile ? "body1" : "h5"}
              sx={{
                padding: theme.padding.paddingX[3],
                fontWeight: theme.fonts.fontWeights.regular,
                textAlign: isMobile ? "start" : "center",
              }}
            >
              Use my current location
            </Text>
          </Button> */}
      </ButtonBox>
    </ContentContainer>
  );
}

SelectLocationInputType.propTypes = {
  setCurrentScreen: PropTypes.func.isRequired,
};

export default SelectLocationInputType;
