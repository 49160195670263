import React from "react";
import { Box, Grid, styled } from "@mui/material";
import { useSelector } from "react-redux";
import Text from "../../../components/text.component";
import { profileSelector } from "../../../services/profile/profile-slice.services";

const SeperateLine = styled(Box)(({ theme }) => ({
  marginTop: "25px",
  marginBottom: "25px",
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

function BankForm() {
  const { getProfileObj } = useSelector(profileSelector);

  if (
    getProfileObj.data.bankDetails.name !== null &&
    getProfileObj.data.bankDetails.bankName !== null &&
    getProfileObj.data.bankDetails.bankAccountNo !== null
  ) {
    return (
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Text>Bank Details</Text>
        </SpaceBetweenBox>
        <Text type="GreyColor">{`Account Holder Name: ${
          getProfileObj.data.bankDetails.name || ""
        }`}</Text>
        <Text type="GreyColor">
          {`Bank Name: ${getProfileObj.data.bankDetails.bankName || ""}`}
        </Text>
        {/* <Text type="GreyColor">{`Bank Account No.: **** **** ${getProfileObj.data.bankDetails.bankAccountNo.slice(
          -4,
        )}`}</Text> */}
        <Text type="GreyColor">
          {`Bank Account No.: ${getProfileObj.data.bankDetails.bankAccountNo || ""}` || ""}
        </Text>
        <SeperateLine />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <SpaceBetweenBox>
        <Text>Bank Details</Text>
      </SpaceBetweenBox>
      <SeperateLine />
    </Grid>
  );
}

export default BankForm;
