import React from "react";
import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import Text from "../../../../components/text.component";
import Spacer from "../../../../components/spacer.component";
import FormTextEditor from "../../../../components/forms/form-text-editor.component";
import { experienceSelector } from "../../../../services/experience/experience-slice.services";
import SkeletonLoader from "../../../../components/skeleton.component";

const ContentContainer = styled(Box)(({ isMobile }) => ({
  width: "100%",
  overflowY: "scroll",
  height: isMobile ? null : "85%",
  display: "flex",
  flexDirection: "column",
  alignItems: isMobile ? null : "center",
  paddingBottom: isMobile ? "100px" : null,
}));

function ExperienceDescriptionForm() {
  const theme = useTheme();
  const TitleText = "Let's describe your Experience";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getExperiencesDetailsObj } = useSelector(experienceSelector);

  return (
    <ContentContainer isMobile={isMobile}>
      <Text
        variant={isMobile ? "h5" : "h3"}
        sx={{
          fontWeight: theme.fonts.fontWeights.bold,
          textAlign: isMobile ? "start" : "center",
        }}
      >
        {TitleText}
      </Text>
      <Spacer size={isMobile ? "l" : "xl"} position="top" />

      <Box sx={{ width: "100%" }}>
        <Text sx={{ textAlign: "start" }}>Create your description</Text>
        <Spacer size="s" position="top" />
        {getExperiencesDetailsObj.status === "succeeded" ? (
          <FormTextEditor
            name="description"
            placeholder="Description"
            maxLength={500}
            height="300px"
          />
        ) : (
          <SkeletonLoader height="300px" />
        )}
      </Box>
    </ContentContainer>
  );
}

export default ExperienceDescriptionForm;
