import React, { useContext, useEffect } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import SideProgressView from "../../components/side-progress-view.component";
import SaveAndExitWithBg from "../../components/save-exit-with-bg.component";
import MobileBottomBar from "../../components/mobile-bottom-bar.component";
import routes from "../../../../navigation/routes";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import {
  experienceSelector,
  getExperiencesDetails,
  updateExperience,
} from "../../../../services/experience/experience-slice.services";
import Form from "../../../../components/forms/form.component";

import PCBottomBar from "../../components/pc-bottom-bar.component";
import ImportantInfoForm from "../components/important-info-form.component";

const validationSchema = Yup.object().shape({
  importantInfo: Yup.string().required().label("Important Info"),
  experienceId: Yup.string().required().label("Experience Id"),
  type: Yup.string().required().label("Type"),
  isExit: Yup.bool(),
});

function ImportantInfoFieldSreen() {
  const location = useLocation();
  const experienceId = new URLSearchParams(location.search).get("experienceId");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { updateExperienceObj, getExperiencesDetailsObj } = useSelector(experienceSelector);

  const handleSubmitImportantInfo = (values, { isExit }) => {
    if (values.importantInfo === "" && isExit) {
      history.push(routes.HOME);
      return;
    }
    dispatch(updateExperience(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        history.push({
          pathname: isExit ? routes.HOME : `${routes.SET_PAX}`,
          search: `experienceId=${experienceId}`,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    dispatch(getExperiencesDetails(experienceId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  return (
    <Form
      validationSchema={validationSchema}
      initialValues={{
        importantInfo:
          (getExperiencesDetailsObj.status === "succeeded" &&
            getExperiencesDetailsObj.data.experience.importantInfo) ||
          "",
        experienceId,
        type: "important",
        isExit: false,
      }}
      onSubmit={handleSubmitImportantInfo}
    >
      <Grid container>
        {isMobile ? (
          <Grid item xs={12}>
            <SaveAndExitWithBg handleSubmit={handleSubmitImportantInfo} />
          </Grid>
        ) : (
          <Grid item xs={3}>
            <SideProgressView step={6} />
          </Grid>
        )}

        <Grid item xs={isMobile ? 12 : 9}>
          <Grid
            container
            sx={{
              width: "100%",
              padding: theme.padding.paddingX[0],
              height: isMobile ? null : theme.dimensions.heightWithoutAppBar,
            }}
          >
            <ImportantInfoForm />
            {!isMobile && (
              <PCBottomBar
                isLoading={updateExperienceObj.status === "pending"}
                handleExit={handleSubmitImportantInfo}
                onBackPress={() => history.goBack()}
              />
            )}
          </Grid>
        </Grid>

        {isMobile && (
          <Grid item xs={12}>
            <MobileBottomBar
              borderButtonLabel="Back"
              backgroundButtonLabel="Next"
              borderButtonOnPress={() => history.goBack()}
              isBackgroundButtonLoading={updateExperienceObj.status === "pending"}
            />
          </Grid>
        )}
      </Grid>
    </Form>
  );
}

export default ImportantInfoFieldSreen;
