import React, { useContext, useState } from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import PaddedView from "../../../../components/padded-view.component";
import Spacer from "../../../../components/spacer.component";
import FormLabel from "../../../../components/forms/form-label.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import routes from "../../../../navigation/routes";
import Text from "../../../../components/text.component";
import { authSelector, emailCheck } from "../../../../services/auth/auth-slice.services";
import AgreementCheckbox from "./agreement-checkbox.component";
import FormDebounceFieldText from "./form-debounce-input-field.component";
import Form from "../../../../components/forms/form.component";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";

const CardContainer = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: theme.palette.colors.bg.white,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  flex: 1,
  maxWidth: 500,
  minWidth: isMobile ? 340 : 450,
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "80px",
  backgroundColor: theme.palette.colors.bg.primary,
  justifyContent: "center",
  alignItems: "center",
}));

const emailValidationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});

function SignUpForm() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { registerObj } = useSelector(authSelector);
  const [isAgreeTnc, setIsAgreeTnc] = useState(false);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const { setFieldValue } = useFormikContext();
  const { createSnackBar } = useContext(SnackbarContext);

  const onChangeEmail = (values) => {
    setErrorMessage("");
    dispatch(emailCheck(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        setFieldValue("email", values.email);
      }
      if (meta.requestStatus === "rejected") {
        setErrorMessage(error.message);
        setFieldValue("email", "");
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <CardContainer isMobile={isMobile} sx={{ boxShadow: 2 }}>
      <TitleContainer>
        <Text variant="h5">Sign Up</Text>
      </TitleContainer>

      <PaddedView multiples={isMobile ? 3 : 4}>
        <FormLabel>Email</FormLabel>
        <Spacer size="s" />
        <Form
          validationSchema={emailValidationSchema}
          initialValues={{ email: "" }}
          onSubmit={onChangeEmail}
        >
          <FormDebounceFieldText
            errorMessage={errorMessage}
            isDisableEnter={!isAgreeTnc}
            name="email"
            placeholder="Enter Your Email Address"
          />
        </Form>

        <Spacer size="m" />
        <FormLabel>Password</FormLabel>
        <Spacer size="s" />
        <FormFieldText
          isDisableEnter={!isAgreeTnc}
          name="password"
          placeholder="Enter Your Password"
        />

        <Spacer size="m" />
        <FormLabel>Confirm Password</FormLabel>
        <Spacer size="s" />
        <FormFieldText
          isDisableEnter={!isAgreeTnc}
          type="password"
          name="confirmPassword"
          placeholder="Confirm Your Password"
        />
        <Spacer size="s" />
        <Grid container justifyContent="space-between" sx={{ paddingTop: "5px" }}>
          <AgreementCheckbox isAgreeTnc={isAgreeTnc} setIsAgreeTnc={setIsAgreeTnc} />
        </Grid>

        <Spacer size="xl" />
        <Grid container justifyContent="center">
          <Text variant="body2">
            {"Already have an account? "}
            <Link
              style={{ textTransform: "none", color: theme.palette.colors.text.primary }}
              to={{ pathname: routes.LOGIN }}
            >
              Login
            </Link>
          </Text>
        </Grid>

        <Spacer size="s" />
        <FormSubmitButton isLoading={registerObj.status === "pending"} disabled={!isAgreeTnc}>
          <Typography>Continue</Typography>
        </FormSubmitButton>
      </PaddedView>
    </CardContainer>
  );
}

export default SignUpForm;
