import React from "react";
import { Box, Grid, styled } from "@mui/material";
import PropTypes from "prop-types";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import BorderButton from "../../../components/buttons/border-button.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import CtaButton from "../../../components/buttons/cta-button.component";

const StyledBottomNavBar = styled(Box)(({ theme }) => ({
  background: theme.palette.colors.bg.white,
  position: "fixed",
  width: "100%",
  zIndex: 1,
  bottom: 0,
}));

const MainGrid = styled(Grid)(({ theme }) => ({
  padding: theme.padding.paddingX[2],
}));

function MobileBottomBar({
  borderButtonLabel,
  backgroundButtonLabel,
  borderButtonOnPress,
  backgroundButtonPress,
  isBackgroundButtonLoading,
  isForm,
}) {
  return (
    <StyledBottomNavBar sx={{ boxShadow: 3 }}>
      <MainGrid container>
        <Spacer size="xl" position="top" />
        <Grid container justifyContent="space-between">
          <BorderButton onClickButton={borderButtonOnPress}>
            <Text type="BrandColor" sx={{ paddingX: "15px" }}>
              {borderButtonLabel}
            </Text>
          </BorderButton>
          {isForm ? (
            <FormSubmitButton isLoading={isBackgroundButtonLoading}>
              <Text type="WhiteColor" sx={{ paddingX: "15px" }}>
                {backgroundButtonLabel}
              </Text>
            </FormSubmitButton>
          ) : (
            <CtaButton onClickButton={backgroundButtonPress} isLoading={isBackgroundButtonLoading}>
              <Text type="WhiteColor" sx={{ paddingX: "15px" }}>
                {backgroundButtonLabel}
              </Text>
            </CtaButton>
          )}
        </Grid>
      </MainGrid>
    </StyledBottomNavBar>
  );
}

MobileBottomBar.defaultProps = {
  borderButtonLabel: "Back",
  backgroundButtonLabel: "Next",
  borderButtonOnPress: null,
  backgroundButtonPress: null,
  isBackgroundButtonLoading: false,
  isForm: true,
};

MobileBottomBar.propTypes = {
  borderButtonLabel: PropTypes.string,
  backgroundButtonLabel: PropTypes.string,
  borderButtonOnPress: PropTypes.func,
  backgroundButtonPress: PropTypes.func,
  isBackgroundButtonLoading: PropTypes.bool,
  isForm: PropTypes.bool,
};

export default MobileBottomBar;
