import "react-image-crop/dist/ReactCrop.css";
import React, { useEffect, useState } from "react";
import { Box, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import ReactCrop from "react-image-crop";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Text from "./text.component";
import BorderButton from "./buttons/border-button.component";
import Spacer from "./spacer.component";
import { experienceSelector } from "../services/experience/experience-slice.services";
import CtaButton from "./buttons/cta-button.component";

const ModalBox = styled(Box)(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.primary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "20px",
  paddingRight: "20px",
  maxWidth: isMobile ? "350px" : "60%",
  outline: "none",
  overflowY: "auto",
  maxHeight: "650px",
}));

function ImageCropper({ isShowModal, setIsShowModal, handleUploadImage, file }) {
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({
    unit: "px",
    aspect: 16 / 9,
    width: 100,
    height: 56.25,
    x: 0,
    y: 0,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { uploadImageObj } = useSelector(experienceSelector);

  useEffect(() => {
    setCrop({
      unit: "px",
      aspect: 16 / 9,
      width: 100,
      height: 56.25,
      x: 0,
      y: 0,
    });
  }, [file]);

  const handleCancel = () => {
    setCrop({
      unit: "px",
      aspect: 16 / 9,
      width: 100,
      height: 56.25,
      x: 0,
      y: 0,
    });
    setIsShowModal(false);
  };

  const getCroppedImage = () => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );
    const base64ImageURL = canvas.toDataURL("image/webp");
    handleUploadImage({ image: base64ImageURL });
  };

  return (
    <Modal open={isShowModal} onClose={handleCancel}>
      <ModalBox isMobile={isMobile}>
        {file && (
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <ReactCrop crop={crop} onChange={setCrop} aspect={16 / 9} keepSelection={true}>
                <img
                  src={file}
                  alt="test"
                  onLoad={(o) => {
                    setImage(o.target);
                  }}
                />
              </ReactCrop>
            </Box>
            <Spacer size="l" position="top" />
            <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
              <Box sx={{ width: "45%" }}>
                <CtaButton
                  onClickButton={getCroppedImage}
                  isLoading={uploadImageObj.status === "pending"}
                >
                  <Text type="WhiteColor">Upload</Text>
                </CtaButton>
              </Box>
              <Box sx={{ width: "45%" }}>
                <BorderButton
                  onClickButton={handleCancel}
                  isLoading={uploadImageObj.status === "pending"}
                >
                  <Text type="BrandColor">Cancel</Text>
                </BorderButton>
              </Box>
            </Box>
          </Box>
        )}
      </ModalBox>
    </Modal>
  );
}
ImageCropper.defaultProps = {
  file: "",
};

ImageCropper.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  setIsShowModal: PropTypes.func.isRequired,
  handleUploadImage: PropTypes.func.isRequired,
  file: PropTypes.string,
};

export default ImageCropper;
