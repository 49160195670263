import React, { useContext, useEffect } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import SideProgressView from "../../components/side-progress-view.component";
import SaveAndExitWithBg from "../../components/save-exit-with-bg.component";
import MobileBottomBar from "../../components/mobile-bottom-bar.component";
import routes from "../../../../navigation/routes";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import {
  experienceSelector,
  getExperiencesDetails,
} from "../../../../services/experience/experience-slice.services";
import Form from "../../../../components/forms/form.component";
import PCBottomBar from "../../components/pc-bottom-bar.component";
import ExperiencePreview from "../components/experience-preview.component";

const validationSchema = Yup.object().shape({
  title: Yup.string().required().label("Title"),
  experienceId: Yup.string().required().label("Experience Id"),
  type: Yup.string().required().label("Type"),
  isExit: Yup.bool(),
});

function CheckOutPreviewScreen() {
  const location = useLocation();
  const experienceId = new URLSearchParams(location.search).get("experienceId");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getExperiencesDetailsObj } = useSelector(experienceSelector);

  const handleExit = () => {
    history.push(routes.HOME);
  };

  const handleOnPressNext = () => {
    history.push({
      pathname: `${routes.PUBLISH_EXPERIENCE}`,
      search: `experienceId=${experienceId}`,
    });
  };

  useEffect(() => {
    dispatch(getExperiencesDetails(experienceId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  return (
    <Form
      validationSchema={validationSchema}
      initialValues={{
        experienceId,
      }}
      onSubmit={handleOnPressNext}
    >
      <Grid container>
        {isMobile ? (
          <Grid item xs={12}>
            <SaveAndExitWithBg handleSubmit={handleExit} />
          </Grid>
        ) : (
          <Grid item xs={3}>
            <SideProgressView step={9} />
          </Grid>
        )}

        <Grid item xs={isMobile ? 12 : 9}>
          <Grid
            container
            sx={{
              width: "100%",
              padding: theme.padding.paddingX[0],
              height: isMobile ? null : theme.dimensions.heightWithoutAppBar,
            }}
          >
            <ExperiencePreview />
            {!isMobile && (
              <PCBottomBar
                isLoading={getExperiencesDetailsObj.status === "pending"}
                handleExit={handleExit}
                onBackPress={() => history.goBack()}
                isForm={false}
                backgroundButtonPress={handleOnPressNext}
              />
            )}
          </Grid>
        </Grid>

        {isMobile && (
          <Grid item xs={12}>
            <MobileBottomBar
              borderButtonLabel="Back"
              backgroundButtonLabel="Next"
              borderButtonOnPress={() => history.goBack()}
              isBackgroundButtonLoading={getExperiencesDetailsObj.status === "pending"}
              isForm={false}
              backgroundButtonPress={handleOnPressNext}
            />
          </Grid>
        )}
      </Grid>
    </Form>
  );
}

export default CheckOutPreviewScreen;
