import React from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Text from "../../../../components/text.component";
import Spacer from "../../../../components/spacer.component";
import UploadImagesGrid from "./upload-images-grid.component";
import { experienceSelector } from "../../../../services/experience/experience-slice.services";
import SkeletonLoader from "../../../../components/skeleton.component";

const ContentContainer = styled(Box)(({ isMobile }) => ({
  width: "100%",
  height: isMobile ? null : "85%",
  display: "flex",
  flexDirection: "column",
  alignItems: isMobile ? null : "center",
  paddingBottom: isMobile ? "100px" : null,
}));

const FullWidthScrollBox = styled(Box)(({ isMobile }) => ({
  overflowY: "scroll",
  width: isMobile ? "100%" : "90%",
}));

function UploadImagesForm({ handleDeleteImage, handleOrderImage, setFile, setShowImageCropper }) {
  const theme = useTheme();
  const TitleText = "Add Photos Of Your Experience";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getExperiencesDetailsObj } = useSelector(experienceSelector);
  const imageHeight = isMobile ? "200px" : "400px";

  const renderContent = () => {
    if (getExperiencesDetailsObj.status === "succeeded") {
      return (
        <FullWidthScrollBox isMobile={isMobile}>
          <UploadImagesGrid
            images={getExperiencesDetailsObj.data.images}
            deleteImage={handleDeleteImage}
            handleOrderImage={handleOrderImage}
            setFile={setFile}
            setShowImageCropper={setShowImageCropper}
          />
        </FullWidthScrollBox>
      );
    }
    return (
      <FullWidthScrollBox isMobile={isMobile}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SkeletonLoader height={imageHeight} />
          </Grid>
          <Grid item xs={6}>
            <SkeletonLoader height={imageHeight} />
          </Grid>
          <Grid item xs={6}>
            <SkeletonLoader height={imageHeight} />
          </Grid>
        </Grid>
      </FullWidthScrollBox>
    );
  };

  return (
    <ContentContainer isMobile={isMobile}>
      <Text
        variant={isMobile ? "h5" : "h3"}
        sx={{
          fontWeight: theme.fonts.fontWeights.bold,
          textAlign: isMobile ? "start" : "center",
        }}
      >
        {TitleText}
      </Text>
      <Spacer size="l" position="top" />
      <Text sx={{ textAlign: "start", width: isMobile ? "100%" : "90%" }}>
        Arrange photos for your experience
      </Text>

      <Spacer size="s" position="top" />
      {renderContent()}
    </ContentContainer>
  );
}

UploadImagesForm.propTypes = {
  setShowImageCropper: PropTypes.func.isRequired,
  setFile: PropTypes.func.isRequired,
  handleDeleteImage: PropTypes.func.isRequired,
  handleOrderImage: PropTypes.func.isRequired,
};

export default UploadImagesForm;
