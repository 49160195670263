import React, { useContext } from "react";
import { Avatar, Box, styled, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import {
  profileSelector,
  uploadProfileImage,
} from "../../../services/profile/profile-slice.services";
import ProfileImageUpload from "./profile-image-upload.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import SkeletonLoader from "../../../components/skeleton.component";

const TopAvatarContainer = styled(Box)(({ theme, isMobile }) => ({
  width: "100%",
  height: isMobile ? null : "30%",
  backgroundColor: isMobile ? "transparent" : theme.palette.colors.bg.secondary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.padding.paddingX[0],
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
});

function ProfileSideMenuUserDetails() {
  const { getProfileObj } = useSelector(profileSelector);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));

  const handleUploadImage = (values) => {
    dispatch(uploadProfileImage(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  if (getProfileObj.status === "succeeded") {
    return (
      <TopAvatarContainer isMobile={isMobile}>
        <ProfileImageUpload
          handleUploadImage={handleUploadImage}
          imagePath={getProfileObj.data.merchant.imagePath}
        />
        <Spacer size="s" position="left" />
        <Box>
          <Text variant="h6" sx={{ fontWeight: theme.fonts.fontWeights.regular }}>{`Hi, ${
            getProfileObj.data.merchant.name || "there"
          }!`}</Text>
          <Spacer size="xs" position="top" />
          {getProfileObj.data.merchant.status === "approved" && (
            <CenterRowBox>
              <DoneIcon sx={{ fontSize: "12px", color: theme.palette.colors.text.secondary }} />
              <Spacer size="xs" position="left" />
              <Text sx={{ fontSize: "12px" }} type="GreyColor">
                Account Verified
              </Text>
            </CenterRowBox>
          )}
          {getProfileObj.data.merchant.status === "pending" && (
            <Text sx={{ fontSize: "12px" }} type="GreyColor">
              Pending Verification
            </Text>
          )}

          {getProfileObj.data.merchant.status === "rejected" && (
            <Text sx={{ fontSize: "12px", color: theme.palette.colors.text.secondary }}>
              Verification Rejected
            </Text>
          )}
          {getProfileObj.data.merchant.status === "rejected" && (
            <Text sx={{ fontSize: "12px", color: theme.palette.colors.text.error }}>
              {getProfileObj.data.merchant.reason}
            </Text>
          )}
        </Box>
      </TopAvatarContainer>
    );
  }

  return (
    <TopAvatarContainer>
      <Avatar
        sx={{
          width: 75,
          height: 75,
        }}
      />
      <Spacer size="s" position="left" />
      <SkeletonLoader width="75px" height="25px" />
    </TopAvatarContainer>
  );
}

export default ProfileSideMenuUserDetails;
