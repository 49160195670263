import { Box, Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Text from "../../../components/text.component";
import { calendarSelector } from "../../../services/calendar/calendar-slice.services";
import SkeletonLoader from "../../../components/skeleton.component";
import MultipleBookingTimeSlotCard from "./multiple-booking-timeslot-card.component";
import MultipleBookingCard from "./multiple-booking-card.component";

const SideMenuContainer = styled(Box)(({ theme, isMobile }) => ({
  width: isMobile ? "100%" : "500px",
  paddingTop: theme.padding.paddingX[0],
  paddingBottom: theme.padding.paddingX[0],
  paddingLeft: isMobile ? theme.padding.paddingX[0] : null,
  paddingRight: isMobile ? theme.padding.paddingX[0] : null,
}));

const AddSlotButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  textDecoration: "underline",
  textDecorationColor: theme.palette.colors.brand.primary,
  "&:hover": {
    backgroundColor: "transparent",
    borderColor: "transparent",
    boxShadow: "none",
    textDecoration: "underline",
    textDecorationColor: theme.palette.colors.brand.primary,
  },
}));

const HorizontalPaddedBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.padding.paddingX[2],
  paddingRight: theme.padding.paddingX[0],
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
});

const HorizontalSeperateLine = styled(Box)(({ theme }) => ({
  height: "0.5px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const BookingCardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.secondary,
  padding: theme.padding.paddingX[2],
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

function CalendarSideMenu({
  selectedDate,
  selectedExperience,
  setShowAddSlotModal,
  setShowDeleteSlotModal,
  setCancelTimeSlotId,
  setShowEditSlotModal,
  setShowUnableDeleteModal,
  setShowBookedModal,
}) {
  const theme = useTheme();
  const { getCalendarDetailsObj } = useSelector(calendarSelector);
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));

  const renderBookings = () => {
    if (getCalendarDetailsObj.status === "succeeded") {
      if (getCalendarDetailsObj.data.dates[format(new Date(selectedDate), "yyyy-MM-dd")]) {
        return (
          <Grid container spacing={3}>
            {getCalendarDetailsObj.data.dates[format(new Date(selectedDate), "yyyy-MM-dd")].map(
              (item) => (
                <MultipleBookingCard
                  key={item.id}
                  cardDetails={item}
                  selectedExperience={selectedExperience}
                />
              ),
            )}
          </Grid>
        );
      }
      return (
        <Grid container spacing={3}>
          <Grid xs={12} item>
            <Text>No booking for today</Text>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <BookingCardContainer>
            {selectedExperience === "all" && (
              <SpaceBetweenRowBox>
                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                  <SkeletonLoader width="100px" />
                </Text>
              </SpaceBetweenRowBox>
            )}
            <SpaceBetweenRowBox>
              <Text type="GreyColor">Time:</Text>
              <SkeletonLoader width="100px" />
            </SpaceBetweenRowBox>
            <SpaceBetweenRowBox>
              <Text type="GreyColor">Guest:</Text>
              <SkeletonLoader width="100px" />
            </SpaceBetweenRowBox>
          </BookingCardContainer>
        </Grid>
      </Grid>
    );
  };

  const renderTimeSlot = () => {
    if (getCalendarDetailsObj.status === "succeeded") {
      if (getCalendarDetailsObj.data.slots[format(new Date(selectedDate), "yyyy-MM-dd")]) {
        return (
          <Grid container spacing={3}>
            {getCalendarDetailsObj.data.slots[format(new Date(selectedDate), "yyyy-MM-dd")].map(
              (item) =>
                item.bookingType !== "flexi" && (
                  <MultipleBookingTimeSlotCard
                    key={item.id}
                    cardDetails={item}
                    setCancelTimeSlotId={setCancelTimeSlotId}
                    setShowDeleteSlotModal={setShowDeleteSlotModal}
                    setShowEditSlotModal={setShowEditSlotModal}
                    setShowUnableDeleteModal={setShowUnableDeleteModal}
                    setShowBookedModal={setShowBookedModal}
                  />
                ),
            )}
          </Grid>
        );
      }
      return (
        <Grid container spacing={3}>
          <Grid xs={12} item>
            <Text>No Available Timeslot</Text>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <BookingCardContainer>
            <Text type="GreyColor">Check-in</Text>
            <SkeletonLoader />
            <Text type="GreyColor">Check-out</Text>
            <SkeletonLoader />
          </BookingCardContainer>
        </Grid>
      </Grid>
    );
  };

  const renderContent = () => {
    if (selectedDate === null) {
      return (
        <HorizontalPaddedBox
          sx={{
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Text sx={{ textAlign: "center" }}>
            Select a date to check your availability and bookings.
          </Text>
        </HorizontalPaddedBox>
      );
    }
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HorizontalPaddedBox>
            <Text
              variant="h5"
              sx={{
                fontWeight: theme.fonts.fontWeights.bold,
                fontSize: "28px",
              }}
            >
              {format(new Date(selectedDate), "eee, dd MMM")}
            </Text>
          </HorizontalPaddedBox>
        </Grid>
        <Grid item xs={12}>
          <HorizontalSeperateLine />
        </Grid>
        {selectedExperience !== "all" && (
          <>
            <Grid item xs={12}>
              <HorizontalPaddedBox>
                <SpaceBetweenRowBox>
                  <Text>Timeslot</Text>
                  {new Date(selectedDate) > new Date() && (
                    <AddSlotButton disableRipple onClick={() => setShowAddSlotModal(true)}>
                      <Text type="BrandColor">Add Timeslot</Text>
                    </AddSlotButton>
                  )}
                </SpaceBetweenRowBox>
              </HorizontalPaddedBox>
            </Grid>
            <Grid item xs={12}>
              <HorizontalPaddedBox>{renderTimeSlot()}</HorizontalPaddedBox>
            </Grid>
            <Grid item xs={12}>
              <HorizontalSeperateLine />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <HorizontalPaddedBox>
            <Text>Bookings</Text>
          </HorizontalPaddedBox>
        </Grid>
        <Grid item xs={12}>
          <HorizontalPaddedBox>{renderBookings()}</HorizontalPaddedBox>
        </Grid>
        <Grid item xs={12} />
      </Grid>
    );
  };

  return <SideMenuContainer isMobile={isMobile}>{renderContent()}</SideMenuContainer>;
}

CalendarSideMenu.defaultProps = {
  selectedDate: "",
  selectedExperience: "all",
};

CalendarSideMenu.propTypes = {
  selectedDate: PropTypes.string,
  selectedExperience: PropTypes.string,
  setShowAddSlotModal: PropTypes.func.isRequired,
  setShowDeleteSlotModal: PropTypes.func.isRequired,
  setCancelTimeSlotId: PropTypes.func.isRequired,
  setShowEditSlotModal: PropTypes.func.isRequired,
  setShowUnableDeleteModal: PropTypes.func.isRequired,
  setShowBookedModal: PropTypes.func.isRequired,
};

export default CalendarSideMenu;
