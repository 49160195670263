export const fonts = {
  body: "Roboto_400Regular",
  heading: "Roboto_700Bold",
};

export const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 700,
};

export const fontSizes = {
  tab: "12px",
  caption: "15px",
  button: "18px",
  body: "17px",
  label: "20px",
  h5: "26px",
  h4: "34px",
  h3: "45px",
  h2: "56px",
  h1: "112px",
};
