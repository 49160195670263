import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, IconButton, styled, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import Loader from "react-js-loader";
import Text from "../../../components/text.component";
import VerticalPaddedView from "../../../components/vertical-padded-view.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import ProfileSideMenu from "../../profile/components/profile-side-menu.component";
import CurrentStatement from "../components/current-statement.component";
import Spacer from "../../../components/spacer.component";
import routes from "../../../navigation/routes";
import EarningStatement from "../components/earning-statement.component";
import {
  bookingSelector,
  getExperiencesList,
} from "../../../services/booking/booking-slice.services";

import ClosedStatement from "../components/closed-statement.component";
import {
  getClosedStatement,
  getCurrentStatement,
  sendStatementEmail,
  statementSelector,
} from "../../../services/statement/statement-slice.services";

const AntTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.colors.brand.secondary,
    height: "3px",
  },
  borderBottom: `1px solid ${theme.palette.colors.ui.border}`,
  width: "100%",
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.colors.text.secondary,
  "&.Mui-selected": {
    color: theme.palette.colors.brand.secondary,
  },
}));

const StyledBackButton = styled(IconButton)(({ theme }) => ({
  padding: "0px",
  strokeWidth: 1,
  stroke: theme.palette.colors.brand.secondary,
  color: theme.palette.colors.brand.secondary,
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const LoaderContainer = styled(Box)({ height: "200px", justifyContent: "center", display: "flex" });

function SalesTrackingScreen() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const [currentTab, setCurrentTab] = useState(0);
  const { getCurrentStatementObj, getClosedStatementObj } = useSelector(statementSelector);
  const { getExperiencesListObj } = useSelector(bookingSelector);
  const [showEmailFieldModal, setShowEmailFieldModal] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCurrentStatement()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
    dispatch(getClosedStatement(value)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleSendStatementEmail = (value) => {
    dispatch(sendStatementEmail(value)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowEmailFieldModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
    if (newValue === 0) {
      dispatch(getCurrentStatement()).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
    if (newValue === 1) {
      setPage(1);
      dispatch(getClosedStatement(1)).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
    if (newValue === 2) {
      dispatch(getExperiencesList()).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
    }
  };

  const renderLoader = () => (
    <LoaderContainer>
      <Loader type="bubble-top" bgColor={theme.palette.colors.brand.secondary} size={40} />
    </LoaderContainer>
  );

  const renderCurrentStatement = () => {
    if (getCurrentStatementObj.status === "succeeded") {
      return <CurrentStatement />;
    }
    return renderLoader();
  };

  const renderClosedStatement = () => {
    if (getClosedStatementObj.status === "succeeded") {
      return <ClosedStatement handlePageChange={handlePageChange} page={page} />;
    }
    return renderLoader();
  };

  const renderEarningStatement = () => {
    if (getExperiencesListObj.status === "succeeded") {
      return (
        <EarningStatement
          showEmailFieldModal={showEmailFieldModal}
          setShowEmailFieldModal={setShowEmailFieldModal}
          handleSendStatementEmail={handleSendStatementEmail}
        />
      );
    }
    return renderLoader();
  };

  return (
    <VerticalPaddedView multiples={isMobile ? 0 : 7}>
      <Grid container>
        {!isMobile && (
          <Grid item width="450px">
            <ProfileSideMenu />
          </Grid>
        )}

        <Grid item sx={{ display: "flex", flex: 1 }}>
          <PaddedView multiples={isMobile ? 3 : 0}>
            <Box sx={{ width: isMobile ? "100%" : "90%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CenterRowBox>
                    {isMobile && (
                      <Link to={{ pathname: routes.ACCOUNTMENU }}>
                        <StyledBackButton>
                          <ArrowBackIosIcon />
                        </StyledBackButton>
                      </Link>
                    )}
                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }} variant="h4">
                      Sales Tracking
                    </Text>
                  </CenterRowBox>
                </Grid>
                <Grid item xs={12}>
                  <AntTabs
                    variant={isMobile ? "scrollable" : null}
                    value={currentTab}
                    onChange={handleChangeTab}
                  >
                    <AntTab label={<Text>Current</Text>} />
                    <AntTab label={<Text>Closed Statements</Text>} />
                    <AntTab label={<Text>Earnings</Text>} />
                  </AntTabs>
                </Grid>
                <Grid item xs={12}>
                  <Spacer position="top" size="l" />
                  {currentTab === 0 && renderCurrentStatement()}
                  {currentTab === 1 && renderClosedStatement()}
                  {currentTab === 2 && renderEarningStatement()}
                </Grid>
              </Grid>
            </Box>
          </PaddedView>
        </Grid>
      </Grid>
    </VerticalPaddedView>
  );
}

export default SalesTrackingScreen;
