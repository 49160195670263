import React, { useContext, useEffect, useState } from "react";
import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { getExperiencesList } from "../../../services/booking/booking-slice.services";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import { getCalendarDetails } from "../../../services/calendar/calendar-slice.services";
import Loading from "../../../components/notifications/loading.component";
import CalendarSideMenu from "../components/calendar-side-menu.component";
import Form from "../../../components/forms/form.component";
import AddSlotFormModal from "../../edit-experiences/components/add-slot-form-modal.component";
import {
  deleteMassTimeSlot,
  editMassTimeSlot,
  createMassTimeSlot,
  experienceSelector,
} from "../../../services/experience/experience-slice.services";
import Calendar from "../components/calendar.component";
import DialogModal from "../../../components/notifications/dialog-modal.component";
import EditSlotFormModal from "../../edit-experiences/components/edit-slot-form-modal.component";
import TimeSlotEditSuccessModal from "../../../components/notifications/timeslot-edit-success-modal.component";

const VerticalSeperateLine = styled(Box)(({ theme }) => ({
  height: "auto",
  width: "1px",
  backgroundColor: theme.palette.colors.ui.border,
}));

const addTimeslotValidationSchema = Yup.object().shape({
  startDate: Yup.date().required().label("Start Date").nullable(),
  startTime: Yup.date().required().label("Check-in Time"),
  endDate: Yup.date()
    .min(new Date(), "End date can't be before start date")
    .required()
    .label("End Date")
    .nullable(),
  endTime: Yup.date().required().label("Check-out Time"),
  experienceId: Yup.string().label("Experience Id"),
  bookingType: Yup.string().required(),
  capacity: Yup.number()
    .nullable()
    .label("Capacity")
    .when("bookingType", {
      is: "multiple",
      then: Yup.number().min(1).max(300).required(),
    }),
});

const editTimeslotValidationSchema = Yup.object().shape({
  startDate: Yup.date().required().label("Start Date").nullable(),
  startTime: Yup.date().required().label("Check-in Time"),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "End date can't be before start date")
    .required()
    .label("End Date")
    .nullable(),
  endTime: Yup.date().required().label("Check-out Time"),
  bookingType: Yup.string().required(),
  id: Yup.string(),
  capacity: Yup.number()
    .nullable()
    .label("Capacity")
    .when("bookingType", {
      is: "multiple",
      then: Yup.number().min(1).max(300).required(),
    }),
  bookedCapacity: Yup.number(),
});

function CalendarScreen() {
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentExperience, setCurrentExperience] = useState("all");
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [calendarEvent, setCalendarEvent] = useState([]);
  const [currentStartDate, setCurrentStartDate] = useState(null);
  const [currentEndDate, setCurrentEndDate] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));

  const { deleteMassTimeSlotObj } = useSelector(experienceSelector);
  const [showAddSlotModal, setShowAddSlotModal] = useState(false);
  const [showDeleteSlotModal, setShowDeleteSlotModal] = useState(false);
  const [cancelTimeSlotId, setCancelTimeSlotId] = useState(false);

  const [showEditSlotModal, setShowEditSlotModal] = useState(false);
  const [showEditCapacityModal, setShowEditCapacityModal] = useState(false);
  const [showUnableDeleteModal, setShowUnableDeleteModal] = useState(false);
  const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);
  const [showBookedModal, setShowBookedModal] = useState(false);

  const getCalendarEvent = (data) => {
    const event = [];
    Object.keys(data.dates).map((item, index) =>
      event.push({
        start: item,
        end: item,
        id: index,
        title: `${data.dates[item].length} booked`,
      }),
    );
    setCalendarEvent(event);
  };

  const loadCalendarData = (start, end) => {
    setIsLoading(true);
    dispatch(
      getCalendarDetails({
        startDate: format(start, "yyyy-MM-dd"),
        endDate: format(end, "yyyy-MM-dd"),
        experience: currentExperience,
      }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        getCalendarEvent(payload.data);
      }

      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    loadCalendarData(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    );
    dispatch(getExperiencesList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const onChangeExperiences = (event) => {
    setIsLoading(true);
    setCurrentExperience(event.target.value);
    setSelectedDate(null);
    dispatch(
      getCalendarDetails({
        startDate: format(new Date(currentStartDate), "yyyy-MM-dd"),
        endDate: format(new Date(currentEndDate), "yyyy-MM-dd"),
        experience: event.target.value,
      }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        getCalendarEvent(payload.data);
      }

      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleMonthChange = async (payload) => {
    if (currentStartDate !== payload.startStr) {
      setSelectedDate(null);
      setCurrentStartDate(payload.startStr);
      setCurrentEndDate(payload.endStr);
      loadCalendarData(new Date(payload.startStr), new Date(payload.endStr));
    }
  };

  const handleCreateTimeSlot = (values, { resetForm }) => {
    const startAt = `${format(new Date(values.startDate), "yyyy-MM-dd")} ${format(
      new Date(values.startTime),
      "HH:mm:ss",
    )}`;
    const endAt = `${format(new Date(values.endDate), "yyyy-MM-dd")} ${format(
      new Date(values.endTime),
      "HH:mm:ss",
    )}`;
    values.experienceId = currentExperience;
    setIsLoading(true);
    dispatch(createMassTimeSlot({ startAt, endAt, ...values })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowAddSlotModal(false);
        resetForm();
        loadCalendarData(new Date(currentStartDate), new Date(currentEndDate));
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleConfirmCancelSlot = () => {
    setIsLoading(true);
    dispatch(deleteMassTimeSlot({ experienceId: currentExperience, id: cancelTimeSlotId.id })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setShowDeleteSlotModal(false);
          loadCalendarData(new Date(currentStartDate), new Date(currentEndDate));
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        } else if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const handleUpdateTimeSlot = (values) => {
    const startAt = `${format(new Date(values.startDate), "yyyy-MM-dd")} ${format(
      new Date(values.startTime),
      "HH:mm:ss",
    )}`;
    const endAt = `${format(new Date(values.endDate), "yyyy-MM-dd")} ${format(
      new Date(values.endTime),
      "HH:mm:ss",
    )}`;
    values.experienceId = currentExperience;
    setIsLoading(true);
    dispatch(editMassTimeSlot({ startAt, endAt, ...values })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowEditSlotModal(false);
        if (values.slotType === "multiple") {
          setShowEditSuccessModal(true);
        }
        loadCalendarData(new Date(currentStartDate), new Date(currentEndDate));
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: isMobile ? "column" : "row" }}>
      <Loading isLoading={isLoading} />
      <DialogModal
        showModal={showUnableDeleteModal}
        setShowModal={setShowUnableDeleteModal}
        title="Cancel Timeslot"
        message="This timeslot has been booked, you can't cancel it."
        onConfirm={() => setShowUnableDeleteModal(false)}
        buttonText="Ok"
      />
      <TimeSlotEditSuccessModal
        showModal={showEditSuccessModal}
        setShowModal={setShowEditSuccessModal}
        onConfirm={() => setShowEditSuccessModal(false)}
      />
      <DialogModal
        showModal={showBookedModal}
        setShowModal={setShowBookedModal}
        title="Edit Timeslot"
        message="This timeslot has been booked, if you want to edit please contact the user(s), do you want to continue?"
        onConfirm={() => {
          setShowEditSlotModal(true);
          setShowBookedModal(false);
        }}
        buttonText="Confirm"
      />
      <DialogModal
        showModal={showEditCapacityModal}
        setShowModal={setShowEditCapacityModal}
        title="Edit Capacity"
        message="You can just edit the capacity to either equal or more than the current number of booked."
        onConfirm={() => setShowEditCapacityModal(false)}
        buttonText="Ok"
      />
      <Form
        validationSchema={editTimeslotValidationSchema}
        initialValues={{
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          id: "",
          bookingType: "multiple",
          capacity: "",
          bookedCapacity: "",
        }}
        onSubmit={handleUpdateTimeSlot}
      >
        <EditSlotFormModal
          setShowEditCapacityModal={setShowEditCapacityModal}
          isShowModal={showEditSlotModal}
          setIsShowModal={setShowEditSlotModal}
        />
        <Form
          validationSchema={addTimeslotValidationSchema}
          initialValues={{
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
            experienceId: "",
            bookingType: "multiple",
            capacity: "",
          }}
          onSubmit={handleCreateTimeSlot}
        >
          <AddSlotFormModal
            isShowModal={showAddSlotModal}
            setIsShowModal={setShowAddSlotModal}
            fromCalendarScreen={true}
          />
          <DialogModal
            showModal={showDeleteSlotModal}
            setShowModal={setShowDeleteSlotModal}
            title="Cancel Timeslot"
            message="Are you sure you want to cancel this timeslot?"
            onConfirm={handleConfirmCancelSlot}
            buttonText="Confirm"
            disabled={deleteMassTimeSlotObj.status === "pending"}
          />
          <Calendar
            handleMonthChange={handleMonthChange}
            calendarEvent={calendarEvent}
            setSelectedDate={setSelectedDate}
            onChangeExperiences={onChangeExperiences}
            currentExperience={currentExperience.toString()}
          />
        </Form>

        <VerticalSeperateLine />
        <CalendarSideMenu
          selectedDate={selectedDate}
          selectedExperience={currentExperience.toString()}
          setShowAddSlotModal={setShowAddSlotModal}
          setShowDeleteSlotModal={setShowDeleteSlotModal}
          setCancelTimeSlotId={setCancelTimeSlotId}
          setShowEditSlotModal={setShowEditSlotModal}
          setShowUnableDeleteModal={setShowUnableDeleteModal}
          setShowBookedModal={setShowBookedModal}
        />
      </Form>
    </Box>
  );
}

export default CalendarScreen;
