import React from "react";
import { Box, styled, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import Spacer from "../../../components/spacer.component";

const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.padding.paddingX[2],
  boxShadow: `0px 1px 5px 1px ${theme.palette.colors.loading.foregroundColor}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
});

function StatementCard({ id, reason, price, date, status }) {
  const theme = useTheme();

  return (
    <Link
      to={{ pathname: routes.STATEMENT_DETAILS, search: `statementId=${id}` }}
      style={{ textTransform: "none", textDecoration: "none" }}
    >
      <CardContainer>
        <SpaceBetweenRowBox>
          <Text type="GreyColor">{`Ref ID: ${id}`}</Text>
          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>{`RM${price}`}</Text>
        </SpaceBetweenRowBox>
        {(status === "pending" || status === "closed") && <Text>{date}</Text>}
        {status === "rejected" && (
          <>
            <Spacer position="top" size="m" />
            <Text>Reason :</Text>
            <Text>{reason}</Text>
          </>
        )}
      </CardContainer>
    </Link>
  );
}

StatementCard.defaultProps = {
  id: null,
  price: "",
  reason: "",
  date: "",
  status: "open",
};

StatementCard.propTypes = {
  id: PropTypes.number,
  price: PropTypes.string,
  reason: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
};

export default StatementCard;
