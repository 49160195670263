import React from "react";
import { Box, Button, styled } from "@mui/material";
import PropTypes from "prop-types";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import BorderButton from "../../../components/buttons/border-button.component";
import CtaButton from "../../../components/buttons/cta-button.component";

const CardBox = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingLeft: theme.padding.paddingX[2],
  paddingRight: theme.padding.paddingX[2],
  paddingTop: theme.padding.paddingX[1],
  paddingBottom: theme.padding.paddingX[1],
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  ":hover": {
    backgroundColor: theme.palette.colors.bg.secondary,
  },
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.ui.border,
  height: "1px",
  width: "100%",
}));

const CenterRowBox = styled(Box)({ display: "flex", flexDirection: "row", alignItems: "center" });

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

const ViewDetailsButton = styled(Button)({
  textTransform: "none",
  padding: "0px",
  ":hover": {
    backgroundColor: "transparent",
  },
});

function MultipleBookingDetailsCard({ cardDetails, onClickExperienceCardButton, isPending }) {
  const viewBookingDetailsButton = () => (
    <ViewDetailsButton
      disableRipple
      onClick={() => onClickExperienceCardButton(cardDetails.booking.id, "Show Details")}
    >
      <Text type="BrandColor" sx={{ textDecoration: "underline", alignText: "start" }}>
        View Booking Details
      </Text>
    </ViewDetailsButton>
  );

  return (
    <Box>
      <CardBox>
        <CenterRowBox>
          <Text type="GreyColor">Guest:</Text>
          <Spacer size="xs" position="left" />
          <Text>{cardDetails.guest.name}</Text>
        </CenterRowBox>

        <CenterRowBox>
          <Text type="GreyColor">Price:</Text>
          <Spacer size="xs" position="left" />
          <Text>{`RM ${cardDetails.booking.price}`}</Text>
        </CenterRowBox>

        {viewBookingDetailsButton()}
        {isPending && (
          <>
            <Spacer position="top" size="m" />
            <SeperateLine />
            <Spacer position="top" size="m" />
            <SpaceBetweenBox>
              <Box sx={{ width: "90%" }}>
                <BorderButton
                  onClickButton={() =>
                    onClickExperienceCardButton(cardDetails.booking.id, "Reschedule")
                  }
                >
                  <Text type="BrandColor">Reschedule</Text>
                </BorderButton>
              </Box>
              <Spacer size="s" position="left" />
              <Box sx={{ width: "90%" }}>
                <CtaButton
                  onClickButton={() =>
                    onClickExperienceCardButton(cardDetails.booking.id, "Confirm")
                  }
                >
                  <Text type="WhiteColor">Confirm</Text>
                </CtaButton>
              </Box>
            </SpaceBetweenBox>
          </>
        )}
      </CardBox>
      <Spacer size="xs" position="top" />
      <SeperateLine />
    </Box>
  );
}

MultipleBookingDetailsCard.defaultProps = {
  isPending: false,
  cardDetails: {
    booking: {
      id: null,
      noPax: 0,
      price: "",
    },
    guest: {
      name: "",
    },
  },
};

MultipleBookingDetailsCard.propTypes = {
  isPending: PropTypes.bool,
  onClickExperienceCardButton: PropTypes.func.isRequired,
  cardDetails: PropTypes.shape({
    booking: PropTypes.shape({
      id: PropTypes.number,
      noPax: PropTypes.number,
      price: PropTypes.string,
    }),
    guest: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

export default MultipleBookingDetailsCard;
