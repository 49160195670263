import React from "react";
import PropTypes from "prop-types";
import { Box, styled, useTheme } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { v4 as uuidv4 } from "uuid";
import SideProgressBg from "../../../assets/images/side-progress-bg.png";
import Text from "../../../components/text.component";

const BackgroundImage = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${SideProgressBg})`,
  backgroundSize: "cover",
  minHeight: theme.dimensions.heightWithoutAppBar,
  opacity: 1,
  width: "100%",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const MaxWidthBox = styled(Box)({
  maxWidth: "80%",
  " .MuiTimelineItem-root": {
    height: "50px",
    overflow: "hidden",
    padding: "auto 0px",
    display: "flex",
  },
  " .MuiTimelineItem-positionRight": {
    display: "flex",
    justifyContent: "center",
    "&.MuiTimelineItem-missingOppositeContent": {
      "&:before": {
        display: "none",
      },
    },
  },
  " .MuiTimelineSeparator-root": {
    height: "50px",
  },
});

function SideProgressView({ step }) {
  const theme = useTheme();
  const STEP1 = [
    { label: "Select Categories", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
  ];
  const STEP2 = [
    { label: "Select Categories", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Add Address", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
  ];
  const STEP3 = [
    { label: "Select Categories", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Add Address", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Create Title", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
  ];
  const STEP4 = [
    { label: "Select Categories", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Add Address", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Create Title", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Upload Photos", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
  ];
  const STEP5 = [
    { label: "Select Categories", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Add Address", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Create Title", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Upload Photos", variant: "filled", color: theme.palette.colors.brand.white },
    {
      label: "Add Overview Description",
      variant: "filled",
      color: theme.palette.colors.brand.white,
    },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
  ];
  const STEP6 = [
    { label: "Select Categories", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Add Address", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Create Title", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Upload Photos", variant: "filled", color: theme.palette.colors.brand.white },
    {
      label: "Add Overview Description",
      variant: "filled",
      color: theme.palette.colors.brand.white,
    },
    {
      label: "Add Important Information",
      variant: "filled",
      color: theme.palette.colors.brand.white,
    },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
  ];
  const STEP7 = [
    { label: "Select Categories", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Add Address", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Create Title", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Upload Photos", variant: "filled", color: theme.palette.colors.brand.white },
    {
      label: "Add Overview Description",
      variant: "filled",
      color: theme.palette.colors.brand.white,
    },
    {
      label: "Add Important Information",
      variant: "filled",
      color: theme.palette.colors.brand.white,
    },
    { label: "Set Pax and Price", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "", variant: "outlined", color: null },
    { label: "", variant: "outlined", color: null },
  ];
  const STEP8 = [
    { label: "Select Categories", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Add Address", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Create Title", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Upload Photos", variant: "filled", color: theme.palette.colors.brand.white },
    {
      label: "Add Overview Description",
      variant: "filled",
      color: theme.palette.colors.brand.white,
    },
    {
      label: "Add Important Information",
      variant: "filled",
      color: theme.palette.colors.brand.white,
    },
    { label: "Set Pax and Price", variant: "filled", color: theme.palette.colors.brand.white },
    {
      label: "Experience Availability",
      variant: "filled",
      color: theme.palette.colors.brand.white,
    },
    { label: "", variant: "outlined", color: null },
  ];
  const STEP9 = [
    { label: "Select Categories", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Add Address", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Create Title", variant: "filled", color: theme.palette.colors.brand.white },
    { label: "Upload Photos", variant: "filled", color: theme.palette.colors.brand.white },
    {
      label: "Add Overview Description",
      variant: "filled",
      color: theme.palette.colors.brand.white,
    },
    {
      label: "Add Important Information",
      variant: "filled",
      color: theme.palette.colors.brand.white,
    },
    { label: "Set Pax and Price", variant: "filled", color: theme.palette.colors.brand.white },
    {
      label: "Experience Availability",
      variant: "filled",
      color: theme.palette.colors.brand.white,
    },
    {
      label: "Check out your experience",
      variant: "filled",
      color: theme.palette.colors.brand.white,
    },
  ];

  const getCurrentList = () => {
    if (step === 1) {
      return STEP1;
    }
    if (step === 2) {
      return STEP2;
    }
    if (step === 3) {
      return STEP3;
    }
    if (step === 4) {
      return STEP4;
    }
    if (step === 5) {
      return STEP5;
    }
    if (step === 6) {
      return STEP6;
    }
    if (step === 7) {
      return STEP7;
    }
    if (step === 8) {
      return STEP8;
    }
    return STEP9;
  };

  return (
    <BackgroundImage>
      {step !== 0 && (
        <MaxWidthBox>
          <Timeline position="right">
            {getCurrentList().map((item, index) => (
              <Box sx={{ height: "55px" }} key={uuidv4()}>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot variant={item.variant} sx={{ backgroundColor: item.color }} />
                    {index !== STEP1.length - 1 && (
                      <TimelineConnector
                        sx={{ backgroundColor: theme.palette.colors.brand.white, width: "1px" }}
                      />
                    )}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Text type="WhiteColor">{item.label}</Text>
                  </TimelineContent>
                </TimelineItem>
              </Box>
            ))}
          </Timeline>
        </MaxWidthBox>
      )}
    </BackgroundImage>
  );
}

SideProgressView.defaultProps = {
  step: 0,
};

SideProgressView.propTypes = {
  step: PropTypes.number,
};

export default SideProgressView;
