import React from "react";
import { Box, InputAdornment, styled, TextField, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import Text from "../../../../components/text.component";

const PriceFieldBox = styled(Box)(({ theme }) => ({
  "& .MuiTextField-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    justifyContent: "center",
    alignItems: "center",
    "& .MuiOutlinedInput-root": {
      borderTopLeftRadius: theme.shape.borderRadius[0],
      borderTopRightRadius: theme.shape.borderRadius[0],
      borderBottomLeftRadius: theme.shape.borderRadius[0],
      borderBottomRightRadius: theme.shape.borderRadius[0],
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));

function PriceFieldForm() {
  const theme = useTheme();
  const { setFieldValue, values, touched, errors, setFieldTouched, handleSubmit } =
    useFormikContext();
  const showPriceError = touched.price && typeof errors.price === "string";

  const handleChange = (evt) => {
    const { value } = evt.target;
    if (value.match(/\./g)) {
      const [, decimal] = value.split(".");
      if (decimal?.length > 2) {
        return;
      }
    }
    setFieldValue("price", value);
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <PriceFieldBox>
      <TextField
        onKeyDown={keyPress}
        error={showPriceError}
        variant="outlined"
        helperText={showPriceError ? errors.price : null}
        onBlur={() => setFieldTouched("price")}
        name="price"
        placeholder="Price"
        onChange={handleChange}
        value={values.price === null ? "" : values.price}
        InputProps={{
          style: {
            fontSize: "25px",
            fontWeight: theme.fonts.fontWeights.bold,
          },
          startAdornment: (
            <InputAdornment position="start">
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold, fontSize: "25px" }}>RM</Text>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start">
              <Text type="GreyColor" variant="body2">
                per pax
              </Text>
            </InputAdornment>
          ),
        }}
      />
    </PriceFieldBox>
  );
}

export default PriceFieldForm;
