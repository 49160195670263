import React, { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Text from "../../../../components/text.component";

const CardBox = styled(Box)(({ theme, isMobile }) => ({
  width: isMobile ? "295px" : "375px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  padding: theme.padding.paddingX[2],
  boxShadow: `0px 0.5px 5px 0.5px ${theme.palette.colors.loading.foregroundColor}`,
}));

const StyledButtonIcon = styled(IconButton)(({ theme }) => ({
  padding: 0,
  backgroundColor: theme.palette.colors.brand.white,
  ":hover": { backgroundColor: theme.palette.colors.brand.white },
}));

const SpaceBetweenRowBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const VerticalSeperateLine = styled(Box)(({ theme }) => ({
  minHeight: "70px",
  width: "1px",
  backgroundColor: theme.palette.colors.ui.border,
}));

const SideBox = styled(Box)({
  width: "49%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "5px",
});

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

function FlexiSlotCard({
  cardDetails,
  handleCancelSlot,
  setShowUnableDeleteModal,
  setShowBookedModal,
  setShowEditSlotModal,
  onCompleteFlexiBooking,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showMenu, setShowMenu] = useState(null);
  const { setFieldValue } = useFormikContext();

  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const handleOnClickCancel = () => {
    handleCloseMenu();
    if (
      cardDetails.massSlot.capacity.redeemed !== null &&
      cardDetails.massSlot.capacity.redeemed > 0
    ) {
      setShowUnableDeleteModal(true);
      return;
    }
    handleCancelSlot({ id: cardDetails.massSlot.id, classType: "massSlot" });
  };

  const handleOnClickEdit = () => {
    setFieldValue("id", cardDetails.massSlot.id);
    setFieldValue("capacity", cardDetails.massSlot.capacity.total);
    setFieldValue("bookingType", "flexi");
    setFieldValue("flexiTitle", cardDetails.massSlot.title);
    setFieldValue("bookedCapacity", cardDetails.massSlot.capacity.occupied);
    if (cardDetails.massSlot.capacity.redeemed !== 0) {
      setShowBookedModal(true);
    } else {
      setShowEditSlotModal(true);
    }
    handleCloseMenu();
  };

  return (
    <Grid item>
      <CardBox isMobile={isMobile}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SpaceBetweenRowBox>
              <Text type="GreyColor" variant="body2">
                {`Capacity: ${cardDetails.massSlot.capacity.occupied} / ${cardDetails.massSlot.capacity.total}`}
              </Text>
              {cardDetails.massSlot.status !== "completed" && (
                <StyledButtonIcon disabled onClick={handleOpenMenu}>
                  <MoreHorizIcon />
                </StyledButtonIcon>
              )}
            </SpaceBetweenRowBox>
            <StyledMenu
              sx={{ mt: "30px" }}
              anchorEl={showMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(showMenu)}
              onClose={handleCloseMenu}
            >
              <MenuItem
                disabled
                key="0"
                onClick={() => onCompleteFlexiBooking(cardDetails.massSlot.id)}
              >
                <Text textAlign="center">Complete</Text>
              </MenuItem>
              <MenuItem disabled key="1" onClick={handleOnClickEdit}>
                <Text textAlign="center">Edit</Text>
              </MenuItem>
              <MenuItem disabled key="2" onClick={handleOnClickCancel}>
                <Text textAlign="center">Cancel</Text>
              </MenuItem>
            </StyledMenu>
          </Grid>
          <Grid item xs={12}>
            <SpaceBetweenRowBox>
              <SideBox>
                <Text>{cardDetails.massSlot.title}</Text>
              </SideBox>
              <VerticalSeperateLine />
              <SideBox>
                <Text>{`Redeemed: ${cardDetails.massSlot.capacity.redeemed}`} </Text>
              </SideBox>
            </SpaceBetweenRowBox>
          </Grid>
        </Grid>
      </CardBox>
    </Grid>
  );
}

FlexiSlotCard.defaultProps = {
  cardDetails: {
    massSlot: {
      capacity: {
        occupied: null,
        redeemed: null,
        total: null,
      },
      id: null,
      status: "",
      title: "",
    },
  },
};

FlexiSlotCard.propTypes = {
  onCompleteFlexiBooking: PropTypes.func.isRequired,
  setShowEditSlotModal: PropTypes.func.isRequired,
  setShowBookedModal: PropTypes.func.isRequired,
  setShowUnableDeleteModal: PropTypes.func.isRequired,
  handleCancelSlot: PropTypes.func.isRequired,
  cardDetails: PropTypes.shape({
    massSlot: PropTypes.shape({
      capacity: PropTypes.shape({
        occupied: PropTypes.number,
        redeemed: PropTypes.number,
        total: PropTypes.number,
      }),
      id: PropTypes.number,
      status: PropTypes.string,
      title: PropTypes.string,
    }),
  }),
};

export default FlexiSlotCard;
