import { Box, Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Loader from "react-js-loader";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import HomeScreenBanner from "../../../assets/images/home-screen-banner.png";
import CtaButton from "../../../components/buttons/cta-button.component";
import SecondaryBackgroundButton from "../../../components/buttons/secondary-background-button.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  bookingSelector,
  getBookingDetails,
  getScheduledBooking,
} from "../../../services/booking/booking-slice.services";
import {
  experienceSelector,
  getFlexiExperiences,
} from "../../../services/experience/experience-slice.services";
import { profileSelector } from "../../../services/profile/profile-slice.services";
import BookingDetailsModal from "../../bookings/components/booking-details-modal.component";
import ExperienceCard from "../../experiences/components/experience-card.component";
import BookingCard from "../components/booking-card.component";
import ExperienceTypeSection from "../components/experience-type-section.component";

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
});

const CenterBox = styled(Box)({ display: "flex", justifyContent: "center", alignItems: "center" });

const BackgroundImage = styled(Box)(() => ({
  backgroundImage: `url(${HomeScreenBanner})`,
  backgroundSize: "cover",
  opacity: 1,
  width: "100%",
  height: "250px",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const PaddedBox = styled(Box)(({ theme }) => ({
  padding: theme.padding.paddingX[0],
  width: "100%",
}));

const BookingScheduleButton = styled(Button)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  textTransform: "none",
  boxShadow: 0,
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

function HomeScreen() {
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const isShowThreeItems = useMediaQuery(theme.breakpoints.down("1200"));
  const isShowTwoItems = useMediaQuery(theme.breakpoints.down("960"));
  const createSnackBar = useContext(SnackbarContext);
  const { getSavedExperiencesObj, getFlexiExperiencesObj } = useSelector(experienceSelector);
  const { getScheduledBookingObj } = useSelector(bookingSelector);
  const [bookingTab, setBookingTab] = useState("today");
  const [showBookingDetails, setShowBookingDetails] = useState(false);
  const localBackToScreen = localStorage.getItem("backToScreen");
  const merchantEmail = localStorage.getItem("merchantEmail");
  const { getProfileObj } = useSelector(profileSelector);

  const getItemToShow = () => {
    if (isMobile) {
      return 12;
    }
    if (isShowTwoItems) {
      return 6;
    }
    if (isShowThreeItems) {
      return 4;
    }

    return 3;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (localBackToScreen === "email") {
      history.push(routes.EMAILVERIFICATION, { email: merchantEmail });
    }
    if (localBackToScreen === "businessDetails") {
      history.push(routes.BUSINESSDETAILS);
    }
    // dispatch(getSavedExperiences()).then(({ meta, error }) => {
    //   if (meta.requestStatus === "rejected") {
    //     createSnackBar({
    //       message: error.message,
    //       type: "error",
    //       open: true,
    //     });
    //   }
    // });
    dispatch(getFlexiExperiences()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getScheduledBooking(bookingTab)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const onClickBooking = (bookingId) => {
    dispatch(getBookingDetails(bookingId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
    setShowBookingDetails(true);
  };

  const renderSavedExperiences = () => {
    if (getSavedExperiencesObj.status === "succeeded") {
      if (getSavedExperiencesObj.data.length === 0) {
        return (
          <Grid item xs={12}>
            <Spacer size="xl" position="top" />
            <CenterBox>
              <Text>No items.</Text>
            </CenterBox>
          </Grid>
        );
      }
      return getSavedExperiencesObj.data.map(
        (items) =>
          items.experience.status.toUpperCase() !== "PUBLISH" && (
            <Grid item key={items.experience.id} xs={getItemToShow()}>
              <ExperienceCard cardDetails={items.experience} />
            </Grid>
          ),
      );
    }
    return (
      <Grid item xs={12}>
        <Spacer size="xl" position="top" />
        <CenterBox>
          <Loader type="bubble-top" bgColor={theme.palette.colors.brand.secondary} size={40} />
        </CenterBox>
      </Grid>
    );
  };

  const onChangeBookingSchedule = (tabs) => {
    setBookingTab(tabs);
    dispatch(getScheduledBooking(tabs)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderBookingScheduleButton = () => {
    if (isMobile) {
      return (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <BookingScheduleButton
                sx={{
                  width: "100%",
                  border: `1px solid ${
                    bookingTab === "today"
                      ? theme.palette.colors.brand.primary
                      : theme.palette.colors.brand.secondary
                  }`,
                }}
                onClick={() => onChangeBookingSchedule("today")}
              >
                <Text type={bookingTab === "today" ? "BrandColor" : null} variant="body2">
                  Today
                </Text>
              </BookingScheduleButton>
            </Grid>
            <Grid item xs={6}>
              <BookingScheduleButton
                sx={{
                  width: "100%",
                  border: `1px solid ${
                    bookingTab === "upcoming"
                      ? theme.palette.colors.brand.primary
                      : theme.palette.colors.brand.secondary
                  }`,
                }}
                onClick={() => onChangeBookingSchedule("upcoming")}
              >
                <Text type={bookingTab === "upcoming" ? "BrandColor" : null} variant="body2">
                  Upcoming Booking
                </Text>
              </BookingScheduleButton>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        <Box sx={{ display: "flex" }}>
          <BookingScheduleButton
            sx={{
              width: "200px",
              border: `1px solid ${
                bookingTab === "today"
                  ? theme.palette.colors.brand.primary
                  : theme.palette.colors.brand.secondary
              }`,
            }}
            onClick={() => onChangeBookingSchedule("today")}
          >
            <Text type={bookingTab === "today" ? "BrandColor" : null} variant="body2">
              Today
            </Text>
          </BookingScheduleButton>
          <Spacer position="left" size="m" />
          <BookingScheduleButton
            sx={{
              width: "200px",
              border: `1px solid ${
                bookingTab === "upcoming"
                  ? theme.palette.colors.brand.primary
                  : theme.palette.colors.brand.secondary
              }`,
            }}
            onClick={() => onChangeBookingSchedule("upcoming")}
          >
            <Text type={bookingTab === "upcoming" ? "BrandColor" : null} variant="body2">
              Upcoming Booking
            </Text>
          </BookingScheduleButton>
        </Box>
      </Grid>
    );
  };

  const onCreateButtonClick = () => {
    if (getProfileObj.data.merchant.status === "approved") {
      history.push(routes.CREATEEXPERIENCES);
      return;
    }
    createSnackBar({
      message: "Your account is being verified. Please come back later.",
      type: "error",
      open: true,
    });
  };

  const renderBookings = () => {
    if (getScheduledBookingObj.status === "succeeded") {
      if (getScheduledBookingObj.data.items.length === 0) {
        return (
          <Grid item xs={12}>
            <Spacer size="xl" position="top" />
            <CenterBox>
              <Text>Bookings that are made will appear here.</Text>
            </CenterBox>
          </Grid>
        );
      }
      return getScheduledBookingObj.data.items.map((items) => (
        <Grid item key={items.booking.id} xs={isMobile ? 12 : null}>
          <BookingCard cardDetails={items} onClickBooking={onClickBooking} />
        </Grid>
      ));
    }
    return (
      <Grid item xs={12}>
        <Spacer size="xl" position="top" />
        <CenterBox>
          <Loader type="bubble-top" bgColor={theme.palette.colors.brand.secondary} size={40} />
        </CenterBox>
      </Grid>
    );
  };

  const topBannerTitleButton = () => {
    if (isMobile) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Text
            type="WhiteColor"
            variant={isMobile ? "h6" : "h4"}
            sx={{ fontWeight: theme.fonts.fontWeights.bold }}
          >
            Create Experience
          </Text>
          <Text type="WhiteColor">Just add some details requested to your listing.</Text>
          <Spacer size="xl" position="top" />
          <SecondaryBackgroundButton onClickButton={onCreateButtonClick} disabled>
            <Text type="WhiteColor" variant="body2">
              Create Experiences
            </Text>
          </SecondaryBackgroundButton>
        </Box>
      );
    }
    return (
      <SpaceBetweenRowBox>
        <Box>
          <Text
            type="WhiteColor"
            variant={isMobile ? "h6" : "h4"}
            sx={{ fontWeight: theme.fonts.fontWeights.bold }}
          >
            Create Experience
          </Text>
          <Text type="WhiteColor">Just add some details requested to your listing.</Text>
        </Box>

        <SecondaryBackgroundButton onClickButton={onCreateButtonClick} disabled>
          <Text type="WhiteColor" variant="body2">
            Create Experiences
          </Text>
        </SecondaryBackgroundButton>
      </SpaceBetweenRowBox>
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <BookingDetailsModal showModal={showBookingDetails} setShowModal={setShowBookingDetails} />
      <Grid container spacing={2}>
        <ExperienceTypeSection />
        {/* <Grid item xs={12}>
          <BackgroundImage>
            <PaddedBox>{topBannerTitleButton()}</PaddedBox>
          </BackgroundImage>
        </Grid> */}
        {/* <Grid item xs={12}>
          <PaddedBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SpaceBetweenRowBox>
                  <Text
                    variant={isMobile ? "h6" : "h4"}
                    sx={{ fontWeight: theme.fonts.fontWeights.bold }}
                  >
                    Saved Experience
                  </Text>
                  <CtaButton onClickButton={() => history.push(routes.EXPERIENCES)}>
                    <Text type="WhiteColor" variant="body2">
                      Show All
                    </Text>
                  </CtaButton>
                </SpaceBetweenRowBox>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {renderSavedExperiences()}
                </Grid>
              </Grid>
            </Grid>
          </PaddedBox>
        </Grid> */}
        <Grid item xs={12}>
          <PaddedBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SpaceBetweenRowBox>
                  <Text
                    variant={isMobile ? "h6" : "h4"}
                    sx={{ fontWeight: theme.fonts.fontWeights.bold }}
                  >
                    Your Bookings
                  </Text>
                  <CtaButton onClickButton={() => history.push(routes.BOOKINGS)}>
                    <Text type="WhiteColor" variant="body2">
                      All Bookings
                    </Text>
                  </CtaButton>
                </SpaceBetweenRowBox>
              </Grid>
              {renderBookingScheduleButton()}

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {renderBookings()}
                </Grid>
              </Grid>
            </Grid>
          </PaddedBox>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomeScreen;
