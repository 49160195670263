import { Box, Grid, styled } from "@mui/material";
import React, { useState } from "react";
import Loading from "../../../components/notifications/loading.component";
import FlexiExperienceType from "./flexi-experience-type.component";

const PaddedBox = styled(Box)(({ theme }) => ({
  padding: theme.padding.paddingX[0],
  width: "100%",
}));

function ExperienceTypeSection() {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Grid item xs={12}>
      <Loading isLoading={isLoading} />
      <PaddedBox>
        <Grid container>
          {/* <Grid item xs={6}>
            <Text
              variant={isMobile ? "h6" : "h4"}
              sx={{ fontWeight: theme.fonts.fontWeights.bold }}
            >
              Standard Booking Experiences
            </Text>
          </Grid> */}
          <Grid item xs={12}>
            <FlexiExperienceType setIsLoading={setIsLoading} />
          </Grid>
        </Grid>
      </PaddedBox>
    </Grid>
  );
}

export default ExperienceTypeSection;
