import React from "react";
import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import PaddedView from "../../../../components/padded-view.component";
import Spacer from "../../../../components/spacer.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Text from "../../../../components/text.component";
import { authSelector } from "../../../../services/auth/auth-slice.services";
import ResendCountdownButton from "../../../../components/buttons/resend-countdown-button.component";

const CardContainer = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: theme.palette.colors.bg.white,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  flex: 1,
  maxWidth: 500,
  minWidth: isMobile ? 340 : 450,
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "80px",
  backgroundColor: theme.palette.colors.bg.primary,
  justifyContent: "center",
  alignItems: "center",
}));

const CenterBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

function ResetPasswordForm({ handleResendCode }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { resetPasswordObj } = useSelector(authSelector);
  const { values } = useFormikContext();

  return (
    <CardContainer isMobile={isMobile} sx={{ boxShadow: 2 }}>
      <TitleContainer>
        <Text variant="h5">Create New Password</Text>
      </TitleContainer>

      <PaddedView multiples={isMobile ? 3 : 4}>
        <Spacer size="s" />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CenterBox>
              <Text
                sx={{ textAlign: "center" }}
              >{`A verification code has been sent to ${values.email}.`}</Text>
            </CenterBox>
            <Spacer size="s" />
          </Grid>
          <Grid item xs={12}>
            <FormFieldText name="verificationCode" placeholder="Enter Verification Code" />
          </Grid>
          <Grid item xs={12}>
            <CenterBox>
              <ResendCountdownButton onClickButton={handleResendCode} />
            </CenterBox>
          </Grid>
        </Grid>
        <Spacer size="l" />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormFieldText name="password" placeholder="Enter New Password" />
          </Grid>
          <Grid item xs={12}>
            <FormFieldText
              type="password"
              name="confirmPassword"
              placeholder="Confirm New Password"
            />
          </Grid>
        </Grid>
        <Spacer size="xxl" />

        <FormSubmitButton isLoading={resetPasswordObj.status === "pending"}>
          <Typography>Confirm</Typography>
        </FormSubmitButton>
      </PaddedView>
    </CardContainer>
  );
}

ResetPasswordForm.propTypes = {
  handleResendCode: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
