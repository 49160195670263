import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api.services";

export const createExperience = createAsyncThunk(
  "auth/merchants/create_experience",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/experiences`,
      payload,
    );
    return response;
  },
);

export const getExperiencesDetails = createAsyncThunk(
  "auth/merchants/experiences_details",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/experiences/${payload}`,
    );
    return response;
  },
);

export const updateExperience = createAsyncThunk(
  "auth/merchants/update_experience",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/experiences/${payload.experienceId}?type=${payload.type}`,
      payload,
    );
    return response;
  },
);

export const updateExperienceCategory = createAsyncThunk(
  "auth/merchants/update_experience_category",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/experiences/${payload.experienceId}/categories`,
      payload,
    );
    return response;
  },
);

export const uploadImage = createAsyncThunk("auth/merchants/upload_image", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/experiences/${payload.experienceId}/images`,
    payload,
  );
  return response;
});

export const deleteImage = createAsyncThunk("auth/merchants/delete_image", async (payload) => {
  const response = await delReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/experiences/${payload.experienceId}/images/${payload.imageId}`,
  );
  return response;
});

export const orderImage = createAsyncThunk("auth/merchants/delete_image", async (payload) => {
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/experiences/${payload.experienceId}/images/${payload.imageId}/reorder?type=${payload.type}`,
  );
  return response;
});

export const deleteMassTimeSlot = createAsyncThunk(
  "auth/merchants/delete_mass_slot",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/experiences/${payload.experienceId}/mass_slots/${payload.id}`,
    );
    return response;
  },
);

export const editMassTimeSlot = createAsyncThunk(
  "auth/merchants/edit_mass_slot",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/experiences/${payload.experienceId}/mass_slots/${payload.id}`,
      payload,
    );
    return response;
  },
);

export const createMassTimeSlot = createAsyncThunk(
  "auth/merchants/create_mass_slot",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/experiences/${payload.experienceId}/mass_slots`,
      payload,
    );
    return response;
  },
);

export const searchExperiences = createAsyncThunk(
  "auth/merchants/search_experiences",
  async (payload) => {
    const processedParams = `${
      payload.q !== undefined && payload.q.length > 0 ? `q=${payload.q}&` : ""
    }${
      payload.status !== undefined && payload.status.length > 0 ? `status=${payload.status}&` : ""
    }${
      payload.states !== undefined && payload.states.length > 0 ? `states=${payload.states}&` : ""
    }${payload.type !== undefined && payload.type.length > 0 ? `type=${payload.type}&` : ""}page=${
      payload.page
    }`;

    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/experiences?${processedParams}`,
    );
    return response;
  },
);

export const getSavedExperiences = createAsyncThunk(
  "auth/merchants/saved_experiences",
  async () => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/experiences/saved`,
    );
    return response;
  },
);

export const getFlexiExperiences = createAsyncThunk(
  "auth/merchants/flexi_experiences",
  async () => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/experiences/flexi`,
    );
    return response;
  },
);

export const deleteExperience = createAsyncThunk(
  "auth/merchants/delete_experince",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/experiences/${payload}`,
    );
    return response;
  },
);

const experienceSlice = createSlice({
  name: "experience",
  initialState: {
    createExperienceObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getExperiencesDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateExperienceObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateExperienceCategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    uploadImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    orderImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteMassTimeSlotObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    editMassTimeSlotObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createMassTimeSlotObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    searchExperiencesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteExperienceObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSavedExperiencesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getFlexiExperiencesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    resetExperienceDetailsObj: (state) => {
      state.getExperiencesDetailsObj.status = "idle";
      state.getExperiencesDetailsObj.data = null;
      state.getExperiencesDetailsObj.successMessage = null;
      state.getExperiencesDetailsObj.errorMessage = null;
    },
  },
  extraReducers: {
    [createExperience.pending]: (state) => {
      state.createExperienceObj.status = "pending";
    },
    [createExperience.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createExperienceObj.status = "succeeded";
      state.createExperienceObj.data = data;
      state.createExperienceObj.successMessage = message;
    },
    [createExperience.rejected]: (state, action) => {
      const { message } = action.error;

      state.createExperienceObj.status = "failed";
      state.createExperienceObj.errorMessage = message;
    },
    [updateExperience.pending]: (state) => {
      state.updateExperienceObj.status = "pending";
    },
    [updateExperience.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateExperienceObj.status = "succeeded";
      state.updateExperienceObj.data = data;
      state.updateExperienceObj.successMessage = message;

      state.getExperiencesDetailsObj.data = data;
    },
    [updateExperience.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateExperienceObj.status = "failed";
      state.updateExperienceObj.errorMessage = message;
    },
    [updateExperienceCategory.pending]: (state) => {
      state.updateExperienceCategoryObj.status = "pending";
    },
    [updateExperienceCategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateExperienceCategoryObj.status = "succeeded";
      state.updateExperienceCategoryObj.data = data;
      state.updateExperienceCategoryObj.successMessage = message;

      state.getExperiencesDetailsObj.data = data;
    },
    [updateExperienceCategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateExperienceCategoryObj.status = "failed";
      state.updateExperienceCategoryObj.errorMessage = message;
    },
    [getExperiencesDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getExperiencesDetailsObj.status = "succeeded";
      state.getExperiencesDetailsObj.data = data;
      state.getExperiencesDetailsObj.successMessage = message;
    },
    [getExperiencesDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getExperiencesDetailsObj.status = "failed";
      state.getExperiencesDetailsObj.errorMessage = message;
    },
    [uploadImage.pending]: (state) => {
      state.uploadImageObj.status = "pending";
    },
    [uploadImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.uploadImageObj.status = "succeeded";
      state.uploadImageObj.data = data;
      state.uploadImageObj.successMessage = message;

      state.getExperiencesDetailsObj.data = data;
    },
    [uploadImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.uploadImageObj.status = "failed";
      state.uploadImageObj.errorMessage = message;
    },
    [deleteImage.pending]: (state) => {
      state.deleteImageObj.status = "pending";
    },
    [deleteImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteImageObj.status = "succeeded";
      state.deleteImageObj.data = data;
      state.deleteImageObj.successMessage = message;

      state.getExperiencesDetailsObj.data = data;
    },
    [deleteImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteImageObj.status = "failed";
      state.deleteImageObj.errorMessage = message;
    },
    [orderImage.pending]: (state) => {
      state.orderImageObj.status = "pending";
    },
    [orderImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.orderImageObj.status = "succeeded";
      state.orderImageObj.data = data;
      state.orderImageObj.successMessage = message;

      state.getExperiencesDetailsObj.data = data;
    },
    [orderImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.orderImageObj.status = "failed";
      state.orderImageObj.errorMessage = message;
    },
    [deleteMassTimeSlot.pending]: (state) => {
      state.deleteMassTimeSlotObj.status = "pending";
    },
    [deleteMassTimeSlot.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteMassTimeSlotObj.status = "succeeded";
      state.deleteMassTimeSlotObj.data = data;
      state.deleteMassTimeSlotObj.successMessage = message;

      state.getExperiencesDetailsObj.data = data;
    },
    [deleteMassTimeSlot.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteMassTimeSlotObj.status = "failed";
      state.deleteMassTimeSlotObj.errorMessage = message;
    },
    [editMassTimeSlot.pending]: (state) => {
      state.editMassTimeSlotObj.status = "pending";
    },
    [editMassTimeSlot.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.editMassTimeSlotObj.status = "succeeded";
      state.editMassTimeSlotObj.data = data;
      state.editMassTimeSlotObj.successMessage = message;

      state.getExperiencesDetailsObj.data = data;
    },
    [editMassTimeSlot.rejected]: (state, action) => {
      const { message } = action.error;

      state.editMassTimeSlotObj.status = "failed";
      state.editMassTimeSlotObj.errorMessage = message;
    },
    [createMassTimeSlot.pending]: (state) => {
      state.createMassTimeSlotObj.status = "pending";
    },
    [createMassTimeSlot.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createMassTimeSlotObj.status = "succeeded";
      state.createMassTimeSlotObj.data = data;
      state.createMassTimeSlotObj.successMessage = message;

      state.getExperiencesDetailsObj.data = data;
    },
    [createMassTimeSlot.rejected]: (state, action) => {
      const { message } = action.error;

      state.createMassTimeSlotObj.status = "failed";
      state.createMassTimeSlotObj.errorMessage = message;
    },
    [searchExperiences.pending]: (state) => {
      state.searchExperiencesObj.status = "pending";
    },
    [searchExperiences.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchExperiencesObj.status = "succeeded";
      state.searchExperiencesObj.data = data;
      state.searchExperiencesObj.successMessage = message;
    },
    [searchExperiences.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchExperiencesObj.status = "failed";
      state.searchExperiencesObj.errorMessage = message;
    },
    [deleteExperience.pending]: (state) => {
      state.deleteExperienceObj.status = "pending";
    },
    [deleteExperience.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteExperienceObj.status = "succeeded";
      state.deleteExperienceObj.data = data;
      state.deleteExperienceObj.successMessage = message;
    },
    [deleteExperience.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteExperienceObj.status = "failed";
      state.deleteExperienceObj.errorMessage = message;
    },
    [getSavedExperiences.pending]: (state) => {
      state.getSavedExperiencesObj.status = "pending";
    },
    [getSavedExperiences.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSavedExperiencesObj.status = "succeeded";
      state.getSavedExperiencesObj.data = data;
      state.getSavedExperiencesObj.successMessage = message;
    },
    [getSavedExperiences.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSavedExperiencesObj.status = "failed";
      state.getSavedExperiencesObj.errorMessage = message;
    },
    [getFlexiExperiences.pending]: (state) => {
      state.getFlexiExperiencesObj.status = "pending";
    },
    [getFlexiExperiences.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getFlexiExperiencesObj.status = "succeeded";
      state.getFlexiExperiencesObj.data = data;
      state.getFlexiExperiencesObj.successMessage = message;
    },
    [getFlexiExperiences.rejected]: (state, action) => {
      const { message } = action.error;

      state.getFlexiExperiencesObj.status = "failed";
      state.getFlexiExperiencesObj.errorMessage = message;
    },
  },
});

export default experienceSlice.reducer;

// state
export const experienceSelector = (state) => state.experience;

export const { resetExperienceDetailsObj } = experienceSlice.actions;
