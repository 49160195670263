import React from "react";
import { Box, styled } from "@mui/material";
import Spacer from "../../../components/spacer.component";
import SkeletonLoader from "../../../components/skeleton.component";

const CardBox = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingLeft: theme.padding.paddingX[2],
  paddingRight: theme.padding.paddingX[2],
  paddingTop: theme.padding.paddingX[1],
  paddingBottom: theme.padding.paddingX[1],
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  ":hover": {
    backgroundColor: theme.palette.colors.bg.secondary,
  },
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.ui.border,
  height: "1px",
  width: "100%",
}));

const CenterRowBox = styled(Box)({ display: "flex", flexDirection: "row", alignItems: "center" });

function BookingDetailsCardLoader() {
  return (
    <Box>
      <CardBox>
        <CenterRowBox>
          <SkeletonLoader width="125px" />
        </CenterRowBox>
        <CenterRowBox>
          <SkeletonLoader width="100px" />
        </CenterRowBox>
        <CenterRowBox>
          <SkeletonLoader width="100px" />
        </CenterRowBox>
        <SkeletonLoader width="200px" />
      </CardBox>
      <Spacer size="xs" position="top" />
      <SeperateLine />
    </Box>
  );
}

export default BookingDetailsCardLoader;
