import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq } from "../api.services";

export const getExperiencesList = createAsyncThunk(
  "auth/merchants/booking_experience_list",
  async () => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/bookings/experiences`,
    );
    return response;
  },
);

export const getBookingList = createAsyncThunk("auth/merchants/booking_list", async (payload) => {
  let processedExperience = [];

  if (payload.experience.length === 0) {
    processedExperience = ["all"].toString();
  } else {
    payload.experience.map((item) => processedExperience.push(item.id));
  }

  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/bookings?type=${
      payload.type
    }&experience=${processedExperience.toString()}&page=${payload.page}`,
  );
  return response;
});

export const getBookingDetails = createAsyncThunk(
  "auth/merchants/booking_details",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/bookings/${payload}`,
    );
    return response;
  },
);

export const confirmBooking = createAsyncThunk(
  "auth/merchants/booking_confirm",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/bookings/${payload}/confirm`,
    );
    return response;
  },
);

export const rescheduleBooking = createAsyncThunk(
  "auth/merchants/booking_reschedule",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/bookings/${payload}/reschedule`,
    );
    return response;
  },
);

export const getScheduledBooking = createAsyncThunk(
  "auth/merchants/homepage_bookings",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/bookings/schedules?q=${payload}`,
    );
    return response;
  },
);

export const flexiCodeRedemptions = createAsyncThunk(
  "auth/merchants/flexi_redemptions",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/bookings/flexi_redemption/redeem`,
      payload,
    );
    return response;
  },
);

export const getMultipleBookingDetails = createAsyncThunk(
  "auth/merchants/get_multiple_booking_details",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/bookings/multiple/${payload}`,
    );
    return response;
  },
);

export const getFlexiBookingDetails = createAsyncThunk(
  "auth/merchants/get_flexi_booking_details",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/bookings/flexi/${payload}`,
    );
    return response;
  },
);

export const getCancelledBookingDetails = createAsyncThunk(
  "auth/merchants/get_cancelled_booking_details",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/bookings/cancelled/${payload}`,
    );
    return response;
  },
);

export const getRescheduleBookingDetails = createAsyncThunk(
  "auth/merchants/get_reschedule_booking_details",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/bookings/reschedule/${payload}`,
    );
    return response;
  },
);

export const getFlexiList = createAsyncThunk("auth/merchants/get_flexi_list", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/bookings/flexi_redemption/list/${payload.experienceId}?type=${payload.type}&page=${payload.page}`,
  );
  return response;
});

export const completeFlexiBooing = createAsyncThunk(
  "auth/merchants/complete_flexi_booking",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/bookings/flexi_redemption/${payload}/complete`,
    );
    return response;
  },
);

const bookingSlice = createSlice({
  name: "booking",
  initialState: {
    getExperiencesListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getBookingListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getBookingDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    confirmBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    rescheduleBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getScheduledBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    flexiCodeRedemptionsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMultipleBookingDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getFlexiBookingDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getCancelledBookingDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getRescheduleBookingDetailsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getFlexiListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    completeFlexiBooingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getExperiencesList.pending]: (state) => {
      state.getExperiencesListObj.status = "pending";
    },
    [getExperiencesList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getExperiencesListObj.status = "succeeded";
      state.getExperiencesListObj.data = data;
      state.getExperiencesListObj.successMessage = message;
    },
    [getExperiencesList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getExperiencesListObj.status = "failed";
      state.getExperiencesListObj.errorMessage = message;
    },
    [getBookingList.pending]: (state) => {
      state.getBookingListObj.status = "pending";
    },
    [getBookingList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBookingListObj.status = "succeeded";
      state.getBookingListObj.data = data;
      state.getBookingListObj.successMessage = message;
    },
    [getBookingList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBookingListObj.status = "failed";
      state.getBookingListObj.errorMessage = message;
    },
    [getBookingDetails.pending]: (state) => {
      state.getBookingDetailsObj.status = "pending";
    },
    [getBookingDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBookingDetailsObj.status = "succeeded";
      state.getBookingDetailsObj.data = data;
      state.getBookingDetailsObj.successMessage = message;
    },
    [getBookingDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBookingDetailsObj.status = "failed";
      state.getBookingDetailsObj.errorMessage = message;
    },
    [confirmBooking.pending]: (state) => {
      state.confirmBookingObj.status = "pending";
    },
    [confirmBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.confirmBookingObj.status = "succeeded";
      state.confirmBookingObj.data = data;
      state.confirmBookingObj.successMessage = message;
    },
    [confirmBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.confirmBookingObj.status = "failed";
      state.confirmBookingObj.errorMessage = message;
    },
    [rescheduleBooking.pending]: (state) => {
      state.rescheduleBookingObj.status = "pending";
    },
    [rescheduleBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.rescheduleBookingObj.status = "succeeded";
      state.rescheduleBookingObj.data = data;
      state.rescheduleBookingObj.successMessage = message;
    },
    [rescheduleBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.rescheduleBookingObj.status = "failed";
      state.rescheduleBookingObj.errorMessage = message;
    },
    [getScheduledBooking.pending]: (state) => {
      state.getScheduledBookingObj.status = "pending";
    },
    [getScheduledBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getScheduledBookingObj.status = "succeeded";
      state.getScheduledBookingObj.data = data;
      state.getScheduledBookingObj.successMessage = message;
    },
    [getScheduledBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.getScheduledBookingObj.status = "failed";
      state.getScheduledBookingObj.errorMessage = message;
    },
    [flexiCodeRedemptions.pending]: (state) => {
      state.flexiCodeRedemptionsObj.status = "pending";
    },
    [flexiCodeRedemptions.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.flexiCodeRedemptionsObj.status = "succeeded";
      state.flexiCodeRedemptionsObj.data = data;
      state.flexiCodeRedemptionsObj.successMessage = message;
    },
    [flexiCodeRedemptions.rejected]: (state, action) => {
      const { message } = action.error;

      state.flexiCodeRedemptionsObj.status = "failed";
      state.flexiCodeRedemptionsObj.errorMessage = message;
    },
    [getMultipleBookingDetails.pending]: (state) => {
      state.getMultipleBookingDetailsObj.status = "pending";
    },
    [getMultipleBookingDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMultipleBookingDetailsObj.status = "succeeded";
      state.getMultipleBookingDetailsObj.data = data;
      state.getMultipleBookingDetailsObj.successMessage = message;
    },
    [getMultipleBookingDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMultipleBookingDetailsObj.status = "failed";
      state.getMultipleBookingDetailsObj.errorMessage = message;
    },
    [getFlexiBookingDetails.pending]: (state) => {
      state.getFlexiBookingDetailsObj.status = "pending";
    },
    [getFlexiBookingDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getFlexiBookingDetailsObj.status = "succeeded";
      state.getFlexiBookingDetailsObj.data = data;
      state.getFlexiBookingDetailsObj.successMessage = message;
    },
    [getFlexiBookingDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getFlexiBookingDetailsObj.status = "failed";
      state.getFlexiBookingDetailsObj.errorMessage = message;
    },
    [getCancelledBookingDetails.pending]: (state) => {
      state.getCancelledBookingDetailsObj.status = "pending";
    },
    [getCancelledBookingDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCancelledBookingDetailsObj.status = "succeeded";
      state.getCancelledBookingDetailsObj.data = data;
      state.getCancelledBookingDetailsObj.successMessage = message;
    },
    [getCancelledBookingDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getCancelledBookingDetailsObj.status = "failed";
      state.getCancelledBookingDetailsObj.errorMessage = message;
    },
    [getRescheduleBookingDetails.pending]: (state) => {
      state.getRescheduleBookingDetailsObj.status = "pending";
    },
    [getRescheduleBookingDetails.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getRescheduleBookingDetailsObj.status = "succeeded";
      state.getRescheduleBookingDetailsObj.data = data;
      state.getRescheduleBookingDetailsObj.successMessage = message;
    },
    [getRescheduleBookingDetails.rejected]: (state, action) => {
      const { message } = action.error;

      state.getRescheduleBookingDetailsObj.status = "failed";
      state.getRescheduleBookingDetailsObj.errorMessage = message;
    },
    [getFlexiList.pending]: (state) => {
      state.getFlexiListObj.status = "pending";
    },
    [getFlexiList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getFlexiListObj.status = "succeeded";
      state.getFlexiListObj.data = data;
      state.getFlexiListObj.successMessage = message;
    },
    [getFlexiList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getFlexiListObj.status = "failed";
      state.getFlexiListObj.errorMessage = message;
    },
    [completeFlexiBooing.pending]: (state) => {
      state.completeFlexiBooingObj.status = "pending";
    },
    [completeFlexiBooing.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.completeFlexiBooingObj.status = "succeeded";
      state.completeFlexiBooingObj.data = data;
      state.completeFlexiBooingObj.successMessage = message;
    },
    [completeFlexiBooing.rejected]: (state, action) => {
      const { message } = action.error;

      state.completeFlexiBooingObj.status = "failed";
      state.completeFlexiBooingObj.errorMessage = message;
    },
  },
});

export default bookingSlice.reducer;

// state
export const bookingSelector = (state) => state.booking;
