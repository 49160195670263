import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import SkeletonLoader from "../../../components/skeleton.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { experienceSelector } from "../../../services/experience/experience-slice.services";
import UploadImagesGrid from "./upload-images-grid.component";

function EditPhoto({ handleDeleteImage, handleOrderImage, setFile, setShowImageCropper }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const label = "Add and edit photos of your experience";
  const { getExperiencesDetailsObj } = useSelector(experienceSelector);
  const imageHeight = isMobile ? "200px" : "400px";
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Text sx={{ fontSize: theme.fonts.fontSizes.label }}>Photo</Text>
            <Spacer size="s" position="top" />
            <Text type="GreyColor">{label}</Text>
          </Grid>
          <Grid item xs={12}>
            {getExperiencesDetailsObj.status === "succeeded" ? (
              <UploadImagesGrid
                setFile={setFile}
                setShowImageCropper={setShowImageCropper}
                images={getExperiencesDetailsObj.data.images}
                deleteImage={handleDeleteImage}
                handleOrderImage={handleOrderImage}
              />
            ) : (
              <Grid container spacing={2} sx={{ paddingRight: isMobile ? null : "10px" }}>
                <Grid item xs={12}>
                  <SkeletonLoader height={imageHeight} width="100%" />
                </Grid>
                <Grid item xs={6}>
                  <SkeletonLoader height={imageHeight} width="100%" />
                </Grid>
                <Grid item xs={6}>
                  <SkeletonLoader height={imageHeight} width="100%" />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
EditPhoto.propTypes = {
  handleDeleteImage: PropTypes.func.isRequired,
  handleOrderImage: PropTypes.func.isRequired,
  setFile: PropTypes.func.isRequired,
  setShowImageCropper: PropTypes.func.isRequired,
};

export default EditPhoto;
