import React from "react";
import { Box, Checkbox, FormControl, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import Text from "../../../../components/text.component";
import TextButton from "../../../../components/buttons/text-button.component";
import Spacer from "../../../../components/spacer.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import { experienceSelector } from "../../../../services/experience/experience-slice.services";
import { categorySelector } from "../../../../services/category/category-slice.services";

const SeperateLine = styled(Box)(({ theme }) => ({
  marginTop: "25px",
  marginBottom: "25px",
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const CategoriesCard = styled(Box)(({ theme, isMobile }) => ({
  display: "flex",
  flexDirection: "row",
  backgroundColor: theme.palette.colors.bg.secondary,
  alignItems: "center",
  paddingLeft: theme.padding.paddingX[2],
  paddingRight: theme.padding.paddingX[2],
  width: isMobile ? "100%" : null,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  " .MuiMenuItem-root": {
    padding: "0px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  " .MuiCheckbox-root": {
    paddingLeft: "0px",
  },
}));

function CategoryEditForm({ isEditing, setIsEditing }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { values, setFieldValue, resetForm } = useFormikContext();
  const { updateExperienceCategoryObj } = useSelector(experienceSelector);
  const { getCategoriesObj } = useSelector(categorySelector);
  const toggleEdit = () => {
    setIsEditing((i) => ({ ...i, categories: true }));
  };

  const onCancelEdit = () => {
    resetForm();
    setIsEditing((i) => ({ ...i, categories: false }));
  };

  const handleCheckbox = (data) => {
    const isChecked = values.categories.some((checkedCheckbox) => checkedCheckbox.id === data.id);
    if (isChecked) {
      setFieldValue(
        "categories",
        values.categories.filter((checkedCheckbox) => checkedCheckbox.id !== data.id),
      );
    } else {
      setFieldValue("categories", values.categories.concat(data));
    }
  };

  const defaultCategoryListString = () => {
    const categoryListString = [];
    values.categories.map((item) => categoryListString.push(item.label));
    return categoryListString.join(", ");
  };

  if (isEditing.categories) {
    return (
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Text>Experience Categories</Text>
          <TextButton label="Cancel" onClickButton={onCancelEdit} />
        </SpaceBetweenBox>
        <Spacer size="l" position="top" />
        <FormControl>
          <Grid container spacing={3}>
            {getCategoriesObj.data.map((item) => (
              <Grid item key={item.id} width={isMobile ? "100%" : null}>
                <CategoriesCard
                  isMobile
                  sx={{
                    border: `1px solid ${
                      values.categories.some((checkedCheckbox) => checkedCheckbox.id === item.id)
                        ? theme.palette.colors.brand.secondary
                        : theme.palette.colors.ui.border
                    }`,
                  }}
                >
                  <Checkbox
                    disableRipple
                    sx={{
                      color: theme.palette.colors.brand.secondary,
                      "&.Mui-checked": {
                        color: theme.palette.colors.brand.secondary,
                      },
                      ":hover": { backgroundColor: "transparent" },
                    }}
                    onChange={() => handleCheckbox(item)}
                    checked={values.categories.some(
                      (checkedCheckbox) => checkedCheckbox.id === item.id,
                    )}
                  />
                  <Text>{item.label}</Text>
                </CategoriesCard>
              </Grid>
            ))}
          </Grid>
        </FormControl>
        <Spacer size="l" position="top" />
        <FormSubmitButton width="100px" isLoading={updateExperienceCategoryObj.status === "pending"}>
          <Text type="WhiteColor">Save</Text>
        </FormSubmitButton>
        <SeperateLine />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <SpaceBetweenBox>
        <Text>Experience Categories</Text>
        <TextButton label="Edit" onClickButton={toggleEdit} isDisabled={true} />
      </SpaceBetweenBox>
      <Spacer size="xs" position="top" />
      <Text type="GreyColor">{defaultCategoryListString()}</Text>
      <SeperateLine />
    </Grid>
  );
}

CategoryEditForm.propTypes = {
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.shape({ categories: PropTypes.bool }).isRequired,
};

export default CategoryEditForm;
