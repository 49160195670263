import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

function VerticalPaddedView({ children, multiples }) {
  return <Box sx={{ paddingY: multiples * 1, flex: 1 }}>{children}</Box>;
}

VerticalPaddedView.defaultProps = {
  multiples: 1,
  children: null,
};

VerticalPaddedView.propTypes = {
  children: PropTypes.node,
  multiples: PropTypes.number,
};

export default VerticalPaddedView;
