import React from "react";
import { Box, Grid, styled } from "@mui/material";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Text from "../../../components/text.component";
import { profileSelector } from "../../../services/profile/profile-slice.services";
import TextButton from "../../../components/buttons/text-button.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import Spacer from "../../../components/spacer.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";

const SeperateLine = styled(Box)(({ theme }) => ({
  marginTop: "25px",
  marginBottom: "25px",
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

function CompanyWebsiteForm({ isEditing, setIsEditing, websiteEdit, setWebsiteEdit }) {
  const { getProfileObj, updateWebsiteObj } = useSelector(profileSelector);

  const toggleEdit = () => {
    setWebsiteEdit(true);
    setIsEditing(true);
  };

  const onCancelEdit = () => {
    setWebsiteEdit(false);
    setIsEditing(false);
  };

  if (websiteEdit) {
    return (
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Text>Company Website</Text>
          <TextButton label="Cancel" onClickButton={onCancelEdit} />
        </SpaceBetweenBox>
        <Spacer size="m" position="top" />
        <FormFieldText placeholder="Company Website" name="companyWebsite" />
        <Spacer size="m" position="top" />
        <FormSubmitButton width="100px" isLoading={updateWebsiteObj.status === "pending"}>
          <Text type="WhiteColor">Save</Text>
        </FormSubmitButton>
        <SeperateLine />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <SpaceBetweenBox>
        <Text>Company Website</Text>
        <TextButton label="Edit" onClickButton={toggleEdit} isDisabled={isEditing} />
      </SpaceBetweenBox>
      <Text type="GreyColor">{getProfileObj.data.merchant.website}</Text>
      <SeperateLine />
    </Grid>
  );
}

CompanyWebsiteForm.propTypes = {
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setWebsiteEdit: PropTypes.func.isRequired,
  websiteEdit: PropTypes.bool.isRequired,
};

export default CompanyWebsiteForm;
