import React from "react";
import { Box, Button, Grid, styled, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import PaddedView from "../../../components/padded-view.component";
import Text from "../../../components/text.component";

const CardContainer = styled(Box)(({ theme }) => ({
  height: "400px",
  width: "100%",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  border: `1px solid ${theme.palette.colors.ui.border}`,
  overflow: "hidden",
  boxShadow: `0px 0.5px 5px 0.5px ${theme.palette.colors.loading.foregroundColor}`,
  "& .MuiButton-root": {
    backgroundColor: "transparent",
    textTransform: "none",
    boxShadow: 0,
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "0.5px",
  backgroundColor: theme.palette.colors.ui.border,
}));

function SideMenu({ setCurrentTab, currentTab }) {
  const theme = useTheme();
  return (
    <CardContainer>
      <PaddedView multiples={5}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button disableRipple onClick={() => setCurrentTab(0)}>
              <Text
                sx={{ fontSize: theme.fonts.fontSizes.label }}
                type={currentTab === 0 ? "Primary" : "GreyColor"}
              >
                Photos
              </Text>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <SeperateLine />
          </Grid>
          <Grid item xs={12}>
            <Button disableRipple onClick={() => setCurrentTab(1)}>
              <Text
                sx={{ fontSize: theme.fonts.fontSizes.label, textAlign: "start" }}
                type={currentTab === 1 ? null : "GreyColor"}
              >
                Experience Details
              </Text>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <SeperateLine />
          </Grid>
          <Grid item xs={12}>
            <Button disableRipple onClick={() => setCurrentTab(2)}>
              <Text
                sx={{ fontSize: theme.fonts.fontSizes.label }}
                type={currentTab === 2 ? null : "GreyColor"}
              >
                Availability
              </Text>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <SeperateLine />
          </Grid>
          <Grid item xs={12}>
            <Button disableRipple onClick={() => setCurrentTab(3)}>
              <Text
                sx={{ fontSize: theme.fonts.fontSizes.label, textAlign: "start" }}
                type={currentTab === 3 ? null : "GreyColor"}
              >
                Flexi Redemption
              </Text>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <SeperateLine />
          </Grid>
        </Grid>
      </PaddedView>
    </CardContainer>
  );
}

SideMenu.propTypes = {
  setCurrentTab: PropTypes.func.isRequired,
  currentTab: PropTypes.number.isRequired,
};

export default SideMenu;
