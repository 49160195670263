import React from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

import HTMLRenderer from "react-html-renderer";
import Text from "../../../../components/text.component";
import Spacer from "../../../../components/spacer.component";
import { experienceSelector } from "../../../../services/experience/experience-slice.services";
import SkeletonLoader from "../../../../components/skeleton.component";
import LocationMap from "../../../../components/location-map.component";

const ContentContainer = styled(Box)(({ isMobile }) => ({
  width: "100%",
  height: isMobile ? null : "85%",
  display: "flex",
  flexDirection: "column",
  alignItems: isMobile ? null : "center",
  paddingBottom: isMobile ? "100px" : null,
}));

const PreviewContainer = styled(Box)(({ theme, isMobile }) => ({
  width: isMobile ? "100%" : "85%",
  padding: theme.padding.paddingX[3],
  backgroundColor: theme.palette.colors.bg.secondary,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  border: `20px solid ${theme.palette.colors.bg.secondary}`,
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "0.5px",
  backgroundColor: theme.palette.colors.ui.border,
}));

function ExperiencePreview() {
  const theme = useTheme();
  const TitleText = "Check out your Experience";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getExperiencesDetailsObj } = useSelector(experienceSelector);
  const imageHeight = isMobile ? "150px" : "400px";

  const renderExperienceDetails = () => {
    if (isMobile) {
      return (
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Text>{getExperiencesDetailsObj.data.experience.title}</Text>
            </Grid>
            <Grid item xs={12}>
              <Text type="GreyColor">{getExperiencesDetailsObj.data.location.state}</Text>
            </Grid>
            <Grid item xs={12}>
              <CenterRowBox>
                <Text>{`RM${getExperiencesDetailsObj.data.experience.price}`}</Text>
                <Spacer size="s" position="left" />
                <Text type="GreyColor">/ pax</Text>
              </CenterRowBox>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Text sx={{ fontSize: theme.fonts.fontSizes.button }}>
            {getExperiencesDetailsObj.data.experience.title}
          </Text>
          <CenterRowBox>
            <Text
              sx={{ fontSize: theme.fonts.fontSizes.button }}
            >{`RM${getExperiencesDetailsObj.data.experience.price}`}</Text>
            <Spacer size="s" position="left" />
            <Text type="GreyColor">/ pax</Text>
          </CenterRowBox>
        </SpaceBetweenBox>
        <Text type="GreyColor">{getExperiencesDetailsObj.data.location.state}</Text>
      </Grid>
    );
  };

  return (
    <ContentContainer isMobile={isMobile}>
      <Text
        variant={isMobile ? "h5" : "h3"}
        sx={{
          fontWeight: theme.fonts.fontWeights.bold,
          textAlign: isMobile ? "start" : "center",
        }}
      >
        {TitleText}
      </Text>
      <Spacer size={isMobile ? "l" : "xl"} position="top" />

      <PreviewContainer isMobile={isMobile} sx={{ overflowY: "scroll" }}>
        {getExperiencesDetailsObj.status === "succeeded" ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <img
                src={getExperiencesDetailsObj.data.images[0]?.path}
                alt="img"
                style={{
                  height: imageHeight,
                  width: "100%",
                  borderTopLeftRadius: theme.shape.borderRadius[1],
                  borderTopRightRadius: theme.shape.borderRadius[1],
                  backgroundSize: "auto",
                }}
              />
            </Grid>
            {renderExperienceDetails()}

            <Grid item xs={12}>
              <SeperateLine />
            </Grid>
            <Grid item xs={12}>
              <Text>Overview</Text>
              <Box sx={{ width: "100%" }}>
                <HTMLRenderer html={getExperiencesDetailsObj.data.experience.description} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <SeperateLine />
            </Grid>
            <Grid item xs={12}>
              <Text>Important Info</Text>
              <Box sx={{ width: "100%" }}>
                <HTMLRenderer html={getExperiencesDetailsObj.data.experience.importantInfo} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <SeperateLine />
            </Grid>
            <Grid item xs={12}>
              <Text>Location</Text>
              <Text type="GreyColor">{getExperiencesDetailsObj.data.location.address}</Text>
              <Spacer size="s" position="top" />
              <LocationMap
                lat={getExperiencesDetailsObj.data.location.lat}
                long={getExperiencesDetailsObj.data.location.long}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SkeletonLoader height={imageHeight} />
            </Grid>
            <Grid item xs={12}>
              <SkeletonLoader height="25px" width="125px" />
              <SkeletonLoader height="25px" width="125px" />
              <SkeletonLoader height="25px" width="125px" />
            </Grid>

            <Grid item xs={12}>
              <SeperateLine />
            </Grid>
            <Grid item xs={12}>
              <SkeletonLoader height="25px" width="125px" />
              <SkeletonLoader height="100px" />
            </Grid>
            <Grid item xs={12}>
              <SeperateLine />
            </Grid>
            <Grid item xs={12}>
              <SkeletonLoader height="25px" width="125px" />
              <SkeletonLoader height="100px" />
            </Grid>
            <Grid item xs={12}>
              <SeperateLine />
            </Grid>
            <Grid item xs={12}>
              <SkeletonLoader height="25px" width="125px" />
              <SkeletonLoader height="25px" />
              <SkeletonLoader height={imageHeight} />
            </Grid>
          </Grid>
        )}
      </PreviewContainer>
    </ContentContainer>
  );
}

export default ExperiencePreview;
