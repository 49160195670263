import React from "react";
import { Box, Button, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import PaddedView from "../../../../components/padded-view.component";
import Spacer from "../../../../components/spacer.component";
import FormLabel from "../../../../components/forms/form-label.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Text from "../../../../components/text.component";
import { authSelector } from "../../../../services/auth/auth-slice.services";
import FormPhoneNumber from "../../../../components/forms/form-phone-number.component";
import FormFieldBankDropdown from "../../../../components/forms/form-field-bank-dropdown.component";

const CardContainer = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: theme.palette.colors.bg.white,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  flex: 1,
  maxWidth: 500,
  minWidth: isMobile ? 340 : 450,
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "80px",
  backgroundColor: theme.palette.colors.bg.primary,
  justifyContent: "center",
  alignItems: "center",
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  "& .MuiButton-root": {
    marginTop: "-3px",
    height: "13px",
    borderTopLeftRadius: theme.shape.borderRadius[3],
    borderTopRightRadius: theme.shape.borderRadius[3],
    borderBottomLeftRadius: theme.shape.borderRadius[3],
    borderBottomRightRadius: theme.shape.borderRadius[3],
    textTransform: "none",
    backgroundColor: "transparent",
    boxShadow: "none",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
    "&:disabled": { backgroundColor: "transparent" },
  },
}));

function BusinessDetailsForm({ handleLogout }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { registerObj } = useSelector(authSelector);

  return (
    <CardContainer isMobile={isMobile} sx={{ boxShadow: 2 }}>
      <TitleContainer>
        <Text variant="h5">Fill In Your Details</Text>
      </TitleContainer>

      <PaddedView multiples={isMobile ? 3 : 4}>
        <FormLabel>Business Name</FormLabel>
        <Spacer size="s" />
        <FormFieldText name="name" placeholder="Enter Your Business Name" />

        <Spacer size="m" />
        <FormLabel>Business Registration Number</FormLabel>
        <Spacer size="s" />
        <FormFieldText
          name="businessRegNumber"
          placeholder="Enter Your Business Registration Number"
        />

        <Spacer size="m" />
        <FormLabel>Company Website</FormLabel>
        <Spacer size="s" />
        <FormFieldText name="companyWebsite" placeholder="www.yolo.com" />

        <Spacer size="m" />
        <FormLabel>Contact No</FormLabel>
        <Spacer size="s" />
        <FormPhoneNumber name="contactNo" placeholder="Enter Your Contact Number" />

        <Spacer size="m" />
        <FormLabel>Bank Name</FormLabel>
        <Spacer size="s" />
        <FormFieldBankDropdown name="bankName" placeholder="Bank Name" />

        <Spacer size="m" />
        <FormLabel>Account Holder Name</FormLabel>
        <Spacer size="s" />
        <FormFieldText name="accHolderName" placeholder="Account Holder Name" />

        <Spacer size="m" />
        <FormLabel>Account Number</FormLabel>
        <Spacer size="s" />
        <FormFieldText name="bankAccountNo" placeholder="Account Number" />

        <Spacer size="xxl" />
        <FormSubmitButton isLoading={registerObj.status === "pending"}>
          <Typography>Confirm</Typography>
        </FormSubmitButton>

        <Spacer size="xl" />
        <Grid container sx={{ justifyContent: "space-between" }}>
          <ButtonContainer>
            <Button disableRipple onClick={handleLogout}>
              <Text type="BrandColor">Logout</Text>
            </Button>
          </ButtonContainer>
        </Grid>
      </PaddedView>
    </CardContainer>
  );
}

BusinessDetailsForm.propTypes = {
  handleLogout: PropTypes.func.isRequired,
};

export default BusinessDetailsForm;
