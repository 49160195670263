import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  styled,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Text from "../../../components/text.component";
import FormLabel from "../../../components/forms/form-label.component";
import Spacer from "../../../components/spacer.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormTimePicker from "../../../components/forms/form-time-picker.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import { experienceSelector } from "../../../services/experience/experience-slice.services";
import FormFieldText from "../../../components/forms/form-field-text.component";

const ModalBox = styled(Box)(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "50px",
  paddingRight: "50px",
  width: isMobile ? "350px" : "450px",
  outline: "none",
  maxHeight: "85%",
  display: "flex",
  flexDirection: "column",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: theme.palette.colors.ui.border,
}));

const StyledRadioGroup = styled(RadioGroup)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

const BrandColorRadioButton = styled(Radio)(({ theme }) => ({
  "&, &.Mui-checked": {
    color: theme.palette.colors.brand.primary,
  },
}));

const AlignCenterRow = styled(Box)({ display: "flex", alignItems: "center", flexDirection: "row" });

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  fontSize: "20px",
  color: theme.palette.colors.brand.secondary,
}));

export default function AddSlotFormModal({ isShowModal, setIsShowModal, fromCalendarScreen }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { createMassTimeSlotObj, getExperiencesDetailsObj } = useSelector(experienceSelector);
  const { values, setFieldValue, resetForm } = useFormikContext();

  const radioButtonOnChange = (event) => {
    resetForm();
    setFieldValue("bookingType", event.target.value);
    setFieldValue("capacity", "");
  };

  const handleCancel = () => {
    resetForm();
    setIsShowModal(false);
  };

  const multipleBookingIdentifier = () => (
    <>
      Creates a date and timeslot for your experience which users can book. You are able to select
      the capacity of the bookings for the given date and timeslot.
      <br />
      <br />
      Suitable for :
      <br />- 1 to 1 experiences
      <br />- Experience catering to a single group at a time
      <br />- Experience catering to multiple people at a time
      <br />- Experience catering to multiple separate groups of people
    </>
  );

  return (
    <Modal open={isShowModal} onClose={handleCancel}>
      <ModalBox isMobile={isMobile}>
        <Box>
          <Grid container justifyContent="space-between" alignItems="center">
            <Box width="25px" />
            <Text variant="h5">Add Timeslot</Text>
            <CloseIconButton onClick={handleCancel}>
              <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
            </CloseIconButton>
          </Grid>
        </Box>
        <Spacer position="top" size="m" />
        <SeperateLine />
        <Spacer position="top" size="m" />
        <Box sx={{ display: "flex", flex: 1, overflowY: "auto", flexDirection: "column" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl sx={{ width: "100%" }}>
                <StyledRadioGroup value={values.bookingType} onChange={radioButtonOnChange}>
                  <FormControlLabel
                    value="multiple"
                    control={<BrandColorRadioButton />}
                    label={
                      <AlignCenterRow>
                        <Text>Standard Booking</Text>
                        <Spacer size="s" position="left" />
                        <Tooltip title={multipleBookingIdentifier()} arrow>
                          <StyledInfoOutlinedIcon />
                        </Tooltip>
                      </AlignCenterRow>
                    }
                  />
                  <FormControlLabel
                    disabled={
                      (getExperiencesDetailsObj.status === "succeeded" &&
                        !getExperiencesDetailsObj.data.allowCreateFlexi) ||
                      fromCalendarScreen
                    }
                    value="flexi"
                    control={<BrandColorRadioButton />}
                    label={
                      <AlignCenterRow>
                        <Text>Flexi Booking</Text>
                        <Spacer size="s" position="left" />
                        <Tooltip
                          title="Allows users to purchase a redemption ticket for your experience. Simply key in the unique code of the redemption ticket when presented for it to be claimed."
                          arrow
                        >
                          <StyledInfoOutlinedIcon />
                        </Tooltip>
                      </AlignCenterRow>
                    }
                  />
                </StyledRadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Capacity</FormLabel>
              <Spacer size="s" position="top" />
              <FormFieldText name="capacity" placeholder="Capacity" isTransparent={true} />
            </Grid>

            {values.bookingType === "flexi" && (
              <Grid item xs={12}>
                <FormLabel>Title</FormLabel>
                <Spacer size="s" position="top" />
                <FormFieldText name="flexiTitle" placeholder="Title" isTransparent={true} />
              </Grid>
            )}
            {values.bookingType !== "flexi" && (
              <Grid item xs={12}>
                <FormLabel>Start Date</FormLabel>
                <Spacer size="s" position="top" />
                <FormDatePicker name="startDate" placeholder="Start Date" />
              </Grid>
            )}
            {values.bookingType !== "flexi" && (
              <Grid item xs={12}>
                <FormLabel>Check-in Time</FormLabel>
                <Spacer size="s" position="top" />
                <FormTimePicker name="startTime" placeholder="Check-in Time" />
              </Grid>
            )}
            {values.bookingType !== "flexi" && (
              <Grid item xs={12}>
                <FormLabel>End Date</FormLabel>
                <Spacer size="s" position="top" />
                <FormDatePicker name="endDate" placeholder="End Date" />
              </Grid>
            )}
            {values.bookingType !== "flexi" && (
              <Grid item xs={12}>
                <FormLabel>Check-out Time</FormLabel>
                <Spacer size="s" position="top" />
                <FormTimePicker name="endTime" placeholder="Check-out Time" />
              </Grid>
            )}
          </Grid>
        </Box>
        <Spacer size="m" position="top" />
        <FormSubmitButton isLoading={createMassTimeSlotObj.status === "pending"}>
          <Text type="WhiteColor">Add</Text>
        </FormSubmitButton>
      </ModalBox>
    </Modal>
  );
}

AddSlotFormModal.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  setIsShowModal: PropTypes.func.isRequired,
  fromCalendarScreen: PropTypes.bool.isRequired,
};
