import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import Text from "../../../../components/text.component";
import TextButton from "../../../../components/buttons/text-button.component";
import Spacer from "../../../../components/spacer.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import { experienceSelector } from "../../../../services/experience/experience-slice.services";

const SeperateLine = styled(Box)(({ theme }) => ({
  marginTop: "25px",
  marginBottom: "25px",
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const RedDot = styled(Box)(({ theme }) => ({
  height: "10px",
  width: "10px",
  borderRadius: theme.shape.borderRadius[1],
  backgroundColor: theme.palette.colors.ui.redDot,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const GreenDot = styled(Box)(({ theme }) => ({
  height: "10px",
  width: "10px",
  borderRadius: theme.shape.borderRadius[1],
  backgroundColor: theme.palette.colors.ui.greenDot,
}));

const YellowDot = styled(Box)(({ theme }) => ({
  height: "10px",
  width: "10px",
  borderRadius: theme.shape.borderRadius[1],
  backgroundColor: theme.palette.colors.ui.pending,
}));

const StyledRemoveIcon = styled(CloseIcon)(({ theme }) => ({
  height: "8px",
  width: "8px",
  color: theme.palette.colors.brand.white,
}));

function StatusEditForm({ isEditing, setIsEditing }) {
  const { values, setFieldValue, resetForm } = useFormikContext();
  const { updateExperienceObj, deleteExperienceObj, getExperiencesDetailsObj } =
    useSelector(experienceSelector);
  const theme = useTheme();
  const toggleEdit = () => {
    setIsEditing((i) => ({ ...i, status: true }));
  };

  const onCancelEdit = () => {
    resetForm();
    setIsEditing((i) => ({ ...i, status: false }));
  };

  const getStatus = () => {
    if (values.status === "unlisted") {
      return (
        <CenterRowBox>
          <RedDot />
          <Spacer size="s" position="left" />
          <Text type="GreyColor">Unlisted</Text>
        </CenterRowBox>
      );
    }
    if (values.status === "pending") {
      return (
        <CenterRowBox>
          <YellowDot />
          <Spacer size="s" position="left" />
          <Text type="GreyColor">Pending</Text>
        </CenterRowBox>
      );
    }
    return (
      <CenterRowBox>
        <GreenDot />
        <Spacer size="s" position="left" />
        <Text type="GreyColor">Published</Text>
      </CenterRowBox>
    );
  };

  if (isEditing.status) {
    return (
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Text>Experience Status</Text>
          <TextButton label="Cancel" onClickButton={onCancelEdit} />
        </SpaceBetweenBox>
        <Spacer size="m" position="top" />
        <FormControl onChange={(i) => setFieldValue("status", i.target.value)}>
          <RadioGroup>
            <FormControlLabel
              value="publish"
              checked={values.status === "publish"}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: theme.palette.colors.brand.secondary,
                    },
                  }}
                />
              }
              label={
                <>
                  <CenterRowBox>
                    <GreenDot />
                    <Spacer size="s" position="left" />
                    <Text>Publish</Text>
                  </CenterRowBox>
                  <Text variant="body2" type="GreyColor">
                    Your experience can be found in search results and booked.
                  </Text>
                </>
              }
            />
            <Spacer size="m" position="top" />
            <FormControlLabel
              value="unlisted"
              checked={values.status === "unlisted"}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: theme.palette.colors.brand.secondary,
                    },
                  }}
                />
              }
              label={
                <>
                  <CenterRowBox>
                    <RedDot />
                    <Spacer size="s" position="left" />
                    <Text>Unlisted</Text>
                  </CenterRowBox>
                  <Text variant="body2" type="GreyColor">
                    Guests can’t find the experience in search results nor book the experience.
                  </Text>
                </>
              }
            />
            {getExperiencesDetailsObj.data.allowDelete === true && (
              <>
                <Spacer size="m" position="top" />
                <FormControlLabel
                  value="delete"
                  control={
                    <Radio
                      sx={{
                        "&, &.Mui-checked": {
                          color: theme.palette.colors.brand.secondary,
                        },
                      }}
                    />
                  }
                  label={
                    <>
                      <CenterRowBox>
                        <RedDot>
                          <StyledRemoveIcon />
                        </RedDot>
                        <Spacer size="s" position="left" />
                        <Text>Delete</Text>
                      </CenterRowBox>
                      <Text variant="body2" type="GreyColor">
                        Experience will be deleted permanently.
                      </Text>
                    </>
                  }
                />
              </>
            )}
            {getExperiencesDetailsObj.data.status === "pending" && (
              <>
                <Spacer size="m" position="top" />
                <FormControlLabel
                  value="pending"
                  checked={values.status === "pending"}
                  control={
                    <Radio
                      sx={{
                        "&, &.Mui-checked": {
                          color: theme.palette.colors.brand.secondary,
                        },
                      }}
                    />
                  }
                  label={
                    <>
                      <CenterRowBox>
                        <YellowDot />
                        <Spacer size="s" position="left" />
                        <Text>Pending</Text>
                      </CenterRowBox>
                      <Text variant="body2" type="GreyColor">
                        Experience will be saved.
                      </Text>
                    </>
                  }
                />
              </>
            )}
          </RadioGroup>
        </FormControl>
        <Spacer size="l" position="top" />
        <FormSubmitButton
          width="100px"
          isLoading={
            updateExperienceObj.status === "pending" || deleteExperienceObj.status === "pending"
          }
        >
          <Text type="WhiteColor">Save</Text>
        </FormSubmitButton>
        <SeperateLine />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <SpaceBetweenBox>
        <Text>Experience Status</Text>
        <TextButton label="Edit" onClickButton={toggleEdit} isDisabled={true} />
      </SpaceBetweenBox>
      <Spacer size="xs" position="top" />
      {getStatus()}
      <SeperateLine />
    </Grid>
  );
}

StatusEditForm.propTypes = {
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.shape({ status: PropTypes.bool }).isRequired,
};

export default StatusEditForm;
