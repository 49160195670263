import React from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import Spacer from "../../../components/spacer.component";

const CardBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "250px",
  display: "flex",
  flexDirection: "column",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  padding: theme.padding.paddingX[2],
  ":hover": {
    backgroundColor: theme.palette.colors.bg.secondary,
  },
}));

const StyledDynamicFeedIcon = styled(DynamicFeedIcon)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const BottomBorderLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: theme.palette.colors.ui.border,
}));

function ExperienceCard({ cardDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  return (
    <Link
      to={{ pathname: `${routes.EDIT_EXPERIENCES}`, search: `experienceId=${cardDetails.id}` }}
      style={{ textDecoration: "none" }}
    >
      <CardBox isMobile={isMobile}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {cardDetails.title ? (
              <Box>
                <Text
                  sx={{
                    fontSize: theme.fonts.fontSizes.button,
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflowWrap: "anywhere",
                  }}
                >
                  {cardDetails.title}
                </Text>
                <CenterRowBox>
                  <Text>{`RM${cardDetails.price}`}</Text>
                  <Spacer size="s" position="left" />
                  <Text type="GreyColor">/ pax</Text>
                </CenterRowBox>
              </Box>
            ) : (
              <Text
                sx={{
                  fontSize: theme.fonts.fontSizes.button,
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                }}
              >
                Unnamed
              </Text>
            )}
          </Grid>
          <Grid item xs={12}>
            {cardDetails.multipleBooking ? (
              <CenterRowBox>
                <StyledDynamicFeedIcon />
                <Spacer position="left" size="s" />
                <Text>Multiple Booking</Text>
              </CenterRowBox>
            ) : (
              <Spacer position="top" size="l" />
            )}
          </Grid>
          <Grid item xs={12}>
            <Text
              type="GreyColor"
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
            >
              {cardDetails.location}
            </Text>
            <Spacer size="l" position="top" />
            <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
              <Box sx={{ width: "110px" }}>
                <Text type="GreyColor">Status</Text>
                <Text>{cardDetails.status}</Text>
              </Box>
              <Box>
                <Text type="GreyColor">Last Modified</Text>
                <Text
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                  }}
                >
                  {cardDetails.lastModified}
                </Text>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardBox>
      <Spacer size="xs" position="top" />
      <BottomBorderLine />
    </Link>
  );
}

ExperienceCard.defaultProps = {
  cardDetails: {
    id: null,
    title: "",
    price: "",
    location: "",
    lastModified: "",
    status: "",
    multipleBooking: false,
  },
};

ExperienceCard.propTypes = {
  cardDetails: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    price: PropTypes.string,
    location: PropTypes.string,
    lastModified: PropTypes.string,
    status: PropTypes.string,
    multipleBooking: PropTypes.bool,
  }),
};

export default ExperienceCard;
