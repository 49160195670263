import React from "react";
import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import Text from "../../../../components/text.component";
import Spacer from "../../../../components/spacer.component";
import FormFieldQuantity from "../../../../components/forms/form-field-quantity.component";
import PriceFieldForm from "./price-field-form.component";

const ContentContainer = styled(Box)(({ isMobile }) => ({
  width: "100%",
  overflowY: "scroll",
  height: isMobile ? null : "85%",
  display: "flex",
  flexDirection: "column",
  alignItems: isMobile ? null : "center",
  paddingBottom: isMobile ? "100px" : null,
}));

function PaxForm() {
  const theme = useTheme();
  const TitleText = "No of pax per booking";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ContentContainer isMobile={isMobile}>
      <Text
        variant={isMobile ? "h5" : "h3"}
        sx={{
          fontWeight: theme.fonts.fontWeights.bold,
          textAlign: isMobile ? "start" : "center",
        }}
      >
        {TitleText}
      </Text>
      <Spacer size="s" position="top" />
      <Text sx={{ textAlign: "start" }}>Range of number of pax per booking</Text>
      <Spacer size="xl" position="top" />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box>
          <Text>Min no. pax</Text>
          <FormFieldQuantity name="minNoPax" placeholder="Min" />
        </Box>
        <Spacer size="xl" position="left" />
        <Box>
          <Text>Max no. pax</Text>
          <FormFieldQuantity name="maxNoPax" placeholder="Max" />
        </Box>
      </Box>
      <Spacer size="m" position="top" />
      <PriceFieldForm />
    </ContentContainer>
  );
}

export default PaxForm;
