import React from "react";
import { Box, Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";

const CardBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  padding: theme.padding.paddingX[2],
  ":hover": {
    backgroundColor: theme.palette.colors.bg.secondary,
  },
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "0.5px",
  backgroundColor: theme.palette.colors.ui.border,
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const VerticalBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const ViewDetailsButton = styled(Button)({
  textTransform: "none",
  padding: "0px",
  ":hover": {
    backgroundColor: "transparent",
  },
});

const BorderLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: theme.palette.colors.ui.border,
}));

const DetailsTextBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "132px",
  justifyContent: "center",
});

function FlexiBookingCard({ cardDetails, viewMultipleBookingDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("700"));

  const viewBookingDetailsButton = () => (
    <ViewDetailsButton
      disableRipple
      onClick={() => viewMultipleBookingDetails(cardDetails.booking.massSlot.id)}
    >
      <Text type="BrandColor" sx={{ textDecoration: "underline", alignText: "start" }}>
        View Details
      </Text>
    </ViewDetailsButton>
  );

  const renderBottomBar = () => (
    <Grid item xs={12}>
      <Text
        variant="h6"
        sx={{
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: isMobile ? null : 1,
          marginBottom: "5px",
          overflowWrap: "anywhere",
        }}
      >
        {cardDetails.experience.title}
      </Text>
    </Grid>
  );

  const renderBookingDetatils = () => {
    if (isMobile) {
      return (
        <>
          <img
            src={cardDetails.experience.imagePath}
            alt="cover"
            style={{
              width: "100%",
              borderTopLeftRadius: theme.shape.borderRadius[0],
              borderTopRightRadius: theme.shape.borderRadius[0],
              borderBottomLeftRadius: theme.shape.borderRadius[0],
              borderBottomRightRadius: theme.shape.borderRadius[0],
            }}
          />

          <Spacer position="top" size="xs" />
          <VerticalBox>
            <CenterRowBox>
              <Text type="GreyColor" sx={{ width: "70px" }}>
                Title:
              </Text>
              <Text>{cardDetails.flexiTitle}</Text>
            </CenterRowBox>
            <CenterRowBox>
              <Text type="GreyColor" sx={{ width: "70px" }}>
                Price:
              </Text>
              <Text>{`RM${cardDetails.booking.price}`}</Text>
            </CenterRowBox>
            <CenterRowBox>
              <Text type="GreyColor" sx={{ width: "70px" }}>
                Capacity:
              </Text>
              <Text>
                {`${cardDetails.booking.massSlot.capacity.booked} / ${cardDetails.booking.massSlot.capacity.total}`}
              </Text>
            </CenterRowBox>
            <CenterRowBox>
              <Text type="GreyColor" sx={{ width: "70px" }}>
                Type:
              </Text>
              <Text>Flexi Booking</Text>
            </CenterRowBox>
            <Spacer position="top" size="s" />
            <CenterRowBox>{viewBookingDetailsButton()}</CenterRowBox>
          </VerticalBox>
        </>
      );
    }
    return (
      <CenterRowBox>
        <img
          src={cardDetails.experience.imagePath}
          alt="cover"
          style={{
            width: "25%",
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
          }}
        />

        <Spacer position="left" size="m" />
        <DetailsTextBox>
          <>
            <SpaceBetweenBox>
              <CenterRowBox>
                <Text type="GreyColor" sx={{ width: "65px" }}>
                  Title:
                </Text>
                <Text
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                  }}
                >
                  {cardDetails.flexiTitle}
                </Text>
              </CenterRowBox>
            </SpaceBetweenBox>
            <Spacer position="top" size="xs" />
            <CenterRowBox>
              <Text type="GreyColor" sx={{ width: "70px" }}>
                Capacity:
              </Text>
              <Text
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                }}
              >
                {`${cardDetails.booking.massSlot.capacity.booked} / ${cardDetails.booking.massSlot.capacity.total}`}
              </Text>
            </CenterRowBox>
          </>

          <Spacer position="top" size="xs" />
          <SpaceBetweenBox>
            <CenterRowBox>
              <Text type="GreyColor" sx={{ width: "70px" }}>
                Type:
              </Text>
              <Text>Flexi Booking</Text>
            </CenterRowBox>
            <Text>{`RM${cardDetails.booking.price}`}</Text>
          </SpaceBetweenBox>
          <Spacer position="top" size="xs" />
          <SpaceBetweenBox>{viewBookingDetailsButton()}</SpaceBetweenBox>
        </DetailsTextBox>
      </CenterRowBox>
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <CardBox isMobile={isMobile}>
        <Grid container spacing={1} sx={{ height: "100%" }}>
          <Grid item xs={12}>
            {renderBookingDetatils()}
          </Grid>

          <Grid item xs={12}>
            <SeperateLine />
          </Grid>

          {renderBottomBar()}
        </Grid>
      </CardBox>
      <Spacer size="xs" position="top" />
      <BorderLine />
    </Box>
  );
}

FlexiBookingCard.defaultProps = {
  cardDetails: PropTypes.shape({
    booking: PropTypes.shape({
      id: null,
      date: "",
      price: "",
      status: "",
      cancelledAt: "",
      massSlot: {
        capacity: {
          available: null,
          booked: null,
          total: null,
        },
        id: null,
      },
    }),
    experience: PropTypes.shape({
      id: null,
      title: "",
      imagePath: "",
    }),
    user: {
      name: "",
    },
    rated: false,
    flexiTitle: "",
  }),
};

FlexiBookingCard.propTypes = {
  viewMultipleBookingDetails: PropTypes.func.isRequired,
  cardDetails: PropTypes.shape({
    booking: PropTypes.shape({
      id: PropTypes.number,
      date: PropTypes.string,
      price: PropTypes.string,
      status: PropTypes.string,
      cancelledAt: PropTypes.string,
      massSlot: PropTypes.shape({
        capacity: PropTypes.shape({
          available: PropTypes.number,
          booked: PropTypes.number,
          total: PropTypes.number,
        }),
        id: PropTypes.number,
      }),
    }),
    experience: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,

      imagePath: PropTypes.string,
    }),
    guest: PropTypes.shape({
      name: PropTypes.string,
    }),
    rated: PropTypes.bool,
    flexiTitle: PropTypes.string,
  }),
};

export default FlexiBookingCard;
