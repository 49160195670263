import React from "react";
import PropTypes from "prop-types";
import { Box, Modal, styled, IconButton, useTheme, useMediaQuery, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import BorderButton from "../../../components/buttons/border-button.component";
import { bookingSelector } from "../../../services/booking/booking-slice.services";
import BookingDecisionModalLoader from "../loader/booking-decision-modal-loader.component";
import CtaButton from "../../../components/buttons/cta-button.component";

const ModalBox = styled(Box)(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: isMobile ? theme.padding.paddingX[1] : theme.padding.paddingX[0],
  width: isMobile ? "350px" : "700px",
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
  strokeWidth: 2,
  stroke: theme.palette.colors.brand.secondary,
}));

const TopTitleBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const ImageBox = styled(Box)(({ theme }) => ({
  backgroundSize: "100% 100%",
  backgroundColor: theme.palette.colors.loading.imageBg,
  width: "100%",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

function BookingDecisionModal({
  showModal,
  setShowModal,
  title,
  label,
  onConfirmHandle,
  isLoading,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getBookingDetailsObj } = useSelector(bookingSelector);

  const renderContent = () => {
    if (getBookingDetailsObj.status === "succeeded") {
      // WJ image path return null
      return (
        <Grid container spacing={2} paddingTop={2}>
          {isMobile && (
            <Grid item xs={12}>
              <Text lineHeight="20px" variant="h6">
                {getBookingDetailsObj.data.experience.title}
              </Text>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={isMobile ? 12 : 5}>
                <ImageBox
                  sx={{
                    backgroundImage: `url(${getBookingDetailsObj.data.experience.imagePath})`,
                    height: isMobile ? "75px" : "150px",
                  }}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 7}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Text variant="h6" sx={{ fontWeight: theme.fonts.fontWeights.regular }}>
                    {getBookingDetailsObj.data.slot.startAt}
                  </Text>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Text>{`Guest: ${getBookingDetailsObj.data.guest.name}`}</Text>
                    <Text>{`RM${getBookingDetailsObj.data.price}`}</Text>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SeperateLine />
          </Grid>
          <Grid item xs={12}>
            {isMobile ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <BorderButton
                  onClickButton={() => {
                    setShowModal(false);
                  }}
                >
                  <Text type="BrandColor">Cancel</Text>
                </BorderButton>
                <Spacer size="s" position="top" />
                <CtaButton
                  onClickButton={() => onConfirmHandle(getBookingDetailsObj.data.id)}
                  isLoading={isLoading}
                >
                  <Text type="WhiteColor">Confirm</Text>
                </CtaButton>
              </Box>
            ) : (
              <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
                <Text lineHeight="20px" variant="h6" sx={{ width: "65%" }}>
                  {getBookingDetailsObj.data.experience.title}
                </Text>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <BorderButton
                    onClickButton={() => {
                      setShowModal(false);
                    }}
                  >
                    <Text type="BrandColor">Cancel</Text>
                  </BorderButton>
                  <Spacer size="s" position="left" />
                  <CtaButton
                    onClickButton={() => onConfirmHandle(getBookingDetailsObj.data.id)}
                    isLoading={isLoading}
                  >
                    <Text type="WhiteColor">Confirm</Text>
                  </CtaButton>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    }
    return <BookingDecisionModalLoader setShowModal={setShowModal} />;
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        <TopTitleBox>
          <Text variant="h5">{title}</Text>
          <CloseIconButton onClick={() => setShowModal(false)}>
            <CloseIcon sx={{ height: "25px", width: "25px" }} />
          </CloseIconButton>
        </TopTitleBox>
        <Text type="GreyColor">{label}</Text>
        {renderContent()}
      </ModalBox>
    </Modal>
  );
}

BookingDecisionModal.defaultProps = {
  isLoading: false,
};

BookingDecisionModal.propTypes = {
  onConfirmHandle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default BookingDecisionModal;
