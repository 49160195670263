import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_ROUTES}`,
  timeout: 10000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export function setAxiosToken(token) {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
}

api.interceptors.request.use((request) => {
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line
    console.log(`-> AXIOS LOG: Starting Request ${request.method} - ${request.url}`, request);
  }
  return request;
});

api.interceptors.response.use(
  (response) => {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line
      console.log(
        `<- AXIOS LOG: Response: ${response.config.method} - ${response.config.url}`,
        response,
      );
    }
    return response;
  },
  (error) => {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line
      console.warn("AXIOS LOG: Error:", error.response);
    }
    return Promise.reject(error.response.data);
  },
);

export async function getReq(url) {
  const response = await api.get(url).then((res) => res.data);
  return response;
}

export async function postReq(url, payload) {
  const response = await api.post(url, payload).then((res) => res.data);
  return response;
}

export async function putReq(url, payload) {
  const response = await api.put(url, payload).then((res) => res.data);
  return response;
}

export async function delReq(url, payload) {
  const response = await api.delete(url, { data: payload }).then((res) => res.data);
  return response;
}
