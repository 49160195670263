import React, { useContext, useEffect } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import SideProgressView from "../../components/side-progress-view.component";
import SaveAndExitWithBg from "../../components/save-exit-with-bg.component";
import MobileBottomBar from "../../components/mobile-bottom-bar.component";
import routes from "../../../../navigation/routes";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import {
  experienceSelector,
  getExperiencesDetails,
  updateExperience,
} from "../../../../services/experience/experience-slice.services";
import Form from "../../../../components/forms/form.component";
import PCBottomBar from "../../components/pc-bottom-bar.component";
import PaxForm from "../components/pax-form.component";
import PaxFormLoader from "../loader/pax-form-loader.component";

const validationSchema = Yup.object().shape({
  minNoPax: Yup.number().required().min(1).label("Min"),
  maxNoPax: Yup.number()
    .required()
    .min(Yup.ref("minNoPax"), "Max must be greater than Min")
    .label("Max"),
  price: Yup.number().required().label("Price"),
  experienceId: Yup.string().required().label("Experience Id"),
  type: Yup.string().required().label("Type"),
  isExit: Yup.bool(),
});

function SetPaxScreen() {
  const location = useLocation();
  const experienceId = new URLSearchParams(location.search).get("experienceId");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { updateExperienceObj, getExperiencesDetailsObj } = useSelector(experienceSelector);

  const handlePackage = (values, { isExit }) => {
    if (values.price === "" && values.minNoPax === "" && values.maxNoPax === "" && isExit) {
      history.push(routes.HOME);
      return;
    }
    dispatch(updateExperience(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        history.push({
          pathname: isExit ? routes.HOME : `${routes.CREATE_AVAILABLE_SLOT}`,
          search: `experienceId=${experienceId}`,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    dispatch(getExperiencesDetails(experienceId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  return getExperiencesDetailsObj.status === "succeeded" ? (
    <Form
      validationSchema={validationSchema}
      initialValues={{
        minNoPax: getExperiencesDetailsObj.data.experience.minNoPax,
        maxNoPax: getExperiencesDetailsObj.data.experience.maxNoPax,
        price: getExperiencesDetailsObj.data.experience.price,
        experienceId,
        type: "package",
        isExit: false,
      }}
      onSubmit={handlePackage}
    >
      <Grid container>
        {isMobile ? (
          <Grid item xs={12}>
            <SaveAndExitWithBg handleSubmit={handlePackage} />
          </Grid>
        ) : (
          <Grid item xs={3}>
            <SideProgressView step={7} />
          </Grid>
        )}

        <Grid item xs={isMobile ? 12 : 9}>
          <Grid
            container
            sx={{
              width: "100%",
              padding: theme.padding.paddingX[0],
              height: isMobile ? null : theme.dimensions.heightWithoutAppBar,
            }}
          >
            <PaxForm />
            {!isMobile && (
              <PCBottomBar
                isLoading={updateExperienceObj.status === "pending"}
                handleExit={handlePackage}
                onBackPress={() => history.goBack()}
              />
            )}
          </Grid>
        </Grid>

        {isMobile && (
          <Grid item xs={12}>
            <MobileBottomBar
              borderButtonLabel="Back"
              backgroundButtonLabel="Next"
              borderButtonOnPress={() => history.goBack()}
              isBackgroundButtonLoading={updateExperienceObj.status === "pending"}
            />
          </Grid>
        )}
      </Grid>
    </Form>
  ) : (
    <Formik validationSchema={validationSchema} onSubmit={handlePackage}>
      <Grid container>
        {isMobile ? (
          <Grid item xs={12}>
            <SaveAndExitWithBg handleSubmit={handlePackage} />
          </Grid>
        ) : (
          <Grid item xs={3}>
            <SideProgressView step={7} />
          </Grid>
        )}

        <Grid item xs={isMobile ? 12 : 9}>
          <Grid
            container
            sx={{
              width: "100%",
              padding: theme.padding.paddingX[0],
              height: isMobile ? null : theme.dimensions.heightWithoutAppBar,
            }}
          >
            <PaxFormLoader />
            {!isMobile && (
              <PCBottomBar
                isLoading={updateExperienceObj.status === "pending"}
                handleExit={handlePackage}
                onBackPress={() => history.goBack()}
              />
            )}
          </Grid>
        </Grid>

        {isMobile && (
          <Grid item xs={12}>
            <MobileBottomBar
              borderButtonLabel="Back"
              backgroundButtonLabel="Next"
              borderButtonOnPress={() => history.goBack()}
              isBackgroundButtonLoading={updateExperienceObj.status === "pending"}
            />
          </Grid>
        )}
      </Grid>
    </Formik>
  );
}

export default SetPaxScreen;
