import React from "react";
import { Box, Button, styled } from "@mui/material";
import { useFormikContext } from "formik";
import Text from "../../../../components/text.component";

const FIELDHEIGHT = "45px";
const Container = styled(Box)(({ theme }) => ({
  height: FIELDHEIGHT,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  "& .MuiButton-root": {
    textTransform: "none",
    textDecoration: "underline",
    padding: "0px",
    textDecorationColor: theme.palette.colors.brand.primary,
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
    },
  },
}));

function FilterButtonContainer() {
  const { resetForm, handleSubmit } = useFormikContext();
  return (
    <Container>
      <Button disableRipple onClick={handleSubmit}>
        <Text type="BrandColor">Apply</Text>
      </Button>
      <Button
        disableRipple
        onClick={() => {
          resetForm();
          handleSubmit();
        }}
      >
        <Text type="BrandColor">Clear All</Text>
      </Button>
    </Container>
  );
}

export default FilterButtonContainer;
