import React from "react";
import { Box, Grid, styled } from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import Text from "../../../../components/text.component";
import TextButton from "../../../../components/buttons/text-button.component";
import Spacer from "../../../../components/spacer.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import { experienceSelector } from "../../../../services/experience/experience-slice.services";
import PriceFieldForm from "../../../create-experiences/set-pax/components/price-field-form.component";
import FormFieldQuantity from "../../../../components/forms/form-field-quantity.component";

const SeperateLine = styled(Box)(({ theme }) => ({
  marginTop: "25px",
  marginBottom: "25px",
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

function PaxAndPriceEditForm({ isEditing, setIsEditing }) {
  const { values, resetForm } = useFormikContext();
  const { updateExperienceObj } = useSelector(experienceSelector);
  const toggleEdit = () => {
    setIsEditing((i) => ({ ...i, package: true }));
  };

  const onCancelEdit = () => {
    resetForm();
    setIsEditing((i) => ({ ...i, package: false }));
  };

  if (isEditing.package) {
    return (
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Text>Pax and Price</Text>
          <TextButton label="Cancel" onClickButton={onCancelEdit} />
        </SpaceBetweenBox>
        <Spacer size="s" position="top" />
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box>
            <Text>Min no. pax</Text>
            <FormFieldQuantity name="minNoPax" placeholder="Min" />
          </Box>
          <Spacer size="xl" position="left" />
          <Box>
            <Text>Max no. pax</Text>
            <FormFieldQuantity name="maxNoPax" placeholder="Max" />
          </Box>
        </Box>
        <Spacer size="m" position="top" />
        <PriceFieldForm />
        <Spacer size="xl" position="top" />
        <FormSubmitButton width="100px" isLoading={updateExperienceObj.status === "pending"}>
          <Text type="WhiteColor">Save</Text>
        </FormSubmitButton>
        <SeperateLine />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <SpaceBetweenBox>
        <Text>Pax and Price</Text>
        <TextButton label="Edit" onClickButton={toggleEdit} isDisabled={true} />
      </SpaceBetweenBox>
      <Spacer size="xs" position="top" />
      <CenterRowBox>
        <Text sx={{ width: "100px" }}>Min no. pax: </Text>
        <Spacer size="s" position="left" />
        <Text type="GreyColor">{values.minNoPax}</Text>
      </CenterRowBox>
      <CenterRowBox>
        <Text sx={{ width: "100px" }}>Max no. pax: </Text>
        <Spacer size="s" position="left" />
        <Text type="GreyColor">{values.maxNoPax}</Text>
      </CenterRowBox>
      <CenterRowBox>
        <Text sx={{ width: "100px" }}>Price: </Text>
        <Spacer size="s" position="left" />
        <Text type="GreyColor">{`RM${values.price} / pax`}</Text>
      </CenterRowBox>
      <SeperateLine />
    </Grid>
  );
}

PaxAndPriceEditForm.propTypes = {
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.shape({ package: PropTypes.bool }).isRequired,
};

export default PaxAndPriceEditForm;
