import React, { useContext, useEffect } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import SideProgressView from "../../components/side-progress-view.component";
import SaveAndExitWithBg from "../../components/save-exit-with-bg.component";
import MobileBottomBar from "../../components/mobile-bottom-bar.component";
import routes from "../../../../navigation/routes";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import {
  experienceSelector,
  getExperiencesDetails,
  updateExperienceCategory,
} from "../../../../services/experience/experience-slice.services";
import { getCategories } from "../../../../services/category/category-slice.services";
import SelectCategoryForm from "../components/select-category-form.component";
import Form from "../../../../components/forms/form.component";

const validationSchema = Yup.object().shape({
  categories: Yup.array().required().label("Category"),
  experienceId: Yup.string().required().label("Experience Id"),
  type: Yup.string().required().label("Type"),
  isExit: Yup.bool(),
});

function SelectCategoryScreen() {
  const location = useLocation();
  const experienceId = new URLSearchParams(location.search).get("experienceId");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { updateExperienceCategoryObj, getExperiencesDetailsObj } = useSelector(experienceSelector);

  const getDefaultCategories = () => {
    const processedCategory = [];
    if (getExperiencesDetailsObj.status === "succeeded") {
      getExperiencesDetailsObj.data.categories.map((item) =>
        processedCategory.push({ id: item.categoryId, label: item.label }),
      );
      return processedCategory;
    }
    return processedCategory;
  };

  const handleSubmitCategory = (values, { isExit }) => {
    if (values.categories.length === 0 && isExit) {
      history.push(routes.HOME);
      return;
    }

    const categoriesId = [];
    values.categories.map((item) => categoriesId.push(item.id));

    if (categoriesId.length === 0) {
      createSnackBar({
        message: "Select at least 1 category.",
        type: "error",
        open: true,
      });
      return;
    }

    dispatch(updateExperienceCategory({ categoriesId, ...values })).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        history.push({
          pathname: isExit ? routes.HOME : `${routes.SELECT_LOCATION}`,
          search: `experienceId=${experienceId}`,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    dispatch(getCategories()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getExperiencesDetails(experienceId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  return (
    <Form
      validationSchema={validationSchema}
      initialValues={{
        categories: getDefaultCategories(),
        experienceId,
        type: "categories",
        isExit: false,
      }}
      onSubmit={handleSubmitCategory}
    >
      <Grid container>
        {isMobile ? (
          <Grid item xs={12}>
            <SaveAndExitWithBg handleSubmit={handleSubmitCategory} />
          </Grid>
        ) : (
          <Grid item xs={3}>
            <SideProgressView step={1} />
          </Grid>
        )}

        <Grid item xs={isMobile ? 12 : 9}>
          <SelectCategoryForm handleSubmitCategory={handleSubmitCategory} />
        </Grid>

        {isMobile && (
          <Grid item xs={12}>
            <MobileBottomBar
              borderButtonLabel="Back"
              backgroundButtonLabel="Next"
              borderButtonOnPress={() => history.goBack()}
              isBackgroundButtonLoading={updateExperienceCategoryObj.status === "pending"}
            />
          </Grid>
        )}
      </Grid>
    </Form>
  );
}

export default SelectCategoryScreen;
