import React, { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Text from "../../../components/text.component";

const CardBox = styled(Box)(({ theme, isMobile }) => ({
  width: isMobile ? "295px" : "375px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  padding: theme.padding.paddingX[2],
  boxShadow: `0px 0.5px 5px 0.5px ${theme.palette.colors.loading.foregroundColor}`,
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
});

const CenterColumnBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
});

const CenterBox = styled(Box)({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
});

const StyledButtonIcon = styled(IconButton)(({ theme }) => ({
  padding: 0,
  backgroundColor: theme.palette.colors.brand.white,
  ":hover": { backgroundColor: theme.palette.colors.brand.white },
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "1px",
  backgroundColor: theme.palette.colors.ui.border,
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

function SlotCard({
  cardDetails,
  handleCancelSlot,
  setShowEditSlotModal,
  setShowBookedModal,
  setShowUnableDeleteModal,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showMenu, setShowMenu] = useState(null);
  const { setFieldValue } = useFormikContext();

  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const setDataValue = () => {
    setFieldValue("startDate", cardDetails.checkIn.date);
    setFieldValue("startTime", new Date(cardDetails.checkIn.datetime.replace(" ", "T")));
    setFieldValue("endDate", cardDetails.checkOut.date);
    setFieldValue("endTime", new Date(cardDetails.checkOut.datetime.replace(" ", "T")));
    setFieldValue("id", cardDetails.massSlot.id);
    setFieldValue("capacity", cardDetails.massSlot.capacity.total);
    setFieldValue("bookingType", "multiple");
    setFieldValue("bookedCapacity", cardDetails.massSlot.capacity.booked);
    if (cardDetails.massSlot.capacity.booked !== 0) {
      setShowBookedModal(true);
    } else {
      setShowEditSlotModal(true);
    }
  };

  const handleOnClickCancel = () => {
    handleCloseMenu();
    if (cardDetails.massSlot.capacity.booked !== null && cardDetails.massSlot.capacity.booked > 0) {
      setShowUnableDeleteModal(true);
      return;
    }
    handleCancelSlot({ id: cardDetails.massSlot.id, classType: "massSlot" });
  };

  return (
    <Grid item>
      <CardBox isMobile={isMobile}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SpaceBetweenBox>
              {cardDetails.bookingType === "multiple" ? (
                <Text type="GreyColor" variant="body2">
                  {`Capacity: ${cardDetails.massSlot.capacity.total}`}
                </Text>
              ) : (
                <Box />
              )}

              <StyledButtonIcon onClick={handleOpenMenu}>
                <MoreHorizIcon />
              </StyledButtonIcon>
              <StyledMenu
                sx={{ mt: "30px" }}
                anchorEl={showMenu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(showMenu)}
                onClose={handleCloseMenu}
              >
                <MenuItem
                  key="1"
                  onClick={() => {
                    setDataValue();
                    handleCloseMenu();
                  }}
                >
                  <Text textAlign="center">Edit</Text>
                </MenuItem>
                <MenuItem key="2" onClick={handleOnClickCancel}>
                  <Text textAlign="center">Cancel</Text>
                </MenuItem>
              </StyledMenu>
            </SpaceBetweenBox>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item width="48%">
                <CenterColumnBox>
                  <Box>
                    <Text type="GreyColor" variant="body2">
                      Check-in
                    </Text>
                    <Text
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                      }}
                    >
                      {cardDetails.checkIn.date}
                    </Text>
                    <Text>{cardDetails.checkIn.time}</Text>
                  </Box>
                </CenterColumnBox>
              </Grid>
              <Grid item width="4%">
                <CenterBox>
                  <SeperateLine />
                </CenterBox>
              </Grid>
              <Grid item width="48%">
                <CenterColumnBox>
                  <Box>
                    <Text type="GreyColor" variant="body2">
                      Check-out
                    </Text>
                    <Text
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                      }}
                    >
                      {cardDetails.checkOut.date}
                    </Text>
                    <Text>{cardDetails.checkOut.time}</Text>
                  </Box>
                </CenterColumnBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardBox>
    </Grid>
  );
}

SlotCard.defaultProps = {
  cardDetails: {
    multipleBooking: "",
    id: null,
    checkIn: {
      date: "",
      time: "",
    },
    checkOut: {
      date: "",
      time: "",
    },
    massSlot: { capacity: { available: null, booked: null, total: null }, id: null },
  },
};

SlotCard.propTypes = {
  setShowUnableDeleteModal: PropTypes.func.isRequired,
  setShowBookedModal: PropTypes.func.isRequired,
  setShowEditSlotModal: PropTypes.func.isRequired,
  handleCancelSlot: PropTypes.func.isRequired,
  cardDetails: PropTypes.shape({
    bookingType: PropTypes.string,
    id: PropTypes.number,
    checkIn: PropTypes.shape({
      date: PropTypes.string,
      time: PropTypes.string,
      datetime: PropTypes.string,
    }),
    checkOut: PropTypes.shape({
      date: PropTypes.string,
      time: PropTypes.string,
      datetime: PropTypes.string,
    }),
    massSlot: PropTypes.shape({
      capacity: PropTypes.shape({
        available: PropTypes.number,
        booked: PropTypes.number,
        total: PropTypes.number,
      }),
      id: PropTypes.number,
    }),
  }),
};

export default SlotCard;
