import React from "react";
import PropTypes from "prop-types";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

import logo from "./yolo_logo.png";
import routes from "../../navigation/routes";

function YoloLogo({ isDisable }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        "& .MuiButton-root": {
          "&:hover": {
            backgroundColor: "transparent",
            borderColor: "transparent",
            boxShadow: "none",
          },
        },
      }}
    >
      {isDisable ? (
        <img width={isMobile ? 75 : 100} height="auto" src={logo} alt="logo" />
      ) : (
        <Link to={{ pathname: routes.HOME }}>
          <Button disableRipple>
            <img width={isMobile ? 75 : 100} height="auto" src={logo} alt="logo" />
          </Button>
        </Link>
      )}
    </Box>
  );
}
YoloLogo.defaultProps = {
  isDisable: false,
};

YoloLogo.propTypes = {
  isDisable: PropTypes.bool,
};
export default YoloLogo;
