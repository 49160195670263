import React from "react";
import PropTypes from "prop-types";
import Text from "../text.component";

function FormLabel({ children, disabled }) {
  return <Text type={disabled ? "Placeholder" : null}>{children}</Text>;
}

FormLabel.defaultProps = {
  disabled: false,
};

FormLabel.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

export default FormLabel;
