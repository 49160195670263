import React, { useContext, useEffect, useState } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import SideProgressView from "../../components/side-progress-view.component";
import SaveAndExitWithBg from "../../components/save-exit-with-bg.component";
import MobileBottomBar from "../../components/mobile-bottom-bar.component";
import routes from "../../../../navigation/routes";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import {
  experienceSelector,
  getExperiencesDetails,
  updateExperience,
} from "../../../../services/experience/experience-slice.services";
import Form from "../../../../components/forms/form.component";
import SelectLocationInputType from "../components/select-location-input-type.component";
import AddressForm from "../components/address-form.component";
import PCBottomBar from "../../components/pc-bottom-bar.component";
import LocationMap from "../components/location-map.component";

const validationSchema = Yup.object().shape({
  locationLat: Yup.string().required(),
  locationLong: Yup.string().required(),
  address: Yup.string().required().label("Address"),
  postCode: Yup.string().label("Zip Code"),
  city: Yup.string().label("City"),
  state: Yup.string().label("State"),
  country: Yup.string().label("Country"),
  experienceId: Yup.string().required().label("Experience Id"),
  type: Yup.string().required().label("Type"),
  isExit: Yup.bool(),
});

function SelectLocationScreen() {
  const location = useLocation();
  const experienceId = new URLSearchParams(location.search).get("experienceId");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { createExperienceObj, getExperiencesDetailsObj } = useSelector(experienceSelector);
  const [currentScreen, setCurrentScreen] = useState("Select Location Type");

  const handleSubmitAddress = (values, { isExit }) => {
    if (values.address === "" && isExit) {
      history.push(routes.HOME);
    }
    values.locationLat = values.locationLat.toString();
    values.locationLong = values.locationLong.toString();
    dispatch(updateExperience(values)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        history.push({
          pathname: isExit ? routes.HOME : `${routes.TITILE_FIELD}`,
          search: `experienceId=${experienceId}`,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    dispatch(getExperiencesDetails(experienceId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  return (
    <Form
      validationSchema={validationSchema}
      initialValues={{
        experienceId,
        type: "address",
        isExit: false,
        address:
          (getExperiencesDetailsObj.status === "succeeded" &&
            getExperiencesDetailsObj.data.location.address) ||
          "",
        postCode:
          (getExperiencesDetailsObj.status === "succeeded" &&
            getExperiencesDetailsObj.data.location.postCode) ||
          "",
        city:
          (getExperiencesDetailsObj.status === "succeeded" &&
            getExperiencesDetailsObj.data.location.city) ||
          "",
        state:
          (getExperiencesDetailsObj.status === "succeeded" &&
            getExperiencesDetailsObj.data.location.state) ||
          "",
        country:
          (getExperiencesDetailsObj.status === "succeeded" &&
            getExperiencesDetailsObj.data.location.country) ||
          "",
        locationLat:
          (getExperiencesDetailsObj.status === "succeeded" &&
            getExperiencesDetailsObj.data.location.lat) ||
          "",
        locationLong:
          (getExperiencesDetailsObj.status === "succeeded" &&
            getExperiencesDetailsObj.data.location.long) ||
          "",
      }}
      onSubmit={handleSubmitAddress}
    >
      <Grid container>
        {isMobile ? (
          <Grid item xs={12}>
            <SaveAndExitWithBg handleSubmit={handleSubmitAddress} />
          </Grid>
        ) : (
          <Grid item xs={3}>
            <SideProgressView step={2} />
          </Grid>
        )}

        <Grid item xs={isMobile ? 12 : 9}>
          <Grid
            container
            sx={{
              width: "100%",
              padding: theme.padding.paddingX[0],
              height: isMobile ? null : theme.dimensions.heightWithoutAppBar,
            }}
          >
            {currentScreen === "Select Location Type" && (
              <SelectLocationInputType
                handleSubmitAddress={handleSubmitAddress}
                setCurrentScreen={setCurrentScreen}
              />
            )}
            {currentScreen === "Address Form" && (
              <AddressForm
                handleSubmitAddress={handleSubmitAddress}
                setCurrentScreen={setCurrentScreen}
              />
            )}
            {currentScreen === "Map" && (
              <LocationMap
                handleSubmitAddress={handleSubmitAddress}
                setCurrentScreen={setCurrentScreen}
              />
            )}
            {!isMobile && (
              <PCBottomBar handleExit={handleSubmitAddress} onBackPress={() => history.goBack()} />
            )}
          </Grid>
        </Grid>

        {isMobile && (
          <Grid item xs={12}>
            <MobileBottomBar
              borderButtonLabel="Back"
              backgroundButtonLabel="Next"
              borderButtonOnPress={() => history.goBack()}
              isBackgroundButtonLoading={createExperienceObj.status === "pending"}
            />
          </Grid>
        )}
      </Grid>
    </Form>
  );
}

export default SelectLocationScreen;
