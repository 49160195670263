import React, { useContext, useEffect, useState } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import SideProgressView from "../../components/side-progress-view.component";
import SaveAndExitWithBg from "../../components/save-exit-with-bg.component";
import MobileBottomBar from "../../components/mobile-bottom-bar.component";
import routes from "../../../../navigation/routes";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import {
  deleteImage,
  experienceSelector,
  getExperiencesDetails,
  orderImage,
  uploadImage,
} from "../../../../services/experience/experience-slice.services";
import Form from "../../../../components/forms/form.component";
import UploadImagesForm from "../components/upload-images-form.component";
import PCBottomBar from "../../components/pc-bottom-bar.component";
import Loading from "../../../../components/notifications/loading.component";
import ImageCropper from "../../../../components/image-cropper.component";

const validationSchema = Yup.object().shape({
  experienceId: Yup.string().required().label("Experience Id"),
});

function UploadImageScreen() {
  const location = useLocation();
  const experienceId = new URLSearchParams(location.search).get("experienceId");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getExperiencesDetailsObj } = useSelector(experienceSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [file, setFile] = useState(null);

  const handleUploadImage = (values) => {
    setIsLoading(true);
    dispatch(uploadImage({ experienceId, ...values })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowImageCropper(false);
        setFile(null);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleDeleteImage = (values) => {
    setIsLoading(true);
    dispatch(deleteImage({ experienceId, ...values })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleOrderImage = (values) => {
    setIsLoading(true);
    dispatch(orderImage({ experienceId, ...values })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleExit = () => {
    history.push(routes.HOME);
  };

  const handleOnPressNext = () => {
    if (getExperiencesDetailsObj.status === "succeeded") {
      if (getExperiencesDetailsObj.data.images.length >= 2) {
        history.push({
          pathname: `${routes.DESCRIPTION_FIELD}`,
          search: `experienceId=${experienceId}`,
        });
      } else {
        createSnackBar({
          message: "Please upload at least one cover photo and one experience photo.",
          type: "error",
          open: true,
        });
      }
    }
  };

  useEffect(() => {
    dispatch(getExperiencesDetails(experienceId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  return (
    <Form
      initialValues={{ experienceId }}
      validationSchema={validationSchema}
      onSubmit={handleOnPressNext}
    >
      <ImageCropper
        setIsShowModal={setShowImageCropper}
        isShowModal={showImageCropper}
        handleUploadImage={handleUploadImage}
        file={file}
      />
      <Loading isLoading={isLoading} />
      <Grid container>
        {isMobile ? (
          <Grid item xs={12}>
            <SaveAndExitWithBg handleSubmit={handleExit} />
          </Grid>
        ) : (
          <Grid item xs={3}>
            <SideProgressView step={4} />
          </Grid>
        )}

        <Grid item xs={isMobile ? 12 : 9}>
          <Grid
            container
            sx={{
              width: "100%",
              padding: theme.padding.paddingX[0],
              height: isMobile ? null : theme.dimensions.heightWithoutAppBar,
            }}
          >
            <UploadImagesForm
              handleDeleteImage={handleDeleteImage}
              handleOrderImage={handleOrderImage}
              setFile={setFile}
              setShowImageCropper={setShowImageCropper}
            />
            {!isMobile && (
              <PCBottomBar
                handleExit={handleExit}
                onBackPress={() => history.goBack()}
                isForm={false}
                backgroundButtonPress={handleOnPressNext}
              />
            )}
          </Grid>
        </Grid>

        {isMobile && (
          <Grid item xs={12}>
            <MobileBottomBar
              borderButtonLabel="Back"
              backgroundButtonLabel="Next"
              borderButtonOnPress={() => history.goBack()}
              isForm={false}
              backgroundButtonPress={handleOnPressNext}
            />
          </Grid>
        )}
      </Grid>
    </Form>
  );
}

export default UploadImageScreen;
