import React from "react";
import { Box, Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LandingHomeTopBanner from "../../../assets/images/landing-top-bg.png";
import LandingHomeSecondBanner from "../../../assets/images/landing-second-banner.png";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import Spacer from "../../../components/spacer.component";

const BackgroundImage = styled(Box)(({ isMobile }) => ({
  backgroundImage: `url(${LandingHomeTopBanner})`,
  backgroundSize: "cover",
  opacity: 1,
  width: "100%",
  height: isMobile ? "300px" : "450px",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(to right bottom, ${theme.palette.colors.brand.linear[0]}, ${theme.palette.colors.brand.linear[1]})`,
  textTransform: "none",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  backgroundColor: theme.palette.colors.brand.primary,
  boxShadow: 0,
  "&:hover": {
    backgroundColor: theme.palette.colors.brand.primary,
  },
  "&:disabled": {
    backgroundColor: theme.palette.colors.ui.disabled,
  },
}));

const PaddedBox = styled(Box)(({ theme }) => ({
  padding: theme.padding.paddingX[0],
  width: "100%",
}));

const CenteredBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const BannerImage = styled(Box)(({ isMobile }) => ({
  backgroundImage: `url(${LandingHomeSecondBanner})`,
  backgroundSize: "cover",
  opacity: 1,
  width: "100%",
  height: isMobile ? "300px" : "450px",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
}));

const CenterAlignBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  height: "100%",
  padding: theme.padding.paddingX[0],
  width: "100%",
}));

function LandingHomeScreen() {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));

  const renderInstructions = () => (
    <Grid item xs={12}>
      <PaddedBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Text
              variant="h3"
              sx={{ fontWeight: theme.fonts.fontWeights.bold, textAlign: "center" }}
            >
              Easy to get started
            </Text>
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <CenteredBox>
              <HowToRegOutlinedIcon
                sx={{ fontSize: "75px", color: theme.palette.colors.brand.secondary }}
              />
              <Text
                variant="h5"
                sx={{ fontWeight: theme.fonts.fontWeights.bold, textAlign: "center" }}
              >
                Open Account
              </Text>
              <Text type="GreyColor" sx={{ textAlign: "center" }}>
                Create an merchant account to post experiences.
              </Text>
            </CenteredBox>
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <CenteredBox>
              <FormatListBulletedIcon
                sx={{ fontSize: "75px", color: theme.palette.colors.brand.secondary }}
              />
              <Text
                variant="h5"
                sx={{ fontWeight: theme.fonts.fontWeights.bold, textAlign: "center" }}
              >
                Create Experiences
              </Text>
              <Text type="GreyColor" sx={{ textAlign: "center" }}>
                Your page is how people find you. Make a great first impression by highlighting your
                expertise and adding high quality photos.
              </Text>
            </CenteredBox>
          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <CenteredBox>
              <CloudUploadIcon
                sx={{ fontSize: "75px", color: theme.palette.colors.brand.secondary }}
              />
              <Text
                variant="h5"
                sx={{ fontWeight: theme.fonts.fontWeights.bold, textAlign: "center" }}
              >
                Publish Experiences
              </Text>
              <Text type="GreyColor" sx={{ textAlign: "center" }}>
                Guests allow to find it in search results and book your experiences.
              </Text>
            </CenteredBox>
          </Grid>
        </Grid>
      </PaddedBox>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BackgroundImage isMobile={isMobile}>
            <Text
              type="WhiteColor"
              variant={isMobile ? "h4" : "h3"}
              sx={{ fontWeight: theme.fonts.fontWeights.bold, textAlign: "center" }}
            >
              You Can List
            </Text>
            <Text
              type="WhiteColor"
              variant={isMobile ? "h4" : "h3"}
              sx={{ fontWeight: theme.fonts.fontWeights.bold, textAlign: "center" }}
            >
              Anything, Anywhere
            </Text>
            <Spacer size="xl" position="top" />
            <GradientButton onClick={() => history.push(routes.LOGIN)}>
              <Text type="WhiteColor" sx={{ paddingX: theme.padding.paddingX[2] }}>
                Become A Merchant
              </Text>
            </GradientButton>
          </BackgroundImage>
        </Grid>
        {renderInstructions()}
        <Grid item xs={12}>
          <PaddedBox>
            <BannerImage>
              <CenterAlignBox>
                <Text
                  type="WhiteColor"
                  variant={isMobile ? "h4" : "h3"}
                  sx={{ fontWeight: theme.fonts.fontWeights.bold, textAlign: "start" }}
                >
                  What Experiences
                </Text>
                <Text
                  type="WhiteColor"
                  variant={isMobile ? "h4" : "h3"}
                  sx={{ fontWeight: theme.fonts.fontWeights.bold, textAlign: "start" }}
                >
                  Do I Need To List?
                </Text>
                <Text type="WhiteColor" sx={{ width: isMobile ? "100%" : "40%" }}>
                  We are firm believers that every experience is unique in its own right. Whether
                  you are a provider of gourmet experiences, luxury resort getaways, extreme indoor
                  & outdoor activities, workshops or even rejuvenating spa treatments, we would love
                  for you to list with us.
                </Text>
              </CenterAlignBox>
            </BannerImage>
          </PaddedBox>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LandingHomeScreen;
