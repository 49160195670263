import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import DialogModal from "../../../components/notifications/dialog-modal.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import routes from "../../../navigation/routes";
import {
  deleteExperience,
  experienceSelector,
  updateExperience,
  updateExperienceCategory
} from "../../../services/experience/experience-slice.services";
import ExperienceDetailsLoader from "../loader/experience-details-loader.component";
import AddressEditForm from "./experience-details-form/address-edit-form.component";
import CategoryEditForm from "./experience-details-form/category-edit-form.component";
import DescriptionEditForm from "./experience-details-form/description-edit-form.component";
import ImportantInfoEditForm from "./experience-details-form/important-info-edit-form.component";
import PaxAndPriceEditForm from "./experience-details-form/pax-and-price-edit-form.component";
import StatusEditForm from "./experience-details-form/status-edit-form.component";
import TitleEditForm from "./experience-details-form/title-edit-form.component";

const titleValidationSchema = Yup.object().shape({
  title: Yup.string().required().label("Title"),
  experienceId: Yup.string().required().label("Experience Id"),
  type: Yup.string().required().label("Type"),
});

const descriptionValidationSchema = Yup.object().shape({
  description: Yup.string().required().label("Description"),
  experienceId: Yup.string().required().label("Experience Id"),
  type: Yup.string().required().label("Type"),
});

const importantInfoValidationSchema = Yup.object().shape({
  importantInfo: Yup.string().required().label("Important Info"),
  experienceId: Yup.string().required().label("Experience Id"),
  type: Yup.string().required().label("Type"),
});

const addressValidationSchema = Yup.object().shape({
  locationLat: Yup.string().required(),
  locationLong: Yup.string().required(),
  address: Yup.string().required().label("Address"),
  postCode: Yup.string().label("Zip Code"),
  city: Yup.string().label("City"),
  state: Yup.string().label("State"),
  country: Yup.string().label("Country"),
  experienceId: Yup.string().required().label("Experience Id"),
  type: Yup.string().required().label("Type"),
});

const paxValidationSchema = Yup.object().shape({
  minNoPax: Yup.number().required().min(1).label("Min"),
  maxNoPax: Yup.number()
    .required()
    .min(Yup.ref("minNoPax"), "Max must be greater than Min")
    .label("Max"),
  price: Yup.number().required().label("Price"),
  experienceId: Yup.string().required().label("Experience Id"),
  type: Yup.string().required().label("Type"),
});

const statusValidationSchema = Yup.object().shape({
  status: Yup.string().required().label("Status"),
  experienceId: Yup.string().required().label("Experience Id"),
  type: Yup.string().required().label("Type"),
});

const categoriesValidationShema = Yup.object().shape({
  categories: Yup.array().required().label("Category"),
  experienceId: Yup.string().required().label("Experience Id"),
});

function ExperienceDetails({ experienceId }) {
  const [isEditing, setIsEditing] = useState({
    title: false,
    description: false,
    important: false,
    address: false,
    package: false,
    status: false,
    categories: false,
  });

  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getExperiencesDetailsObj, updateExperienceObj, deleteExperienceObj } =
    useSelector(experienceSelector);
  const history = useHistory();
  const [showConfirmPublishModal, setShowConfirmPublishModal] = useState(false);
  const [showConfirmUnlistedModal, setShowConfirmUnlistedModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showUnablePublishModal, setShowUnablePublishModal] = useState(false);

  const resetState = (type) => {
    if (type === "categories") {
      setIsEditing((i) => ({ ...i, categories: false }));
    }
    if (type === "title") {
      setIsEditing((i) => ({ ...i, title: false }));
    }
    if (type === "description") {
      setIsEditing((i) => ({ ...i, description: false }));
    }
    if (type === "important") {
      setIsEditing((i) => ({ ...i, important: false }));
    }
    if (type === "address") {
      setIsEditing((i) => ({ ...i, address: false }));
    }
    if (type === "package") {
      setIsEditing((i) => ({ ...i, package: false }));
    }
    if (type === "status") {
      setIsEditing((i) => ({ ...i, status: false }));
    }

    setShowConfirmPublishModal(false);
    setShowConfirmUnlistedModal(false);
    setShowConfirmDeleteModal(false);
  };

  const handleUpdateExperienceDetails = (values) => {
    dispatch(updateExperience(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        resetState(values.type);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };
  const handleUpdateExperienceStatus = (values) => {
    if (values.status === "delete") {
      setShowConfirmDeleteModal(true);
      return;
    }
    if (values.status === "publish") {
      if (
        getExperiencesDetailsObj.data.categories.length <= 0 ||
        !getExperiencesDetailsObj.data.experience.title ||
        !getExperiencesDetailsObj.data.experience.description ||
        !getExperiencesDetailsObj.data.experience.type ||
        !getExperiencesDetailsObj.data.experience.importantInfo ||
        getExperiencesDetailsObj.data.images.length <= 0 ||
        !getExperiencesDetailsObj.data.location.address ||
        !getExperiencesDetailsObj.data.location.lat ||
        !getExperiencesDetailsObj.data.location.long ||
        getExperiencesDetailsObj.data.slots.length <= 0
      ) {
        setShowUnablePublishModal(true);
        return;
      }
      setShowConfirmPublishModal(true);
      return;
    }
    if (values.status === "unlisted") {
      setShowConfirmUnlistedModal(true);
      return;
    }
    dispatch(updateExperience(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        resetState("status");
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const onConfirmUpdateStatus = (status) => {
    dispatch(updateExperience({ experienceId, type: "status", status })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          resetState("status");
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const onConfirmDelete = () => {
    dispatch(deleteExperience(experienceId)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        resetState("status");
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        history.push(routes.EXPERIENCES);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleUpdateCategory = (values) => {
    const categoriesId = [];
    values.categories.map((item) => categoriesId.push(item.id));

    if (categoriesId.length === 0) {
      createSnackBar({
        message: "Select at least 1 category.",
        type: "error",
        open: true,
      });
      return;
    }

    dispatch(updateExperienceCategory({ categoriesId, ...values })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        resetState('categories');
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const getDefaultCategories = () => {
    const processedCategory = [];
    if (getExperiencesDetailsObj.status === "succeeded") {
      getExperiencesDetailsObj.data.categories.map((item) =>
        processedCategory.push({ id: item.categoryId, label: item.label }),
      );
      return processedCategory;
    }
    return processedCategory;
  };

  if (getExperiencesDetailsObj.status === "succeeded") {
    return (
      <>
        <Form
          validationSchema={categoriesValidationShema}
          initialValues={{
            categories: getDefaultCategories(),
            experienceId,
          }}
          onSubmit={handleUpdateCategory}
        >
          <CategoryEditForm setIsEditing={setIsEditing} isEditing={isEditing} />
        </Form>
        <Form
          validationSchema={titleValidationSchema}
          initialValues={{
            title: getExperiencesDetailsObj.data.experience.title,
            experienceId,
            type: "title",
          }}
          onSubmit={handleUpdateExperienceDetails}
        >
          <TitleEditForm setIsEditing={setIsEditing} isEditing={isEditing} />
        </Form>
        <Form
          validationSchema={descriptionValidationSchema}
          initialValues={{
            description: getExperiencesDetailsObj.data.experience.description,
            experienceId,
            type: "description",
          }}
          onSubmit={handleUpdateExperienceDetails}
        >
          <DescriptionEditForm setIsEditing={setIsEditing} isEditing={isEditing} />
        </Form>
        <Form
          validationSchema={importantInfoValidationSchema}
          initialValues={{
            importantInfo: getExperiencesDetailsObj.data.experience.importantInfo,
            experienceId,
            type: "important",
          }}
          onSubmit={handleUpdateExperienceDetails}
        >
          <ImportantInfoEditForm setIsEditing={setIsEditing} isEditing={isEditing} />
        </Form>
        <Form
          validationSchema={addressValidationSchema}
          initialValues={{
            locationLat: getExperiencesDetailsObj.data.location.lat,
            locationLong: getExperiencesDetailsObj.data.location.long,
            address: getExperiencesDetailsObj.data.location.address,
            postCode: getExperiencesDetailsObj.data.location.postCode,
            city: getExperiencesDetailsObj.data.location.city,
            state: getExperiencesDetailsObj.data.location.state,
            country: getExperiencesDetailsObj.data.location.country,
            experienceId,
            type: "address",
          }}
          onSubmit={handleUpdateExperienceDetails}
        >
          <AddressEditForm setIsEditing={setIsEditing} isEditing={isEditing} />
        </Form>
        <Form
          validationSchema={paxValidationSchema}
          initialValues={{
            minNoPax: getExperiencesDetailsObj.data.experience.minNoPax,
            maxNoPax: getExperiencesDetailsObj.data.experience.maxNoPax,
            price: getExperiencesDetailsObj.data.experience.price,
            experienceId,
            type: "package",
          }}
          onSubmit={handleUpdateExperienceDetails}
        >
          <PaxAndPriceEditForm setIsEditing={setIsEditing} isEditing={isEditing} />
        </Form>
        <Form
          validationSchema={statusValidationSchema}
          initialValues={{
            status: getExperiencesDetailsObj.data.status,
            type: "status",
            experienceId,
          }}
          onSubmit={handleUpdateExperienceStatus}
        >
          <StatusEditForm setIsEditing={setIsEditing} isEditing={isEditing} />
        </Form>
        <DialogModal
          showModal={showConfirmPublishModal}
          setShowModal={setShowConfirmPublishModal}
          title="Publish"
          message="Please make sure the information provided is correct."
          buttonText="Confirm"
          onConfirm={() => onConfirmUpdateStatus("publish")}
          disabled={updateExperienceObj.status === "pending"}
        />
        <DialogModal
          showModal={showConfirmUnlistedModal}
          setShowModal={setShowConfirmUnlistedModal}
          title="Unlisted"
          message="Are you sure you want to leave your experience unlisted?"
          buttonText="Confirm"
          onConfirm={() => onConfirmUpdateStatus("unlisted")}
          disabled={updateExperienceObj.status === "pending"}
        />
        <DialogModal
          showModal={showConfirmDeleteModal}
          setShowModal={setShowConfirmDeleteModal}
          title="Delete"
          message="Are you sure you want to delete your experience?"
          hint="You won't be able to find or edit your experience page anymore."
          buttonText="Confirm"
          onConfirm={onConfirmDelete}
          disabled={deleteExperienceObj.status === "pending"}
        />
        <DialogModal
          showModal={showUnablePublishModal}
          setShowModal={setShowUnablePublishModal}
          title="Unable to Publish"
          message="Unable to publish this experiences"
          hint="Fill up all experience details and try again"
          buttonText="Ok"
          onConfirm={() => setShowUnablePublishModal(false)}
        />
      </>
    );
  }

  return <ExperienceDetailsLoader />;
}

ExperienceDetails.propTypes = {
  experienceId: PropTypes.string.isRequired,
};

export default ExperienceDetails;
