import React, { useContext, useEffect } from "react";
import { Box, Grid, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import Text from "../../../components/text.component";
import VerticalPaddedView from "../../../components/vertical-padded-view.component";
import ProfileSideMenu from "../components/profile-side-menu.component";
import { getProfile, profileSelector } from "../../../services/profile/profile-slice.services";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import ProfileSideMenuUserDetails from "../components/profile-side-menu-user-details.component";
import ProfileDetailsLoader from "../loader/profile-details-loader.component";
import ProfileDetails from "../components/profile-details.component";
import routes from "../../../navigation/routes";

const StyledBackButton = styled(IconButton)(({ theme }) => ({
  padding: "0px",
  strokeWidth: 1,
  stroke: theme.palette.colors.brand.secondary,
  color: theme.palette.colors.brand.secondary,
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

function ProfileScreen() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const { getProfileObj } = useSelector(profileSelector);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getProfile()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  return (
    <VerticalPaddedView multiples={isMobile ? 0 : 7}>
      <Grid container>
        {!isMobile && (
          <Grid item width="450px">
            <ProfileSideMenu />
          </Grid>
        )}

        <Grid item sx={{ display: "flex", flex: 1 }}>
          <PaddedView multiples={isMobile ? 3 : 0}>
            <Box sx={{ width: isMobile ? "100%" : "90%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CenterRowBox>
                    {isMobile && (
                      <Link to={{ pathname: routes.ACCOUNTMENU }}>
                        <StyledBackButton>
                          <ArrowBackIosIcon />
                        </StyledBackButton>
                      </Link>
                    )}
                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }} variant="h4">
                      Profile
                    </Text>
                  </CenterRowBox>
                </Grid>

                <Grid item xs={12}>
                  {isMobile && <ProfileSideMenuUserDetails />}
                </Grid>
              </Grid>
              {getProfileObj.status === "succeeded" ? <ProfileDetails /> : <ProfileDetailsLoader />}
            </Box>
          </PaddedView>
        </Grid>
      </Grid>
    </VerticalPaddedView>
  );
}

export default ProfileScreen;
