import React from "react";
import { Box, Grid, styled, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import Text from "../../../components/text.component";

const BookingCardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.secondary,
  padding: theme.padding.paddingX[2],
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
});

function MultipleBookingCard({ cardDetails, selectedExperience }) {
  const theme = useTheme();

  return (
    <Grid xs={12} item>
      <BookingCardContainer>
        {selectedExperience === "all" && (
          <SpaceBetweenRowBox>
            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold, overflowWrap: "anywhere" }}>
              {cardDetails.experience.title}
            </Text>
          </SpaceBetweenRowBox>
        )}
        <SpaceBetweenRowBox>
          <Text type="GreyColor">Time (In):</Text>
          <Text>{cardDetails.slots.startTime}</Text>
        </SpaceBetweenRowBox>
        <SpaceBetweenRowBox>
          <Text type="GreyColor">Time (Out):</Text>
          <Text>{cardDetails.slots.endTime}</Text>
        </SpaceBetweenRowBox>
        <SpaceBetweenRowBox>
          <Text type="GreyColor">Booked:</Text>
          <Text>{`${cardDetails.slots.capacity.booked} / ${cardDetails.slots.capacity.total}`}</Text>
        </SpaceBetweenRowBox>
        <SpaceBetweenRowBox>
          <Text type="GreyColor">No of Pax:</Text>
          <Text>{`${cardDetails.noPax}`}</Text>
        </SpaceBetweenRowBox>
      </BookingCardContainer>
    </Grid>
  );
}

MultipleBookingCard.defaultProps = {
  selectedExperience: "",
  cardDetails: {
    experience: { id: null, title: "" },
    id: null,
    noPax: null,
    slots: {
      capacity: {
        total: null,
        available: null,
        booked: null,
      },
      endTime: "",
      id: null,
      multipleBooking: false,
      startTime: "",
    },
    status: "",
    time: "",
    user: { name: "" },
  },
};

MultipleBookingCard.propTypes = {
  selectedExperience: PropTypes.string,
  cardDetails: PropTypes.shape({
    experience: PropTypes.shape({ id: PropTypes.number, title: PropTypes.string }),
    id: PropTypes.number,
    noPax: PropTypes.number,
    slots: PropTypes.shape({
      capacity: PropTypes.shape({
        total: PropTypes.number,
        available: PropTypes.number,
        booked: PropTypes.number,
      }),
      endTime: PropTypes.string,
      id: PropTypes.number,
      multipleBooking: PropTypes.bool,
      startTime: PropTypes.string,
    }),
    status: PropTypes.string,
    time: PropTypes.string,
    user: PropTypes.shape({ name: PropTypes.string }),
  }),
};

export default MultipleBookingCard;
