import React, { useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import SkeletonLoader from "../../../components/skeleton.component";
import MultipleBookingDetailsCardLoader from "../loader/booking-card-loader.component";
import { bookingSelector } from "../../../services/booking/booking-slice.services";
import FlexiBookingDetailsCard from "./flexi-booking-details-card.component";

const ModalBox = styled(Box)(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
  width: "90%",
  height: "90%",
  maxWidth: "1000px",
  paddingTop: isMobile ? theme.padding.paddingX[1] : theme.padding.paddingX[0],
  paddingBottom: isMobile ? theme.padding.paddingX[1] : theme.padding.paddingX[0],
  overflow: "hidden",
}));

const PaddingBox = styled(Box)(({ theme, isMobile }) => ({
  paddingLeft: isMobile ? theme.padding.paddingX[1] : theme.padding.paddingX[0],
  paddingRight: isMobile ? theme.padding.paddingX[1] : theme.padding.paddingX[0],
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  height: "1px",
}));

const MenuBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  "& .MuiOutlinedInput-root": {
    " &.Mui-focused fieldset": {
      borderColor: theme.palette.colors.brand.secondary,
    },
  },
  "& .MuiSelect-iconOutlined": {
    color: theme.palette.colors.brand.secondary,
  },
}));

const StatusBox = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  width: "120px",
  display: "flex",
  justifyContent: "center",
}));

const StatusList = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Confirmed",
    value: "confirmed",
  },
  {
    label: "Completed",
    value: "completed",
  },
];

export default function FlexiBookingListModal({
  showModal,
  setShowModal,
  onClickExperienceCardButton,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isShowTwoItems = useMediaQuery(theme.breakpoints.down("870"));
  const { getFlexiBookingDetailsObj } = useSelector(bookingSelector);
  const [currentStatus, setCurrentStatus] = useState("all");

  const getItemToShow = () => {
    if (isMobile) {
      return 12;
    }
    if (isShowTwoItems) {
      return 6;
    }
    return 4;
  };

  const handleCancel = () => {
    setCurrentStatus("all");
    setShowModal(false);
  };

  const renderNoItem = () => (
    <Grid item xs={12}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Text>No Items</Text>
      </Box>
    </Grid>
  );

  const renderStatusBox = (status) => {
    if (status === "confirmed") {
      return (
        <StatusBox sx={{ border: `1px solid ${theme.palette.colors.button.confirmedBooking}` }}>
          <Text
            sx={{
              paddingX: theme.padding.paddingX[3],
              color: theme.palette.colors.button.confirmedBooking,
            }}
          >
            Confirmed
          </Text>
        </StatusBox>
      );
    }
    if (status === "completed") {
      return (
        <StatusBox sx={{ border: `1px solid ${theme.palette.colors.brand.primary}` }}>
          <Text
            sx={{
              paddingX: theme.padding.paddingX[3],
              color: theme.palette.colors.brand.primary,
            }}
          >
            Completed
          </Text>
        </StatusBox>
      );
    }
    return null;
  };

  const statusMenu = () => (
    <FormControl>
      <Select
        variant="outlined"
        value={currentStatus}
        sx={{
          minWidth: "150px",
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          height: "40px",
          backgroundColor: "transparent",
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              marginTop: "5px",
            },
          },
        }}
        defaultValue={currentStatus}
        onChange={(event) => {
          setCurrentStatus(event.target.value);
        }}
      >
        {StatusList.map((item) => (
          <MenuItem value={item.value} key={item.value}>
            <Text>{item.label}</Text>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderConfirmed = () => {
    if (getFlexiBookingDetailsObj.data.confirmed.length === 0) {
      if (currentStatus === "confirmed") {
        return renderNoItem();
      }
      if (currentStatus === "all") {
        return null;
      }
    }
    return (
      <Grid item xs={12}>
        <Box sx={{ width: "100%" }}>
          {renderStatusBox("confirmed")}
          <Spacer size="s" position="top" />
          <Grid container spacing={3}>
            {getFlexiBookingDetailsObj.data.confirmed.map((item) => (
              <Grid item key={item.booking.id} xs={getItemToShow()}>
                <FlexiBookingDetailsCard
                  cardDetails={item}
                  onClickExperienceCardButton={onClickExperienceCardButton}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    );
  };

  const renderCompleted = () => {
    if (getFlexiBookingDetailsObj.data.completed.length === 0) {
      if (currentStatus === "completed") {
        return renderNoItem();
      }
      if (currentStatus === "all") {
        return null;
      }
    }
    return (
      <Grid item xs={12}>
        <Box sx={{ width: "100%" }}>
          {renderStatusBox("completed")}
          <Spacer size="s" position="top" />
          <Grid container spacing={3}>
            {getFlexiBookingDetailsObj.data.completed.map((item) => (
              <Grid item key={item.booking.id} xs={getItemToShow()}>
                <FlexiBookingDetailsCard
                  cardDetails={item}
                  onClickExperienceCardButton={onClickExperienceCardButton}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    );
  };

  const renderContent = () => {
    if (getFlexiBookingDetailsObj.status === "succeeded") {
      return (
        <Box sx={{ width: "100%", height: "100%" }}>
          <PaddingBox>
            <SpaceBetweenRowBox>
              <Box sx={{ width: "24px" }} />
              <Text variant="h5" sx={{ textAlign: "center", overflowWrap: "anywhere" }}>
                {getFlexiBookingDetailsObj.data.title}
              </Text>
              <CloseIconButton onClick={handleCancel}>
                <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
              </CloseIconButton>
            </SpaceBetweenRowBox>
          </PaddingBox>
          <Spacer size="l" position="top" />

          <SeperateLine />

          <Spacer size="l" position="top" />
          <PaddingBox
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: isMobile ? "flex-start" : "space-between",
              alignItems: isMobile ? null : "center",
            }}
          >
            <Text>{`Title: ${getFlexiBookingDetailsObj.data.flexiTitle}`}</Text>
            <MenuBox>
              <Text>Status : </Text>
              <Spacer size="s" position="left" />
              {statusMenu()}
            </MenuBox>
          </PaddingBox>
          <Spacer size="m" position="top" />
          <PaddingBox isMobile={isMobile} sx={{ overflowY: "scroll", height: "80%" }}>
            <Grid container spacing={5}>
              {(currentStatus === "confirmed" || currentStatus === "all") && renderConfirmed()}
              {(currentStatus === "completed" || currentStatus === "all") && renderCompleted()}

              <Grid item xs={12}>
                <Spacer position="top" size="xxl" />
              </Grid>
            </Grid>
          </PaddingBox>
        </Box>
      );
    }
    return (
      <Box sx={{ width: "100%", height: "100%" }}>
        <PaddingBox>
          <SpaceBetweenRowBox>
            <Box sx={{ width: "24px" }} />
            <SkeletonLoader width="120px" height="35px" />
            <CloseIconButton onClick={handleCancel}>
              <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
            </CloseIconButton>
          </SpaceBetweenRowBox>
        </PaddingBox>
        <Spacer size="l" position="top" />
        <SeperateLine />
        <Spacer size="l" position="top" />
        <PaddingBox
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: isMobile ? "flex-start" : "space-between",
            alignItems: isMobile ? null : "center",
          }}
        >
          <SkeletonLoader width="150px" height="35px" />
          <MenuBox>
            <SkeletonLoader width="150px" height="35px" />
          </MenuBox>
        </PaddingBox>
        <Spacer size="m" position="top" />
        <PaddingBox isMobile={isMobile} sx={{ overflowY: "scroll", height: "80%" }}>
          <Grid item xs={12}>
            <Box sx={{ width: "100%" }}>
              <SkeletonLoader width="100px" height="35px" />
              <Spacer size="s" position="top" />
              <Grid container spacing={3}>
                <Grid item xs={getItemToShow()}>
                  <MultipleBookingDetailsCardLoader />
                </Grid>
                <Grid item xs={getItemToShow()}>
                  <MultipleBookingDetailsCardLoader />
                </Grid>
                <Grid item xs={getItemToShow()}>
                  <MultipleBookingDetailsCardLoader />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </PaddingBox>
      </Box>
    );
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox isMobile={isMobile}>{renderContent()}</ModalBox>
    </Modal>
  );
}

FlexiBookingListModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onClickExperienceCardButton: PropTypes.func.isRequired,
};
