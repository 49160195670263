import React from "react";
import { Box, Button, styled, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import Loader from "react-js-loader";

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  "& .MuiButton-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: "transparent",
    textTransform: "none",
    border: `1px solid ${theme.palette.colors.brand.secondary}`,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const LoadingBox = styled(Box)({
  height: "25px",
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
});

function SecondaryBorderButton({ children, handleSubmit, isLoading, width }) {
  const theme = useTheme();
  return (
    <ButtonContainer>
      {isLoading ? (
        <Button variant="contained" disabled sx={{ width }}>
          <LoadingBox>
            <Loader type="bubble-loop" bgColor={theme.palette.colors.brand.secondary} size={30} />
          </LoadingBox>
        </Button>
      ) : (
        <Button variant="contained" onClick={handleSubmit} sx={{ width, boxShadow: 0 }}>
          {children}
        </Button>
      )}
    </ButtonContainer>
  );
}

SecondaryBorderButton.defaultProps = {
  width: "100%",
};

SecondaryBorderButton.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
};

export default SecondaryBorderButton;
