import React from "react";
import PropTypes from "prop-types";
import { FormGroup, FormControlLabel, useTheme } from "@mui/material";
import CheckBox from "@mui/material/Checkbox";
import { useFormikContext } from "formik";
import Text from "../text.component";

function FormMiniCheckBox({ name, label }) {
  const theme = useTheme();
  const { setFieldValue, values } = useFormikContext();
  const handleOnChange = (event) => {
    setFieldValue(name, event.target.checked);
  };
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <CheckBox
            checked={values[name]}
            onChange={handleOnChange}
            size="small"
            sx={{
              color: theme.palette.colors.brand.primary,
              "&.Mui-checked": {
                color: theme.palette.colors.brand.primary,
              },
            }}
          />
        }
        label={
          <Text type="BrandColor" variant="body2">
            {label}
          </Text>
        }
      />
    </FormGroup>
  );
}

FormMiniCheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FormMiniCheckBox;
