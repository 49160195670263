import { configureStore } from "@reduxjs/toolkit";
import authSliceServices from "./auth/auth-slice.services";
import bookingSliceServices from "./booking/booking-slice.services";
import calendarSliceServices from "./calendar/calendar-slice.services";
import experienceSliceServices from "./experience/experience-slice.services";
import profileSliceServices from "./profile/profile-slice.services";
import statementSliceServices from "./statement/statement-slice.services";
import categorySliceServices from "./category/category-slice.services";

const store = configureStore({
  reducer: {
    auth: authSliceServices,
    profile: profileSliceServices,
    experience: experienceSliceServices,
    booking: bookingSliceServices,
    calendar: calendarSliceServices,
    statement: statementSliceServices,
    category: categorySliceServices
  },
});

export default store;
