import React from "react";
import { Box, Grid, styled } from "@mui/material";
import { useSelector } from "react-redux";
import Text from "../../../components/text.component";
import { profileSelector } from "../../../services/profile/profile-slice.services";

const SeperateLine = styled(Box)(({ theme }) => ({
  marginTop: "25px",
  marginBottom: "25px",
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

function BusinessRegistrationNoForm() {
  const { getProfileObj } = useSelector(profileSelector);

  return (
    <Grid item xs={12}>
      <SpaceBetweenBox>
        <Text>Business Registration Number</Text>
      </SpaceBetweenBox>
      <Text type="GreyColor">{getProfileObj.data.merchant.businessRegNumber}</Text>
      <SeperateLine />
    </Grid>
  );
}

export default BusinessRegistrationNoForm;
