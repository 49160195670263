import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Box, IconButton, InputAdornment, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

function FormTimePicker({ name, width, placeholder, disabled }) {
  const { setFieldValue, values, touched, errors, setFieldTouched } = useFormikContext();
  const [currentTime, setCurrentTime] = useState("");
  const theme = useTheme();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (time) => {
    setFieldValue(name, time);
    setCurrentTime(time);
  };

  if (disabled) {
    return (
      <Box
        sx={{
          "& .MuiTextField-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            width,
            "& .MuiOutlinedInput-root": {
              borderTopLeftRadius: theme.shape.borderRadius[0],
              borderTopRightRadius: theme.shape.borderRadius[0],
              borderBottomLeftRadius: theme.shape.borderRadius[0],
              borderBottomRightRadius: theme.shape.borderRadius[0],
              backgroundColor: "transparent",
            },
          },
        }}
      >
        <TextField
          disabled={true}
          error={showError}
          variant="outlined"
          placeholder={placeholder}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  disabled={true}
                  sx={{
                    padding: "0px",
                    margin: "0px -12px 0px 0px",
                    color: theme.palette.colors.ui.border,
                  }}
                >
                  <AccessTimeIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        disabled={disabled}
        label=""
        value={values[name] ? values[name] : currentTime}
        onChange={handleChange}
        inputProps={{ placeholder }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={showError}
            onBlur={() => setFieldTouched(name)}
            helperText={showError ? errors[name] : null}
            placeholder={placeholder}
            sx={{
              width,
              "& .MuiOutlinedInput-root": {
                borderTopLeftRadius: theme.shape.borderRadius[0],
                borderTopRightRadius: theme.shape.borderRadius[0],
                borderBottomLeftRadius: theme.shape.borderRadius[0],
                borderBottomRightRadius: theme.shape.borderRadius[0],
                backgroundColor: "transparent",
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}

FormTimePicker.defaultProps = {
  width: "100%",
  placeholder: "time",
  disabled: false,
};

FormTimePicker.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  placeholder: PropTypes.string,
};

export default FormTimePicker;
