import { Grid, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import CtaButton from "../../../components/buttons/cta-button.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";

function FlexiExperienceCard({ cardDetails, setShowCodeRedemptionModal, setRedeemExpereinceId }) {
  const theme = useTheme();

  return (
    <Grid container sx={{ border: "1px solid", borderRadius: `${theme.shape.borderRadius[1]}px` }}>
      <Grid item xs={3}>
        <img
          src={cardDetails.imagePath}
          width="100%"
          height="100%"
          alt="experience_img"
          style={{
            borderRadius: `${theme.shape.borderRadius[1]}px`,
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
            objectFit: "cover",
          }}
        />
      </Grid>
      <Grid item xs={9}>
        <Grid
          item
          display="grid"
          flexDirection="column"
          alignContent="space-between"
          height="100%"
          xs={12}
        >
          <Grid item padding="15px">
            <Grid item>
              <Text>{cardDetails.title}</Text>
            </Grid>
            <Spacer />
            <Grid item>
              <Text>RM {cardDetails.price}</Text>
            </Grid>
          </Grid>
          <Grid item container xs={12} justifyContent="end">
            <Grid item margin="10px">
              <CtaButton
                onClickButton={() => {
                  setShowCodeRedemptionModal(true);
                  setRedeemExpereinceId(cardDetails.id);
                }}
              >
                <Text type="WhiteColor">Insert Code</Text>
              </CtaButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

FlexiExperienceCard.propTypes = {
  cardDetails: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    price: PropTypes.string,
    imagePath: PropTypes.string,
    flexiTitle: PropTypes.string,
    status: PropTypes.string,
    capacity: PropTypes.shape({
      total: PropTypes.number,
      available: PropTypes.number,
      occupied: PropTypes.number,
      redeemed: PropTypes.number,
    }),
  }).isRequired,
  setShowCodeRedemptionModal: PropTypes.func.isRequired,
  setRedeemExpereinceId: PropTypes.func.isRequired,
};

export default FlexiExperienceCard;
