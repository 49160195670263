import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import routes from "./routes";
import { isLogin } from "../infrastructure/utils";

function PrivateRoute({ component: Component, ...otherProps }) {
  return (
    <Route
      {...otherProps}
      render={(props) => (isLogin() ? <Component {...props} /> : <Redirect to={routes.LOGIN} />)}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PrivateRoute;
