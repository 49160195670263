import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import SkeletonLoader from "../../../components/skeleton.component";
import { experienceSelector } from "../../../services/experience/experience-slice.services";
import SlotCard from "./slot-card.component";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import CtaButton from "../../../components/buttons/cta-button.component";

const SpaceBetweenBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

function EditSlots({
  setShowAddSlotModal,
  handleCancelSlot,
  setShowEditSlotModal,
  setShowBookedModal,
  setShowUnableDeleteModal,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getExperiencesDetailsObj } = useSelector(experienceSelector);

  const renderTimeSlotList = () => {
    const bookingList = getExperiencesDetailsObj.data.slots.filter(
      (item) => item.bookingType !== "flexi",
    );

    if (bookingList <= 0) {
      return (
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Spacer position="top" size="xl" />
            <Text sx={{ textAlign: "center" }}>No Created Timeslot</Text>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={3} justifyContent={isMobile ? "center" : "space-evenly"}>
        {bookingList.map(
          (item) =>
            item.bookingType !== "flexi" && (
              <SlotCard
                cardDetails={item}
                key={item.id}
                handleCancelSlot={handleCancelSlot}
                setShowEditSlotModal={setShowEditSlotModal}
                setShowBookedModal={setShowBookedModal}
                setShowUnableDeleteModal={setShowUnableDeleteModal}
              />
            ),
        )}
      </Grid>
    );
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Text>Availability</Text>
              <CtaButton onClickButton={() => setShowAddSlotModal(true)}>
                <Text type="WhiteColor">Add Timeslot</Text>
              </CtaButton>
            </SpaceBetweenBox>
          </Grid>
          <Grid item xs={12}>
            {getExperiencesDetailsObj.status === "succeeded" ? (
              renderTimeSlotList()
            ) : (
              <Grid container spacing={3} sx={{ paddingRight: isMobile ? null : "10px" }}>
                <Grid item>
                  <SkeletonLoader width="325px" height="150px" />
                </Grid>
                <Grid item>
                  <SkeletonLoader width="325px" height="150px" />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
EditSlots.propTypes = {
  setShowAddSlotModal: PropTypes.func.isRequired,
  handleCancelSlot: PropTypes.func.isRequired,
  setShowEditSlotModal: PropTypes.func.isRequired,
  setShowBookedModal: PropTypes.func.isRequired,
  setShowUnableDeleteModal: PropTypes.func.isRequired,
};

export default EditSlots;
