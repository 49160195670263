import React from "react";
import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import Text from "../../../../components/text.component";
import Spacer from "../../../../components/spacer.component";

const ContentContainer = styled(Box)(({ isMobile }) => ({
  width: "100%",
  overflowY: "scroll",
  height: isMobile ? null : "85%",
  display: "flex",
  flexDirection: "column",
  alignItems: isMobile ? null : "center",
  paddingBottom: isMobile ? "100px" : null,
}));

function PublishExperienceForm() {
  const theme = useTheme();
  const TitleText = "Saved your Experience";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ContentContainer isMobile={isMobile}>
      <Text
        variant={isMobile ? "h5" : "h3"}
        sx={{
          fontWeight: theme.fonts.fontWeights.bold,
          textAlign: isMobile ? "start" : "center",
        }}
      >
        {TitleText}
      </Text>
      <Spacer size={isMobile ? "l" : "xl"} position="top" />
      <Text type="GreyColor">Do you want to publish your experience directly?</Text>
    </ContentContainer>
  );
}

export default PublishExperienceForm;
