import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Pagination, styled, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Loader from "react-js-loader";
import * as Yup from "yup";
import Text from "../../../../components/text.component";
import Spacer from "../../../../components/spacer.component";
import FlexiSlotCard from "./flexi-slot-card.component";
import {
  bookingSelector,
  completeFlexiBooing,
  flexiCodeRedemptions,
  getFlexiList,
} from "../../../../services/booking/booking-slice.services";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import BorderButton from "../../../../components/buttons/border-button.component";
import Form from "../../../../components/forms/form.component";
import FlexiRedemptionsCodeInsertModal from "./flexi-redemptions-code-insert-modal.component";

const AntTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.colors.brand.secondary,
    height: "3px",
  },
  borderBottom: `1px solid ${theme.palette.colors.ui.border}`,
  width: "100%",
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.colors.text.secondary,
  "&.Mui-selected": {
    color: theme.palette.colors.brand.secondary,
  },
}));

const codeRedemptionValidationSchema = Yup.object().shape({
  flexiCode: Yup.string().label("Code").required(),
});

function FlexiRedemptions({
  handleCancelSlot,
  setShowBookedModal,
  setShowUnableDeleteModal,
  setShowEditSlotModal,
  experienceId,
  setIsLoading,
}) {
  const { getFlexiListObj } = useSelector(bookingSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentTabIndex, setcurrentTabIndex] = useState(0);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [page, setPage] = useState(1);
  const [showCodeRedemptionModal, setShowCodeRedemptionModal] = useState(false);
  const [redemptionStep, setRedemptionStep] = useState(1);

  const getCompletedFlexiList = (newPage) => {
    dispatch(getFlexiList({ experienceId, type: "completed", page: newPage })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const getOnGoingFlexiList = (newPage) => {
    dispatch(getFlexiList({ experienceId, type: "ongoing", page: newPage })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const flexiCodeRedemptionsHandle = (values) => {
    let redemption = "";
    if (redemptionStep === 1) {
      redemption = "preview";
    } else if (redemptionStep === 2) {
      redemption = "complete";
    }
    setIsLoading(true);
    dispatch(
      flexiCodeRedemptions({ experienceId: Number(experienceId), redemption, ...values }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        if (redemptionStep === 1) {
          setRedemptionStep(2);
        } else if (redemptionStep === 2) {
          setRedemptionStep(3);
          setPage(1);
          if (currentTabIndex === 0) {
            getOnGoingFlexiList(1);
          }
          if (currentTabIndex === 1) {
            getCompletedFlexiList(1);
          }
        } else {
          setRedemptionStep(1);
        }
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0);
    if (currentTabIndex === 0) {
      getOnGoingFlexiList(newPage);
    }
    if (currentTabIndex === 1) {
      getCompletedFlexiList(newPage);
    }
  };

  useEffect(() => {
    setPage(1);
    getOnGoingFlexiList(1);
  }, []);

  const onCompleteFlexiBooking = (id) => {
    dispatch(completeFlexiBooing(id)).then(({ meta, error }) => {
      if (meta.requestStatus === "fulfilled") {
        getOnGoingFlexiList(1);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleChangeTab = (event, newValue) => {
    setcurrentTabIndex(newValue);
    setPage(1);
    if (newValue === 0) {
      getOnGoingFlexiList(1);
    }
    if (newValue === 1) {
      getCompletedFlexiList(1);
    }
  };

  const renderFlexiSlot = () => {
    if (getFlexiListObj.data.items <= 0) {
      return (
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Spacer position="top" size="xl" />
            <Text sx={{ textAlign: "center" }}>No Items</Text>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={3} justifyContent={isMobile ? "center" : "space-evenly"}>
        {getFlexiListObj.data.items.map((item) => (
          <FlexiSlotCard
            cardDetails={item}
            key={item.massSlot.id}
            handleCancelSlot={handleCancelSlot}
            setShowBookedModal={setShowBookedModal}
            setShowUnableDeleteModal={setShowUnableDeleteModal}
            setShowEditSlotModal={setShowEditSlotModal}
            setShowCodeRedemptionModal={setShowCodeRedemptionModal}
            onCompleteFlexiBooking={onCompleteFlexiBooking}
          />
        ))}
      </Grid>
    );
  };

  return (
    <Grid container spacing={3}>
      <Form
        validationSchema={codeRedemptionValidationSchema}
        initialValues={{
          flexiCode: "",
        }}
        onSubmit={flexiCodeRedemptionsHandle}
      >
        <FlexiRedemptionsCodeInsertModal
          isShowModal={showCodeRedemptionModal}
          setIsShowModal={setShowCodeRedemptionModal}
          redemptionStep={redemptionStep}
          setRedemptionStep={setRedemptionStep}
        />
      </Form>
      <Grid item xs={12}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Text variant="h6">Flexi Redemption</Text>
          <BorderButton onClickButton={() => setShowCodeRedemptionModal(true)}>
            <Text type="BrandColor">Insert Code</Text>
          </BorderButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <AntTabs variant="fullWidth" value={currentTabIndex} onChange={handleChangeTab}>
          <AntTab label={<Text>Ongoing</Text>} />
          <AntTab label={<Text>History</Text>} />
        </AntTabs>
      </Grid>
      <Grid item xs={12}>
        {getFlexiListObj.status === "succeeded" ? (
          renderFlexiSlot()
        ) : (
          <Grid
            container
            spacing={3}
            sx={{
              paddingRight: isMobile ? null : "10px",
              justifyContent: "center",
              alignItems: "center",
              height: "250px",
            }}
          >
            <Loader type="bubble-loop" bgColor={theme.palette.colors.brand.secondary} size={70} />
          </Grid>
        )}
      </Grid>

      {getFlexiListObj.status === "succeeded" && getFlexiListObj.data.pagination.totalPages >= 2 && (
        <Grid item xs={12}>
          <Grid container justifyContent="center" paddingTop="50px">
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.secondary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={handlePageChange}
              count={getFlexiListObj.data.pagination.totalPages}
              variant="outlined"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

FlexiRedemptions.propTypes = {
  setShowUnableDeleteModal: PropTypes.func.isRequired,
  handleCancelSlot: PropTypes.func.isRequired,
  setShowBookedModal: PropTypes.func.isRequired,
  setShowEditSlotModal: PropTypes.func.isRequired,
  experienceId: PropTypes.string.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default FlexiRedemptions;
