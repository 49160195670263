import React, { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import {
  useTheme,
  Toolbar,
  Box,
  useMediaQuery,
  styled,
  Grid,
  Avatar,
  IconButton,
  MenuItem,
  Menu,
} from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import YoloLogo from "../../assets/images/yolo_logo";
import { isLogin } from "../utils/index";
import { getProfile } from "../../services/profile/profile-slice.services";
import { SnackbarContext } from "../../components/notifications/snackbar.context";
import Text from "../../components/text.component";
import PCViewAppBar from "./pc-appbar-components/pc-view-appbar.component";
import { logout, resetUser } from "../../services/auth/auth-slice.services";
import routes from "../../navigation/routes";
import MobileDrawerAppBar from "./mobile-appbar-components/mobile-drawer-appbar.component";
import AppFooter from "./app-footer.component";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

const AppBarContainer = styled(Box)(({ isMobile, theme }) => ({
  justifyContent: isMobile ? "space-between" : "center",
  alignItems: "center",
  display: "flex",
  width: "100%",
  paddingLeft: isMobile ? 0 : theme.padding.paddingX[0],
  paddingRight: isMobile ? 0 : theme.padding.paddingX[0],
}));

const MaxWidthBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  maxWidth: theme.dimensions.PCMaxWidth,
  backgroundColor: theme.palette.colors.bg.white,
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.white,
  overflowX: "auto",
  overflowY: "auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const MainContainer = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: theme.palette.colors.bg.white,
  minHeight: isMobile ? "80vh" : "100vh",
}));

function AppBarLayout({ children }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const createSnackBar = useContext(SnackbarContext);
  const location = useLocation();
  const [avatarMenu, setAvatarMenu] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAvatarMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAvatarMenu(null);
  };

  useEffect(() => {
    if (isLogin())
      dispatch(getProfile()).then(({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      });
  }, []);

  const handleLogout = () => {
    dispatch(logout()).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        history.push(routes.LOGIN);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: "Logout Successful",
          type: "success",
          open: true,
        });
        dispatch(resetUser());
        history.push(routes.LOGIN);
      }
    });
  };

  const renderAppBar = () => {
    if (!isLogin()) {
      return (
        <AppBarContainer isMobile={isMobile}>
          <MaxWidthBox>
            <YoloLogo />
            <Grid
              container
              spacing={3}
              sx={{
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Grid item>
                <IconButton onClick={handleOpenUserMenu} sx={{ padding: "0px" }}>
                  <Avatar sx={{ width: "40px", height: "40px" }} />
                </IconButton>
                <StyledMenu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={avatarMenu}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(avatarMenu)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem
                    key={1}
                    onClick={() => {
                      handleCloseUserMenu();
                      history.push(routes.LOGIN);
                    }}
                  >
                    <Text textAlign="center">Login</Text>
                  </MenuItem>
                  <MenuItem
                    key={2}
                    onClick={() => {
                      handleCloseUserMenu();
                      history.push(routes.SIGNUP);
                    }}
                  >
                    <Text textAlign="center">Sign Up</Text>
                  </MenuItem>
                  <MenuItem
                    key={3}
                    onClick={() => {
                      handleCloseUserMenu();
                    }}
                  >
                    <Text textAlign="center">Help & Support</Text>
                  </MenuItem>
                </StyledMenu>
              </Grid>
            </Grid>
          </MaxWidthBox>
        </AppBarContainer>
      );
    }
    if (!isMobile) {
      return <PCViewAppBar handleLogout={handleLogout} />;
    }
    return <MobileDrawerAppBar handleLogout={handleLogout} />;
  };

  return (
    <MainContainer>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: theme.palette.colors.bg.white,
        }}
      >
        <Toolbar disableGutters={!isMobile}>{renderAppBar()}</Toolbar>
      </AppBar>

      <ContentWrapper isMobile={isMobile}>
        <MaxWidthBox
          sx={{
            paddingBottom:
              location.pathname.includes("create") || location.pathname.includes("calendar")
                ? null
                : "50px",
            minHeight: theme.dimensions.heightWithoutAppBar,
          }}
        >
          {children}
        </MaxWidthBox>
        <AppFooter />
      </ContentWrapper>
    </MainContainer>
  );
}

AppBarLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppBarLayout;
