import React from "react";
import { Box, Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";

const CardBox = styled(Box)(({ theme, isMobile }) => ({
  width: isMobile ? "100%" : "315px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  paddingLeft: theme.padding.paddingX[2],
  paddingRight: theme.padding.paddingX[2],
  paddingTop: theme.padding.paddingX[1],
  paddingBottom: theme.padding.paddingX[1],
  ":hover": {
    backgroundColor: theme.palette.colors.bg.secondary,
  },
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const CardButton = styled(Button)({
  padding: 0,
  textTransform: "none",
  ":hover": { backgroundColor: "transparent" },
});

const ColumnBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

function BookingCard({ cardDetails, onClickBooking }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  return (
    <ColumnBox>
      <CardButton onClick={() => onClickBooking(cardDetails.booking.id)} disableRipple>
        <CardBox isMobile={isMobile}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Text
                sx={{
                  fontSize: theme.fonts.fontSizes.button,
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                  textAlign: "start",
                  overflowWrap: "anywhere",
                }}
              >
                {cardDetails.experience.title}
              </Text>
            </Grid>
            <Grid item xs={12}>
              <SpaceBetweenRowBox>
                <Text type="GreyColor">Date</Text>
                <Text>{cardDetails.booking.date}</Text>
              </SpaceBetweenRowBox>
              <SpaceBetweenRowBox>
                <Text type="GreyColor">Time</Text>
                <Text>{cardDetails.booking.time}</Text>
              </SpaceBetweenRowBox>
            </Grid>
            <Grid item xs={12}>
              <SeperateLine />
            </Grid>
            <Grid item xs={12}>
              <Text variant="h6">{cardDetails.user.name}</Text>
              <Text>{cardDetails.user.email}</Text>
            </Grid>
          </Grid>
        </CardBox>
      </CardButton>
      <Spacer size="xs" position="top" />
      <SeperateLine />
    </ColumnBox>
  );
}

BookingCard.defaultProps = {
  cardDetails: PropTypes.shape({
    booking: PropTypes.shape({
      id: null,
      date: "",
      time: "",
      noPax: null,
    }),
    experience: PropTypes.shape({
      id: null,
      title: "",
    }),
    user: PropTypes.shape({
      name: "",
      email: "",
    }),
  }),
  onClickBooking: null,
};

BookingCard.propTypes = {
  onClickBooking: PropTypes.func,
  cardDetails: PropTypes.shape({
    booking: PropTypes.shape({
      id: PropTypes.number,
      date: PropTypes.string,
      time: PropTypes.string,
      noPax: PropTypes.number,
    }),
    experience: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    user: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
};

export default BookingCard;
