import React from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import Text from "../../../components/text.component";
import SkeletonLoader from "../../../components/skeleton.component";

const SeperateLine = styled(Box)(({ theme }) => ({
  marginTop: "25px",
  marginBottom: "25px",
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

function ProfileDetailsLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Grid item xs={12}>
        <Text>Business Name</Text>
        <SkeletonLoader height="25px" width={isMobile ? "90%" : "30%"} />
        <SeperateLine />
      </Grid>
      <Grid item xs={12}>
        <Text>Business Registration Number</Text>
        <SkeletonLoader height="25px" width={isMobile ? "90%" : "30%"} />
        <SeperateLine />
      </Grid>
      <Grid item xs={12}>
        <Text>Company Website</Text>
        <SkeletonLoader height="25px" width={isMobile ? "90%" : "30%"} />
        <SeperateLine />
      </Grid>
      <Grid item xs={12}>
        <Text>Contact No</Text>
        <SkeletonLoader height="25px" width={isMobile ? "90%" : "30%"} />
        <SeperateLine />
      </Grid>
      <Grid item xs={12}>
        <Text>Email Address</Text>
        <SkeletonLoader height="25px" width={isMobile ? "90%" : "30%"} />
        <SeperateLine />
      </Grid>
      <Grid item xs={12}>
        <Text>Password</Text>
        <SkeletonLoader height="25px" width={isMobile ? "90%" : "30%"} />
        <SeperateLine />
      </Grid>
      <Grid item xs={12}>
        <Text>Bank Details</Text>
        <SkeletonLoader height="25px" width={isMobile ? "90%" : "30%"} />
        <SeperateLine />
      </Grid>
    </>
  );
}

export default ProfileDetailsLoader;
