import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import SideProgressView from "../../components/side-progress-view.component";
import MobileBottomBar from "../../components/mobile-bottom-bar.component";
import routes from "../../../../navigation/routes";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import {
  getExperiencesDetails,
  updateExperience,
} from "../../../../services/experience/experience-slice.services";
import Form from "../../../../components/forms/form.component";
import PublishExperienceForm from "../components/publish-experience-form.component";
import BorderButton from "../../../../components/buttons/border-button.component";
import Text from "../../../../components/text.component";
import CreateExpBg from "../../../../assets/images/mobile-create-exp-bg.png";
import Loading from "../../../../components/notifications/loading.component";
import CtaButton from "../../../../components/buttons/cta-button.component";

const BackgroundImage = styled(Box)({
  backgroundImage: `url(${CreateExpBg})`,
  minHeight: "200px",
  opacity: 1,
  width: "100%",
  backgroundSize: "cover",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  marginTop: "10px",
  marginBottom: "10px",
}));

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
});

const validationSchema = Yup.object().shape({
  experienceId: Yup.string().required().label("Experience Id"),
  type: Yup.string().required().label("Type"),
  isExit: Yup.bool(),
});

function PublishExperienceScreen() {
  const location = useLocation();
  const experienceId = new URLSearchParams(location.search).get("experienceId");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);

  const handlePublish = (status) => {
    setIsLoading(true);
    const values = { status, type: "status", experienceId };
    dispatch(updateExperience(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        history.push(routes.HOME);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    dispatch(getExperiencesDetails(experienceId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  return (
    <Form
      initialValues={{ experienceId }}
      validationSchema={validationSchema}
      onSubmit={() => handlePublish("publish")}
    >
      <Loading isLoading={isLoading} />
      <Grid container>
        {isMobile ? (
          <Grid item xs={12}>
            <BackgroundImage />
          </Grid>
        ) : (
          <Grid item xs={3}>
            <SideProgressView step={0} />
          </Grid>
        )}

        <Grid item xs={isMobile ? 12 : 9}>
          <Grid
            container
            sx={{
              width: "100%",
              padding: theme.padding.paddingX[0],
              height: isMobile ? null : theme.dimensions.heightWithoutAppBar,
            }}
          >
            <PublishExperienceForm />
            {!isMobile && (
              <>
                <SeperateLine />
                <SpaceBetweenBox>
                  <BorderButton
                    onClickButton={() => handlePublish("unlisted")}
                    isLoading={isLoading}
                  >
                    <Text type="BrandColor" sx={{ paddingX: "15px" }}>
                      Later
                    </Text>
                  </BorderButton>

                  <CtaButton onClickButton={() => handlePublish("publish")} isLoading={isLoading}>
                    <Text type="WhiteColor" sx={{ paddingX: "15px" }}>
                      Publish
                    </Text>
                  </CtaButton>
                </SpaceBetweenBox>
              </>
            )}
          </Grid>
        </Grid>

        {isMobile && (
          <Grid item xs={12}>
            <MobileBottomBar
              borderButtonLabel="Later"
              backgroundButtonLabel="Publish"
              borderButtonOnPress={() => handlePublish("unlisted")}
              isBackgroundButtonLoading={isLoading}
              isForm={false}
              backgroundButtonPress={() => handlePublish("publish")}
            />
          </Grid>
        )}
      </Grid>
    </Form>
  );
}

export default PublishExperienceScreen;
