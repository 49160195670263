import { Box, Button, FormLabel, Grid, styled } from "@mui/material";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CtaButton from "../../../../components/buttons/cta-button.component";
import { SnackbarContext } from "../../../../components/notifications/snackbar.context";
import PaddedView from "../../../../components/padded-view.component";
import SkeletonLoader from "../../../../components/skeleton.component";
import Spacer from "../../../../components/spacer.component";
import Text from "../../../../components/text.component";
import routes from "../../../../navigation/routes";
import { logout, resetUser } from "../../../../services/auth/auth-slice.services";

const CardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.white,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  flex: 1,
  maxWidth: 500,
  minWidth: 450,
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "80px",
  backgroundColor: theme.palette.colors.bg.primary,
  justifyContent: "center",
  alignItems: "center",
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  "& .MuiButton-root": {
    marginTop: "-3px",
    height: "13px",
    borderTopLeftRadius: theme.shape.borderRadius[3],
    borderTopRightRadius: theme.shape.borderRadius[3],
    borderBottomLeftRadius: theme.shape.borderRadius[3],
    borderBottomRightRadius: theme.shape.borderRadius[3],
    textTransform: "none",
    backgroundColor: "transparent",
    boxShadow: "none",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
    "&:disabled": { backgroundColor: "transparent" },
  },
}));

export default function BusinessFormLoader() {
  const createSnackBar = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(logout()).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        history.push(routes.LOGIN);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: "Logout Successful",
          type: "success",
          open: true,
        });
        dispatch(resetUser());
        history.push(routes.LOGIN);
      }
    });
  };

  return (
    <CardContainer sx={{ boxShadow: 2 }}>
      <TitleContainer>
        <Text variant="h5">Fill In Your Details</Text>
      </TitleContainer>

      <PaddedView multiples={4}>
        <FormLabel>Business Name</FormLabel>
        <Spacer size="s" />
        <SkeletonLoader height="50px" />

        <Spacer size="m" />
        <FormLabel>Business Registration Number</FormLabel>
        <Spacer size="s" />
        <SkeletonLoader height="50px" />

        <Spacer size="m" />
        <FormLabel>Company Website</FormLabel>
        <Spacer size="s" />
        <SkeletonLoader height="50px" />

        <Spacer size="m" />
        <FormLabel>Contact No</FormLabel>
        <Spacer size="s" />
        <SkeletonLoader height="50px" />

        <Spacer size="m" />
        <FormLabel>Bank Name</FormLabel>
        <Spacer size="s" />
        <SkeletonLoader height="50px" />

        <Spacer size="m" />
        <FormLabel>Account Holder Name</FormLabel>
        <Spacer size="s" />
        <SkeletonLoader height="50px" />

        <Spacer size="m" />
        <FormLabel>Account Number</FormLabel>
        <Spacer size="s" />
        <SkeletonLoader height="50px" />

        <Spacer size="xxl" />
        <CtaButton isLoading={true}>
          <Text>Confirm</Text>
        </CtaButton>

        <Spacer size="xl" />
        <Grid container sx={{ justifyContent: "space-between" }}>
          <ButtonContainer>
            <Button disableRipple onClick={handleLogout}>
              <Text type="BrandColor">Logout</Text>
            </Button>
          </ButtonContainer>
        </Grid>
      </PaddedView>
    </CardContainer>
  );
}
