import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import SkeletonLoader from "../../../components/skeleton.component";
import BorderButton from "../../../components/buttons/border-button.component";
import Text from "../../../components/text.component";

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

function BookingDecisionModalLoader({ setShowModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={2} paddingTop={2}>
      {isMobile && (
        <Grid item xs={12}>
          <SkeletonLoader width="100px" height="25px" />
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={isMobile ? 12 : 5}>
            <SkeletonLoader height={isMobile ? "75px" : "150px"} width="100%" />
          </Grid>
          <Grid item xs={isMobile ? 12 : 7}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <SkeletonLoader width="100px" height="25px" />
              <SkeletonLoader width="100px" height="25px" />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <SkeletonLoader width="100px" height="25px" />
                <SkeletonLoader width="100px" height="25px" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SeperateLine />
      </Grid>
      <Grid item xs={12}>
        {isMobile ? (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <BorderButton
              onClickButton={() => {
                setShowModal(false);
              }}
            >
              <Text type="BrandColor">Cancel</Text>
            </BorderButton>
          </Box>
        ) : (
          <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
            <SkeletonLoader width="100px" height="25px" />
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <BorderButton
                onClickButton={() => {
                  setShowModal(false);
                }}
              >
                <Text type="BrandColor">Cancel</Text>
              </BorderButton>
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

BookingDecisionModalLoader.propTypes = {
  setShowModal: PropTypes.func.isRequired,
};

export default BookingDecisionModalLoader;
