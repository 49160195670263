import { Checkbox, FormControlLabel, FormGroup, Link, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Text from "../../../../components/text.component";

function AgreementCheckbox({ isAgreeTnc, setIsAgreeTnc }) {
  const theme = useTheme();
  const handleOnChange = (event) => {
    setIsAgreeTnc(event.target.checked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={isAgreeTnc}
            onChange={handleOnChange}
            size="small"
            sx={{
              color: theme.palette.colors.brand.primary,
              "&.Mui-checked": {
                color: theme.palette.colors.brand.primary,
              },
            }}
          />
        }
        label={
          <Text variant="body2">
            {"By signing up, you acknowledge and agree with our "}
            <Link
              href="http://merchantinfo.yolo.my/terms-and-conditions/"
              target="_blank"
              sx={{
                color: theme.palette.colors.brand.primary,
                textDecorationColor: theme.palette.colors.brand.primary,
              }}
            >
              terms and condition
            </Link>
            .
          </Text>
        }
      />
    </FormGroup>
  );
}

AgreementCheckbox.propTypes = {
  isAgreeTnc: PropTypes.bool.isRequired,
  setIsAgreeTnc: PropTypes.func.isRequired,
};

export default AgreementCheckbox;
