import React, { useState } from "react";
import { Box, Drawer, IconButton, useMediaQuery, useTheme, styled, Grid } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Text from "../../../components/text.component";
import SecondaryBorderButton from "../../../components/buttons/secondary-border-button.component";
import { authSelector } from "../../../services/auth/auth-slice.services";
import YoloLogo from "../../../assets/images/yolo_logo";
import MobileDrawerAvatar from "./mobile-drawer-avatar.component";
import PaddedView from "../../../components/padded-view.component";
import routes from "../../../navigation/routes";
import Spacer from "../../../components/spacer.component";

const StyledIconButton = styled(IconButton)`
  height: 100%;
  color: ${({ theme }) => theme.palette.colors.brand.secondary};
  size: small;
`;

const AppBarContainer = styled(Box)({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  width: "100%",
  height: "100%",
  maxWidth: "1800px",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

function MobileDrawerAppBar({ handleLogout }) {
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const isTablet = useMediaQuery(theme.breakpoints.down("900") && theme.breakpoints.up("sm"));
  const { logoutObj } = useSelector(authSelector);

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <AppBarContainer>
      <YoloLogo />
      <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <StyledIconButton
          onClick={() => {
            handleDrawerToggle();
          }}
          size="large"
        >
          <MenuIcon />
        </StyledIconButton>
        <Drawer
          anchor="right"
          variant="temporary"
          open={openDrawer}
          onClose={handleDrawerToggle}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: isTablet ? "35%" : "80%",
              minWidth: "300px",
              backgroundColor: theme.palette.colors.bg.white,
            },
          }}
        >
          <MobileDrawerAvatar />

          <PaddedView multiples={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Text type="GreyColor" variant="body2">
                  Menu
                </Text>
              </Grid>
              <Grid item xs={12}>
                <Link
                  style={{ textDecoration: "none" }}
                  onClick={handleDrawerToggle}
                  to={{ pathname: routes.HOME }}
                >
                  <Text>Home</Text>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link
                  style={{ textDecoration: "none" }}
                  onClick={handleDrawerToggle}
                  to={{ pathname: routes.EXPERIENCES }}
                >
                  <Text>Experiences</Text>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link
                  style={{ textDecoration: "none" }}
                  onClick={handleDrawerToggle}
                  to={{ pathname: routes.BOOKINGS }}
                >
                  <Text>Bookings</Text>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link
                  style={{ textDecoration: "none" }}
                  onClick={handleDrawerToggle}
                  to={{ pathname: routes.CALENDAR }}
                >
                  <Text>Calendar</Text>
                </Link>
              </Grid>
            </Grid>

            <Spacer size="xl" position="top" />
            <SeperateLine />
            <Spacer size="xl" position="top" />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Text type="GreyColor" variant="body2">
                  Menu
                </Text>
              </Grid>
              <Grid item xs={12}>
                <Link
                  style={{ textDecoration: "none" }}
                  onClick={handleDrawerToggle}
                  to={{ pathname: routes.ACCOUNTMENU }}
                >
                  <Text>Account</Text>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Text>Help & Support</Text>
              </Grid>
            </Grid>

            <Spacer size="xl" position="top" />
            <SeperateLine />
            <Spacer size="xl" position="top" />

            <SecondaryBorderButton
              isLoading={logoutObj.status === "pending"}
              handleSubmit={handleLogout}
            >
              <Text>Logout</Text>
            </SecondaryBorderButton>
          </PaddedView>
        </Drawer>
      </Box>
    </AppBarContainer>
  );
}

MobileDrawerAppBar.propTypes = {
  handleLogout: PropTypes.func.isRequired,
};

export default MobileDrawerAppBar;
