import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, styled, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";
import Text from "../../../components/text.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import {
  deleteImage,
  deleteMassTimeSlot,
  editMassTimeSlot,
  createMassTimeSlot,
  experienceSelector,
  getExperiencesDetails,
  orderImage,
  uploadImage,
} from "../../../services/experience/experience-slice.services";
import { getCategories } from "../../../services/category/category-slice.services";
import Spacer from "../../../components/spacer.component";
import SkeletonLoader from "../../../components/skeleton.component";
import SideMenu from "../components/side-menu.component";
import EditPhoto from "../components/edit-photo.component";
import Loading from "../../../components/notifications/loading.component";
import EditSlots from "../components/edit-slots.component";
import AddSlotFormModal from "../components/add-slot-form-modal.component";
import Form from "../../../components/forms/form.component";
import DialogModal from "../../../components/notifications/dialog-modal.component";
import EditSlotFormModal from "../components/edit-slot-form-modal.component";
import ExperienceDetails from "../components/experience-details.component";
import TimeSlotEditSuccessModal from "../../../components/notifications/timeslot-edit-success-modal.component";
import ImageCropper from "../../../components/image-cropper.component";
import FlexiRedemptions from "../components/flexi-redemptions/flexi-redemptions.component";
import { getFlexiList } from "../../../services/booking/booking-slice.services";
import CtaButton from "../../../components/buttons/cta-button.component";

const TitleBox = styled(Box)(({ isMobile }) => ({
  width: "100%",
  display: "flex",
  flexDirection: isMobile ? "column" : "row",
  justifyContent: isMobile ? null : "space-between",
  alignItems: isMobile ? null : "center",
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const RedDot = styled(Box)(({ theme }) => ({
  height: "10px",
  width: "10px",
  borderRadius: theme.shape.borderRadius[1],
  backgroundColor: theme.palette.colors.ui.redDot,
}));

const GreenDot = styled(Box)(({ theme }) => ({
  height: "10px",
  width: "10px",
  borderRadius: theme.shape.borderRadius[1],
  backgroundColor: theme.palette.colors.ui.greenDot,
}));

const YellowDot = styled(Box)(({ theme }) => ({
  height: "10px",
  width: "10px",
  borderRadius: theme.shape.borderRadius[1],
  backgroundColor: theme.palette.colors.ui.pending,
}));

const AntTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.colors.brand.secondary,
    height: "3px",
  },
  borderBottom: `1px solid ${theme.palette.colors.ui.border}`,
  width: "100%",
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.colors.text.secondary,
  "&.Mui-selected": {
    color: theme.palette.colors.brand.secondary,
  },
}));

const addTimeslotValidationSchema = Yup.object().shape({
  startDate: Yup.date().label("Start Date").nullable().when("bookingType", {
    is: "multiple",
    then: Yup.date().required(),
  }),
  startTime: Yup.date().label("Check-in Time").nullable().when("bookingType", {
    is: "multiple",
    then: Yup.date().required(),
  }),
  endDate: Yup.date()
    .label("End Date")
    .nullable()
    .when("bookingType", {
      is: "multiple",
      then: Yup.date().min(Yup.ref("startDate"), "End date can't be before start date").required(),
    }),
  endTime: Yup.date().label("Check-out Time").nullable().when("bookingType", {
    is: "multiple",
    then: Yup.date().required(),
  }),
  experienceId: Yup.string().required().label("Experience Id"),
  bookingType: Yup.string().required(),
  capacity: Yup.number()
    .nullable()
    .label("Capacity")
    .when("bookingType", {
      is: "multiple",
      then: Yup.number().min(1).max(300).required(),
    })
    .when("bookingType", {
      is: "flexi",
      then: Yup.number().min(1).max(300).required(),
    }),
  flexiTitle: Yup.string()
    .label("Title")
    .when("bookingType", {
      is: "flexi",
      then: Yup.string().min(2).required(),
    }),
});

const editTimeslotValidationSchema = Yup.object().shape({
  startDate: Yup.date().required().label("Start Date").nullable(),
  startTime: Yup.date().required().label("Check-in Time"),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "End date can't be before start date")
    .required()
    .label("End Date")
    .nullable(),
  endTime: Yup.date().required().label("Check-out Time"),
  bookingType: Yup.string().required(),
  id: Yup.string(),
  capacity: Yup.number()
    .nullable()
    .label("Capacity")
    .when("bookingType", {
      is: "multiple",
      then: Yup.number().min(1).max(300).required(),
    }),
  bookedCapacity: Yup.number(),
});

const editFlexiSlotValidationSchema = Yup.object().shape({
  bookingType: Yup.string().required(),
  id: Yup.string(),
  capacity: Yup.number().label("Capacity").required(),
  flexiTitle: Yup.string().label("Title").required(),
  bookedCapacity: Yup.number(),
});

function EditExperienceScreen() {
  const location = useLocation();
  const experienceId = new URLSearchParams(location.search).get("experienceId");
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("1030"));
  const { getExperiencesDetailsObj, deleteMassTimeSlotObj } = useSelector(experienceSelector);
  const [currentTab, setCurrentTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [showAddSlotModal, setShowAddSlotModal] = useState(false);
  const [cancelTimeSlotId, setCancelTimeSlotId] = useState(null);
  const [showCancelSlotConfirmation, setShowCancelSlotConfirmation] = useState(false);
  const [showEditSlotModal, setShowEditSlotModal] = useState(false);
  const [showEditCapacityModal, setShowEditCapacityModal] = useState(false);
  const [showBookedModal, setShowBookedModal] = useState(false);
  const [showUnableDeleteModal, setShowUnableDeleteModal] = useState(false);
  const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);

  const [showImageCropper, setShowImageCropper] = useState(false);
  const [file, setFile] = useState(null);

  const refreshFlexiList = () => {
    dispatch(getFlexiList({ experienceId, type: "ongoing", page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleUploadImage = (values) => {
    setIsLoading(true);
    dispatch(uploadImage({ experienceId, ...values })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowImageCropper(false);
        setFile(null);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleDeleteImage = (values) => {
    if (getExperiencesDetailsObj.data.images.length === 1) {
      createSnackBar({
        message: "Must have at least one image.",
        type: "error",
        open: true,
      });
      return;
    }
    setIsLoading(true);
    dispatch(deleteImage({ experienceId, ...values })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleOrderImage = (values) => {
    setIsLoading(true);
    dispatch(orderImage({ experienceId, ...values })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCategories()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
    dispatch(getExperiencesDetails(experienceId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const redirectToExperience = () => {
    window.open(
      `${process.env.REACT_APP_USER}/experiences/details?experienceId=${experienceId}`,
      "blank",
    );
  };

  const getStatus = () => {
    if (getExperiencesDetailsObj.data.status === "unlisted") {
      return (
        <CenterRowBox>
          <RedDot />
          <Spacer size="s" position="left" />
          <Text>Unlisted</Text>
        </CenterRowBox>
      );
    }
    if (getExperiencesDetailsObj.data.status === "pending") {
      return (
        <CenterRowBox>
          <YellowDot />
          <Spacer size="s" position="left" />
          <Text>Pending</Text>
        </CenterRowBox>
      );
    }
    return (
      <CenterRowBox>
        <GreenDot />
        <Spacer size="s" position="left" />
        <Text>Published</Text>
        {!isMobile && (
          <>
            <Spacer size="m" position="left" />
            <CtaButton width="150px" onClickButton={redirectToExperience}>
              View Experience
            </CtaButton>
          </>
        )}
      </CenterRowBox>
    );
  };

  const renderTitleAndStatus = () => {
    if (getExperiencesDetailsObj.status === "succeeded") {
      return (
        <Grid item xs={12}>
          <TitleBox isMobile={isMobile}>
            <Text
              sx={{ fontWeight: theme.fonts.fontWeights.bold, overflowWrap: "anywhere" }}
              variant="h4"
            >
              {getExperiencesDetailsObj.data.experience.title}
            </Text>
            <Spacer size="s" position="top" />
            {getStatus()}

            {isMobile && getExperiencesDetailsObj.data.status === "publish" && (
              <>
                <Spacer size="s" position="top" />
                <CtaButton width="150px" onClickButton={redirectToExperience}>
                  View Experience
                </CtaButton>
              </>
            )}
          </TitleBox>
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        <TitleBox isMobile={isMobile}>
          <SkeletonLoader width="250px" height="45px" />
          <Spacer size="s" position="top" />
          <SkeletonLoader width="100px" height="25px" />
        </TitleBox>
      </Grid>
    );
  };

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleCreateTimeSlot = (values, { resetForm }) => {
    let startAt = "";
    let endAt = "";
    if (values.bookingType !== "flexi") {
      startAt = `${values.startDate} ${format(new Date(values.startTime), "HH:mm:ss")}`;
      endAt = `${values.endDate} ${format(new Date(values.endTime), "HH:mm:ss")}`;
    }

    setIsLoading(true);
    dispatch(createMassTimeSlot({ startAt, endAt, ...values })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowAddSlotModal(false);
        resetForm();
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleUpdateTimeSlot = (values) => {
    let startAt = format(
      new Date(new Date().setDate(new Date().getDate() + 1)),
      "yyyy-MM-dd HH:mm:ss",
    );
    let endAt = format(
      new Date(new Date().setDate(new Date().getDate() + 3)),
      "yyyy-MM-dd HH:mm:ss",
    );
    if (values.bookingType !== "flexi") {
      startAt = `${format(new Date(values.startDate), "yyyy-MM-dd")} ${format(
        new Date(values.startTime),
        "HH:mm:ss",
      )}`;
      endAt = `${format(new Date(values.endDate), "yyyy-MM-dd")} ${format(
        new Date(values.endTime),
        "HH:mm:ss",
      )}`;
    }
    setIsLoading(true);
    dispatch(editMassTimeSlot({ experienceId, startAt, endAt, ...values })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setShowEditSlotModal(false);
          if (values.bookingType === "multiple") {
            setShowEditSuccessModal(true);
          } else {
            refreshFlexiList();
          }
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        } else if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const handleCancelSlot = (slot) => {
    setCancelTimeSlotId(slot);
    setShowCancelSlotConfirmation(true);
  };

  const handleConfirmCancelSlot = () => {
    setIsLoading(true);
    dispatch(deleteMassTimeSlot({ experienceId, id: cancelTimeSlotId.id })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setShowCancelSlotConfirmation(false);
          refreshFlexiList();
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        } else if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  return (
    <Box sx={{ padding: theme.padding.paddingX[0], width: "100%" }}>
      <ImageCropper
        setIsShowModal={setShowImageCropper}
        isShowModal={showImageCropper}
        handleUploadImage={handleUploadImage}
        file={file}
      />
      <DialogModal
        showModal={showCancelSlotConfirmation}
        setShowModal={setShowCancelSlotConfirmation}
        title="Cancel Timeslot"
        message="Are you sure you want to cancel this timeslot?"
        onConfirm={handleConfirmCancelSlot}
        buttonText="Confirm"
        disabled={deleteMassTimeSlotObj.status === "pending"}
      />
      <DialogModal
        showModal={showUnableDeleteModal}
        setShowModal={setShowUnableDeleteModal}
        title="Cancel Timeslot"
        message="This timeslot has been booked, you can't cancel it."
        onConfirm={() => setShowUnableDeleteModal(false)}
        buttonText="Ok"
      />
      <TimeSlotEditSuccessModal
        showModal={showEditSuccessModal}
        setShowModal={setShowEditSuccessModal}
        onConfirm={() => setShowEditSuccessModal(false)}
      />
      <DialogModal
        showModal={showEditCapacityModal}
        setShowModal={setShowEditCapacityModal}
        title="Edit Capacity"
        message="You can just edit the capacity to either equal or more than the current number of booked."
        onConfirm={() => setShowEditCapacityModal(false)}
        buttonText="Ok"
      />
      <DialogModal
        showModal={showBookedModal}
        setShowModal={setShowBookedModal}
        title="Edit Timeslot"
        message="This timeslot has been booked, if you want to edit please contact the user(s), do you want to continue?"
        onConfirm={() => {
          setShowEditSlotModal(true);
          setShowBookedModal(false);
        }}
        buttonText="Confirm"
      />
      <Form
        validationSchema={addTimeslotValidationSchema}
        initialValues={{
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          experienceId,
          bookingType: "multiple",
          capacity: "",
          flexiTitle: "",
        }}
        onSubmit={handleCreateTimeSlot}
      >
        <AddSlotFormModal
          isShowModal={showAddSlotModal}
          setIsShowModal={setShowAddSlotModal}
          fromCalendarScreen={false}
        />
      </Form>
      <Loading isLoading={isLoading} />
      <Grid container spacing={isMobile ? 2 : 5}>
        {renderTitleAndStatus()}
        <Grid item xs={12}>
          <Grid container spacing={5} justifyContent={isMobile ? null : "space-between"}>
            {isMobile ? (
              <Grid item xs={12}>
                <AntTabs
                  variant={isMobile ? "fullWidth" : "scrollable"}
                  value={currentTab}
                  onChange={handleChangeTab}
                >
                  <AntTab label={<Typography>Photo</Typography>} />
                  <AntTab label={<Typography>Experience Details</Typography>} />

                  <AntTab label={<Typography>Availability</Typography>} />
                  <AntTab label={<Typography>Flexi Redemption</Typography>} />
                </AntTabs>
              </Grid>
            ) : (
              <Grid item sx={{ width: "25%" }}>
                <SideMenu setCurrentTab={setCurrentTab} currentTab={currentTab} />
              </Grid>
            )}

            <Grid item width={isMobile ? "100%" : "70%"}>
              {currentTab === 0 && (
                <EditPhoto
                  handleDeleteImage={handleDeleteImage}
                  handleOrderImage={handleOrderImage}
                  setFile={setFile}
                  setShowImageCropper={setShowImageCropper}
                />
              )}
              {currentTab === 1 && <ExperienceDetails experienceId={experienceId} />}
              {currentTab === 2 && (
                <Form
                  validationSchema={editTimeslotValidationSchema}
                  initialValues={{
                    startDate: "",
                    startTime: "",
                    endDate: "",
                    endTime: "",
                    id: "",
                    bookingType: "multiple",
                    capacity: "",
                    bookedCapacity: "",
                  }}
                  onSubmit={handleUpdateTimeSlot}
                >
                  <EditSlotFormModal
                    setShowEditCapacityModal={setShowEditCapacityModal}
                    isShowModal={showEditSlotModal}
                    setIsShowModal={setShowEditSlotModal}
                  />

                  <EditSlots
                    setShowAddSlotModal={setShowAddSlotModal}
                    setShowEditSlotModal={setShowEditSlotModal}
                    handleCancelSlot={handleCancelSlot}
                    setShowBookedModal={setShowBookedModal}
                    setShowUnableDeleteModal={setShowUnableDeleteModal}
                  />
                </Form>
              )}
              {currentTab === 3 && (
                <Form
                  validationSchema={editFlexiSlotValidationSchema}
                  initialValues={{
                    experienceId,
                    bookingType: "flexi",
                    capacity: "",
                    flexiTitle: "",
                  }}
                  onSubmit={handleUpdateTimeSlot}
                >
                  <EditSlotFormModal
                    setShowEditCapacityModal={setShowEditCapacityModal}
                    isShowModal={showEditSlotModal}
                    setIsShowModal={setShowEditSlotModal}
                  />
                  <FlexiRedemptions
                    experienceId={experienceId}
                    setIsLoading={setIsLoading}
                    handleCancelSlot={handleCancelSlot}
                    setShowBookedModal={setShowBookedModal}
                    setShowUnableDeleteModal={setShowUnableDeleteModal}
                    setShowEditSlotModal={setShowEditSlotModal}
                  />
                </Form>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EditExperienceScreen;
