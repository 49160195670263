import React from "react";
import PropTypes from "prop-types";
import { TextField, styled, useMediaQuery, InputAdornment, IconButton, Box } from "@mui/material";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { format } from "date-fns";
import EventIcon from "@mui/icons-material/Event";

const DateField = styled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    backgroundColor: theme.palette.colors.bg.white,
  },
}));
function FormDatePicker({ name, width, placeholder, isNeedMinDate, disabled }) {
  const { setFieldValue, values, touched, errors, setFieldTouched } = useFormikContext();
  const minDate = new Date().setDate(new Date().getDate() + 1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const showError = (touched[name] && typeof errors[name] === "string") || false;

  const handleChange = (date) => {
    setFieldValue(name, format(date, "yyyy-MM-dd"));
  };

  if (disabled) {
    return (
      <Box
        sx={{
          "& .MuiTextField-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            width,
            "& .MuiOutlinedInput-root": {
              borderTopLeftRadius: theme.shape.borderRadius[0],
              borderTopRightRadius: theme.shape.borderRadius[0],
              borderBottomLeftRadius: theme.shape.borderRadius[0],
              borderBottomRightRadius: theme.shape.borderRadius[0],
              backgroundColor: "transparent",
            },
          },
        }}
      >
        <TextField
          disabled={true}
          error={showError}
          variant="outlined"
          placeholder={placeholder}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  disabled={true}
                  sx={{
                    padding: "0px",
                    margin: "0px -12px 0px 0px",
                    color: theme.palette.colors.ui.border,
                  }}
                >
                  <EventIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {isMobile ? (
        <MobileDatePicker
          disabled={disabled}
          minDate={isNeedMinDate ? minDate : null}
          disableHighlightToday={true}
          label="Date"
          inputFormat="dd/MM/yyyy"
          value={values[name]}
          onChange={handleChange}
          InputProps={{ error: showError }}
          renderInput={(params) => (
            <DateField
              InputLabelProps={{ error: showError }}
              onBlur={() => setFieldTouched(name)}
              {...params}
            />
          )}
        />
      ) : (
        <DesktopDatePicker
          disabled={disabled}
          label=""
          minDate={isNeedMinDate ? minDate : null}
          disableHighlightToday={true}
          inputFormat="dd/MM/yyyy"
          value={values[name]}
          onChange={handleChange}
          InputLabelProps={{
            error: true,
          }}
          InputProps={{ placeholder, error: showError }}
          renderInput={(params) => (
            <DateField
              onBlur={() => setFieldTouched(name)}
              helperText={showError ? errors[name] : null}
              FormHelperTextProps={{ error: showError }}
              InputLabelProps={{ error: showError }}
              inputProps={{ readOnly: true }}
              placeholder={placeholder}
              sx={{ width }}
              {...params}
            />
          )}
        />
      )}
    </LocalizationProvider>
  );
}

FormDatePicker.defaultProps = {
  width: "100%",
  placeholder: "dd/mm/yyyy",
  isNeedMinDate: true,
  disabled: false,
};

FormDatePicker.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  isNeedMinDate: PropTypes.bool,
};

export default FormDatePicker;
