import React from "react";
import { Box, Grid, IconButton, styled } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import Text from "../../../components/text.component";
import SkeletonLoader from "../../../components/skeleton.component";
import Spacer from "../../../components/spacer.component";

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "0.5px",
  backgroundColor: theme.palette.colors.ui.border,
}));

const StatusTimeContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.secondary,
  padding: theme.padding.paddingX[2],
  borderRadius: theme.shape.borderRadius[0],
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

function BookingDetailsModalLoader({ handleCancel }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Box />
          <Text variant="h5" textAlign="center">
            Booking Details
          </Text>
          <CloseIconButton onClick={handleCancel}>
            <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
          </CloseIconButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SeperateLine />
      </Grid>
      <Grid item xs={12}>
        <StatusTimeContainer>
          <SkeletonLoader width="200px" height="30px" />
          <SkeletonLoader width="100px" height="25px" />
        </StatusTimeContainer>
      </Grid>
      <Grid item xs={12}>
        <SpaceBetweenRowBox>
          <Text>Experience</Text>
          <SkeletonLoader width="100px" height="25px" />
        </SpaceBetweenRowBox>
        <Spacer position="top" size="s" />
        <SpaceBetweenRowBox>
          <Text>Guest</Text>
          <SkeletonLoader width="100px" height="25px" />
        </SpaceBetweenRowBox>
        <Spacer position="top" size="s" />
        <Spacer position="top" size="s" />
        <SpaceBetweenRowBox>
          <Text>Price</Text>
          <SkeletonLoader width="100px" height="25px" />
        </SpaceBetweenRowBox>
      </Grid>
      <Grid item xs={12}>
        <SeperateLine />
      </Grid>
      <Grid item xs={12}>
        <SkeletonLoader height="25px" />
      </Grid>
    </Grid>
  );
}

BookingDetailsModalLoader.propTypes = {
  handleCancel: PropTypes.func.isRequired,
};
export default BookingDetailsModalLoader;
