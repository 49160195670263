import React from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Text from "../text.component";
import Spacer from "../spacer.component";

const StyledButtonBox = styled(Box)(({ theme }) => ({
  "& .MuiButton-root": {
    width: "100%",
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    textTransform: "none",
    backgroundColor: theme.palette.colors.brand.primary,
    "&:hover": {
      backgroundColor: theme.palette.colors.brand.primary,
    },
    "&:disabled": {
      backgroundColor: theme.palette.colors.ui.disabled,
    },
  },
}));

const ModalBox = styled(Box)(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  padding: isMobile ? theme.padding.paddingX[1] : theme.padding.paddingX[0],
  width: isMobile ? "350px" : "450px",
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

const StyledGreenTick = styled(CheckCircleIcon)(({ theme }) => ({
  fontSize: "100px",
  textAlign: "center",
  color: theme.palette.colors.button.switch,
}));

export default function TimeSlotEditSuccessModal({ showModal, setShowModal, onConfirm }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox isMobile={isMobile}>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <CloseIconButton onClick={handleCancel}>
                <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
              </CloseIconButton>
            </Grid>
            <Text variant="h5" textAlign="center">
              Edit Timeslot
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <StyledGreenTick />
            </Box>
            <Spacer size="m" position="top" />
            <Text sx={{ textAlign: "center" }}>
              The timeslot updated! Emails has been sent out to the user(s). Please do also check
              with them for further confirmation.
            </Text>
          </Grid>
          <Grid item xs={12}>
            <StyledButtonBox>
              <Button variant="contain" onClick={handleConfirm}>
                <Text type="WhiteColor" variant="h6">
                  Ok
                </Text>
              </Button>
            </StyledButtonBox>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

TimeSlotEditSuccessModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
