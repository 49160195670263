import React, { useState } from "react";
import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { format } from "date-fns";
import PropTypes from "prop-types";
import Text from "../../../components/text.component";
import FormFieldDropdown from "../../../components/forms/form-field-dropdown.component";
import { bookingSelector } from "../../../services/booking/booking-slice.services";
import Form from "../../../components/forms/form.component";
import Spacer from "../../../components/spacer.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import EmailFieldModal from "./email-field-modal.component";
import { profileSelector } from "../../../services/profile/profile-slice.services";

const validationSchema = Yup.object().shape({
  startDate: Yup.date().required().label("Start Date").nullable(),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "End date can't be before start date")
    .required()
    .label("End Date")
    .nullable(),
  experienceId: Yup.string().label("Experience"),
});

const emailValidation = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});

const SpacebetweenCenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

const CenterColumnBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

function EarningStatement({
  showEmailFieldModal,
  setShowEmailFieldModal,
  handleSendStatementEmail,
}) {
  const theme = useTheme();
  const { getExperiencesListObj } = useSelector(bookingSelector);
  const { getProfileObj } = useSelector(profileSelector);
  const isMobile = useMediaQuery(theme.breakpoints.down("850"));
  const [emailDetails, setEmailDetails] = useState({});

  const onConfirmForm = (values) => {
    setEmailDetails(values);
    setShowEmailFieldModal(true);
  };

  const onConfirmSendEmail = (values) => {
    const newValues = {
      startDate: format(new Date(emailDetails.startDate), "yyyy-MM-dd"),
      endDate: format(new Date(emailDetails.endDate), "yyyy-MM-dd"),
      experienceId: emailDetails.experienceId,
      ...values,
    };
    handleSendStatementEmail(newValues);
  };

  const renderContent = () => {
    const processedData = [{ label: "All Experiences", value: "" }];
    getExperiencesListObj.data.map(
      (item) =>
        item.title !== null && processedData.push({ label: item.title, value: item.id.toString() }),
    );
    return (
      <Form
        initialValues={{ experienceId: "", startDate: new Date(), endDate: new Date() }}
        validationSchema={validationSchema}
        onSubmit={onConfirmForm}
      >
        <Grid item xs={12}>
          <Text>Select Experiences:</Text>
          <Spacer size="m" position="top" />
          <FormFieldDropdown
            itemList={processedData}
            name="experienceId"
            placeholder="Experience"
          />
        </Grid>
        <Grid item xs={12}>
          <Text>Select a summary period:</Text>
          <Spacer size="m" position="top" />
          {isMobile ? (
            <CenterColumnBox>
              <FormDatePicker name="startDate" isNeedMinDate={false} />
              <Spacer size="s" position="top" />
              <Text>to</Text>
              <Spacer size="s" position="top" />
              <FormDatePicker name="endDate" isNeedMinDate={false} />
            </CenterColumnBox>
          ) : (
            <SpacebetweenCenterRowBox>
              <FormDatePicker name="startDate" isNeedMinDate={false} width="45%" />
              <Text>to</Text>
              <FormDatePicker name="endDate" isNeedMinDate={false} width="45%" />
            </SpacebetweenCenterRowBox>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
            <FormSubmitButton>
              <Text type="WhiteColor" sx={{ paddingX: theme.padding.paddingX[3] }}>
                Send Email
              </Text>
            </FormSubmitButton>
          </Box>
        </Grid>
      </Form>
    );
  };

  return (
    <Grid container spacing={5}>
      {renderContent()}
      <Form
        initialValues={{
          email: getProfileObj.status === "succeeded" ? getProfileObj.data.merchant.email : "",
        }}
        validationSchema={emailValidation}
        onSubmit={onConfirmSendEmail}
      >
        <EmailFieldModal
          showModal={showEmailFieldModal}
          setShowModal={setShowEmailFieldModal}
          name="email"
        />
      </Form>
    </Grid>
  );
}

EarningStatement.propTypes = {
  showEmailFieldModal: PropTypes.bool.isRequired,
  setShowEmailFieldModal: PropTypes.func.isRequired,
  handleSendStatementEmail: PropTypes.func.isRequired,
};

export default EarningStatement;
