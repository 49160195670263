import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Avatar, Icon } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/webp"];

function ProfileImageUpload({ imagePath, handleUploadImage }) {
  const theme = useTheme();
  const [filePreview, setFilePreview] = useState(imagePath);
  const createSnackBar = useContext(SnackbarContext);

  return (
    <Box sx={{ width: "75px" }}>
      <Button
        disableRipple
        component="label"
        sx={{
          padding: 0,
          backgroundColor: "transparent",
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          "&:hover": {
            background: "none",
          },
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Avatar
            sx={{
              width: 75,
              height: 75,
            }}
            src={filePreview}
          />
          <Icon
            size="large"
            sx={{
              position: "absolute",
              left: "50px",
              top: "50px",
              height: 30,
              color: theme.palette.colors.brand.secondary,
            }}
          >
            <AddCircleIcon sx={{ height: "100%", width: 20 }} />
          </Icon>
        </Box>
        <input
          type="file"
          hidden
          accept="image/*"
          onChange={(e) => {
            const { files } = e.target;
            if (files.length !== 0) {
              const myFile = files[0]; // single file upload only
              const withinFileSizeLimit = myFile && myFile.size <= 5000000; // 1mb = 1^6 bytes
              const allowedFileType = myFile && SUPPORTED_FORMATS.includes(myFile.type);
              if (myFile && withinFileSizeLimit && allowedFileType) {
                const fileReader = new FileReader(); // to preview the image
                fileReader.onload = () => {
                  if (fileReader.readyState === 2) {
                    setFilePreview(fileReader.result);
                    const img = new Image();
                    img.src = URL.createObjectURL(myFile);
                    img.onload = () => {
                      const canvas = document.createElement("canvas");
                      canvas.width = img.width;
                      canvas.height = img.height;
                      const ctx = canvas.getContext("2d");
                      ctx.drawImage(img, 0, 0);
                      const dataURL = canvas.toDataURL("image/webp", 0.5);
                      handleUploadImage({ image: dataURL });
                    };
                  }
                };
                fileReader.readAsDataURL(myFile);
              } else if (!withinFileSizeLimit) {
                createSnackBar({
                  message: "Image file cannot be more than 5mb",
                  type: "error",
                  open: true,
                });
              } else if (!allowedFileType) {
                createSnackBar({
                  message: `Only ${SUPPORTED_FORMATS} are allowed`,
                  type: "error",
                  open: true,
                });
              }
            }
          }}
        />
      </Button>
    </Box>
  );
}

ProfileImageUpload.defaultProps = {
  imagePath: "",
};

ProfileImageUpload.propTypes = {
  imagePath: PropTypes.string,
  handleUploadImage: PropTypes.func.isRequired,
};

export default ProfileImageUpload;
