import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import PropTypes from "prop-types";
import { Box, FormControl, MenuItem, Select, styled, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import Text from "../../../components/text.component";
import { bookingSelector } from "../../../services/booking/booking-slice.services";
import Spacer from "../../../components/spacer.component";

const CalendarContainer = styled(Box)(({ theme }) => ({
  paddingLeft: theme.padding.paddingX[0],
  paddingRight: theme.padding.paddingX[0],
  paddingTop: theme.padding.paddingX[0],
  paddingBottom: theme.padding.paddingX[0],
  width: "100%",
  ".fc-button-primary": {
    border: `1px solid ${theme.palette.colors.brand.primary}`,
    backgroundColor: theme.palette.colors.brand.primary,
    boxShadow: "none",
    ":hover, :active, :disabled, :focus": {
      backgroundColor: theme.palette.colors.brand.primary,
      border: `1px solid ${theme.palette.colors.brand.primary}`,
      boxShadow: "none",
      ":focus": {
        backgroundColor: theme.palette.colors.brand.primary,
        border: `1px solid ${theme.palette.colors.brand.primary}`,
        boxShadow: "none",
      },
    },
  },
  ".fc-highlight": {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.colors.brand.secondary}`,
  },
  ".fc-day-past": {
    opacity: 0.3,
  },
  "& .MuiOutlinedInput-root": {
    " &.Mui-focused fieldset": {
      borderColor: theme.palette.colors.brand.secondary,
    },
  },
  "& .MuiSelect-iconOutlined": {
    color: theme.palette.colors.brand.secondary,
  },
}));

function Calendar({
  setSelectedDate,
  handleMonthChange,
  calendarEvent,
  onChangeExperiences,
  currentExperience,
}) {
  const theme = useTheme();
  const { setFieldValue } = useFormikContext();
  const { getExperiencesListObj } = useSelector(bookingSelector);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const renderExperiencesButton = () => (
    <FormControl sx={{ maxWidth: "350px" }}>
      <Select
        variant="outlined"
        value={currentExperience}
        style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "pre" }}
        sx={{
          minWidth: "150px",

          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          height: "40px",
          backgroundColor: "transparent",
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              marginTop: "5px",
              maxWidth: "350px",
            },
          },
        }}
        defaultValue={currentExperience}
        onChange={(event) => {
          setFieldValue("experienceId", event.target.value);
          onChangeExperiences(event);
        }}
      >
        <MenuItem value="all">
          <Text>All</Text>
        </MenuItem>
        {getExperiencesListObj.data.map(
          (item) =>
            item.title !== null && (
              <MenuItem value={item.id} key={item.id}>
                <Text noWrap>{item.title}</Text>
              </MenuItem>
            ),
        )}
      </Select>
    </FormControl>
  );

  return (
    <CalendarContainer>
      {getExperiencesListObj.status === "succeeded" && renderExperiencesButton()}
      <Spacer size="l" position="top" />
      <FullCalendar
        eventClick={(i) => setSelectedDate(i.event.startStr)}
        headerToolbar={{ left: "title", center: "", right: "prev,next" }}
        datesSet={handleMonthChange}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={calendarEvent}
        eventBackgroundColor="transparent"
        eventTextColor={theme.palette.colors.brand.primary}
        eventBorderColor="transparent"
        dateClick={(i) => {
          setFieldValue("startDate", new Date(i.dateStr));
          setSelectedDate(i.dateStr);
        }}
        selectable={true}
        validRange={{
          start: "2022-04-01",
        }}
        height={isMobile ? "550px" : "650px"}
      />
    </CalendarContainer>
  );
}

Calendar.defaultProps = {
  calendarEvent: [],
};

Calendar.propTypes = {
  setSelectedDate: PropTypes.func.isRequired,
  handleMonthChange: PropTypes.func.isRequired,
  calendarEvent: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })),
  onChangeExperiences: PropTypes.func.isRequired,
  currentExperience: PropTypes.string.isRequired,
};

export default Calendar;
