import React from "react";
import { Box, Grid, IconButton, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormLabel from "../../../../components/forms/form-label.component";
import Spacer from "../../../../components/spacer.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Text from "../../../../components/text.component";
import { bookingSelector } from "../../../../services/booking/booking-slice.services";
import SkeletonLoader from "../../../../components/skeleton.component";
import CtaButton from "../../../../components/buttons/cta-button.component";

const ModalBox = styled(Box)(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  paddingTop: "25px",
  paddingBottom: "25px",
  paddingLeft: "50px",
  paddingRight: "50px",
  width: isMobile ? "350px" : "450px",
  outline: "none",
  height: "80%",
  overflow: "auto",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: theme.palette.colors.ui.border,
}));

const VerticalSpaceBetween = styled(Box)({
  flexDirection: "column",
  justifyContent: "space-between",
  display: "flex",
  height: "100%",
});

const CenterRowBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
});

const StyledGreenTick = styled(CheckCircleIcon)(({ theme }) => ({
  fontSize: "100px",
  textAlign: "center",
  color: theme.palette.colors.button.switch,
}));

const CenterViewBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export default function FlexiRedemptionsCodeInsertModal({
  isShowModal,
  setIsShowModal,
  redemptionStep,
  setRedemptionStep,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { flexiCodeRedemptionsObj } = useSelector(bookingSelector);
  const { resetForm } = useFormikContext();

  const handleCancel = () => {
    resetForm();
    setRedemptionStep(1);
    setIsShowModal(false);
  };

  const renderContent = () => {
    if (redemptionStep === 3) {
      return (
        <VerticalSpaceBetween>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Box width="25px" />
                <Text variant="h5">Verify Code</Text>
                <CloseIconButton onClick={handleCancel}>
                  <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
                </CloseIconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SeperateLine />
            </Grid>
          </Grid>

          <CenterViewBox>
            <StyledGreenTick />
            <Spacer size="xl" position="top" />
            <Text sx={{ textAlign: "center" }}>
              Code verified successfully! This booking was completed.
            </Text>
          </CenterViewBox>

          <CtaButton onClickButton={handleCancel}>
            <Text type="WhiteColor">Ok</Text>
          </CtaButton>
        </VerticalSpaceBetween>
      );
    }

    if (redemptionStep === 2) {
      if (flexiCodeRedemptionsObj.status === "succeeded" && flexiCodeRedemptionsObj.data) {
        return (
          <VerticalSpaceBetween>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Box width="25px" />
                  <Text variant="h5">User Details</Text>
                  <CloseIconButton onClick={handleCancel}>
                    <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
                  </CloseIconButton>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <SeperateLine />
              </Grid>

              <Grid item xs={12}>
                <CenterRowBox>
                  <Text type="GreyColor">Guest:</Text>
                  <Spacer size="xs" position="left" />
                  <Text>{flexiCodeRedemptionsObj.data.guest.name}</Text>
                </CenterRowBox>
                <Spacer size="xs" position="top" />
                <CenterRowBox>
                  <Text type="GreyColor">Email:</Text>
                  <Spacer size="xs" position="left" />
                  <Text>{flexiCodeRedemptionsObj.data.guest.email}</Text>
                </CenterRowBox>
                {flexiCodeRedemptionsObj.data.guest.phoneNumber && (
                  <>
                    <Spacer size="xs" position="top" />
                    <CenterRowBox>
                      <Text type="GreyColor">Phone Number:</Text>
                      <Spacer size="xs" position="left" />
                      <Text>{flexiCodeRedemptionsObj.data.guest.phoneNumber}</Text>
                    </CenterRowBox>
                  </>
                )}
                <Spacer size="xs" position="top" />
                <CenterRowBox>
                  <Text type="GreyColor">Price:</Text>
                  <Spacer size="xs" position="left" />
                  <Text>{`RM${flexiCodeRedemptionsObj.data.booking.price}`}</Text>
                </CenterRowBox>
                <Spacer size="xs" position="top" />
                <Text type="GreyColor">Message:</Text>
                <Text>
                  {flexiCodeRedemptionsObj.data.booking.message
                    ? flexiCodeRedemptionsObj.data.booking.message
                    : "No Message"}
                </Text>
              </Grid>
            </Grid>

            <FormSubmitButton isLoading={flexiCodeRedemptionsObj.status === "pending"}>
              <Text type="WhiteColor">Verify</Text>
            </FormSubmitButton>
          </VerticalSpaceBetween>
        );
      }
      return (
        <VerticalSpaceBetween>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Box width="25px" />
                <Text variant="h5">User Details</Text>
                <CloseIconButton onClick={handleCancel}>
                  <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
                </CloseIconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SeperateLine />
            </Grid>

            <Grid item xs={12}>
              <SkeletonLoader width="100%" height="35px" />
              <Spacer size="xs" position="top" />
              <SkeletonLoader width="100%" height="35px" />
              <Spacer size="xs" position="top" />
              <SkeletonLoader width="100%" height="35px" />
              <Spacer size="xs" position="top" />
              <SkeletonLoader width="100%" height="35px" />
              <Spacer size="xs" position="top" />
              <SkeletonLoader width="100%" height="35px" />
              <Spacer size="xs" position="top" />
              <SkeletonLoader width="100%" height="35px" />
            </Grid>
          </Grid>

          <FormSubmitButton isLoading={true}>
            <Text type="WhiteColor">Next</Text>
          </FormSubmitButton>
        </VerticalSpaceBetween>
      );
    }

    return (
      <VerticalSpaceBetween>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Box width="25px" />
              <Text variant="h5">Insert Code</Text>
              <CloseIconButton onClick={handleCancel}>
                <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
              </CloseIconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SeperateLine />
          </Grid>

          <Grid item xs={12}>
            <FormLabel>Redemption Booking</FormLabel>
            <Spacer size="s" position="top" />
            <FormFieldText name="flexiCode" placeholder="Insert code here" isTransparent={true} />
          </Grid>
        </Grid>

        <FormSubmitButton isLoading={flexiCodeRedemptionsObj.status === "pending"}>
          <Text type="WhiteColor">Next</Text>
        </FormSubmitButton>
      </VerticalSpaceBetween>
    );
  };

  return (
    <Modal open={isShowModal} onClose={handleCancel}>
      <ModalBox isMobile={isMobile}>{renderContent()}</ModalBox>
    </Modal>
  );
}

FlexiRedemptionsCodeInsertModal.defaultProps = {
  redemptionStep: 1,
};

FlexiRedemptionsCodeInsertModal.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  setIsShowModal: PropTypes.func.isRequired,
  redemptionStep: PropTypes.number,
  setRedemptionStep: PropTypes.func.isRequired,
};
