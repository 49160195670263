import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import PublicRoute from "./public.routes";
import RestrictedRoute from "./restricted.routes";
import PrivateRoute from "./private.routes";
import HomeScreen from "../features/home/screen/home.screen";
import ProfileScreen from "../features/profile/screen/profile.screen";
import LoginScreen from "../features/auth/login/screen/login.screen";
import SignUpScreen from "../features/auth/sign-up/screen/sign-up.screen";
import EmailVerificationScreen from "../features/auth/email-verification/screen/email-verification.screen";
import ForgotPasswordScreen from "../features/auth/reset-password/screen/forgot-password.screen";
import ResetPasswordScreen from "../features/auth/reset-password/screen/reset-password.screen";
import BusinessDetailScreen from "../features/auth/sign-up/screen/business-detail.screen";
import { isLogin } from "../infrastructure/utils";
import AppBarLayout from "../infrastructure/layout/appbar.layout";
import AccountMenuScreen from "../features/profile/screen/account-menu.screen";
import CreateExperienceScreen from "../features/create-experiences/create-experiences/screens/create-experiences.screen";
import SelectCategoryScreen from "../features/create-experiences/select-category/screens/select-category.screen";
import SelectLocationScreen from "../features/create-experiences/select-location/screens/select-location.screen";
import ExperienceTitleField from "../features/create-experiences/experience-title-field/screen/experience-title-field.screen";
import UploadImageScreen from "../features/create-experiences/upload-images/screens/upload-images.screen";
import ImportantInfoFieldSreen from "../features/create-experiences/important-info-field/screen/important-info-field.screen";
import ExperienceDescriptionFieldScreen from "../features/create-experiences/experiece-description/screens/experience-description-field.screen";
import CreateAvailableSlotScreen from "../features/create-experiences/create-availability-slot/screens/create-availability-slot.screen";
import CheckOutPreviewScreen from "../features/create-experiences/checkout-preview/screens/check-out-preview.screen";
import PublishExperienceScreen from "../features/create-experiences/publish-experience/screens/publish-experience.screen";
import ExperienceScreen from "../features/experiences/screens/experiences.screen";
import EditExperienceScreen from "../features/edit-experiences/screens/edit-experience.screen";
import LandingHomeScreen from "../features/home/screen/landing-home.screen";
import BookingScreen from "../features/bookings/screens/bookings.screen";
import CalendarScreen from "../features/calendar/screen/calendar.screen";
import SalesTrackingScreen from "../features/sales/screen/sales-tracking.screen";
import StatementDetailScreen from "../features/sales/screen/statement-details.screen";
import SetPaxScreen from "../features/create-experiences/set-pax/screens/set-pax.screen";

function index() {
  return (
    <BrowserRouter>
      <Switch>
        <RestrictedRoute exact path="/sign_up" component={() => <SignUpScreen />} />
        <PrivateRoute
          exact
          path="/email_verification"
          component={(props) => <EmailVerificationScreen {...props} />}
        />
        <PrivateRoute exact path="/business_details" component={() => <BusinessDetailScreen />} />
        <RestrictedRoute exact path="/login" component={() => <LoginScreen />} />
        <RestrictedRoute exact path="/forgot_password" component={() => <ForgotPasswordScreen />} />
        <RestrictedRoute
          exact
          path="/reset_password"
          component={(props) => <ResetPasswordScreen {...props} />}
        />

        <AppBarLayout>
          <PublicRoute
            exact
            path="/"
            component={() => (isLogin() ? <HomeScreen /> : <LandingHomeScreen />)}
          />
          <PrivateRoute exact path="/profile" component={() => <ProfileScreen />} />
          <PrivateRoute exact path="/account_menu" component={() => <AccountMenuScreen />} />
          <PrivateRoute exact path="/sales" component={() => <SalesTrackingScreen />} />
          <PrivateRoute exact path="/sales/details" component={() => <StatementDetailScreen />} />

          <PrivateRoute
            exact
            path="/experiences/create"
            component={() => <CreateExperienceScreen />}
          />
          <PrivateRoute
            exact
            path="/experiences/create/category"
            component={() => <SelectCategoryScreen />}
          />
          <PrivateRoute
            exact
            path="/experiences/create/location"
            component={() => <SelectLocationScreen />}
          />
          <PrivateRoute
            exact
            path="/experiences/create/title"
            component={() => <ExperienceTitleField />}
          />
          <PrivateRoute
            exact
            path="/experiences/create/upload_images"
            component={() => <UploadImageScreen />}
          />
          <PrivateRoute
            exact
            path="/experiences/create/description"
            component={() => <ExperienceDescriptionFieldScreen />}
          />
          <PrivateRoute
            exact
            path="/experiences/create/important_info"
            component={() => <ImportantInfoFieldSreen />}
          />
          <PrivateRoute exact path="/experiences/create/pax" component={() => <SetPaxScreen />} />
          <PrivateRoute
            exact
            path="/experiences/create/slot"
            component={() => <CreateAvailableSlotScreen />}
          />
          <PrivateRoute
            exact
            path="/experiences/create/preview"
            component={() => <CheckOutPreviewScreen />}
          />
          <PrivateRoute
            exact
            path="/experiences/create/publish"
            component={() => <PublishExperienceScreen />}
          />
          <PrivateRoute exact path="/experiences" component={() => <ExperienceScreen />} />
          <PrivateRoute exact path="/experiences/edit" component={() => <EditExperienceScreen />} />
          <PrivateRoute exact path="/bookings" component={() => <BookingScreen />} />
          <PrivateRoute exact path="/calendar" component={() => <CalendarScreen />} />
        </AppBarLayout>
      </Switch>
    </BrowserRouter>
  );
}

export default index;
