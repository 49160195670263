import React from "react";
import { Box, styled } from "@mui/material";

import PaddedView from "../../../components/padded-view.component";
import ProfileSideMenuButtonList from "./profile-side-menu-button-list.component";
import ProfileSideMenuUserDetails from "./profile-side-menu-user-details.component";

const CenterBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const CardContainer = styled(Box)(({ theme }) => ({
  height: "500px",
  width: "75%",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  border: `1px solid ${theme.palette.colors.ui.border}`,
  minWidth: "250px",
  maxWidth: "350px",
  overflow: "hidden",
  boxShadow: `0px 0.5px 5px 0.5px ${theme.palette.colors.loading.foregroundColor}`,
}));

const BottomMenuContainer = styled(Box)({
  width: "100%",
  height: "70%",
});

function ProfileSideMenu() {
  return (
    <CenterBox>
      <CardContainer>
        <ProfileSideMenuUserDetails />
        <BottomMenuContainer>
          <PaddedView multiples={2}>
            <ProfileSideMenuButtonList />
          </PaddedView>
        </BottomMenuContainer>
      </CardContainer>
    </CenterBox>
  );
}

export default ProfileSideMenu;
