import { Box, Grid, styled } from "@mui/material";
import React from "react";
import SkeletonLoader from "../../../components/skeleton.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";

const SeperateLine = styled(Box)(({ theme }) => ({
  marginTop: "25px",
  marginBottom: "25px",
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

function ExperienceDetailsLoader() {
  return (
    <>
      <Grid item xs={12}>
        <Text>Experience Categories</Text>
        <Spacer size="xs" position="top" />
        <SkeletonLoader width="200px" height="35px" />
        <SeperateLine />
      </Grid>
      <Grid item xs={12}>
        <Text>Experience Title</Text>
        <Spacer size="xs" position="top" />
        <SkeletonLoader width="200px" height="35px" />
        <SeperateLine />
      </Grid>
      <Grid item xs={12}>
        <Text>Experience Description</Text>
        <Spacer size="xs" position="top" />
        <SkeletonLoader width="200px" height="35px" />
        <SeperateLine />
      </Grid>
      <Grid item xs={12}>
        <Text>Important Informationn</Text>
        <Spacer size="xs" position="top" />
        <SkeletonLoader width="200px" height="35px" />
        <SeperateLine />
      </Grid>
      <Grid item xs={12}>
        <Text>Experience Description</Text>
        <Spacer size="xs" position="top" />
        <SkeletonLoader width="200px" height="35px" />
        <SeperateLine />
      </Grid>
      <Grid item xs={12}>
        <Text>Address</Text>
        <Spacer size="xs" position="top" />
        <SkeletonLoader width="200px" height="35px" />
        <SeperateLine />
      </Grid>
      <Grid item xs={12}>
        <Text>Price</Text>
        <Spacer size="xs" position="top" />
        <SkeletonLoader width="200px" height="35px" />
        <SeperateLine />
      </Grid>
      <Grid item xs={12}>
        <Text>Experience Status</Text>
        <Spacer size="xs" position="top" />
        <SkeletonLoader width="200px" height="35px" />
        <SeperateLine />
      </Grid>
    </>
  );
}

export default ExperienceDetailsLoader;
