import React from "react";
import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GoogleMapReact from "google-map-react";

const StyledIcon = styled(Box)(({ theme }) => ({
  width: "30px",
  height: "30px",
  borderRadius: "15px",
  color: theme.palette.colors.brand.white,
  background: theme.palette.colors.brand.primary,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
}));

const StyledLocationOnIcon = styled(LocationOnIcon)({
  fontSize: "20px",
});

function AnyReactComponent() {
  return (
    <StyledIcon>
      <StyledLocationOnIcon />
    </StyledIcon>
  );
}

function LocationMap({ lat, long }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const defaultProps = {
    center: {
      lat: parseFloat(lat),
      lng: parseFloat(long),
    },
    zoom: 17,
  };

  return (
    <Box sx={{ width: "100%", height: isMobile ? "175px" : "300px" }}>
      <GoogleMapReact
        draggable={false}
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{
          panControl: false,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          overviewMapControl: false,
          rotateControl: false,
          zoomControlOptions: false,
        }}
      >
        <AnyReactComponent lat={parseFloat(3.154896)} lng={parseFloat(101.596759)} />
      </GoogleMapReact>
    </Box>
  );
}

LocationMap.defaultProps = {
  lat: "0.0",
  long: "0.0",
};

LocationMap.propTypes = {
  lat: PropTypes.string,
  long: PropTypes.string,
};

export default LocationMap;
