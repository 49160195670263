import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import Loader from "react-js-loader";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notifications/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { flexiCodeRedemptions } from "../../../services/booking/booking-slice.services";
import {
  experienceSelector,
  getFlexiExperiences,
} from "../../../services/experience/experience-slice.services";
import FlexiRedemptionsCodeInsertModal from "../../edit-experiences/components/flexi-redemptions/flexi-redemptions-code-insert-modal.component";
import FlexiExperienceCard from "./flexi-experience-card.component";

const CenterBox = styled(Box)({ display: "flex", justifyContent: "center", alignItems: "center" });

const codeRedemptionValidationSchema = Yup.object().shape({
  flexiCode: Yup.string().label("Code").required(),
});

function FlexiExperienceType({ setIsLoading }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const dispatch = useDispatch();
  const { getFlexiExperiencesObj } = useSelector(experienceSelector);
  const createSnackBar = useContext(SnackbarContext);

  const [showCodeRedemptionModal, setShowCodeRedemptionModal] = useState(false);
  const [redeemExperienceId, setRedeemExpereinceId] = useState(null);
  const [redemptionStep, setRedemptionStep] = useState(1);

  const flexiCodeRedemptionsHandle = (values) => {
    let redemption = "";
    if (redemptionStep === 1) {
      redemption = "preview";
    } else if (redemptionStep === 2) {
      redemption = "complete";
    }
    setIsLoading(true);
    dispatch(
      flexiCodeRedemptions({ experienceId: redeemExperienceId, redemption, ...values }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        if (redemptionStep === 1) {
          setRedemptionStep(2);
        } else if (redemptionStep === 2) {
          dispatch(getFlexiExperiences());
          setRedemptionStep(3);
        } else {
          setRedemptionStep(1);
        }
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderFlexiExperiences = () => {
    if (getFlexiExperiencesObj.status === "succeeded") {
      if (getFlexiExperiencesObj.data.length === 0) {
        return (
          <Grid item xs={12}>
            <Spacer size="xl" position="top" />
            <CenterBox>
              <Text>Your experiences will appear here when there are purchases.</Text>
            </CenterBox>
          </Grid>
        );
      }
      return getFlexiExperiencesObj.data.map((item) => (
        <Grid item xs={6} key={item.id}>
          <FlexiExperienceCard
            cardDetails={item}
            setShowCodeRedemptionModal={setShowCodeRedemptionModal}
            setRedeemExpereinceId={setRedeemExpereinceId}
          />
        </Grid>
      ));
    }
    return (
      <Grid item xs={12}>
        <Spacer size="xl" position="top" />
        <CenterBox>
          <Loader type="bubble-top" bgColor={theme.palette.colors.brand.secondary} size={40} />
        </CenterBox>
      </Grid>
    );
  };

  return (
    <>
      <Form
        validationSchema={codeRedemptionValidationSchema}
        initialValues={{
          flexiCode: "",
        }}
        onSubmit={flexiCodeRedemptionsHandle}
      >
        <FlexiRedemptionsCodeInsertModal
          isShowModal={showCodeRedemptionModal}
          setIsShowModal={setShowCodeRedemptionModal}
          redemptionStep={redemptionStep}
          setRedemptionStep={setRedemptionStep}
        />
      </Form>
      <Text variant={isMobile ? "h6" : "h4"} sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
        Flexi Booking Experiences
      </Text>
      <Spacer />
      <Grid container rowSpacing={2} columnSpacing={2}>
        {renderFlexiExperiences()}
      </Grid>
    </>
  );
}

FlexiExperienceType.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
};

export default FlexiExperienceType;
