import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  styled,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Text from "../../../../components/text.component";
import Spacer from "../../../../components/spacer.component";
import { experienceSelector } from "../../../../services/experience/experience-slice.services";
import SkeletonLoader from "../../../../components/skeleton.component";
import FormDatePicker from "../../../../components/forms/form-date-picker.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import FormLabel from "../../../../components/forms/form-label.component";
import FormTimePicker from "../../../../components/forms/form-time-picker.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";

const ContentContainer = styled(Box)(({ isMobile }) => ({
  width: "100%",
  height: isMobile ? null : "85%",
  display: "flex",
  flexDirection: "column",
  paddingBottom: isMobile ? "100px" : "20px",
}));

const TimeSlotContainer = styled(Box)(({ theme }) => ({
  alignItems: "flex-start",
  height: "100%",
  backgroundColor: theme.palette.colors.bg.secondary,
  padding: theme.padding.paddingX[1],
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const CenterRowBox = styled(Box)(({ theme }) => ({
  padding: theme.padding.paddingX[3],
  backgroundColor: theme.palette.colors.bg.primary,
  border: `0.5px solid ${theme.palette.colors.ui.border}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  display: "flex",
  alignItems: "center",
}));

const IconContainer = styled(Box)({ width: "15%", display: "flex", justifyContent: "center" });

const StyledRadioGroup = styled(RadioGroup)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

const BrandColorRadioButton = styled(Radio)(({ theme }) => ({
  "&, &.Mui-checked": {
    color: theme.palette.colors.brand.primary,
  },
}));

const AlignCenterRow = styled(Box)({ display: "flex", alignItems: "center", flexDirection: "row" });

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  fontSize: "20px",
  color: theme.palette.colors.brand.secondary,
}));

function TimeSlotForm({ handleDeleteTimeSlot }) {
  const { values, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const TitleText = "Select Availability For Your Experience";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getExperiencesDetailsObj, createMassTimeSlotObj } = useSelector(experienceSelector);

  const radioButtonOnChange = (event) => {
    setFieldValue("bookingType", event.target.value);
  };

  const multipleBookingIdentifier = () => (
    <>
      Creates a date and timeslot for your experience which users can book. You are able to select
      the capacity of the bookings for the given date and timeslot.
      <br />
      <br />
      Suitable for :
      <br />- 1 to 1 experiences
      <br />- Experience catering to a single group at a time
      <br />- Experience catering to multiple people at a time
      <br />- Experience catering to multiple separate groups of people
    </>
  );

  const renderFormAndSlots = () => {
    if (getExperiencesDetailsObj.status === "succeeded") {
      return (
        <Box sx={{ overflowY: "scroll", paddingX: isMobile ? null : theme.padding.paddingX[3] }}>
          <Grid container spacing={2}>
            <Grid item xs={isMobile ? 12 : 6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <StyledRadioGroup value={values.bookingType} onChange={radioButtonOnChange}>
                      <FormControlLabel
                        value="multiple"
                        control={<BrandColorRadioButton />}
                        label={
                          <AlignCenterRow>
                            <Text>Standard Booking</Text>
                            <Spacer size="s" position="left" />
                            <Tooltip title={multipleBookingIdentifier()} arrow>
                              <StyledInfoOutlinedIcon />
                            </Tooltip>
                          </AlignCenterRow>
                        }
                      />
                      <FormControlLabel
                        disabled={
                          getExperiencesDetailsObj.status === "succeeded" &&
                          !getExperiencesDetailsObj.data.allowCreateFlexi
                        }
                        checked={
                          getExperiencesDetailsObj.status === "succeeded" &&
                          getExperiencesDetailsObj.data.allowCreateFlexi &&
                          values.bookingType === "flexi"
                        }
                        value="flexi"
                        control={<BrandColorRadioButton />}
                        label={
                          <AlignCenterRow>
                            <Text>Flexi Booking</Text>
                            <Spacer size="s" position="left" />
                            <Tooltip
                              title="Allows users to purchase a redemption ticket for your experience. Simply key in the unique code of the redemption ticket when presented for it to be claimed."
                              arrow
                            >
                              <StyledInfoOutlinedIcon />
                            </Tooltip>
                          </AlignCenterRow>
                        }
                      />
                    </StyledRadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel>Capacity</FormLabel>
                  <Spacer size="s" position="top" />
                  <FormFieldText name="capacity" placeholder="Capacity" isTransparent={true} />
                </Grid>
                {values.bookingType === "flexi" && (
                  <Grid item xs={12}>
                    <FormLabel>Title</FormLabel>
                    <Spacer size="s" position="top" />
                    <FormFieldText name="flexiTitle" placeholder="Title" isTransparent={true} />
                  </Grid>
                )}
                {values.bookingType !== "flexi" && (
                  <Grid item xs={12}>
                    <FormLabel>Start Date</FormLabel>
                    <Spacer size="s" position="top" />
                    <FormDatePicker name="startDate" placeholder="Start Date" />
                  </Grid>
                )}
                {values.bookingType !== "flexi" && (
                  <Grid item xs={12}>
                    <FormLabel>Check-in Time</FormLabel>
                    <Spacer size="s" position="top" />
                    <FormTimePicker name="startTime" placeholder="Check-in Time" />
                  </Grid>
                )}
                {values.bookingType !== "flexi" && (
                  <Grid item xs={12}>
                    <FormLabel>End Date</FormLabel>
                    <Spacer size="s" position="top" />
                    <FormDatePicker name="endDate" placeholder="End Date" />
                  </Grid>
                )}
                {values.bookingType !== "flexi" && (
                  <Grid item xs={12}>
                    <FormLabel>Check-out Time</FormLabel>
                    <Spacer size="s" position="top" />
                    <FormTimePicker name="endTime" placeholder="Check-out Time" />
                  </Grid>
                )}
                <Grid item>
                  <Spacer size="m" position="top" />
                  <FormSubmitButton
                    disabled={
                      getExperiencesDetailsObj.status === "succeeded" &&
                      !getExperiencesDetailsObj.data.allowCreateFlexi &&
                      values.bookingType === "flexi"
                    }
                    isLoading={createMassTimeSlotObj.status === "pending"}
                  >
                    <Text type="WhiteColor" sx={{ paddingLeft: "15px", paddingRight: "15px" }}>
                      Add to timeslot
                    </Text>
                  </FormSubmitButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={isMobile ? 12 : 6} marginTop={isMobile ? "20px" : null}>
              <TimeSlotContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Text>Timeslot</Text>
                  </Grid>
                  {getExperiencesDetailsObj.data.slots.length === 0 && (
                    <Grid item xs={12}>
                      <Spacer size="l" position="top" />
                      <Text sx={{ textAlign: "center" }}>No Created Timeslot</Text>
                    </Grid>
                  )}
                  {getExperiencesDetailsObj.data.slots.map((item) =>
                    item.bookingType === "flexi" ? (
                      <Grid item xs={12} key={item.id}>
                        <CenterRowBox>
                          <Box sx={{ width: "85%" }}>
                            <Text type="BrandColor">Flexi</Text>
                            <Text type="GreyColor">{`Title: ${item.massSlot.flexiTitle}`}</Text>
                            <Text type="GreyColor">{`Capacity: ${item.massSlot.capacity.total}`}</Text>
                          </Box>
                          <IconContainer>
                            <IconButton onClick={() => handleDeleteTimeSlot(item.massSlot.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </IconContainer>
                        </CenterRowBox>
                      </Grid>
                    ) : (
                      <Grid item xs={12} key={item.id}>
                        <CenterRowBox>
                          {isMobile ? (
                            <Box sx={{ width: "85%" }}>
                              <Text type="GreyColor">Check-in:</Text>
                              <Text type="GreyColor">{item.checkIn.time}</Text>
                              <Text type="GreyColor">{item.checkIn.date}</Text>
                              <Spacer size="s" position="top" />
                              <Text type="GreyColor">Check-Out:</Text>
                              <Text type="GreyColor">{item.checkOut.time}</Text>
                              <Text type="GreyColor">{item.checkOut.date}</Text>
                              {item.bookingType === "multiple" && (
                                <>
                                  <Spacer size="s" position="top" />
                                  <Text type="GreyColor">Capacity:</Text>
                                  <Text type="GreyColor">{item.massSlot.capacity.total}</Text>
                                </>
                              )}
                            </Box>
                          ) : (
                            <Box sx={{ width: "85%" }}>
                              <Text type="GreyColor">{`${item.checkIn.date} | Check-in: ${item.checkIn.time}`}</Text>
                              <Text type="GreyColor">{`${item.checkOut.date} | Check-out: ${item.checkOut.time}`}</Text>
                              {item.bookingType === "multiple" && (
                                <Text type="GreyColor">{`Capacity: ${item.massSlot.capacity.total}`}</Text>
                              )}
                            </Box>
                          )}

                          <IconContainer>
                            <IconButton
                              onClick={() => handleDeleteTimeSlot(item.massSlot.id, "massSlot")}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </IconContainer>
                        </CenterRowBox>
                      </Grid>
                    ),
                  )}
                </Grid>
              </TimeSlotContainer>
            </Grid>
          </Grid>
        </Box>
      );
    }
    return (
      <Box sx={{ overflowY: "scroll", paddingX: theme.padding.paddingX[3] }}>
        <Grid container spacing={2}>
          <Grid item xs={isMobile ? 12 : 6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormLabel>Start Date</FormLabel>
                <Spacer size="s" position="top" />
                <SkeletonLoader height="55px" />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>Check-in Time</FormLabel>
                <Spacer size="s" position="top" />
                <SkeletonLoader height="55px" />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>End Date</FormLabel>
                <Spacer size="s" position="top" />
                <SkeletonLoader height="55px" />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>Check-out Time</FormLabel>
                <Spacer size="s" position="top" />
                <SkeletonLoader height="55px" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <TimeSlotContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Text>Timeslot</Text>
                </Grid>
                <Grid item xs={12}>
                  <SkeletonLoader height="55px" />
                </Grid>
                <Grid item xs={12}>
                  <SkeletonLoader height="55px" />
                </Grid>
                <Grid item xs={12}>
                  <SkeletonLoader height="55px" />
                </Grid>
              </Grid>
            </TimeSlotContainer>
          </Grid>
        </Grid>
      </Box>
    );
  };
  return (
    <ContentContainer isMobile={isMobile}>
      <Text
        variant="h6"
        sx={{
          fontWeight: theme.fonts.fontWeights.bold,
          textAlign: "start",
        }}
      >
        {TitleText}
      </Text>
      <Spacer size="m" position="top" />
      {renderFormAndSlots()}
    </ContentContainer>
  );
}

TimeSlotForm.propTypes = {
  handleDeleteTimeSlot: PropTypes.func.isRequired,
};

export default TimeSlotForm;
