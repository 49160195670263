import React from "react";
import { Box, Grid, IconButton, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import Text from "../../../components/text.component";
import Spacer from "../../../components/spacer.component";
import SkeletonLoader from "../../../components/skeleton.component";
import { bookingSelector } from "../../../services/booking/booking-slice.services";
import BookingDetailsCardLoader from "../loader/booking-card-loader.component";
import CancelledBookingDetailsCard from "./cancelled-booking-details-card.component";

const ModalBox = styled(Box)(({ theme, isMobile }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.brand.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
  width: "90%",
  height: "90%",
  maxWidth: "1000px",
  paddingTop: isMobile ? theme.padding.paddingX[1] : theme.padding.paddingX[0],
  paddingBottom: isMobile ? theme.padding.paddingX[1] : theme.padding.paddingX[0],
  overflow: "hidden",
}));

const PaddingBox = styled(Box)(({ theme, isMobile }) => ({
  paddingLeft: isMobile ? theme.padding.paddingX[1] : theme.padding.paddingX[0],
  paddingRight: isMobile ? theme.padding.paddingX[1] : theme.padding.paddingX[0],
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  height: "25px",
  width: "25px",
  color: theme.palette.colors.brand.secondary,
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
  height: "1px",
}));

const MenuBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  "& .MuiOutlinedInput-root": {
    " &.Mui-focused fieldset": {
      borderColor: theme.palette.colors.brand.secondary,
    },
  },
  "& .MuiSelect-iconOutlined": {
    color: theme.palette.colors.brand.secondary,
  },
}));

export default function CancelledBookingListModal({
  showModal,
  setShowModal,
  onClickExperienceCardButton,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isShowTwoItems = useMediaQuery(theme.breakpoints.down("870"));
  const { getCancelledBookingDetailsObj } = useSelector(bookingSelector);

  const getItemToShow = () => {
    if (isMobile) {
      return 12;
    }
    if (isShowTwoItems) {
      return 6;
    }
    return 4;
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const renderNoItem = () => (
    <Grid item xs={12}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Text>No Items</Text>
      </Box>
    </Grid>
  );

  const renderContent = () => {
    if (getCancelledBookingDetailsObj.status === "succeeded") {
      return (
        <Box sx={{ width: "100%", height: "100%" }}>
          <PaddingBox>
            <SpaceBetweenRowBox>
              <Box sx={{ width: "24px" }} />
              <Text variant="h5" sx={{ textAlign: "center" }}>
                {getCancelledBookingDetailsObj.data &&
                  getCancelledBookingDetailsObj.data[0] &&
                  getCancelledBookingDetailsObj.data[0].experience &&
                  getCancelledBookingDetailsObj.data[0].experience.title}
              </Text>
              <CloseIconButton onClick={handleCancel}>
                <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
              </CloseIconButton>
            </SpaceBetweenRowBox>
          </PaddingBox>
          <Spacer size="l" position="top" />

          <SeperateLine />

          <Spacer size="l" position="top" />

          <PaddingBox isMobile={isMobile} sx={{ overflowY: "scroll", height: "80%" }}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Box sx={{ width: "100%" }}>
                  <Grid container spacing={3}>
                    {getCancelledBookingDetailsObj.data.length <= 0
                      ? renderNoItem()
                      : getCancelledBookingDetailsObj.data.map((item) => (
                          <Grid item key={item.booking.id} xs={getItemToShow()}>
                            <CancelledBookingDetailsCard
                              cardDetails={item}
                              onClickExperienceCardButton={onClickExperienceCardButton}
                            />
                          </Grid>
                        ))}
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Spacer position="top" size="xxl" />
              </Grid>
            </Grid>
          </PaddingBox>
        </Box>
      );
    }
    return (
      <Box sx={{ width: "100%", height: "100%" }}>
        <PaddingBox>
          <SpaceBetweenRowBox>
            <Box sx={{ width: "24px" }} />
            <SkeletonLoader width="120px" height="35px" />
            <CloseIconButton onClick={handleCancel}>
              <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
            </CloseIconButton>
          </SpaceBetweenRowBox>
        </PaddingBox>
        <Spacer size="l" position="top" />
        <SeperateLine />
        <Spacer size="l" position="top" />
        <PaddingBox
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: isMobile ? "flex-start" : "space-between",
            alignItems: isMobile ? null : "center",
          }}
        >
          <SkeletonLoader width="150px" height="35px" />
          <MenuBox>
            <SkeletonLoader width="150px" height="35px" />
          </MenuBox>
        </PaddingBox>
        <Spacer size="m" position="top" />
        <PaddingBox isMobile={isMobile} sx={{ overflowY: "scroll", height: "80%" }}>
          <Grid item xs={12}>
            <Box sx={{ width: "100%" }}>
              <SkeletonLoader width="100px" height="35px" />
              <Spacer size="s" position="top" />
              <Grid container spacing={3}>
                <Grid item xs={getItemToShow()}>
                  <BookingDetailsCardLoader />
                </Grid>
                <Grid item xs={getItemToShow()}>
                  <BookingDetailsCardLoader />
                </Grid>
                <Grid item xs={getItemToShow()}>
                  <BookingDetailsCardLoader />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </PaddingBox>
      </Box>
    );
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox isMobile={isMobile}>{renderContent()}</ModalBox>
    </Modal>
  );
}

CancelledBookingListModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onClickExperienceCardButton: PropTypes.func.isRequired,
};
