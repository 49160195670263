import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

function TextButton({ label, onClickButton, isDisabled, isUnderline }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        "& .MuiButton-root": {
          textDecoration: "underline",
          textDecorationColor: isUnderline ? theme.palette.colors.brand.primary : "transparent",
          marginTop: "-3px",
          height: "13px",
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          textTransform: "none",
          "&:hover": {
            backgroundColor: "transparent",
            borderColor: "transparent",
            boxShadow: "none",
          },
        },
      }}
    >
      <Button
        disableRipple
        disabled={isDisabled}
        onClick={() => {
          onClickButton();
        }}
        sx={{
          boxShadow: "none",
          padding: 0,
          "&:disabled": { backgroundColor: "transparent" },
        }}
      >
        <Typography
          textAlign="start"
          sx={{
            color: isDisabled
              ? theme.palette.colors.text.disabled
              : theme.palette.colors.brand.primary,
          }}
        >
          {label}
        </Typography>
      </Button>
    </Box>
  );
}

TextButton.defaultProps = {
  isDisabled: false,
  isUnderline: false,
};

TextButton.propTypes = {
  onClickButton: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  isUnderline: PropTypes.bool,
};

export default TextButton;
