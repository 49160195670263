import React from "react";
import { Box, Button, styled } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import CreateExpBg from "../../../assets/images/mobile-create-exp-bg.png";
import Text from "../../../components/text.component";

const BackgroundImage = styled(Box)({
  backgroundImage: `url(${CreateExpBg})`,
  minHeight: "200px",
  opacity: 1,
  width: "100%",
  backgroundSize: "cover",
});

const PaddedBox = styled(Box)(({ theme }) => ({
  padding: theme.padding.paddingX[2],
  justifyContent: "flex-end",
  display: "flex",
  "& .MuiButton-root": {
    borderTopLeftRadius: theme.shape.borderRadius[0],
    borderTopRightRadius: theme.shape.borderRadius[0],
    borderBottomLeftRadius: theme.shape.borderRadius[0],
    borderBottomRightRadius: theme.shape.borderRadius[0],
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
}));

function SaveAndExitWithBg({ handleSubmit }) {
  const { values } = useFormikContext();

  return (
    <BackgroundImage>
      <PaddedBox>
        <Button
          onClick={() => {
            handleSubmit(values, { isExit: true });
          }}
          sx={{ padding: 0 }}
          disableRipple
        >
          <Text type="WhiteColor" sx={{ textAlign: "end" }}>
            Save and Exit
          </Text>
        </Button>
      </PaddedBox>
    </BackgroundImage>
  );
}

SaveAndExitWithBg.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default SaveAndExitWithBg;
